import { AvInput } from "availity-reactstrap-validation";
import React, { useEffect, useReducer, useState } from "react";
import { Button, Input } from "reactstrap";
import seasonReducer from "../seasonReducer";
import { SeasonBadge } from "../ui/SeasonBadge";
import DeleteButton from "../../../../ui/table-utils/DeleteButton";

const TableCell = ({
  handleDelete,
  handleUpdate,
  seasonType,
  seasonInTransit,
}) => {
  const [edit, setEdit] = useState(false);
  // state for editing and displaying season type..
  const initalState = {
    _id: seasonType._id,
    name: seasonType.name,
    color: seasonType.color,
    type: seasonType.type
  };
  const [rowData, dispatch] = useReducer(seasonReducer, initalState);

  useEffect(() => {
    if (!edit) {
      dispatch({ type: "RESET", payload: initalState });
    }
  }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <tr>
      {edit ? (
        <>
          <td>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <AvInput
                  className="col mr-2"
                  type="input"
                  name="seasonTypeName"
                  value={seasonType.name}
                  required
                  onChange={(event) => {
                    dispatch({
                      type: "SEASON_TYPE_NAME",
                      payload: event.target.value
                    });
                  }}
                />
                <Input
                  className="col mr-2 input-color"
                  type="color"
                  defaultValue={seasonType.color}
                  onChange={(event) => {
                    dispatch({
                      type: "SEASON_TYPE_COLOR",
                      payload: event.target.value
                    });
                  }}
                />
              </div>
            </div>
          </td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                className="link mr-2"
                disabled={!rowData.name}
                onClick={() => handleUpdate(rowData)}
              >
                Save
              </Button>
              <Button
                className="link"
                onClick={() => {
                  setEdit(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </td>
        </>
      ) : (
        <>
          <td>
            <SeasonBadge season={seasonType} />
          </td>
          <td className="text-nowrap">
            <Button
              className="p-0 mr-1 shadow-none"
              color="link"
              onClick={() => {
                setEdit(true);
              }}
              disabled={seasonInTransit}
            >
              Edit
            </Button>
            <DeleteButton
              id={seasonType._id}
              itemInTransit={seasonInTransit}
              handleDelete={handleDelete}
            />
          </td>
        </>
      )}
    </tr>
  );
};

export default TableCell;
