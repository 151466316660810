import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "react-switch";

import variables from "../../styles/bootstrap/_variables.scss";
import FlagIcon from "../ui/FlagIcon";

class StyleTest extends Component {
  constructor() {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked });
  }

  render() {
    return (
      <div className="container">
        <div className="card mb-5">
          <div className="card-header">Typography</div>
          <div className="card-body">
            <h1>H1 Lorem ipsum</h1>
            <h2>H2 Lorem ipsum</h2>
            <h3>H3 Lorem ipsum</h3>
            <p>
              Body text, lorem ipsum consectetur adipiscing elit. Cras consequat
              lacinia tortor, at sodales sem ornare a.
            </p>
            <p className="text-muted">muted text</p>
            <p className="text-black-50">semi-black text</p>
          </div>
        </div>

        <div className="card mb-5">
          <div className="card-header">Buttons</div>
          <div className="card-body">
            <div className="d-block mb-3">
              <h3 className="mb-1">Standard</h3>
              <button className="btn btn-primary mr-2">Primary</button>
              <button className="btn btn-secondary mr-2">Secondary</button>
              <button className="btn btn-danger mr-2">Danger</button>
              <button className="btn btn-gray mr-2" disabled>
                Gray
              </button>
            </div>
            <div className="d-block mb-3">
              <h3 className="mb-1">Large</h3>
              <button className="btn btn-primary btn-lg mr-2">Primary</button>
              <button className="btn btn-secondary btn-lg mr-2">
                Secondary
              </button>
              <button className="btn btn-danger btn-lg mr-2">Danger</button>
            </div>
            <div className="d-block mb-3">
              <h3 className="mb-1">Small</h3>
              <button className="btn btn-primary btn-sm mr-2">Primary</button>
              <button className="btn btn-secondary btn-sm mr-2">
                Secondary
              </button>
              <button className="btn btn-danger btn-sm">Danger</button>
            </div>
            <div className="d-block mb-3">
              <h3 className="mb-1">Large block</h3>
              <button className="btn btn-primary btn-lg btn-block mr-2">
                Primary
              </button>
            </div>

            <div className="d-block mb-3">
              <h3>Button group</h3>
              <div className="btn-group">
                <button type="button" className="btn btn-outline-light">
                  9
                </button>
                <button type="button" className="btn btn-outline-light">
                  18
                </button>
                <button type="button" className="btn btn-primary">
                  27
                </button>
              </div>
            </div>

            <div className="d-block mb-3">
              <h3>Large button group</h3>
              <div className="btn-group">
                <button type="button" className="btn btn-lg btn-outline-light">
                  9
                </button>
                <button type="button" className="btn btn-lg btn-outline-light">
                  18
                </button>
                <button type="button" className="btn btn-lg btn-primary">
                  27
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-5">
          <div className="card-header">Custom Form Elements</div>
          <div className="card-body">
            <h3 id="custom-input-fields">Custom input fields</h3>
            <div className="float-input-group mb-3">
              <input
                type="text"
                id="exampleInput1"
                aria-describedby="emailHelp"
                placeholder="This is placeholder. The label is hidden when empty"
              />
              <label htmlFor="exampleInput1">This is a label</label>
            </div>

            <div className="float-input-group mb-3">
              <input
                type="text"
                id="exampleInput2"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                defaultValue="A prefilled form input"
              />
              <label htmlFor="exampleInput2">This is a label</label>
            </div>

            <div className="float-input-group mb-3">
              <label htmlFor="dropdown">Dropdown label</label>
              <div className="dropdown" id="dropdown">
                <button
                  className="btn btn-outline-light dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dropdown button
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="#custom-input-fields">
                    Action
                  </a>
                  <a className="dropdown-item" href="#custom-input-fields">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#custom-input-fields">
                    Something else here
                  </a>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <h3>Calendars</h3>
              <div className="dropdown dropdown--calendar mb-3">
                <label htmlFor="calendar-dropdown">Calendar dropdown</label>
                <button
                  className="btn btn-outline-light dropdown-toggle"
                  type="button"
                  id="calendar-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{}}
                >
                  <FontAwesomeIcon
                    icon={["far", "calendar-alt"]}
                    className="mr-3"
                  />
                  2019-01-01
                  <FontAwesomeIcon
                    icon="arrow-right"
                    className="mx-2 text-muted"
                  />
                  2019-05-01
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="calendar-dropdown"
                >
                  <div className="dropdown-item">Action</div>
                  <div className="dropdown-item">Another action</div>
                  <div className="dropdown-item">Something else here</div>
                </div>
              </div>
              <div className="dropdown dropdown--calendar">
                <label htmlFor="calendar-dropdown">
                  Calendar dropdown large
                </label>
                <button
                  className="btn btn-outline-light btn-lg dropdown-toggle"
                  type="button"
                  id="calendar-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon
                    icon={["far", "calendar-alt"]}
                    className="mr-3"
                  />
                  2019-01-01
                  <FontAwesomeIcon
                    icon="arrow-right"
                    className="mx-2 text-muted"
                  />
                  2019-05-01
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="calendar-dropdown"
                >
                  <div className="dropdown-item">Action</div>
                  <div className="dropdown-item">Another action</div>
                  <div className="dropdown-item">Something else here</div>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <h3>Switch</h3>
              <div className="switch-group mr-3">
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={this.handleChange}
                  checked={false}
                  onColor={variables.primary}
                  offColor={variables.gray300}
                  width={50}
                  id="switch-1"
                />
                <label htmlFor="switch-1" className="text-muted">
                  Off (add .text-muted)
                </label>
              </div>
              <div className="switch-group">
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={this.handleChange}
                  checked={true}
                  onColor={variables.primary}
                  offColor={variables.gray300}
                  width={50}
                  id="switch-2"
                />
                <label htmlFor="switch-1">On</label>
              </div>
            </div>

            <div className="mb-3">
              <h3>Checkboxes</h3>
              <div className="checkbox-group d-inline-block mr-3">
                <input
                  id="checkbox-1"
                  type="checkbox"
                  value="value1"
                  defaultChecked={false}
                />
                <label htmlFor="checkbox-1">Unchecked</label>
              </div>
              <div className="checkbox-group d-inline-block">
                <input
                  id="checkbox-2"
                  type="checkbox"
                  value="value1"
                  defaultChecked
                />
                <label htmlFor="checkbox-2">Checked</label>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-5">
          <div className="card-header">Pills</div>
          <div className="card-body">
            <h3>Dismissable pill</h3>
            <div className="d-block mb-3 Pill Pill--active Pill--dismissable mr-2">
              Pill
              <div className="Pill__button--close">x</div>
            </div>

            <h3>Pills with custom content</h3>
            <div className="d-block pill pill--active mr-2">
              <FlagIcon code="gb" className="mr-1" />
              EN
              <FontAwesomeIcon icon="check-circle" className="ml-2 mr-1" />
              <span style={{ position: "relative", top: "3px" }}>*</span>
            </div>
            <div className="pill">
              <FlagIcon code="no" className="mr-1" />
              NB
            </div>
          </div>
        </div>

        <div className="card mb-5">
          <div className="card-header">Input Groups</div>
          <div className="card-body">
            <h3>Standard input group</h3>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Input group example"
                aria-label="Input group example"
                aria-describedby="btnGroupAddon"
              />
              <div className="input-group-prepend input-group-append">
                <div className="input-group-text" id="btnGroupAddon">
                  to
                </div>
              </div>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Input group example"
                aria-label="Input group example"
                aria-describedby="btnGroupAddon"
              />
            </div>

            <div className="mb-3">
              <h3>Dropdown input group</h3>
              <label htmlFor="input-group">Large dropdown input group</label>
              <div className="input-group">
                <div className="input-group-prepend" id="input-group">
                  <button
                    className="btn btn-lg btn-outline-light dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dropdown
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#input-group">
                      Action
                    </a>
                    <a className="dropdown-item" href="#input-group">
                      Another action
                    </a>
                    <a className="dropdown-item" href="#input-group">
                      Something else here
                    </a>
                    <div role="separator" className="dropdown-divider" />
                    <a className="dropdown-item" href="#input-group">
                      Separated link
                    </a>
                  </div>
                </div>
                <div className="input-group-prepend input-group-append">
                  <div className="input-group-text" id="btnGroupAddon">
                    to
                  </div>
                </div>
                <div className="input-group-append" id="input-group">
                  <button
                    className="btn btn-lg btn-outline-light dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dropdown
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#input-group">
                      Action
                    </a>
                    <a className="dropdown-item" href="#input-group">
                      Another action
                    </a>
                    <a className="dropdown-item" href="#input-group">
                      Something else here
                    </a>
                    <div role="separator" className="dropdown-divider" />
                    <a className="dropdown-item" href="#input-group">
                      Separated link
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <label htmlFor="input-group">Dropdown input group</label>
              <div className="input-group">
                <div className="input-group-prepend" id="input-group">
                  <button
                    className="btn btn-outline-light dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dropdown
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#input-group">
                      Action
                    </a>
                    <a className="dropdown-item" href="#input-group">
                      Another action
                    </a>
                    <a className="dropdown-item" href="#input-group">
                      Something else here
                    </a>
                    <div role="separator" className="dropdown-divider" />
                    <a className="dropdown-item" href="#input-group">
                      Separated link
                    </a>
                  </div>
                </div>
                <div className="input-group-prepend input-group-append">
                  <div className="input-group-text" id="btnGroupAddon">
                    to
                  </div>
                </div>
                <div className="input-group-append" id="input-group">
                  <button
                    className="btn btn-outline-light dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dropdown
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#input-group">
                      Action
                    </a>
                    <a className="dropdown-item" href="#input-group">
                      Another action
                    </a>
                    <a className="dropdown-item" href="#input-group">
                      Something else here
                    </a>
                    <div role="separator" className="dropdown-divider" />
                    <a className="dropdown-item" href="#input-group">
                      Separated link
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-5">
          <div className="card-header">Table</div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col" colSpan="3">
                      Standard
                    </th>
                    <th scope="col" colSpan="3">
                      Weekend
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">Rate</th>
                    <th scope="col">Early bird</th>
                    <th scope="col">Std.</th>
                    <th scope="col">Twilight</th>
                    <th scope="col">Early bird</th>
                    <th scope="col">Std.</th>
                    <th scope="col">Twilight</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Greenfee</td>
                    <td>€45</td>
                    <td>€45</td>
                    <td>€45</td>
                    <td>€45</td>
                    <td>€45</td>
                    <td>€45</td>
                  </tr>
                  <tr>
                    <td>Buggy</td>
                    <td>€45</td>
                    <td>€45</td>
                    <td>€45</td>
                    <td>€45</td>
                    <td>€45</td>
                    <td>€45</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StyleTest;
