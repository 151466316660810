import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import Loader from "../Loader";

function CommentListExpanded({
  user,
  comments,
  toggle,
  onAddCommentClick,
  onCloseTask,
  onEditCommentClick,
  onDeleteComment,
  newCommentLoading,
  closeTaskLoading
}) {
  const [closingTaskId, setClosingTaskId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleDeleteClick = (comment) => {
    setCommentToDelete(comment);
    toggleModal();
  };

  const handleConfirmDelete = () => {
    if (commentToDelete) {
      onDeleteComment(commentToDelete);
      setCommentToDelete(null);
      toggleModal();
    }
  };

  const Comment = ({ comment }) => {
    const isAuthor =
      comment.userFullName === `${user.firstName} ${user.lastName}`;

    return (
      <div className="card my-2">
        <div className="card-header">
          <p className="m-0">
            <strong>{comment.userFullName} </strong>•{" "}
            {moment(comment.timestamp).calendar()}
          </p>
        </div>
        <div className="card-body" style={{ whiteSpace: "pre-wrap" }}>
          {comment.body}
        </div>
        <div className="card-footer d-flex justify-content-between align-items-center">
          {comment.task ? (
            <CommentFooterWithTask
              commentId={comment._id}
              commentTask={comment.task}
            />
          ) : (
            <div className="font-weight-light font-italic">
              No action requested
            </div>
          )}
          <div>
            <button
              className="btn btn-link p-0"
              onClick={() => onEditCommentClick(comment)}
              disabled={!isAuthor}
            >
              <FontAwesomeIcon icon="edit" /> Edit
            </button>
            <button
              className="btn btn-link text-danger p-0 ml-2"
              onClick={() => handleDeleteClick(comment)}
              disabled={!isAuthor}
            >
              <FontAwesomeIcon icon="trash" /> Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  const CommentFooterWithTask = ({ commentId, commentTask }) => {
    const { _id, taskOpen, closeDate, closedBy } = commentTask;
    let footerContent;

    if (taskOpen) {
      footerContent = (
        <>
          <strong className="text-danger">
            <FontAwesomeIcon icon="flag" /> unresolved{" "}
          </strong>
          •{" "}
          {closeTaskLoading && closingTaskId === _id ? (
            <span className="d-inline-flex">
              <Loader size={25} />
            </span>
          ) : (
            <button
              className="btn btn-link border-0 p-0 d-inline text-left align-top"
              onClick={() => {
                setClosingTaskId(_id);
                onCloseTask(commentId);
              }}
            >
              mark as resolved
            </button>
          )}
        </>
      );
    } else {
      footerContent = (
        <>
          <FontAwesomeIcon icon="check" className="text-success" /> closed by{" "}
          <strong>{closedBy} </strong>• {moment(closeDate).calendar()}
        </>
      );
    }
    return <div className="font-weight-light">{footerContent}</div>;
  };

  const LoadingComment = () => (
    <div className="card my-2">
      <div className="card-header">
        <p className="m-0 font-weight-bold font-italic">Creating comment...</p>
      </div>
      <div className="card-body py-1">
        <Loader size={50} />
      </div>
      <div className="font-weight-light font-italic">No action requested</div>
    </div>
  );

  return (
    <div className="CommentsContainer CommentsContainer__expanded">
      <div className="container">
        <div className="row">
          <div className="col-8 align-self-center">
            <h4 className="m-0">Comments</h4>
          </div>
          <div className="col-2">
            <button
              type="button"
              className="btn btn-link"
              onClick={onAddCommentClick}
            >
              <FontAwesomeIcon
                icon="edit"
                size="lg"
                id="create-new-comment-icon"
              />
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="auto"
                target="create-new-comment-icon"
              >
                Create new comment
              </UncontrolledTooltip>
            </button>
          </div>
          <div className="col-2">
            <button className="btn btn-link text-danger" onClick={toggle}>
              <FontAwesomeIcon
                icon="times"
                size="lg"
                id="close-comments-icon"
              />
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="auto"
                target="close-comments-icon"
              >
                Close
              </UncontrolledTooltip>
            </button>
          </div>
        </div>
      </div>
      <div className="commentList d-flex flex-column flex-fill overflow-auto">
        {newCommentLoading && <LoadingComment />}
        {comments.map((comment, i) => (
          <Comment key={i} comment={comment} />
        ))}
      </div>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delete Comment</ModalHeader>
        <ModalBody>Are you sure you want to delete this comment?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CommentListExpanded;
