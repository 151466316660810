import * as moment from "moment";
export default function teeTimeTableReducer(state, action) {
  switch (action.type) {
    case "INIT":
    case "UPDATE":
      return action.payload;
    case "SET_DATES":
      return {
        ...state,
        startDate: moment(action.payload.startDate).startOf("day").toDate(),
        endDate: moment(action.payload.endDate).endOf("day").toDate()
      };
    case "STARTTIME": {
      return { ...state, startTime: action.payload };
    }
    case "ENDTIME": {
      return { ...state, endTime: action.payload };
    }
    case "INTERVAL": {
      return { ...state, interval: action.payload };
    }
    case "DAY_SPLIT_OVERRIDE_UPDATE": {
      return { ...state, daySplitOverride: action.payload };
    }
    case "WEEKDAY_OVERRIDE_ADD": {
      return {
        ...state,
        weekdayOverride: [...(state.weekdayOverride ?? []), action.payload]
      };
    }
    case "WEEKDAY_OVERRIDE_UPDATE": {
      let weekdayOverrideCopy = [...state.weekdayOverride];
      weekdayOverrideCopy[action.payload.index] = action.payload.value;
      return { ...state, weekdayOverride: weekdayOverrideCopy };
    }
    case "WEEKDAY_OVERRIDE_REMOVE": {
      return {
        ...state,
        weekdayOverride: state.weekdayOverride.filter(
          (_, index) => index !== action.payload
        )
      };
    }
    case "DATE_OVERRIDE_ADD": {
      return {
        ...state,
        dateOverride: [...(state.dateOverride ?? []), action.payload]
      };
    }
    case "DATE_OVERRIDE_UPDATE": {
      let dateOverrideCopy = [...state.dateOverride];
      dateOverrideCopy[action.payload.index] = action.payload.value;
      return { ...state, dateOverride: dateOverrideCopy };
    }
    case "DATE_OVERRIDE_REMOVE": {
      return {
        ...state,
        dateOverride: state.dateOverride.filter(
          (_, index) => index !== action.payload
        )
      };
    }

    default:
      return state;
  }
}
