export default function siteReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return { ...action.payload, loaded: true };
    case "NAME":
      return { ...state, name: action.payload };
    case "COMPANY_NAME":
      return { ...state, companyName: action.payload };
    case "ADDRESS":
      return { ...state, address: action.payload };
    case "CITY":
      return { ...state, city: action.payload };
    case "STATE":
      return { ...state, state: action.payload };
    case "POSTAL_CODE":
      return { ...state, postalCode: action.payload };
    case "COUNTRY":
      return { ...state, country: action.payload };
    case "COMMISSION":
      return { ...state, commission: action.payload };
    case "EMAIL":
      return { ...state, email: action.payload };
    case "ADD_EMAIL": {
      const updatedEmails = state.emails
        ? [...state.emails, action.payload]
        : [action.payload];
      return { ...state, emails: updatedEmails };
    }
    case "REMOVE_EMAIL":
      return {
        ...state,
        emails: state.emails.filter((email) => email !== action.payload)
      };
    case "UPDATE_EMAIL": {
      const { oldEmail, newEmail } = action.payload;
      const updatedEmails = state.emails.map((email) => {
        if (email === oldEmail) {
          return newEmail;
        }
        return email;
      });

      return { ...state, emails: updatedEmails };
    }
    case "HEADER_FONT":
      return {
        ...state,
        siteFonts: { ...state.siteFonts, header: action.payload }
      };
    case "BODY_FONT":
      return {
        ...state,
        siteFonts: { ...state.siteFonts, body: action.payload }
      };
    case "SOCIAL_MEDIA_INSTAGRAM":
      return {
        ...state,
        socialMedia: { ...state.socialMedia, instagramUrl: action.payload }
      };
    case "SOCIAL_MEDIA_FACEBOOK":
      return {
        ...state,
        socialMedia: { ...state.socialMedia, facebookUrl: action.payload }
      };
    case "SOCIAL_MEDIA_LINKEDIN":
      return {
        ...state,
        socialMedia: { ...state.socialMedia, linkedinUrl: action.payload }
      };
    case "PARENT_SITE_URL":
      return {
        ...state,
        parentSite: { ...state.parentSite, url: action.payload }
      };
    case "PARENT_SITE_NAME":
      return {
        ...state,
        parentSite: { ...state.parentSite, name: action.payload }
      };
    case "LOGO_TOP_SLUG":
      return { ...state, logoTopSlug: action.payload };
    case "LOGO_BOTTOM_SLUG":
      return { ...state, logoBottomSlug: action.payload };
    case "HEADER_IMAGE_SLUG":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          headerImageSlug: action.payload
        }
      };
    case "START_PAGE_LOGO_SLUG":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          logoStartPageSlug: action.payload
        }
      };
    case "HEADER_IMAGE_GRADIENT_TOP":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          headerImageGradient: {
            ...state.startPage.headerImageGradient,
            topColor: action.payload
          }
        }
      };
    case "HEADER_IMAGE_GRADIENT_BOTTOM":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          headerImageGradient: {
            ...state.startPage.headerImageGradient,
            bottomColor: action.payload
          }
        }
      };
    case "GOLF_COURSES":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          courses: action.payload
        }
      };
    case "HIGHLIGHTS":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          highlights: action.payload
        }
      };
    case "GOLF_DESTINATIONS":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          golfDestinations: action.payload
        }
      };
    case "PARTNER_LINK_IDS":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          partnerLinks: {
            ...state.startPage.partnerLinks,
            viewModelIds: action.payload
          }
        }
      };
    case "I18N":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          partnerLinks: {
            ...state.startPage.partnerLinks,
            viewModelI18n: action.payload
          }
        }
      };
    case "GOLF_DESTINATION_PILLS":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          golfDestinationPills: action.payload
        }
      };
    case "TESTIMONIALS":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          testimonials: action.payload
        }
      };
    case "MAP":
      return {
        ...state,
        startPage: {
          ...state.startPage,
          startPageMap: action.payload
        }
      };
    case "VAT_NUMBER":
      return {
        ...state,
        vatNumber: action.payload
      };
    default:
      return;
  }
}
