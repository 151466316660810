export default function GolfDestinationReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return action.payload;
    case "CATEGORIES": {
      return { ...state, categories: action.payload };
    }
    case "CURRENCY":
      return { ...state, currency: action.payload };
    case "COUNTRY":
      return { ...state, country: action.payload };
    case "COUNTRY_CODE":
      return { ...state, countryCode: action.payload };
    case "COORDINATES": {
      const { key, value } = action.payload;
      return {
        ...state,
        coordinates: {
          ...state.coordinates,
          [key]: value
        }
      };
    }
    case "CITIES":
      return { ...state, cities: action.payload };
    case "DESCRIPTION":
      return { ...state, description: action.payload };
    case "FULL_COURSE_OBJECTS": {
      return { ...state, fullCoursesObjects: action.payload };
    }
    case "HIGHLIGHTS": {
      return {
        ...state,
        highlights: action.payload
      };
    }
    case "IMAGE_ID":
      return { ...state, imgId: action.payload };
    case "I18N":
      return { ...state, i18n: action.payload };
    case "KEYFIGURES": {
      const { key, value } = action.payload;
      return {
        ...state,
        keyFigures: {
          ...state.keyFigures,
          [key]: value
        }
      };
    }
    case "NAME":
      return { ...state, name: action.payload };
    case "RADIUS":
      return { ...state, radius: action.payload };
    case "SLUG":
      return { ...state, slug: action.payload };
    case "SUMMARY":
      return { ...state, summary: action.payload };
    case "ZOOM":
      return { ...state, zoom: action.payload };
    case "PUBLISHED":
      return { ...state, published: action.payload };
    default:
      return state;
  }
}
