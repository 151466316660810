const getStyle = (textStyle) => `text-${textStyle} font-weight-bold`;
export const BookingStatus = Object.freeze({
  CANCELLED: {
    label: "Cancelled",
    style: getStyle("danger")
  },
  CANCELLED_BY_CLUB: {
    label: "Cancelled by club",
    style: getStyle("danger")
  },
  EXPIRED: {
    label: "Expired",
    style: getStyle("danger")
  },
  FAILED: {
    label: "Failed",
    style: getStyle("danger")
  },
  BOOKED: {
    label: "Confirmed",
    style: getStyle("success")
  },
  PRE_BOOKED: {
    label: "Pre-booked",
    style: getStyle("warning")
  },
  NA: {
    label: "No valid status",
    style: getStyle("danger")
  }
});
