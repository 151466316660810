// Vendor
import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import "easymde/dist/easymde.min.css";

// App
import SelectTable from "../ui/SelectTable";
import LocaleHelper from "../../common/LocaleHelper";
import { LanguageButton } from "../ui/FunctionalComponents";
import { PARTNER_LINKS } from "../../common/Queries";

const PartnerLinkSelect = (props) => {
  const locales = LocaleHelper.getLocales();
  const { i18n, onChange, partnerLinkIds } = props;
  const [selectedLang, setSelectedLang] = useState(locales[0].lang);
  const [selectedIds, setSelectedIds] = useState(
    selectedLang === "en-GB" ? partnerLinkIds : i18n[selectedLang].ids
  );

  const handleSelectionOfPartner = (value) => {
    if (selectedLang === "en-GB") {
      onChange({ key: "partnerLinkIds", value });
    } else {
      let i18nCopy = { ...i18n };
      i18nCopy[selectedLang] = { ...i18nCopy[selectedLang] };
      i18nCopy[selectedLang].ids = value;
      onChange({ key: "i18n", value: i18nCopy });
    }
  };

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
  };

  useEffect(() => {
    setSelectedIds(
      selectedLang === "en-GB" ? partnerLinkIds : i18n[selectedLang].ids
    );
  }, [selectedLang, partnerLinkIds, i18n]);

  return (
    <div className="ClubCoursePresentation mt-5">
      <div className="ClubCoursePresentation__header d-flex">
        <h3>Partner Links</h3>
      </div>

      <div className="ClubCoursePresentation__flag-btns mb-2">
        <LanguageButton
          flagIcon="gb"
          label="EN"
          selected={selectedLang === "en-GB"}
          onClick={() => handleLanguageChange("en-GB")}
        />
        {locales.map(
          (locale, index) =>
            i18n[locale.lang] && (
              <LanguageButton
                flagIcon={locale.flagIcon}
                label={locale.lang.substr(0, 2).toUpperCase()}
                key={index}
                selected={locale.lang === selectedLang}
                onClick={() => handleLanguageChange(locale.lang)}
              />
            )
        )}
      </div>

      <Row form className="mb-2">
        <SelectTable
          onChange={handleSelectionOfPartner}
          selectQuery={PARTNER_LINKS}
          listItems={selectedIds}
          selectContainerClass="col-6"
          listContainerClass="col-12"
        />
      </Row>
    </div>
  );
};

export default PartnerLinkSelect;
