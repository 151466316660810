import React, { useContext, useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import { getCommissionTypeLabel } from "../../../SeasonHelper";
import { SeasonScheduleContext } from "../../SeasonSchedule";

/**
 * @param { Object } props
 * @param { string } props.id
 * @param { string } props.name
 * @param { string } props.value
 * @param { string } [props.validateValue]
 * @param { VoidCallback<string> } props.onChange
 */
export function RateNumberEdit(props) {
  const {
    id,
    name,
    value: initialValue,
    validateValue,
    onChange,
    disabled,
    positiveNumbersOnly,
    required
  } = props;
  function invalidate() {
    addInvalidField(id);
    setInvalid(true);
  }

  function validate() {
    removeInvalidField(id);
    setInvalid(false);
  }
  // Use the context from Season.js
  const { addInvalidField, removeInvalidField } = useContext(
    SeasonScheduleContext
  );
  const [value, setValue] = useState(initialValue);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    // update from initial value if not same
    if (initialValue !== value) setValue(initialValue);
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const hasValidateValue = validateValue && !isNaN(validateValue);
    // invalidate if dependency field has value but this doesn't (and if not already invalid)
    // or if positive numbers only (and if not already invalid)
    if (
      (hasValidateValue && !value && !invalid) ||
      (positiveNumbersOnly && value < 0 && !invalid) ||
      (required === true && !invalid && !value)
    )
      invalidate();
    // validate if has value and not already valid
    else if (value && invalid) {
      if (typeof value === "number") {
        if (value > 0) validate();
      }
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const hasValidateValue = validateValue && !isNaN(validateValue);
    // invalidate if dependency field has value but this doesn't (and if not already invalid)
    if (hasValidateValue && !value && !invalid && required === false)
      invalidate();
    // validate if dependency field doesn't have value and is invalid
    else if (!hasValidateValue && invalid && !required) validate();
  }, [validateValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (required && !value && !invalid) {
      invalidate();
    } else if (required === false || (required === true && value)) validate();
  }, [required]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <input
        className={`form-control form-control-sm ${
          invalid ? "is-invalid" : ""
        }`}
        type="number"
        name={name}
        min={0}
        disabled={disabled}
        value={value || ""}
        onKeyPress={(event) => {
          const { value } = event.target;
          if (event.charCode === 13) {
            // don't submit form if field is missing value but validate value is set
            if (value === "" && validateValue && !isNaN(validateValue)) return;

            onChange(value);
          }
        }}
        onChange={(event) => {
          const { value } = event.target;
          setValue(value);
          onChange(value);
        }}
      />
      {invalid && (
        <span className="invalid-feedback">This field is required</span>
      )}
    </>
  );
}

export function RateNumberRead({ ratePrice, field, customValue }) {
  let value = customValue || (ratePrice && ratePrice[field]) || undefined;
  return value ? value : <span className="text-muted">-</span>;
}
export const DropDownEdit = (props) => {
  const {
    value: initialValue,
    required,
    isOverridableByCourse,
    onChange,
    id
  } = props;
  // Use the context from Season.js
  const { addInvalidField, removeInvalidField } = React.useContext(
    SeasonScheduleContext
  );

  const [value, setValue] = useState(initialValue);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    // update from initial value if not same
    if (initialValue !== value) setValue(initialValue);
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  function invalidate() {
    addInvalidField(id);
    setInvalid(true);
  }

  function validate() {
    removeInvalidField(id);

    setInvalid(false);
  }
  useEffect(() => {
    if ((required && value?.length < 0 && !invalid) || (required && !value)) {
      invalidate();
    } else if (
      (required && value?.length > 0) ||
      !required ||
      isOverridableByCourse
    ) {
      validate();
    }
  }, [required, value]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <UncontrolledDropdown
        className={`d-inline-block ${invalid ? "is-invalid" : ""} `}
        disabled={isOverridableByCourse}
      >
        <DropdownToggle
          color={!isOverridableByCourse ? "outline-light " : "light"}
          // for border to look nice
          style={
            isOverridableByCourse
              ? { border: "1px solid #747474" }
              : invalid
              ? { border: "1px solid #ff5267" }
              : {}
          }
          caret
          disabled={isOverridableByCourse}
        >
          {getCommissionTypeLabel(value)}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => onChange("")}>Select</DropdownItem>
          <DropdownItem onClick={() => onChange("FIXED")}>Fixed</DropdownItem>
          <DropdownItem onClick={() => onChange("PERCENTAGE")}>%</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {invalid && <div className="text-danger">This field is required</div>}
    </>
  );
};
