// Vendor
import React, { useState } from "react";
import { Button, Table } from "reactstrap";

// App
import FlagIcon from "../FlagIcon";

/**
 * @param { Object } props
 * @param { VoidCallback<{key: string, value: string}|{key: "i18n", value: i18nObj} } props.onChange
 * @param { string } props.targetKey
 * @param { string } [props.value=""]
 * @param { i18nObj } props.i18n
 * @param { string } [props.placeholderText="No value found..."]
 */
export function SingleTranslationTable(props) {
  const { onChange, targetKey, value, i18n, placeholderText } = props;

  const [selectedLang, setSelectedLang] = useState("");
  const [currentText, setCurrentText] = useState("");

  /** @param { string } language */
  function changeActiveLang(language) {
    setSelectedLang(language);
    setCurrentText(language === "en-GB" ? value : i18n[language][targetKey]);
  }

  /** @param { React.ChangeEvent<HTMLInputElement>} e */
  function onTextChangeHandler(e) {
    setCurrentText(e.target.value);
  }

  /** @param { React.KeyboardEvent<HTMLInputElement>} e */
  function onKeyDownHandler(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    } else if (e.key === "Escape") {
      e.preventDefault();
      setSelectedLang("");
    }
  }

  function handleSubmit() {
    if (selectedLang === "en-GB") {
      onChange({ key: targetKey, value: currentText });
    } else {
      const i18nCopy = { ...i18n };
      i18nCopy[selectedLang] = {
        ...i18nCopy[selectedLang],
        [targetKey]: currentText
      };
      onChange({ key: "i18n", value: i18nCopy });
    }
    setSelectedLang("");
  }

  /** @param { { language: string } } param */
  const InactiveRow = ({ language }) => {
    const text = language === "en-GB" ? value : i18n[language][targetKey];
    return (
      <tr>
        <td style={{ width: "71px" }}>
          <FlagIcon
            code={language.substring(3).toLowerCase()}
            className="mr-1"
          />
          {language.substring(0, 2).toUpperCase()}
        </td>
        <td className={`text-left${text ? "" : " text-muted"}`}>
          <div className="w-100 text-truncate">{text || placeholderText}</div>
        </td>
        <td style={{ width: "141px" }}>
          <Button
            className="float-right d-inline-flex p-0"
            color="link"
            onClick={() => changeActiveLang(language)}
          >
            Edit
          </Button>
        </td>
      </tr>
    );
  };

  /** @param { { language: string } } param */
  const ActiveRow = ({ language }) => (
    <tr>
      <td style={{ width: "71px" }}>
        <FlagIcon code={language.substring(3).toLowerCase()} className="mr-1" />
        {language.substring(0, 2).toUpperCase()}
      </td>
      <td>
        <input
          autoFocus
          value={currentText}
          onKeyDown={onKeyDownHandler}
          onChange={onTextChangeHandler}
          className="pl-2 w-100"
        />
      </td>
      <td style={{ width: "141px" }}>
        <Button
          className="d-inline p-0 pl-2 pr-2"
          color="primary"
          onClick={handleSubmit}
        >
          OK
        </Button>
        <Button
          className="btn btn-outline-light d-inline p-0 pl-2 pr-2 ml-2"
          onClick={() => setSelectedLang("")}
        >
          Cancel
        </Button>
      </td>
    </tr>
  );

  return (
    <Table
      borderless
      striped
      className="border w-100"
      style={{ tableLayout: "fixed" }}
    >
      <tbody>
        {selectedLang === "en-GB" ? (
          <ActiveRow language="en-GB" />
        ) : (
          <InactiveRow language="en-GB" />
        )}
        {Object.keys(i18n).map((lang, i) =>
          lang === selectedLang ? (
            <ActiveRow language={lang} key={i} />
          ) : (
            <InactiveRow language={lang} key={i} />
          )
        )}
      </tbody>
    </Table>
  );
}
