import React, { useContext } from "react";
import { LayoutContext } from "../app/Layout";
import { InvoiceContext } from "./Invoice";
import { useMutation } from "@apollo/client";
import {
  INVOICE_ADD_COMMENT,
  INVOICE_CLOSE_COMMENT_TASK,
  INVOICE_DELETE_COMMENT,
  INVOICE_EDIT_COMMENT
} from "../../common/Mutations";
import { CommentListBase } from "../ui/Comments/CommentListBase";

const InvoiceComments = ({ comments }) => {
  const { addAlert } = useContext(LayoutContext);
  const { invoice, dispatch } = useContext(InvoiceContext);

  const [createComment, { loading: createCommentLoading }] = useMutation(
    INVOICE_ADD_COMMENT,
    {
      onCompleted: (data) =>
        dispatch({ type: "COMMENT_ADD", payload: data.invoiceAddComment }),
      onError: (err) => handleError(err, "Error: Could not create comment")
    }
  );

  const [editComment, { loading: editCommentLoading }] = useMutation(
    INVOICE_EDIT_COMMENT,
    {
      onCompleted: (data) => {
        dispatch({
          type: "COMMENTS_UPDATE",
          payload: data.invoiceEditComment.comment
        });
      },
      onError: (err) => handleError(err, "Error: Could not edit comment")
    }
  );

  const [deleteComment, { loading: deleteCommentLoading }] = useMutation(
    INVOICE_DELETE_COMMENT,
    {
      onCompleted: (data) => {
        dispatch({
          type: "COMMENT_DELETE",
          payload: data.invoiceDeleteComment.comment
        });
      },
      onError: (err) => handleError(err, "Error: Could not delete comment")
    }
  );

  const [closeCommentTask, { loading: closeCommentTaskLoading }] = useMutation(
    INVOICE_CLOSE_COMMENT_TASK,
    {
      onCompleted: (data) =>
        dispatch({
          type: "COMMENT_CLOSE_TASK",
          payload: data.invoiceCloseCommentTask
        }),
      onError: (err) => handleError(err, "Error: Could not close task")
    }
  );

  const handleError = (error, alertMessage) => {
    console.error(error);
    addAlert({
      color: "danger",
      message: alertMessage
    });
  };
  const handleNewComment = (input) => {
    createComment({
      variables: {
        invoiceId: invoice._id,
        ...input
      }
    });
  };

  const handleEditComment = (input) => {
    editComment({
      variables: {
        invoiceId: invoice._id,
        ...input
      }
    });
  };

  const handleDeleteComment = (comment) => {
    deleteComment({
      variables: {
        invoiceId: invoice._id,
        ...comment
      }
    });
  };

  const handleCloseCommentTask = (input) => {
    closeCommentTask({
      variables: input
    });
  };

  return (
    <CommentListBase
      comments={comments}
      createComment={createComment}
      closeCommentTask={closeCommentTask}
      onNewComment={handleNewComment}
      onSaveEditComment={handleEditComment}
      onDeleteComment={handleDeleteComment}
      onCloseTask={handleCloseCommentTask}
      loading={
        createCommentLoading ||
        editCommentLoading ||
        closeCommentTaskLoading ||
        deleteCommentLoading
      }
    />
  );
};

export default InvoiceComments;
