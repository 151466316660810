export const CLOUDINARY_URL = "https://res.cloudinary.com/greenfee365";

class CloudinaryHelper {
  static COURSE_IMG_PATH = "courses";
  static GOLF_AREA_IMG_PATH = "areas";
  static FALLBACK_IMG = "fallback.png";
  static COVER_IMG = "cover.jpg";

  static FALLBACK_IMG_URL = `${CLOUDINARY_URL}/images/upload/fallback.png`;
  static COVER_IMG_URL = `${CLOUDINARY_URL}/images/upload/cover.jpg`;

  static image(publicId, transformations, format) {
    return `${CLOUDINARY_URL}/image/upload/${
      transformations ? transformations + "/" : ""
    }${publicId}${format ? "." + format : ""}`;
  }

  static courseImage(slug, transformations, format) {
    return this.image(
      `${this.COURSE_IMG_PATH}/${slug}/${slug}`,
      transformations,
      format
    );
  }

  static areaImage(slug, transformations, format) {
    return this.image(
      `${this.GOLF_AREA_IMG_PATH}/${slug}/${slug}`,
      transformations,
      format
    );
  }
}

export default CloudinaryHelper;
