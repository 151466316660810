// Vendor
import React, { Fragment, useContext, useState } from "react";
import { Table, Popover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { PARTNER_LINKS } from "../../common/Queries";
import { DELETE_PARTNER_LINK } from "../../common/Mutations";
import Loader from "../ui/Loader";
import {
  Button
} from "reactstrap";
import { LayoutContext } from "../app/Layout";

const PartnerLinks = () => {
  const { addAlert } = useContext(LayoutContext);
  const [popoverOpen, setPopoverOpen] = useState("");
  const [deletePartnerLink] = useMutation(
    DELETE_PARTNER_LINK,
    {
      onCompleted: res => {
        addAlert({
          color: "success",
          message: "PartnerLink deleted."
        });
      },
      onError: () =>
        addAlert({ color: "danger", message: "Could not delete PartnerLink." })
    }
  );

  return (
    <div className="mb-4">
      <h1>Partner links</h1>

      <Query query={PARTNER_LINKS} fetchPolicy="cache-and-Link">
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error)
            return (
              <div className="text-danger">Error fetching partner links</div>
            );

          const partnerLinks = data.partnerLinks;
          return (
            <Table borderless striped>
              <Fragment>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {partnerLinks.length > 0 ? (
                    partnerLinks.map((partnerLink, i) => {
                      const popoverId = `remove-partnerLink-popover-${i}`;
                      return (
                        <tr key={i}>
                          <td>{partnerLink.name}</td>
                          <td>
                            <Button
                              className="p-0 text-muted d-inline-flex"
                              color="link"
                              id={popoverId}
                              onClick={() => setPopoverOpen(popoverId)}
                            >
                              <FontAwesomeIcon icon="trash-alt" />
                            </Button>
                            <Popover
                              target={popoverId}
                              isOpen={popoverOpen === popoverId}
                              placement="left"
                              toggle={() => setPopoverOpen("")}
                              className="p-3"
                            >
                              <PopoverBody>
                                Are you sure?
                                <Button
                                  color="link"
                                  onClick={() => {
                                    deletePartnerLink({ variables: { partnerLinkId: partnerLink._id }, refetchQueries: [{ query: PARTNER_LINKS }] });
                                    setPopoverOpen("");
                                  }}>
                                  Delete
                                </Button>
                              </PopoverBody>
                            </Popover>
                            <Link className="btn btn-link" to={`/partner-Link/${partnerLink._id}`}>Edit</Link>
                          </td>
                        </tr>
                      )
                    })
                  ) : (<tr>
                    <td colSpan="5" className="text-muted text-center">No partnerLinks found</td>
                  </tr>)}
                </tbody>
              </Fragment>
            </Table>
          );
        }}
      </Query>

      <div className="Save-container">
        <div className="Save-container__phantom" />
        <div className="Save-container__main">
          <Link to="/partner-Link" id="btn-new" className="btn btn-secondary btn-lg">
            Create new
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PartnerLinks;
