const SeasonRatePriceCollection = Object.freeze([
  {
    rateType: "GREENFEE_9H",
    name: "Greenfee 9 holes",
    multiplier: 1
  },
  {
    rateType: "ONE_GREENFEE_ONE_BUGGY_9H",
    name: "1 greenfee + buggy 9 holes",
    multiplier: 1
  },
  {
    rateType: "TWO_GREENFEE_9H",
    name: "2 greenfees 9 holes",
    multiplier: 2
  },
  {
    rateType: "TWO_GREENFEE_ONE_BUGGY_9H",
    name: "2 greenfees + buggy 9 holes",
    multiplier: 2
  },
  {
    rateType: "THREE_GREENFEE_9H",
    name: "3 greenfees 9 holes",
    multiplier: 3
  },
  {
    rateType: "FOUR_GREENFEE_9H",
    name: "4 greenfees 9 holes",
    multiplier: 4
  },
  {
    rateType: "FOUR_GREENFEE_TWO_BUGGY_9H",
    name: "4 greenfees + 2 buggies 9 holes",
    multiplier: 4
  },
  {
    rateType: "GREENFEE_18",
    name: "Greenfee 18 holes",
    multiplier: 1
  },
  {
    rateType: "ONE_GREENFEE_ONE_BUGGY",
    name: "1 greenfee + buggy",
    multiplier: 1
  },
  {
    rateType: "TWO_GREENFEE",
    name: "2 greenfees",
    multiplier: 2
  },
  {
    rateType: "TWO_GREENFEE_ONE_BUGGY",
    name: "2 greenfees + buggy",
    multiplier: 2
  },
  {
    rateType: "THREE_GREENFEE",
    name: "3 greenfees",
    multiplier: 3
  },
  {
    rateType: "FOUR_GREENFEE",
    name: "4 greenfees",
    multiplier: 4
  },
  {
    rateType: "FOUR_GREENFEE_TWO_BUGGY",
    name: "4 greenfees + 2 buggies",
    multiplier: 4
  }
]);

export default SeasonRatePriceCollection;
