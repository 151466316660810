import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { FormGroup, Modal, Label, ModalBody, ModalHeader } from "reactstrap";
import { ClubCourseContext } from "../../ClubCourse";
import { getFormattedSeasonType } from "../SeasonHelper";
import seasonReducer from "../seasonReducer";
import SeasonTypeList from "./SeasonTypeList";

const SeasonEditTypesModal = ({ isOpen, onClose, toggle }) => {
  const initalState = {
    name: "",
    type: "",
    color: "#FFA500"
  };
  const [state, dispatch] = useReducer(seasonReducer, initalState);
  const {
    state: courseState,
    dispatch: courseDispatch,
    saveCourse
  } = useContext(ClubCourseContext);

  // state for toggling input for adding new season types
  const [saveState, setSaveState] = useState(false);

  // state used to trigger save handler in useEffect
  const [toggleAdd, setToggleAdd] = useState(false);

  const handleToggleAdd = () => {
    setToggleAdd(!toggleAdd);
  };

  const onSaveNewSeasonType = () => {
    state.type = getFormattedSeasonType(state.name);

    courseDispatch({
      type: "SEASON_TYPES_ADD",
      payload: {
        seasonType: state,
        setSaveState
      }
    });
  };

  const handleEditSeasonType = (rowData) => {
    rowData.type = getFormattedSeasonType(rowData.name);
    courseDispatch({
      type: "SEASON_TYPES_UPDATE",
      payload: {
        data: rowData,
        setSaveState
      }
    });
  };
  const handleDeleteSeasonType = (id) => {
    courseDispatch({
      type: "SEASON_TYPES_DELETE",
      payload: {
        id,
        setSaveState
      }
    });
  };

  // this functions is to remove the season type from state when calling saveCourse from parent
  const awaitSave = async () => {
    const result = await saveCourse();
    // if there is an error saving course, remove the season type from state
    if (!result.data?.updateCourse.ok || !result.data) {
      courseDispatch({ type: "REMOVE_SEASON_TYPE" });
    }
  };
  // used for saving course when saveState is called from reducer
  // can't saveCourse() in validSubmit because dispatch is async so this is a must
  useEffect(() => {
    if (saveState) {
      awaitSave();
      setSaveState(false);
      handleToggleAdd();
      onClose();
    }
  }, [courseState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // reseting state when closing modal or toggling edit
    if (!isOpen || !toggleAdd) {
      setToggleAdd(false);
      dispatch({ type: "RESET", payload: initalState });
    }
  }, [isOpen, toggleAdd]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal toggle={toggle} className="SeasonEditModal" isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Edit season types</ModalHeader>
      <ModalBody>
        <AvForm
          beforeSubmitValidation={(event) => event.stopPropagation()}
          onValidSubmit={onSaveNewSeasonType}
        >
          {toggleAdd ? (
            <div className="d-flex flex-row align-items-center">
              <div className="d-flex">
                <div className="mr-2">
                  <Label> Season type name:</Label>
                  <FormGroup className="mb-0">
                    <AvField
                      type="text"
                      name="SeasonTypeName"
                      placeholder="New season type"
                      required
                      onChange={(event) => {
                        dispatch({
                          type: "SEASON_TYPE_NAME",
                          payload: event.target.value
                        });
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="mr-2">
                  <Label> Season type color:</Label>
                  <AvField
                    type="color"
                    name="SeasonTypeColor"
                    className="input-color"
                    value={state.color}
                    list="default-colors"
                    onChange={(event) => {
                      dispatch({
                        type: "SEASON_TYPE_COLOR",
                        payload: event.target.value
                      });
                    }}
                  />
                  <datalist id="default-colors">
                    <option>#f1f1f1</option>
                    <option>#3dbfbe</option>
                    <option>#ff5267</option>
                    <option>#f9f06b </option>
                    <option>#ffbe6f</option>
                    <option>#dc8add </option>
                    <option>#cdab8f </option>
                    <option>#abd918 </option>
                    <option>#ff3cc6 </option>
                    <option>#FFA500 </option>
                  </datalist>
                </div>
              </div>
              <div className="mt-2 ml-4">
                <button className="btn btn-primary mr-2" type="submit">
                  Add
                </button>
                <button className="btn btn-danger" onClick={handleToggleAdd}>
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <div
                className="btn btn-primary"
                onClick={() => handleToggleAdd()}
              >
                Add new
              </div>
            </div>
          )}
        </AvForm>

        <SeasonTypeList
          handleEdit={handleEditSeasonType}
          handleDelete={handleDeleteSeasonType}
        />
      </ModalBody>
    </Modal>
  );
};

export default SeasonEditTypesModal;
