// Vendor
import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";

// App
import CountryCollection from "../../common/CountryCollection";
import {
  PHONE_VALIDATOR,
  URL_VALIDATOR,
  EMAIL_VALIDATOR,
  EMAIL_REGEX
} from "../../common/Validators";
import { InputField, LanguageDropdown } from "./FunctionalComponents";
import FlagIcon from "./FlagIcon";
import PropTypes from "prop-types";
import EmailsList from "./EmailsList";
import { LayoutContext } from "../app/Layout";
import Info from "./Info";
import ClubPanelUsersSelect from "./ClubPanelUsersSelect";

function ContactInfo(props) {
  function handleCountryChange(item) {
    setSelectedCountry(item);
    dispatch({ type: "COUNTRY", payload: item.name });
    dispatch({ type: "COUNTRY_CODE", payload: item.code });
  }

  function getCountryItem(country) {
    return {
      name: country.name,
      code: country.code,
      jsx: (
        <>
          <FlagIcon code={country.code} className="mr-1" />
          <span>{country.name}</span>
        </>
      )
    };
  }

  function toggleCountryOpen() {
    setCountryOpen(!countryOpen);
  }

  const [countryOpen, setCountryOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [emailInput, setEmailInput] = useState("");

  const { state, dispatch } = useContext(props.context);
  const {
    address,
    city,
    country,
    phone,
    website,
    dailyOrderEmail,
    invoiceEmails,
    invoiceEmail,
    postalCode,
    clubPanelUsers
  } = state;

  const { addAlert } = useContext(LayoutContext);

  const validateEmail = () => {
    if (!emailInput) return addAlert({ color: "danger", message: "No email" });
    if (EMAIL_REGEX.test(emailInput) === false)
      return addAlert({ color: "danger", message: "Invalid email" });
    const exists = invoiceEmails?.find((email) => email === emailInput);
    if (!exists) dispatch({ type: "ADD_INVOICE_EMAIL", payload: emailInput });
    setEmailInput("");
  };
  useEffect(() => {
    let match = CountryCollection.find((c) => c.name === country);
    if (match) setSelectedCountry(getCountryItem(match));
    setCountries(CountryCollection.map((c) => getCountryItem(c)));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="ContactInfo mt-3">
      {props.showHeader && <h3>Contact info</h3>}
      <Row>
        <Col md={4}>
          <InputField
            label="Email email"
            value={emailInput}
            onChange={(e) => {
              setEmailInput(e.value);
            }}
            validate={EMAIL_VALIDATOR}
            required={false}
          />
          <div
            className="btn btn-primary"
            style={{ marginTop: "auto" }}
            onClick={validateEmail}
          >
            Add new invoice email
          </div>
        </Col>
        <Col>
          <EmailsList
            emailList={invoiceEmails ?? []}
            onDelete={(email) =>
              dispatch({ type: "REMOVE_INVOICE_EMAIL", payload: email })
            }
            label="Additional Invoice emails"
            tableClassNames="border"
          />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col md="4">
          <div className="d-flex w-100">
            <ClubPanelUsersSelect
              className="w-100 mr-2"
              onChange={(user) => {
                dispatch({ type: "ADD_CLUB_PANEL_USER", payload: {
                  _id: user.value,
                  email: user.label
                } });
              }}
            />
            <div className="mt-2">
              <Info
                value="Only users in this list will be able to access the club panel and
            manage offline bookings made on these courses"
              />
            </div>
          </div>
        </Col>
        <Col>
          
          <EmailsList
            emailList={clubPanelUsers?.map((user) => user.email) ?? []}
            onDelete={(user) => {
              dispatch({ type: "REMOVE_CLUB_PANEL_USER", payload: user });
            }}
            label="Club Panel users"
            tableClassNames="table-danger table-striped border"
          />
        </Col>
      </Row>
      <Row form>
        <Col md={4}>
          <InputField
            targetKey="dailyOrderEmail"
            label="Daily order email"
            value={dailyOrderEmail}
            onChange={(event) =>
              dispatch({ type: "DAILY_ORDER_EMAIL", payload: event.value })
            }
            validate={EMAIL_VALIDATOR}
            required={false}
          />
        </Col>
        <Col>
          <InputField
            targetKey="invoiceEmail"
            label="Invoice email"
            value={invoiceEmail}
            onChange={(e) => {
              dispatch({ type: "INVOICE_EMAIL", payload: e.value });
            }}
            validate={EMAIL_VALIDATOR}
            required={false}
          />
        </Col>

        <Col md={4}>
          <InputField
            targetKey="address"
            label="Address"
            value={address}
            onChange={(event) =>
              dispatch({ type: "ADDRESS", payload: event.value })
            }
            validate={{ required: true }}
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="postalCode"
            label="Postal Code"
            value={postalCode}
            onChange={(event) =>
              dispatch({ type: "POSTAL_CODE", payload: event.value })
            }
            validate={{ required: false }}
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="city"
            label="City"
            value={city}
            onChange={(event) =>
              dispatch({ type: "CITY", payload: event.value })
            }
            validate={{ required: true }}
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="state"
            label="State"
            value={state.state}
            onChange={(event) =>
              dispatch({ type: "STATE", payload: event.value })
            }
            validate={{ required: true }}
          />
        </Col>
        <Col md={4}>
          <LanguageDropdown
            text={country}
            label="Country"
            items={countries}
            selectedItem={selectedCountry}
            onChange={handleCountryChange}
            id="club-country"
            isOpen={countryOpen}
            toggle={toggleCountryOpen}
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="phone"
            label="Phone"
            value={phone}
            onChange={(event) =>
              dispatch({ type: "PHONE", payload: event.value })
            }
            validate={PHONE_VALIDATOR}
            placeholder="Phone: +12 345 67 89"
            required={false}
          />
        </Col>
        {props.showWebsite && (
          <Col md={4}>
            <InputField
              targetKey="website"
              label="Website"
              value={website}
              onChange={(event) =>
                dispatch({ type: "WEBSITE", payload: event.value })
              }
              validate={URL_VALIDATOR}
              required={false}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}

ContactInfo.propTypes = {
  context: PropTypes.object.isRequired,
  showWebsite: PropTypes.bool
};

ContactInfo.defaultProps = {
  showWebsite: true
};

export default ContactInfo;
