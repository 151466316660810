// Vendor
import React, { useEffect, useReducer, useContext, createContext } from "react";
import { withRouter } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AvForm } from "availity-reactstrap-validation";
import { Button, FormGroup } from "reactstrap";

// App
import { PARTNER_NETWORK } from "../../common/Queries";
import {
  UPDATE_PARTNER_NETWORK,
  CREATE_PARTNER_NETWORK
} from "../../common/Mutations";
import partnerNetworkReducer from "./PartnerNetworkReducer";
import { InputField } from "../ui/FunctionalComponents";
import { LayoutContext } from "../app/Layout";
import { PartnerNetworkUserTable } from "./subcomponents/PartnerNetworkUserTable";
import { PartnerNetworkAddEmails } from "./subcomponents/PartnerNetworkAddEmails";

const defaultState = {
  _id: "",
  name: "",
  value: 0,
  users: [],
  fallback: [],
  loaded: false,
  loading: false
};
export const PartnerNetworkContext = createContext(defaultState);

const PartnerNetwork = (props) => {
  const { addAlert } = useContext(LayoutContext);
  const onSave = () => {
    const partnerNetworkInput = {
      name: state.name,
      value: parseFloat(state.value),
      emails: state.users.map(({ email, sendWelcomeEmail }) => ({
        email,
        sendWelcomeEmail
      }))
    };

    state._id
      ? updatePartnerNetwork({
          variables: { input: partnerNetworkInput, partnerNetworkId: state._id }
        })
      : createPartnerNetwork({ variables: { input: partnerNetworkInput } });
  };

  const [state, dispatch] = useReducer(partnerNetworkReducer, defaultState);

  const [updatePartnerNetwork, { loading: mutationLoading }] = useMutation(
    UPDATE_PARTNER_NETWORK,
    {
      onCompleted: (res) => {
        if (!res.updatePartnerNetwork.ok) {
          return addAlert({
            color: "danger",
            message: "Could not save PartnerNetwork."
          });
        }
        dispatch({
          type: "INIT",
          payload: res.updatePartnerNetwork.partnerNetwork
        });
        addAlert({
          color: "success",
          message: "PartnerNetwork updated successfully saved."
        });
      },
      onError: () =>
        addAlert({ color: "danger", message: "Could not save PartnerNetwork." })
    }
  );

  const [createPartnerNetwork, { loading: createLoading }] = useMutation(
    CREATE_PARTNER_NETWORK,
    {
      onCompleted: (res) => {
        if (!res.createPartnerNetwork.ok) {
          return addAlert({
            color: "danger",
            message: "Could not create PartnerNetwork."
          });
        }
        dispatch({
          type: "INIT",
          payload: res.createPartnerNetwork.partnerNetwork
        });
        addAlert({
          color: "success",
          message: "PartnerNetwork created successfully."
        });
      },
      onError: () =>
        addAlert({
          color: "danger",
          message: "Could not create PartnerNetwork."
        })
    }
  );

  const onLoadingData = (data) => {
    dispatch({ type: "INIT", payload: data.partnerNetwork });
  };

  const [getPartnerNetwork] = useLazyQuery(PARTNER_NETWORK, {
    onCompleted: onLoadingData
  });
  useEffect(() => {
    if (props.match.params.id && state._id !== props.match.params.id) {
      dispatch({ type: "SET_LOADING", payload: true });
      getPartnerNetwork({ variables: { id: props.match.params.id } });
    }
  }, [getPartnerNetwork, props.match.params.id, state._id]);

  if (props.match.params.id && !state.loaded) return null;

  return (
    <div className="page">
      <PartnerNetworkContext.Provider value={{ state, dispatch }}>
        <AvForm
          beforeSubmitValidation={(event) => event.stopPropagation()}
          onValidSubmit={onSave}
          className="PartnerNetwork"
        >
          <div className="row">
            <div className="col-md-4">
              <label>Name</label>
              <InputField
                targetKey="name"
                containerClassName="input-field-lg"
                value={state.name}
                onChange={(changeEvent) =>
                  dispatch({ type: "NAME", payload: changeEvent.value })
                }
                options={{ required: true, type: "string" }}
              />
            </div>
            <div className="col-md-4">
              <label>Value (Percentage)</label>
              <InputField
                targetKey="value"
                containerClassName="input-field-lg"
                value={state.value}
                onChange={(changeEvent) =>
                  dispatch({ type: "VALUE", payload: changeEvent.value })
                }
                options={{ required: true, type: "number", max: 100, min: 0 }}
              />
            </div>
          </div>
          <PartnerNetworkAddEmails className="py-2 mb-4" />
          <div>
            <PartnerNetworkUserTable />
          </div>
          <div className="Save-container">
            <div className="Save-container__phantom" />
            <div className="Save-container__main">
              <FormGroup>
                <Button
                  disabled={mutationLoading || createLoading}
                  size="lg"
                  type="submit"
                >
                  {" "}
                  Save{" "}
                </Button>
              </FormGroup>
            </div>
          </div>
        </AvForm>
      </PartnerNetworkContext.Provider>
    </div>
  );
};

export default withRouter(PartnerNetwork);
