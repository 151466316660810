// Vendor
import React, { useContext, useState, useEffect } from "react";
import { Table, Button, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { SingleDatePicker } from "react-dates";

// App
import { TeeTimeTableContext } from "../TeeTimeTable";
import { DayAvailabilityList } from "./DayAvailabilityList";

export function DateOverrideList() {
  const { teeTimeTable, dispatch } = useContext(TeeTimeTableContext);

  const [focus, setFocus] = useState("");
  const [coveredDates, setCoveredDates] = useState([]);

  useEffect(() => {
    let dates =
      teeTimeTable.dateOverride?.reduce(
        (acc, override) => acc.concat(override.dates),
        []
      ) ?? [];
    setCoveredDates(dates);
  }, [teeTimeTable.dateOverride]);

  function handleOnChange(itemIndex, field, value) {
    let objectCopy = { ...teeTimeTable.dateOverride[itemIndex] };
    objectCopy[field] = value;
    dispatch({
      type: "DATE_OVERRIDE_UPDATE",
      payload: {
        index: itemIndex,
        value: objectCopy
      }
    });
  }

  function handleAddOverride() {
    dispatch({
      type: "DATE_OVERRIDE_ADD",
      payload: {
        closed: true,
        dates: [],
        dayAvailability: []
      }
    });
  }

  /**
   *
   * @param { number } overrideIndex
   * @param { number } dateIndex
   * @param { moment.Moment } date
   */
  function handleDateChange(overrideIndex, dateIndex, date) {
    let dateListCopy = [...teeTimeTable.dateOverride[overrideIndex].dates];
    dateListCopy[dateIndex] = date.startOf("day").toDate();

    handleOnChange(overrideIndex, "dates", dateListCopy);
  }

  function handleDateAdd(overrideIndex) {
    let dateListCopy = [
      ...teeTimeTable.dateOverride[overrideIndex].dates,
      teeTimeTable.startDate
    ];
    handleOnChange(overrideIndex, "dates", dateListCopy);
  }

  function handleRemoveDate(overrideIndex, dateIndex) {
    let dateListCopy = teeTimeTable.dateOverride[overrideIndex].dates.filter(
      (_, i) => i !== dateIndex
    );
    handleOnChange(overrideIndex, "dates", dateListCopy);
  }

  return (
    <div className="mt-3">
      <h6>Date overrides</h6>
      <hr />
      <Table className="border-0">
        {teeTimeTable.dateOverride?.length > 0 && (
          <thead>
            <tr>
              <th>Closed</th>
              <th>Availability</th>
              <th>Dates affected</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
        )}
        <tbody
          className={teeTimeTable.dateOverride?.length > 0 ? "border" : ""}
        >
          {!teeTimeTable.dateOverride?.length && (
            <tr>
              <td className="text-muted text-center border-0">
                No items added
              </td>
            </tr>
          )}
          {teeTimeTable.dateOverride?.map((override, index) => (
            <tr key={index}>
              <td className="Col--shrink">
                <div className="d-block pl-2">
                  {/* Invisible label for alignment purposes */}
                  <label className="text-white">Padding</label>
                  <div className="checkbox-group">
                    <input
                      id={`date-override-is-closed-${index}`}
                      name={`date-override-is-closed-${index}`}
                      type="checkbox"
                      checked={override.closed}
                      onChange={event =>
                        handleOnChange(index, "closed", event.target.checked)
                      }
                    />
                    <label
                      htmlFor={`date-override-is-closed-${index}`}
                      className="mb-0"
                    ></label>
                  </div>
                </div>
              </td>
              <td>
                <DayAvailabilityList
                  availabilityList={override.dayAvailability}
                  onChange={value =>
                    handleOnChange(index, "dayAvailability", value)
                  }
                />
              </td>
              <td>
                <div className="d-block">
                  {override.dates.map((date, i) => (
                    <Row key={i}>
                      <Col>
                        <div
                          className="position-relative"
                          style={{ zIndex: 4 }}
                        >
                          <label>Date</label>
                          <SingleDatePicker
                            enableOutsideDays
                            small
                            date={moment(date)}
                            id={`date-override-date-${index}-${i}`}
                            onDateChange={newDate =>
                              handleDateChange(index, i, newDate)
                            }
                            isOutsideRange={d =>
                              d.isBefore(teeTimeTable.startDate) ||
                              d.isAfter(teeTimeTable.endDate) ||
                              coveredDates.some(cd => d.isSame(cd, "day"))
                            }
                            focused={focus === `${index}-${i}`}
                            onFocusChange={arg =>
                              setFocus(arg.focused ? `${index}-${i}` : "")
                            }
                            hideKeyboardShortcutsPanel={true}
                            displayFormat="YYYY-MM-DD"
                          />
                          <Col>
                            <Button
                              color="link"
                              className="text-danger pt-3"
                              id={`delete-date-override-date-${index}-${i}`}
                              onClick={() => handleRemoveDate(index, i)}
                            >
                              <FontAwesomeIcon
                                icon="trash-alt"
                                className="mr-1"
                              />
                            </Button>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                  ))}
                  {override.dates.length > 0 && <hr />}
                  <Button color="link" onClick={() => handleDateAdd(index)}>
                    + Add date
                  </Button>
                </div>
              </td>
              <td>
                <Button
                  color="link"
                  className="text-danger pt-3"
                  id={`delete-day-override-${index}`}
                  onClick={() =>
                    dispatch({
                      type: "DATE_OVERRIDE_REMOVE",
                      payload: index
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon="trash-alt"
                    size="lg"
                    className="mr-1"
                  />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button color="link" onClick={handleAddOverride}>
        + Add date override
      </Button>
    </div>
  );
}
