// Vendor
import React, { useContext } from "react";
import { useMutation } from "@apollo/client";

// App
import {
  ORDER_ADD_COMMENT,
  ORDER_CLOSE_COMMENT_TASK,
  ORDER_DELETE_COMMENT,
  ORDER_EDIT_COMMENT
} from "../../../common/Mutations";
import { LayoutContext } from "../../app/Layout";
import { CommentListBase } from "../../ui/Comments/CommentListBase";
import { OrderContext } from "../Order";

export function OrderComments({ comments }) {
  const { addAlert } = useContext(LayoutContext);
  const { state, dispatch } = useContext(OrderContext);

  const [createComment, { loading: createCommentLoading }] = useMutation(
    ORDER_ADD_COMMENT,
    {
      onCompleted: (data) =>
        dispatch({ type: "COMMENT_ADD", payload: data.orderAddComment }),
      onError: (err) => handleError(err, "Error: Could not create comment")
    }
  );

  const [editComment, { loading: editCommentLoading }] = useMutation(
    ORDER_EDIT_COMMENT,
    {
      onCompleted: (data) =>
        dispatch({
          type: "COMMENT_UPDATE",
          payload: data.orderEditComment.comment
        }),
      onError: (err) => handleError(err, "Error: Could not edit comment")
    }
  );

  const [deleteComment, { loading: deleteCommentLoading }] = useMutation(
    ORDER_DELETE_COMMENT,
    {
      onCompleted: (data) =>
        dispatch({
          type: "COMMENT_DELETE",
          payload: data.orderDeleteComment.comment
        }),
      onError: (err) => handleError(err, "Error: Could not delete comment")
    }
  );

  const [closeCommentTask, { loading: closeCommentTaskLoading }] = useMutation(
    ORDER_CLOSE_COMMENT_TASK,
    {
      onCompleted: (data) =>
        dispatch({
          type: "COMMENT_CLOSE_TASK",
          payload: data.orderCloseCommentTask
        }),
      onError: (err) => handleError(err, "Error: Could not close task")
    }
  );

  function handleError(error, alertMessage) {
    console.error(error);
    addAlert({
      color: "danger",
      message: alertMessage
    });
  }

  function handleNewComment(input) {
    createComment({
      variables: {
        orderId: state.formatted._id,
        ...input
      }
    });
  }

  function handleEditComment(input) {
    editComment({
      variables: {
        orderId: state.formatted._id,
        ...input
      }
    });
  }

  function handleDeleteComment(input) {
    deleteComment({
      variables: {
        orderId: state.formatted._id,
        ...input
      }
    });
  }

  function handleCloseCommentTask(input) {
    closeCommentTask({
      variables: input
    });
  }

  return (
    <CommentListBase
      comments={comments}
      onCloseTask={handleCloseCommentTask}
      onNewComment={handleNewComment}
      newCommentLoading={createCommentLoading}
      onSaveEditComment={handleEditComment}
      onDeleteComment={handleDeleteComment}
      closeTaskLoading={closeCommentTaskLoading}
    />
  );
}
