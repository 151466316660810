import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { DateRangePicker, isInclusivelyAfterDay } from "react-dates";
import momentPropTypes from "react-moment-proptypes";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { validDate } from "../../clubs/courses/seasons/SeasonHelper";

function DateRangeFacet(props) {
  const [focusedInput, setFocus] = useState(null);
  const [startDateMain, setStartDate] = useState(props.startDate);
  const [endDateMain, setEndDate] = useState(props.endDate);

  function getInitialVisibleMonth() {
    switch (focusedInput) {
      case "startDate":
        return startDateMain ?? moment();
      case "endDate":
      default:
        return moment();
    }
  }

  // handler for date validation
  const handleValidateDate = (startDate, endDate) => {
    const updateState = validDate(
      startDate,
      props.season_dates_occupied,
      props.excludeSeason,
      endDate ?? endDateMain
    );
    // updating states only if valid date
    if (updateState) {
      if (startDate) setStartDate(startDate);
      if (endDate) setEndDate(endDate);
      if (startDate && endDate) props.onDatesChange({ startDate, endDate });
      return;
    }
    props.addAlert({
      color: "danger",
      message: "Please don't overlap seasons."
    });
  };

  return (
    <DateRangePicker
      startDatePlaceholderText={props.startDatePlaceholderText}
      endDatePlaceholderText={props.endDatePlaceholderText}
      startDate={startDateMain} // momentPropTypes.momentObj or null,
      startDateId={props.startDateId}
      endDate={endDateMain} // momentPropTypes.momentObj or null,
      endDateId={props.endDateId}
      initialVisibleMonth={getInitialVisibleMonth}
      onClose={({ startDate, endDate }) => {
        if (startDate && endDate && startDate.isAfter(endDate)) {
          setEndDate(startDate);
        }
      }}
      onDatesChange={({ startDate, endDate }) => {
        if (props.dateValidation) return handleValidateDate(startDate, endDate);

        if (props.showClearDates) {
          setStartDate(startDate);
          setEndDate(endDate);
          return props.onDatesChange({ startDate, endDate });
        }

        if (startDate) setStartDate(startDate);
        if (endDate) setEndDate(endDate);

        // only invoke handler if both dates have changed
        if (startDate && endDate) {
          props.onDatesChange({
            startDate,
            endDate
          });
        }
      }} // PropTypes.func.isRequired,
      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={setFocus} // PropTypes.func.isRequired,
      hideKeyboardShortcutsPanel={true}
      small={props.small}
      customInputIcon={
        props.showCalendarIcon ? (
          <FontAwesomeIcon icon={["far", "calendar-alt"]} />
        ) : null
      }
      enableOutsideDays={props.enableDatesOutsideRange}
      isOutsideRange={props.isOutsideRange}
      showClearDates={props.showClearDates}
      displayFormat={props.displayFormat}
      isDayBlocked={props.isDayBlocked}
    />
  );
}

DateRangeFacet.propTypes = {
  endDate: momentPropTypes.momentObj,
  endDateId: PropTypes.string.isRequired,
  enableDatesOutsideRange: PropTypes.bool,
  onDatesChange: PropTypes.func.isRequired,
  isOutsideRange: PropTypes.func.isRequired,
  showCalendarIcon: PropTypes.bool,
  small: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  startDateId: PropTypes.string.isRequired,
  showClearDates: PropTypes.bool,
  startDatePlaceholderText: PropTypes.string,
  endDatePlaceholderText: PropTypes.string,
  displayFormat: PropTypes.string,
  addingSeason: PropTypes.bool
};

DateRangeFacet.defaultProps = {
  enableDatesOutsideRange: false,
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
  showCalendarIcon: false,
  small: false,
  showClearDates: false,
  startDatePlaceholderText: "Start Date",
  endDatePlaceholderText: "End Date",
  displayFormat: "YYYY-MM-DD"
};

export default DateRangeFacet;
