import React, { useContext, useEffect, useState } from "react";
import TableAmadeus from "./TableAmadeus";
import { Button } from "reactstrap";
import CardCreationModal from "./modals/CardCreationModal";
import { LIST_VIRTUAL_CARDS } from "../../common/Queries";
import { useLazyQuery } from "@apollo/client";
import { LayoutContext } from "../app/Layout";
import { useHistory } from "react-router-dom";
import AmadeusSearch from "./AmadeusSearch";
import URLHelper from "../../common/URLHelper";
import CondensedNavButtonBar from "../ui/CondensedNavButtonBar";

const AmadeusList = () => {
  const { addAlert } = useContext(LayoutContext);
  const history = useHistory();

  const urlQueryParams = URLHelper.queryToState(history.location);
  const {
    cardHolder,
    status,
    currency,
    amountFrom,
    amountTo,
    periodFrom,
    periodTo,
    vendorCode,
    subType,
    page
  } = urlQueryParams;

  const [cardsList, setCardsList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [ListVirtualCards, { loading }] = useLazyQuery(LIST_VIRTUAL_CARDS, {
    onCompleted: (response) => {
      if (response.ListVirtualCards.ok) {
        setPageCount(response?.ListVirtualCards?.data?.pageCount);
        setCardsList(response?.ListVirtualCards?.data?.list);
        return;
      }

      addAlert({
        color: "danger",
        message:
          response.ListVirtualCards.returnMessage ||
          "Error while fetching amadeus cards list"
      });
    },
    onError: () => {
      return addAlert({
        color: "danger",
        message: "Error while fetching amadeus cards list"
      });
    }
  });
  const buildFilter = () => {
    let filter = {};
    if (currency) {
      filter.CurrencyCode = {
        _CurrencyCode: currency
      };
    }
    if (cardHolder) {
      filter.CardHolder = cardHolder;
    }

    if (status) {
      filter.CardStatus = {
        _CardStatus: status
      };
    }

    if (vendorCode) {
      filter.VendorCode = {
        _VendorCode: vendorCode
      };
    }

    if (periodFrom || periodTo) {
      filter.Period = {
        Start: periodFrom,
        End: periodTo,
        EventType: "Creation"
      };
    }
    if (amountFrom || amountTo) {
      // Amadeus Api expects currency if amount is being sent, currently hardcoding EUR if user didn't select different one
      if (!filter.CurrencyCode?._CurrencyCode) {
        filter.CurrencyCode = {
          _CurrencyCode: "EUR"
        };
      }
      filter.AmountRange = {
        Min: +amountFrom,
        Max: +amountTo
      };
    }
    if (subType) {
      filter.SubType = {
        _SubType: subType
      };
    }
    return filter;
  };
  const fetchVirtualCards = () => {
    ListVirtualCards({
      variables: {
        filter: buildFilter(),
        pageIndex: pageIndex
      }
    });
  };
  const pageBtnClickHandler = (newIndex) => {
    newIndex = Number(newIndex);
    urlQueryParams.page = newIndex + 1;
    history.push({
      search: URLHelper.queryFromState(urlQueryParams)
    });
  };
  useEffect(() => {
    // Update page index from url if changed
    if (page) {
      setPageIndex(Number(page) - 1);
    }
  }, [page]);

  useEffect(() => {
    // Fetch new list on pageIndex change
    fetchVirtualCards();
  }, [pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  useEffect(() => {
    // Get page count on changed search params
    // Ensure page index 0
    if (pageIndex === 0) {
      fetchVirtualCards();
    } else {
      pageBtnClickHandler(0);
    }
    // eslint-disable-next-line
  }, [
    status,
    cardHolder,
    currency,
    amountFrom,
    amountTo,
    periodFrom,
    periodTo,
    vendorCode,
    subType
  ]);

  return (
    <div className="page">
      <h1>Card Management Dashboard</h1>
      <div className="w-100 d-flex justify-content-between">
        <Button
          size="sm"
          className="m-0 p-0 text-nowrap"
          color="link"
          onClick={toggleModal}
        >
          Create new Card
        </Button>
        <CardCreationModal isOpen={isModalOpen} toggle={toggleModal} />
      </div>
      <AmadeusSearch />
      <TableAmadeus list={cardsList} loading={loading} />
      <CondensedNavButtonBar
        value={pageIndex}
        onBtnClick={pageBtnClickHandler}
        pageCount={pageCount}
      />
    </div>
  );
};

export default AmadeusList;
