import React, { Suspense } from "react";
import InView from "react-intersection-observer";
import Loader from "./Loader";

function Lazy(props) {
  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <div className={props.className} ref={ref} style={props.style}>
          {inView && (
            <Suspense fallback={<Loader />}>{props.children}</Suspense>
          )}
        </div>
      )}
    </InView>
  );
}

Lazy.defaultProps = {
  className: ""
};

export default Lazy;
