import React from "react";

const Checkbox = ({ name, value, onChange }) => {
  return (
    <div>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={value}
        onChange={(event) => {
          onChange(event.target.checked);
        }}
      />
      <label htmlFor={name} className="mb-0"></label>
    </div>
  );
};

export default Checkbox;
