import { AvForm, AvInput } from "availity-reactstrap-validation";
import React from "react";
import { Button, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { EMAIL_VALIDATOR } from "../../common/Validators";
import PropTypes from "prop-types";
const EmailModal = (props) => {
  const { isOpen, toggle, data, onClosed, onChange, handleSubmit, edited } =
    props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      <AvForm onValidSubmit={handleSubmit}>
        <ModalHeader toggle={toggle}>
          {data._id ? "Edit Recipient" : "   recipient"}
        </ModalHeader>
        <ModalBody>
          <Label>First Name</Label>
          <AvInput
            name="firstName"
            type="text"
            className="mb-2"
            value={data.name.firstName}
            required
            onChange={(e) => {
              onChange({ key: "firstName", value: e.target.value });
            }}
          />
          <Label>Last Name</Label>
          <AvInput
            name="lastName"
            type="text"
            className="mb-2"
            required
            value={data.name.lastName}
            onChange={(e) => {
              onChange({ key: "lastName", value: e.target.value });
            }}
          />
          <Label>Email</Label>
          <AvInput
            name="email"
            type="email"
            required
            validate={EMAIL_VALIDATOR}
            value={data.email}
            onChange={(e) => {
              onChange({ key: "email", value: e.target.value });
            }}
          />
          <div className="d-flex justify-content-end w-100 mt-2">
            <Button color="secondary" type="submit" disabled={!edited}>
              Save
            </Button>
          </div>
        </ModalBody>
      </AvForm>
    </Modal>
  );
};

EmailModal.prototype = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    })
  })
};

export default EmailModal;
