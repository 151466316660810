import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { DateRangePicker, isInclusivelyAfterDay } from "react-dates";
import momentPropTypes from "react-moment-proptypes";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { validDate } from "../../clubs/courses/seasons/SeasonHelper";
import SingleDateWrapper from "../SingleDateWrapper";
import {
  faCalendarDay,
  faCalendarWeek
} from "@fortawesome/free-solid-svg-icons";
import Info from "../Info";
import { ToggleIconsExplanation } from "../../../common/InfoContent";

function ToggleableDateRangeFacet(props) {
  const [focusedInput, setFocus] = useState(null);
  const [startDateMain, setStartDate] = useState(props.startDate);
  const [endDateMain, setEndDate] = useState(props.endDate);
  const [isDateRangeMode, setDateRangeMode] = useState(true);

  function getInitialVisibleMonth() {
    switch (focusedInput) {
      case "startDate":
        return startDateMain ?? moment();
      case "endDate":
      default:
        return moment();
    }
  }

  // handler for date validation
  const handleValidateDate = (startDate, endDate) => {
    const updateState = validDate(
      startDate,
      props.season_dates_occupied,
      props.excludeSeason,
      endDate ?? endDateMain
    );
    // updating states only if valid date
    if (updateState) {
      if (startDate) setStartDate(startDate);
      if (endDate) setEndDate(endDate);
      if (startDate && endDate) props.onDatesChange({ startDate, endDate });
      return;
    }
    props.addAlert({
      color: "danger",
      message: "Please don't overlap seasons."
    });
  };

  const onDatesChange = ({ startDate, endDate }) => {
    if (props.dateValidation) {
      handleValidateDate(startDate, endDate);
      return;
    }
    if (props.showClearDates) {
      // this will trigger on both start and end date
      setStartDate(startDate);
      setEndDate(endDate);
      return props.onDatesChange({ startDate, endDate });
    } else {
      if (startDate) setStartDate(startDate);
      if (endDate) setEndDate(endDate);

      // only invoke handler if both dates have changed
      if (startDate && endDate) {
        props.onDatesChange({
          startDate,
          endDate
        });
      }
    }
  };

  const handleIconClick = () => {
    setDateRangeMode(!isDateRangeMode);
    // resetting dates
    props.onDatesChange({ startDate: null, endDate: null });
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon
        icon={isDateRangeMode ? faCalendarWeek : faCalendarDay}
        style={{ fontSize: "24px" }}
        onClick={handleIconClick}
        className="toggle-icon"
      />
      {isDateRangeMode ? (
        <DateRangePicker
          startDatePlaceholderText={props.startDatePlaceholderText}
          endDatePlaceholderText={props.endDatePlaceholderText}
          startDate={startDateMain} // momentPropTypes.momentObj or null,
          startDateId={props.startDateId}
          endDate={endDateMain} // momentPropTypes.momentObj or null,
          endDateId={props.endDateId}
          initialVisibleMonth={getInitialVisibleMonth}
          onClose={({ startDate, endDate }) => {
            if (startDate && endDate && startDate.isAfter(endDate)) {
              setEndDate(startDate);
            }
          }}
          onDatesChange={onDatesChange} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={setFocus} // PropTypes.func.isRequired,
          hideKeyboardShortcutsPanel={true}
          small={props.small}
          customInputIcon={
            props.showCalendarIcon ? (
              <FontAwesomeIcon icon={["far", "calendar-alt"]} />
            ) : null
          }
          enableOutsideDays={props.enableDatesOutsideRange}
          isOutsideRange={props.isOutsideRange}
          showClearDates={props.showClearDates}
          displayFormat={props.displayFormat}
          isDayBlocked={props.isDayBlocked}
        />
      ) : (
        <SingleDateWrapper
          onDateChange={(date) => {
            props.onDatesChange({ startDate: date }, true);
          }}
          showClearDate
          isOutsideRange={props.isOutsideRange}
        />
      )}
      <Info
        id={props.popoverId}
        value={ToggleIconsExplanation}
        className="mr-1"
      />
    </div>
  );
}

ToggleableDateRangeFacet.propTypes = {
  endDate: momentPropTypes.momentObj,
  endDateId: PropTypes.string.isRequired,
  enableDatesOutsideRange: PropTypes.bool,
  onDatesChange: PropTypes.func.isRequired,
  isOutsideRange: PropTypes.func.isRequired,
  showCalendarIcon: PropTypes.bool,
  small: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  startDateId: PropTypes.string.isRequired,
  showClearDates: PropTypes.bool,
  startDatePlaceholderText: PropTypes.string,
  endDatePlaceholderText: PropTypes.string,
  displayFormat: PropTypes.string,
  addingSeason: PropTypes.bool
};

ToggleableDateRangeFacet.defaultProps = {
  enableDatesOutsideRange: false,
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
  showCalendarIcon: false,
  small: false,
  showClearDates: false,
  startDatePlaceholderText: "Start Date",
  endDatePlaceholderText: "End Date",
  displayFormat: "YYYY-MM-DD"
};

export default ToggleableDateRangeFacet;
