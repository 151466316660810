// Vendor
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";

// App
import Loader from "./Loader";
import ManagedTable from "./ManagedTable";

function SelectTable({
  listItems,
  listContainerClass,
  selectContainerClass,
  selectQuery,
  selectQueryOptions,
  title,
  onChange
}) {
  const [formattedItemList, setFormattedItemList] = useState([]);

  useEffect(() => {
    let newFormattedList = listItems.map(item => ({
      id: item._id,
      value: item.name
    }));
    setFormattedItemList(newFormattedList);
  }, [listItems]);

  function onChangeHandler(itemArray) {
    let newArr = [];
    itemArray.forEach(item => {
      let match = listItems.find(e => e._id === item.id);
      match && newArr.push(match);
    });
    onChange(newArr);
  }

  function addItem(item) {
    if (listItems.some(c => c._id === item._id)) return;
    onChange([...listItems, item]);
  }
  const Select = ({ data, error, loading }) => {
    if (error) return <Alert color="danger">Failed to load</Alert>;
    if (loading) return <Loader />;

    // first field contains the data
    const field = Object.keys(data)[0];

    // sort options by name
    const selectableItems = [...data[field]?.filter(x => !formattedItemList.some(y => y.id ===  x._id))].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );

    return (
      <select
        className="form-control form-control-lg mb-3"
        value="default"
        onChange={e => addItem(selectableItems[e.target.value])}
      >
        <option value="default" disabled>
          Select item
        </option>
        {selectableItems.map((item, i) => (
          <option value={i} key={i}>
            {item.name}
          </option>
        ))}
      </select>
    );
  };

  const { error, loading, data } = useQuery(selectQuery, selectQueryOptions);

  return (
    <div className="mb-5">
      <h3 className="mb-3">{title}</h3>
      <div className="row align-items-center">
        <div className={selectContainerClass}>
          <Select error={error} loading={loading} data={data} />
        </div>
        <div className={listContainerClass}>
          <ManagedTable
            listItems={formattedItemList}
            onChange={onChangeHandler}
          />
        </div>
      </div>
    </div>
  );
}

SelectTable.propTypes = {
  selectContainerClass: PropTypes.string,
  listContainerClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showDescriptionCount: PropTypes.bool,
  selectQuery: PropTypes.object.isRequired, // object with gql string
  selectQueryOptions: PropTypes.object,
  title: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string
    })
  )
};
SelectTable.defaultProps = {
  listItems: [],
  selectContainerClass: "col-12 col-md-6",
  listContainerClass: "col-12 col-md-6",
  showDescriptionCount: true,
  title: "",
  selectQueryOptions: {}
};

export default SelectTable;
