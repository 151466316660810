import { useEffect } from "react";
import URLHelper from "./URLHelper";

/**
 * @param { Object } history react-router-dom object histsory
 * @param { Array } items array used for mapping the query params
 * @param { Object } item { key: "key", value: "value" }
 * @param { Array } dependencies array of dependencies used in useEffect
 */

//hook that updates the url with the latest search params
export default function useUpdateUrlSearchParams(history, items, dependencies) {
  let query = {};
  items.forEach((item) => {
    if (item.value !== null && item.value !== "") {
      query[item.key] = item.value;
    }
  });
  useEffect(() => {
    history.push({
      pathname: history.location.pathname,
      search: URLHelper.queryFromState(query)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
