export default function voucherReducer(state, action) {
    switch (action.type) {
        case "INIT":
            return { ...action.payload, loaded: true };
        case "CODE":
            return { ...state, code: action.payload };
        case "VALUE":
            return { ...state, value: action.payload };
        case "COMMENT":
            return { ...state, comment: action.payload };
        case "DATE":
            return { ...state, startDate: action.payload.startDate, endDate: action.payload.endDate };
        default: return;
    }
}