import React from "react";
import { Button } from "reactstrap";
import Loader from "../../ui/Loader";

const ExportExcelButton = ({ loading, onClick, disabled }) => {
  return (
    <>
      {loading ? (
        <Button className="cursor-default" color="link" type="button">
          <Loader size={25} />
        </Button>
      ) : (
        <Button
          className="cursor-default"
          color="primary"
          onClick={onClick}
          disabled={disabled}
        >
          Export to Excel
        </Button>
      )}
    </>
  );
};

export default ExportExcelButton;
