// Vendor
import { useQuery } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";

// App
import { AFFILIATES } from "../../common/Queries";
import Loader from "./Loader";

/**
 * @param { Object } props
 * @param { string } [props.id="affiliate-select"]
 * @param { string } props.label
 * @param { string[] } [props.value]
 * @param { VoidCallback<string[] | null>} props.onChange
 */
export function AffiliateSelect(props) {
  const id = props.id ?? "affiliate-select";
  /** @type {import("@apollo/client").QueryResult<AffiliateData>} */
  const { loading, error, data, refetch } = useQuery(AFFILIATES);
  /** @type { [{value: string, label: string}[], React.Dispatch<React.SetStateAction<{value: string, label: string}[]>>] }} */
  const [options, setOptions] = useState([]);

  const value = useMemo(() => {
    return options
      .filter((a) => props.value?.some((v) => v === a.value))
      .map((b) => ({ value: b.value, label: b.label }));
  }, [options, props.value]);

  useEffect(() => {
    if (data) {
      setOptions(
        [{ value: "NONE", label: "None" }].concat(
          data.affiliates.map((aff) => ({
            value: aff._id,
            label: aff.name
          }))
        )
      );
    }
  }, [data]);

  if (loading) return <Loader />;
  if (error)
    return (
      <p className="text-danger">
        Could not load affiliates.{" "}
        <button color="link" type="button" onClick={refetch}>
          Retry
        </button>
      </p>
    );

  return (
    <>
      <label htmlFor={id}>{props.label}</label>
      <Select
        id={id}
        options={options}
        value={value}
        onChange={(val) => props.onChange(val.map((v) => v.value))}
        isMulti
      />
    </>
  );
}

/**
 * @typedef AffiliateData
 * @property { Object[] } affiliates
 * @property { string } affiliates[]._id
 * @property { string } affiliates[].name
 * @property { string } affiliates[].username
 * @property { string } affiliates[].aid
 * @property { string } affiliates[].cid
 * @property { string[] } affiliates[].excludedTeesheetProviders
 * @property { boolean } affiliates[].isActive
 */
