import gql from "graphql-tag";
import {
  AFFILIATE_ATTRIBUTES,
  CLUB_ATTRIBUTES,
  COMMENT_ATTRIBUTES,
  COURSE_ATTRIBUTES,
  EMAIL_RECIPIENTS_ATTRIBUTES,
  EMAIL_TEMPLATES_ATTRIBUTES,
  GOLF_AREA_FIELDS,
  INVOICE_BASE_ATTRIBUTES,
  PARTNER_LINK_ATTRIBUTES,
  PARTNER_NETWORK_ATTRIBUTES,
  SEASON_RATE_ATTRIBUTES,
  SITE_FIELDS,
  START_PAGE_FIELDS,
  TIMETABLE_ATTRIBUTES,
  TOUR_OPERATOR_ATTRIBUTES,
  VIRTUAL_CARD_ATTRIBUTES,
  VOUCHER_ATTRIBUTES
} from "./GraphQLFields";

//#region  COURSE
export const COURSE_CREATE = gql`
  mutation CreateCourse($input: CourseInput!, $clubId: ID!) {
    createCourse(input: $input, clubId: $clubId) {
      ok
      course {
        ${COURSE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const COURSE_UPDATE = gql`
  mutation UpdateCourse($input: CourseInput!, $courseId: ID!) {
    updateCourse(input: $input, courseId: $courseId) {
      ok
      course {
        ${COURSE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const COURSE_DELETE = gql`
  mutation DeleteCourse($courseId: ID!) {
    deleteCourse(courseId: $courseId) {
      ok
      course {
        _id
      }
      message
    }
  }
`;
//#endregion

//#region TEE TIME TABLES
export const TEE_TIME_TABLES_UPSERT = gql`
  mutation UpsertTeeTimeTable($input: TeeTimeTableInput!, $id: ID) {
    upsertTeeTimeTable(input: $input, id: $id) {
      ${TIMETABLE_ATTRIBUTES}
    }
  }
`;

export const TEE_TIME_TABLES_DELETE = gql`
  mutation DeleteTeeTimeTable($id: ID!) {
    deleteTeeTimeTable(id: $id)
  }
`;
//#endregion

//#region SEASONS
export const SEASON_RATE_UPDATE = gql`
  mutation UpdateSeasonRate($input: SeasonRateInput!, $courseId: ID!, $seasonRateId: ID!) {
    updateSeasonRate(input: $input, courseId: $courseId, seasonRateId: $seasonRateId) {
      ok
      seasonRate {
        ${SEASON_RATE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const SEASON_RATE_CREATE = gql`
  mutation CreateSeasonRate($input: SeasonRateInput!, $courseId: ID!) {
    createSeasonRate(input: $input, courseId: $courseId) {
      ok
      seasonRate {
        ${SEASON_RATE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const SEASON_RATE_DELETE = gql`
  mutation DeleteSeasonRate($seasonRateId: ID!, $courseId: ID!) {
    deleteSeasonRate(seasonRateId: $seasonRateId, courseId: $courseId) {
      ok
      message
    }
  }
`;
//#endregion

//#region CLUB
export const CLUB_UPDATE = gql`
  mutation UpdateClub($clubId: ID!, $input: ClubInput!) {
    updateClub(clubId: $clubId, input: $input) {
      ok
      club {
        ${CLUB_ATTRIBUTES}
      }
      message
    }
  }
`;

export const CLUB_CREATE = gql`
  mutation CreateClub($clubInput: ClubInput!, $courses: [CourseInput]) {
    createClub(clubInfo: $clubInput, courses: $courses) {
      ok
      club {
        ${CLUB_ATTRIBUTES}
      }
      message
    }
  }
`;

export const CLUB_ADD_COMMENT = gql`
  mutation AddClubComment($clubId: ID!, $input: CommentInput!, $hasTask: Boolean) {
    addClubComment(clubId: $clubId, input: $input, hasTask: $hasTask) {
      ok
      comment {
        ${COMMENT_ATTRIBUTES}
      }
      message
    }
  }
`;

export const CLUB_EDIT_COMMENT = gql`
  mutation EditClubComment($clubId: ID!, $commentId: ID!, $input: CommentInput!) {
    editClubComment(clubId: $clubId, commentId: $commentId, input: $input) {
      ok
      comment {
        ${COMMENT_ATTRIBUTES}
      }
      message
    }
  }
`;

export const CLUB_DELETE_COMMENT = gql`
  mutation DeleteClubComment($clubId: ID!, $commentId: ID!, $userFullName: String!) {
    deleteClubComment(clubId: $clubId, commentId: $commentId, userFullName: $userFullName){
      ok
      comment {
        ${COMMENT_ATTRIBUTES}
      }
      message
    }
  }
`;

export const CLUB_CLOSE_COMMENT_TASK = gql`
  mutation CloseClubCommentTask($clubId: ID!, $commentId: ID!, $username: String!) {
    closeClubCommentTask(clubId: $clubId, commentId: $commentId, username: $username){
      ${COMMENT_ATTRIBUTES}
    }
  }
`;
//#endregion

// AUTHENTIFICATION
export const USER_SIGNIN = gql`
  mutation Signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
      user {
        _id
        firstName
        lastName
        email
        updatedAt
      }
    }
  }
`;

//#region GOLF AREA
export const UPDATE_GOLF_AREA = gql`
  mutation UpdateGolfArea($areaId: ID!, $input: GolfAreaInput!) {
    updateGolfArea(areaId: $areaId, input: $input) {
      ok
      message
      golfArea {
        ${GOLF_AREA_FIELDS}
      }
    }
  }
`;

export const CREATE_GOLF_AREA = gql`
  mutation CreateGolfArea($input: GolfAreaInput!) {
    createGolfArea(input: $input) {
      ok
      message
      golfArea {
        ${GOLF_AREA_FIELDS}
      }
    }
  }
`;

export const DELETE_GOLF_AREA = gql`
  mutation DeleteGolfArea($areaId: ID!) {
    deleteGolfArea(areaId: $areaId) {
      ok
      message
    }
  }
`;
//#endregion

// START PAGE
export const UPDATE_START_PAGE = gql`
  mutation UpdateStartPage($input: StartPageInput!) {
    updateStartPage(input: $input) {
      ok
      startPage{
        ${START_PAGE_FIELDS}
      }
      message
    }
  }
`;

//#region IMAGES
export const IMAGES_DELETE = gql`
  mutation DeleteImages($input: ImageDeleteInput!) {
    deleteImages(input: $input) {
      ok
      message
      deleted
    }
  }
`;

export const IMAGE_RENAME = gql`
  mutation renameImage($input: ImageRenameInput!) {
    renameImage(input: $input) {
      ok
      message
    }
  }
`;
//#endregion

//#region RATES
export const MISSING_RATE_TOGGLE_MUTE = gql`
  mutation missingRateToggleMute($id: ID!) {
    missingRateToggleMute(id: $id) {
      ok
      message
      rateId
    }
  }
`;

export const MISSING_RATE_DELETE = gql`
  mutation missingRateDelete($id: ID!) {
    missingRateDelete(id: $id) {
      ok
      message
      rateId
    }
  }
`;
//#endregion

//#region ORDER
export const CANCEL_TEETIME_PROVIDER = gql`
  mutation cancellation($input: CancelTeeTimeInput!) {
    cancellation(input: $input) {
      success
      message
    }
  }
`;

export const CANCEL_TEETIME_INTERNAL = gql`
  mutation internalCancellation($orderId: ID!, $teetimeId: ID!) {
    internalCancellation(orderId: $orderId, teetimeId: $teetimeId) {
      ok
      message
      object
    }
  }
`;

export const ORDER_ADD_COMMENT = gql`
  mutation orderAddComment($orderId: ID!, $input: CommentInput!, $hasTask: Boolean) {
    orderAddComment(orderId: $orderId, input: $input, hasTask: $hasTask) {
      ${COMMENT_ATTRIBUTES}
    }
  }
`;

export const ORDER_EDIT_COMMENT = gql`
  mutation orderEditComment($orderId: ID!, $commentId: ID!, $input: CommentInput!) {
    orderEditComment(orderId: $orderId, commentId: $commentId, input: $input) {
      ok
      comment {
        ${COMMENT_ATTRIBUTES}
      }
      message
    }
  }
`;

export const ORDER_DELETE_COMMENT = gql`
  mutation orderDeleteComment($orderId: ID!, $commentId: ID!, $userFullName: String!) {
    orderDeleteComment(orderId: $orderId, commentId: $commentId, userFullName: $userFullName){
      ok
      comment {
        ${COMMENT_ATTRIBUTES}
      }
      message
    }
  }
`;

export const ORDER_CLOSE_COMMENT_TASK = gql`
  mutation orderCloseCommentTask($commentId: ID!, $username: String!) {
    orderCloseCommentTask(commentId: $commentId, username: $username){
      ${COMMENT_ATTRIBUTES}
    }
  }
`;

export const SEND_ORDER_CONFIRMATION_EMAIL = gql`
  mutation sendOrderConfirmation($orderId: ID!, $targetEmail: String) {
    sendOrderConfirmation(orderId: $orderId, targetEmail: $targetEmail) {
      success
    }
  }
`;

export const SEND_ORDER_CANCELLATION_EMAIL = gql`
  mutation sendCancellationConfirmation(
    $orderId: ID!
    $teetimeId: ID!
    $targetEmail: String
  ) {
    sendCancellationConfirmation(
      orderId: $orderId
      teetimeId: $teetimeId
      targetEmail: $targetEmail
    ) {
      success
    }
  }
`;

export const SEND_ORDER_RECEIPT_EMAIL = gql`
  mutation sendOrderReceipt($orderId: ID!, $targetEmail: String) {
    sendOrderReceipt(orderId: $orderId, targetEmail: $targetEmail) {
      success
    }
  }
`;

export const GENERATE_INVOICES = gql`
  mutation generateInvoicesForOrder($id: ID!) {
    generateInvoicesForOrder(id: $id) {
      ok
      returnMessage
    }
  }
`;
//#endregion

//#region VOUCHER
export const UPDATE_VOUCHER = gql`
  mutation updateVoucher($input: VoucherInput, $voucherId: ID!) {
    updateVoucher(input: $input, voucherId: $voucherId){
      ok
      voucher { ${VOUCHER_ATTRIBUTES} }
      message
    }
  }
`;

export const CREATE_VOUCHER = gql`
  mutation createVoucher($input: VoucherInput) {
    createVoucher(input: $input){
      ok
      voucher { ${VOUCHER_ATTRIBUTES} }
      message
    }
  }
`;

export const DELETE_VOUCHER = gql`
  mutation deleteVoucher($voucherId: ID!) {
    deleteVoucher(voucherId: $voucherId) {
      ok
      message
    }
  }
`;
//#endregion

// AFFILIATE

export const CREATE_AFFILIATE = gql`
  mutation createAffiliate($input: AffiliateCreateInput) {
    mutateAffiliate: createAffiliate(input: $input){
      ok
      affiliate { ${AFFILIATE_ATTRIBUTES} }
      message
      password
    }
  }
`;

export const UPDATE_AFFILIATE = gql`
  mutation updateAffiliate($input:  AffiliateUpdateInput, $affiliateId: ID!) {
    mutateAffiliate: updateAffiliate(input: $input, affiliateId: $affiliateId){
      ok
      affiliate { ${AFFILIATE_ATTRIBUTES} }
      message
    }
  }
`;

export const UPDATE_AFFILIATE_PASSWORD = gql`
  mutation updateAffiliatePassword($affiliateId: ID!) {
    updateAffiliatePassword(affiliateId: $affiliateId) {
      ok
      password
      message
    }
  }
`;

//#region SITE
export const UPDATE_SITE = gql`
  mutation updateSite($input: SiteInput, $siteId: ID!) {
    updateSite(input: $input, siteId: $siteId){
      ok
      site { ${SITE_FIELDS} }
      message
    }
  }
`;

export const CREATE_SITE = gql`
  mutation createSite($input: SiteInput) {
    createSite(input: $input){
      ok
      site { ${SITE_FIELDS} }
      message
    }
  }
`;
//#endregion

//#region PARTNER_NETWORK
export const UPDATE_PARTNER_NETWORK = gql`
  mutation updatePartnerNetwork($input: PartnerNetworkInput, $partnerNetworkId: ID!) {
    updatePartnerNetwork(input: $input, partnerNetworkId: $partnerNetworkId){
      ok
      partnerNetwork { ${PARTNER_NETWORK_ATTRIBUTES} }
      message
    }
  }
`;

export const CREATE_PARTNER_NETWORK = gql`
  mutation createPartnerNetwork($input: PartnerNetworkInput) {
    createPartnerNetwork(input: $input){
      ok
      partnerNetwork { ${PARTNER_NETWORK_ATTRIBUTES} }
      message
    }
  }
`;

export const DELETE_PARTNER_NETWORK = gql`
  mutation deletePartnerNetwork($partnerNetworkId: ID!) {
    deletePartnerNetwork(partnerNetworkId: $partnerNetworkId) {
      ok
      message
    }
  }
`;
//#endregion

//#region TOUR_OPERATOR
export const UPDATE_TOUR_OPERATOR = gql`
  mutation updateTourOperator($input: TourOperatorInput, $tourOperatorId: ID!) {
    updateTourOperator(input: $input, tourOperatorId: $tourOperatorId){
      ok
      tourOperator { ${TOUR_OPERATOR_ATTRIBUTES} }
      message
    }
  }
`;

export const CREATE_TOUR_OPERATOR = gql`
  mutation createTourOperator($input: TourOperatorInput) {
    createTourOperator(input: $input){
      ok
      tourOperator { ${TOUR_OPERATOR_ATTRIBUTES} }
      message
    }
  }
`;

export const DELETE_TOUR_OPERATOR = gql`
  mutation deleteTourOperator($tourOperatorId: ID!) {
    deleteTourOperator(tourOperatorId: $tourOperatorId) {
      ok
      message
    }
  }
`;
//#endregion

//#region PARTNER_LINK
export const UPDATE_PARTNER_LINK = gql`
  mutation updatePartnerLink($input: PartnerLinkInput, $partnerLinkId: ID!) {
    updatePartnerLink(input: $input, partnerLinkId: $partnerLinkId){
      ok
      partnerLink { ${PARTNER_LINK_ATTRIBUTES} }
      message
    }
  }
`;

export const CREATE_PARTNER_LINK = gql`
  mutation createPartnerLink($input: PartnerLinkInput) {
    createPartnerLink(input: $input){
      ok
      partnerLink { ${PARTNER_LINK_ATTRIBUTES} }
      message
    }
  }
`;

export const DELETE_PARTNER_LINK = gql`
  mutation deletePartnerLink($partnerLinkId: ID!) {
    deletePartnerLink(partnerLinkId: $partnerLinkId) {
      ok
      message
    }
  }
`;
//#endregion

//#region EMAIL_SUBSCRIBERS

export const CREATE_EMAIL_RECIPIENT = gql`
  mutation createEmailRecipient($emailRecipientInput: EmailRecipientInput!) {
    createEmailRecipient(emailRecipientInput: $emailRecipientInput) {
      ok
      emailRecipient{
        ${EMAIL_RECIPIENTS_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;
export const UPDATE_EMAIL_RECIPIENT = gql`
  mutation updateEmailRecipient($emailRecipientID:ID, $emailRecipientInput: EmailRecipientInput) {
    updateEmailRecipient(emailRecipientID:$emailRecipientID, emailRecipientInput: $emailRecipientInput) {
      ok
      emailRecipient{
        ${EMAIL_RECIPIENTS_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;
export const DELETE_EMAIL_RECIPIENT = gql`
  mutation deleteEmailRecipient($emailRecipientID: ID!) {
    deleteEmailRecipient(emailRecipientID: $emailRecipientID) {
      ok
      emailRecipient {
        _id
      }
      returnMessage
    }
  }
`;
export const CREATE_EMAIL_TEMPLATE = gql`
  mutation createEmailTemplate($emailTemplateInput: EmailTemplateInput) {
    createEmailTemplate(emailTemplateInput: $emailTemplateInput) {
      ok
      emailTemplate{
        ${EMAIL_TEMPLATES_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;
export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation updateEmailTemplate($emailTemplateID:ID, $emailTemplateInput: EmailTemplateInput) {
    updateEmailTemplate(emailTemplateID:$emailTemplateID, emailTemplateInput: $emailTemplateInput) {
      ok
      emailTemplate{
        ${EMAIL_TEMPLATES_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;
export const DELETE_EMAIL_TEMPLATE = gql`
  mutation deleteEmailTemplate($emailTemplateID: ID!) {
    deleteEmailTemplate(emailTemplateID: $emailTemplateID) {
      ok
      emailTemplate {
        _id
      }
      returnMessage
    }
  }
`;
//#endregion

//#region INVOICE

export const CREATE_INVOICE = gql`
  mutation createInvoice($invoiceInput: InvoiceInput) {
    createInvoice(invoiceInput: $invoiceInput){
      ok
      invoice {
        ${INVOICE_BASE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const REGENERATE_INVOICE = gql`
  mutation regenerateValues($regenerateInput: RegenerateInvoiceInput) {
    regenerateValues(regenerateInput: $regenerateInput){
      ok
      invoice {
        ${INVOICE_BASE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const INVOICE_ADD_COMMENT = gql`
  mutation invoiceAddComment($invoiceId: ID!, $input: CommentInput!, $hasTask: Boolean) {
    invoiceAddComment(invoiceId: $invoiceId, input: $input, hasTask: $hasTask) {
      ${COMMENT_ATTRIBUTES}
    }
  }
`;

export const INVOICE_EDIT_COMMENT = gql`
  mutation invoiceEditComment($invoiceId: ID!, $commentId: ID!, $input: CommentInput!) {
    invoiceEditComment(invoiceId: $invoiceId, commentId: $commentId, input: $input) {
      ok
      comment {
        ${COMMENT_ATTRIBUTES}
      }
      message
    }
  }
`;

export const INVOICE_DELETE_COMMENT = gql`
  mutation invoiceDeleteComment($invoiceId: ID!, $commentId: ID!, $userFullName: String!) {
    invoiceDeleteComment(invoiceId: $invoiceId, commentId: $commentId, userFullName: $userFullName){
      ok
      comment {
        ${COMMENT_ATTRIBUTES}
      }
      message
    }
  }
`;

export const INVOICE_CLOSE_COMMENT_TASK = gql`
  mutation invoiceCloseCommentTask($commentId: ID!, $username: String!) {
    invoiceCloseCommentTask(commentId: $commentId, username: $username){
      ${COMMENT_ATTRIBUTES}
    }
  }
`;

export const FUNDS_TRANSFER_MARK = gql`
  mutation fundsTransfer(
    $filter: InvoiceSearchFilter
    $fundsTransferred: String
  ) {
    fundsTransferMark(filter: $filter, fundsTransferred: $fundsTransferred) {
      ... on SimplePayload {
        ok
        returnMessage
      }
      ... on FilePayload {
        ok
        returnMessage
        file {
          filename
          mimetype
          encoding
        }
      }
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($id:ID! $invoiceInput:InvoiceInput){
    updateInvoice(id:$id,invoiceInput:$invoiceInput){
      ok
      invoice{
        ${INVOICE_BASE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

//#endregion

//#region Amadeus

export const GENERATE_VIRTUAL_CARD = gql`
  mutation GenerateVirtualCard(
    $payload:VCInput!
  ) {
    GenerateVirtualCard(
      payload:$payload
    ) {
      data {
        ${VIRTUAL_CARD_ATTRIBUTES}
      }
      ok
      returnMessage
    }
  }
`;

export const DELETE_VIRTUAL_CARD = gql`
  mutation DeleteVirtualCard(
    $reason: String
    $amadeusReference: String
    $externalReference: String
  ) {
    DeleteVirtualCard(
      reason: $reason
      amadeusReference: $amadeusReference
      externalReference: $externalReference
    ) {
      data {
        ${VIRTUAL_CARD_ATTRIBUTES}
      }
      ok
      returnMessage
    }
  }
`;
export const UPDATE_VIRTUAL_CARD = gql`
  mutation UpdateVirtualCard(
    $payload:UpdateVCInput
  ) {
    UpdateVirtualCard(
      payload:$payload
    ) {
      data {
        ${VIRTUAL_CARD_ATTRIBUTES}
      }
      ok
      returnMessage
    }
  }
`;
export const SCHEDULE_VIRTUAL_CARD = gql`
  mutation ScheduleVirtualCardLoad($payload: ScheduleLoadInput) {
    ScheduleVirtualCardLoad(payload: $payload) {
      data
      ok
      returnMessage
    }
  }
`;

//#endregion

export const CREATE_CURRENCY = gql`
  mutation CreateCurrency($input: CurrencyInput) {
    createCurrency(input: $input) {
      ok
      currency {
        name
        value
      }
      returnMessage
    }
  }
`;
export const UPDATE_CURRENCY = gql`
  mutation UpdateCurrency($input: CurrencyInput) {
    updateCurrency(input: $input) {
      ok
      currency {
        name
        value
      }
      returnMessage
    }
  }
`;
