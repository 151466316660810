// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "reactstrap";

// App
import { TourOperatorContext } from "../TourOperator";
import Loader from "../../ui/Loader";
import { SortableHeader } from "../../ui/table-utils/SortableHeader";

const SortKeys = Object.freeze({
  REGISTERED: "REGISTERED",
  VERIFIED: "VERIFIED"
});

export function TourOperatorUserTable(props) {
  const {
    state: { users, fallback, loading },
    dispatch
  } = useContext(TourOperatorContext);

  const [sortOn, setSortOn] = useState(SortKeys.REGISTERED);
  const [sortAscending, setSortAscending] = useState(null);
  const [sortedUsers, setSortedUsers] = useState([]);

  useEffect(() => {
    /**
     * @param {{email:string, registered:boolean, verified: boolean|null, isNew: boolean}} a
     * @param {{email:string, registered:boolean, verified: boolean|null, isNew: boolean}} b
     */
    function tableSorter(a, b) {
      // Sort new users at the top as default
      if (sortAscending === null && a.isNew !== b.isNew)
        return a.isNew ? -1 : 1;
      switch (sortOn) {
        case SortKeys.REGISTERED:
          if (a.registered !== b.registered)
            return a.registered ^ sortAscending ? -1 : 1;
          break;
        case SortKeys.VERIFIED:
          if (a.verified !== b.verified) {
            // Sort non-registered users at the bottom as default
            return a.verified === null
              ? 1
              : b.verified === null
              ? -1
              : a.verified ^ sortAscending
              ? -1
              : 1;
          }
          break;
        default:
      }
      return 0;
    }

    const userListCopy = [...users];
    userListCopy.forEach(
      (user) => (user.isNew = !fallback.some((v) => v.email === user.email))
    );
    userListCopy.sort(tableSorter);

    setSortedUsers(userListCopy);
  }, [sortOn, sortAscending, users, fallback]);

  function handleSortableHeaderOnClick(key) {
    if (sortOn === key) setSortAscending((prev) => !prev);
    else {
      setSortOn(key);
      setSortAscending(false);
    }
  }

  return (
    <Table striped borderless hover className="text-left">
      <thead>
        <tr>
          <th>Email</th>
          <SortableHeader
            activeSorting={sortOn}
            ascending={sortAscending}
            sortOn={SortKeys.REGISTERED}
            onClick={handleSortableHeaderOnClick}
          >
            Registered
          </SortableHeader>
          <SortableHeader
            activeSorting={sortOn}
            ascending={sortAscending}
            sortOn={SortKeys.VERIFIED}
            onClick={handleSortableHeaderOnClick}
          >
            Account verified
          </SortableHeader>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody className="border">
        {loading ? (
          <tr>
            <td colSpan={6} className="text-center">
              <Loader />
            </td>
          </tr>
        ) : !sortedUsers.length ? (
          <tr>
            <td colSpan={6} className="text-center">
              No items added
            </td>
          </tr>
        ) : (
          sortedUsers.map((user, i) => (
            <tr key={i}>
              <td>{user.email}</td>
              <td
                className={`font-weight-bold ${
                  user.registered === null
                    ? "text-danger"
                    : user.registered
                    ? "text-success"
                    : "text-danger"
                }`}
              >
                {user.registered === null ? "×" : user.registered ? "✓" : "×"}
              </td>
              <td
                className={`font-weight-bold ${
                  user.verified === null
                    ? "text-danger"
                    : user.verified
                    ? "text-success"
                    : "text-danger"
                }`}
              >
                {user.verified === null ? "×" : user.verified ? "✓" : "×"}
              </td>
              <td className="font-weight-bold text-success">
                {fallback.some((fb) => fb.email === user.email) ? (
                  ""
                ) : (
                  <small>NEW</small>
                )}
              </td>
              <td
                className="cursor-pointer container-hover__highlight-inner-target invert"
                onClick={() =>
                  dispatch({ type: "REMOVE_EMAIL", payload: user.email })
                }
              >
                <FontAwesomeIcon
                  icon="trash-alt"
                  size="sm"
                  className="text-muted cursor-pointer highlight-target"
                />
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
}
