import React from "react";
import SVG from "react-inlinesvg";
import rateIconEarlyBird from "../../../../../../../images/rates/rate-early_bird.svg";
import rateIconStandard from "../../../../../../../images/rates/rate-standard.svg";
import rateIconTwilight from "../../../../../../../images/rates/rate-twilight.svg";

function DayType({ dayType }) {
  function getDayTypeIcon(dayType) {
    switch (dayType.type) {
      case "EARLY_BIRD":
        return rateIconEarlyBird;
      case "TWILIGHT":
        return rateIconTwilight;
      case "STANDARD":
        return rateIconStandard;
      default:
        // this is where custom dayTypes will get their icon
        return rateIconStandard;
    }
  }
  return (
    <>
      <span className="icon-container mr-1">
        <SVG className="icon" src={getDayTypeIcon(dayType)} />
      </span>
      {dayType.name}
    </>
  );
}

export default DayType;
