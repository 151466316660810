import { useQuery } from "@apollo/client";
import React, { createContext, useReducer, useState } from "react";
import {
  Button,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Card,
  CardBody
} from "reactstrap";

import Template from "./templates/Template";
import {
  GET_EMAIL_RECIPIENTS,
  GET_EMAIL_TEMPLATES
} from "../../common/Queries";
import Loader from "../ui/Loader";
import Recipients from "./recipients/RecipientList";
import emailReducer from "./emailReducer";

/** @type { EmailContext } */
export const EmailContext = createContext(null);

const Email = () => {
  // queries
  const { loading: loadingRecipients } = useQuery(GET_EMAIL_RECIPIENTS, {
    onCompleted: (data) => {
      dispatch({ type: "INIT_RECIPIENTS", payload: data.getEmailRecipients });
    }
  });
  const { loading: loadingTemplates } = useQuery(GET_EMAIL_TEMPLATES, {
    onCompleted: (data) => {
      dispatch({ type: "INIT_TEMPLATES", payload: data.getEmailTemplates });
    }
  });

  // handlers
  const handleNavLinkClick = (index) => {
    setActiveTab(index);
  };
  const handleAddTemplate = () => {
    // push to existing state templates
    const newTemplate = {
      name: "New template",
      subject: "",
      emailBody: "",
      recipientList: []
    };
    dispatch({
      type: "ADD_DUMMY_TEMPLATE",
      payload: newTemplate
    });
    handleNavLinkClick(state.templates.length + 2);
  };
  const isAddTemplateDisabled = () => {
    const { templates } = state;
    if (templates && templates.length) {
      const newTemplates = templates.filter((c) => c._id === undefined);
      return newTemplates && newTemplates.length === 1;
    }
    return false;
  };

  //reducer
  const initialData = {
    recipients: [],
    templates: []
  };
  const [state, dispatch] = useReducer(emailReducer, initialData);

  // state
  const [activeTab, setActiveTab] = useState(1);

  if (loadingRecipients || loadingTemplates) return <Loader fullscreen />;
  return (
    <EmailContext.Provider
      value={{
        emailsState: state,
        emailsDispatch: dispatch,
        handleNavLinkClick
      }}
    >
      <div className="Email page">
        <h1>Email Page</h1>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === 1 ? "active" : ""}
              onClick={() => handleNavLinkClick(1)}
            >
              Email settings
            </NavLink>
          </NavItem>
          {state.templates.map((template, index) => (
            <NavItem key={template._id || index}>
              <NavLink
                className={activeTab === index + 2 ? "active" : ""}
                onClick={() => handleNavLinkClick(index + 2)}
              >
                {template.name}
              </NavLink>
            </NavItem>
          ))}
          <NavItem>
            <Button
              color="link"
              onClick={handleAddTemplate}
              disabled={isAddTemplateDisabled()}
            >
              + Add new template
            </Button>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <Card>
              <CardBody className="px-4">
                <Recipients />
              </CardBody>
            </Card>
          </TabPane>
          {state.templates.map((template, index) => (
            <TabPane tabId={index + 2} key={template._id || index}>
              <Card>
                <CardBody>
                  <Template data={template} />
                </CardBody>
              </Card>
            </TabPane>
          ))}
        </TabContent>
      </div>
    </EmailContext.Provider>
  );
};

export default Email;
