// Vendor
import React, { useContext } from "react";
import { Row, Col, Input } from "reactstrap";
import Select from "react-select";
import { useQuery } from "@apollo/client";

// App
import DateRangeFacet from "../ui/facet/DateRangeFacet";
import { NumberRangeFacet } from "../ui/facet/NumberRangeFacet";
import { TriStateButtonGroup } from "../ui/FunctionalComponents";
import { GolfersUsersContext } from "./GolfersUserList";
import { LIST_ALL_BOOKED_COURSES } from "../../common/Queries";

export function GolfersUserSearch() {
  const {
    state: { filter },
    dispatch
  } = useContext(GolfersUsersContext);

  const { data, loading } = useQuery(LIST_ALL_BOOKED_COURSES);

  const {
    email,
    placedOrders,
    avgPlayersPerOrder,
    cancellationRate,
    lastOrderDate,
    coursesInclude,
    coursesExclude
  } = filter;

  function handleCoursesOnChange(e, include = true) {
    dispatch({
      type: `${include ? "INCLUDE" : "EXCLUDE"}_COURSES`,
      payload: e?.map(c => c.value)
    });
  }

  /**
   *
   * @param { string } dispatchType
   * @param { {from: number, to?: number }} numberRange
   */
  function handleRangeOnChange(dispatchType, { from, to }) {
    let payload;
    if (!from && !to) payload = null;
    else if (!to) payload = { min: Number(from) };
    else payload = { min: Number(from ?? 0), max: Number(to) };
    dispatch({
      type: dispatchType,
      payload
    });
  }

  function handleEmailOnChange(e) {
    const {
      target: {
        value,
        validity: { valid }
      }
    } = e;
    if (valid) {
      dispatch({ type: "USER_EMAIL", payload: value });
    }
  }

  return (
    <div>
      <Row>
        <Col md={3}>
          <label htmlFor="user-email">User email</label>
          <Input
            id="user-email"
            className="mb-2"
            value={email ?? ""}
            type="text"
            placeholder="Email"
            pattern="^(\w|\.)*@?\w*(\.[A-Za-z]{0,4})?$"
            onChange={handleEmailOnChange}
          />
        </Col>
        <Col sm={1} md={2}>
          <TriStateButtonGroup
            id="btn-group-registered"
            label="Registered"
            value={filter.registered}
            onChange={val => dispatch({ type: "REGISTERED", payload: val })}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <NumberRangeFacet
            min={0}
            value={placedOrders}
            onChange={valueRange =>
              handleRangeOnChange("PLACED_ORDERS", valueRange)
            }
            label="Placed orders"
          />
        </Col>
        <Col md={3}>
          <NumberRangeFacet
            min={0}
            step={0.1}
            value={avgPlayersPerOrder}
            onChange={valueRange =>
              handleRangeOnChange("AVERAGE_PLAYER_COUNT", valueRange)
            }
            label="Average players per order"
          />
        </Col>
        <Col md={3}>
          <NumberRangeFacet
            min={0}
            value={cancellationRate}
            onChange={valueRange =>
              handleRangeOnChange("CANCELLATION_RATE", valueRange)
            }
            label="Cancellation rate"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Last order made</label>
          <DateRangeFacet
            small
            startDate={lastOrderDate?.startDate}
            endDate={lastOrderDate?.endDate}
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            startDateId="last-order-made-from"
            endDateId="last-order-made-to"
            onDatesChange={dateRange =>
              dispatch({ type: "LAST_ORDER_DATE", payload: dateRange })
            }
            showClearDates
            isOutsideRange={() => false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="courses-booked">Courses booked</label>
          <Select
            id="courses-booked"
            isDisabled={loading}
            options={
              data?.allBookedCourses
                .filter(c => !coursesExclude.includes(c._id))
                .map(course => ({ label: course.name, value: course._id })) ??
              []
            }
            onChange={handleCoursesOnChange}
            value={
              data?.allBookedCourses
                .filter(c => coursesInclude.includes(c._id))
                .map(course => ({
                  label: course.name,
                  value: course._id
                })) ?? []
            }
            isMulti
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="courses-not-booked">Courses never booked</label>
          <Select
            id="courses-not-booked"
            isDisabled={loading}
            options={
              data?.allBookedCourses
                .filter(c => !coursesInclude.includes(c._id))
                .map(course => ({ label: course.name, value: course._id })) ??
              []
            }
            onChange={e => handleCoursesOnChange(e, false)}
            value={
              data?.allBookedCourses
                .filter(c => coursesExclude.includes(c._id))
                .map(course => ({
                  label: course.name,
                  value: course._id
                })) ?? []
            }
            isMulti
          />
        </Col>
      </Row>
    </div>
  );
}
