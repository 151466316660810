// Vendor
import React, { useState, useContext } from "react";
import { AvField } from "availity-reactstrap-validation";

// App
import { ClubConnectedAreasList } from "../../../ui/FunctionalComponents";
import { ClubCourseContext } from "../ClubCourse";

function ClubCourseAreaList(props) {
  const { state, dispatch } = useContext(ClubCourseContext);
  const areas = state.areas || [];

  const [inputValue, setInputValue] = useState("");

  function handleInputKeyPress(e) {
    const area = inputValue.trim();

    // charCode 13 === enter
    if (e.charCode === 13) {
      // Prevent enter from trigger a save
      e.preventDefault();

      // Ignore duplicate entries. Case insensitive
      if (area.length > 0 && areaIsUnique(area)) {
        setInputValue("");
        dispatch({ type: "AREAS", payload: [...areas, area] });
      }
    }
  }

  function areaIsUnique(newArea) {
    return !areas.some(area => new RegExp(`^${newArea}$`, "i").test(area));
  }

  return (
    <div className="ClubAreas mt-5">
      <h3>Areas</h3>
      <AvField
        className="form-control-lg"
        name="club_areaInput"
        id="club_areaInput"
        value={inputValue}
        placeholder="Enter new area"
        onChange={e => setInputValue(e.target.value)}
        onKeyPress={handleInputKeyPress}
      />
      <ClubConnectedAreasList
        areas={areas}
        onClickHandler={area =>
          dispatch({
            type: "AREAS",
            payload: areas.filter(item => item !== area)
          })
        }
      />
    </div>
  );
}

export default ClubCourseAreaList;
