export const RateType = Object.freeze({
    NONE: 0,
    GREENFEE_18: 1,
    GREENFEE_9: 2,
    ONE_GREENFEE_ONE_BUGGY: 4,
    TWO_GREENFEE_ONE_BUGGY: 8,
    FOUR_GREENFEE_TWO_BUGGY: 16,
    BUGGY: 32
});

export const ScheduleType = Object.freeze({
    STANDARD: 1,
    WEEKEND: 2,
    CAMPAIGN: 4,
    REOCCURRING: 8,
    HOLIDAY: 16
});

export const ImportantEmails = Object.freeze([
    "Invoicing Email Template",
    "Invoice Warning Email",
]);
