// Vendor
import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { Image } from "cloudinary-react";

// App
// import { CLOUDINARY_PATH_VALIDATOR } from "../../common/Validators";
import { InputField } from "./FunctionalComponents";
import CloudinaryHelper from "../../common/CloudinaryHelper";
import useDebounce from "../../common/useDebounce";

/**
 * @param { Object } props
 * @param { string } [props.className]
 * @param { string } [props.publicId]
 * @param { string } [props.fallback]
 * @param { string } props.onChangeTargetKey
 * @param { VoidCallback<{key: string, value: string }> } props.onChange
 * @param { boolean } [props.required]
 * @param { number } [props.debounceDelay] Delay between user input and trigger of onChange event
 */
export function ImageIdCard({
  className = "",
  publicId = "",
  fallback = CloudinaryHelper.FALLBACK_IMG,
  onChangeTargetKey,
  onChange,
  required = false,
  debounceDelay = 750
}) {
  const [imageId, setImageId] = useState(publicId);
  const debouncedImageId = useDebounce(imageId, debounceDelay);

  function onChangeHandler(keyValuePair) {
    setImageId(keyValuePair.value);
  }

  useEffect(() => {
    setImageId(publicId);
  }, [publicId]);

  useEffect(() => {
    onChange({ key: onChangeTargetKey, value: debouncedImageId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedImageId, onChangeTargetKey]);

  return (
    <Card className={className} style={{ backgroundColor: "#dadada" }}>
      <Image
        className="w-100 border border-bottom-0"
        publicId={publicId.length ? publicId : fallback}
        responsive
      />
      <InputField
        containerClassName="mb-0"
        className="rounded-0"
        targetKey={onChangeTargetKey}
        value={imageId}
        placeholder="Cloudinary public id"
        label="Cloudinary public id"
        // Validation disabled due to the validator freezing the page for unclear reasons
        // validate={CLOUDINARY_PATH_VALIDATOR}
        required={required}
        onChange={onChangeHandler}
      />
    </Card>
  );
}
