import { useLazyQuery } from "@apollo/client";
import React, { useContext } from "react";
import { Button } from "reactstrap";
import { GET_PDF_FILE } from "../../../common/Queries";
import { InvoiceContext } from "../Invoice";
import { LayoutContext } from "../../app/Layout";

/** @param {Boolean} subInvoice  */

const PdfGenerator = ({ subInvoice }) => {
  // getting invoice id from invoice
  const { invoice, editing } = useContext(InvoiceContext);
  const { addAlert } = useContext(LayoutContext);
  const handleGetPdf = (type) => {
    getFile({
      variables: {
        id: invoice._id,
        fileType: type
      }
    });
  };
  const handleOnCompleted = (res) => {
    if (res.getFile.ok) {
      // Decode the base64 string into a pdf file using Buffer from
      const decodedPdf = Buffer.from(res.getFile.file.encoding, "base64");
      // Create a blob of the pdf file
      const blob = new Blob([decodedPdf], { type: "application/pdf" });
      // Create a blob URL for the file
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      // save the file to the user's computer
    } else {
      // add alert
      addAlert({
        color: "danger",
        message: "Error while generating pdf file"
      });
    }
  };
  // query to get pdf
  const [getFile] = useLazyQuery(GET_PDF_FILE, {
    onCompleted: handleOnCompleted,
    onError: () => {
      addAlert({
        color: "danger",
        message: "Error while generating pdf file"
      });
    }
  });
  return (
    <div className="d-flex flex-wrap">
      {subInvoice ? (
        <Button
          className="mb-2"
          onClick={() => handleGetPdf("subinvoice")}
          disabled={editing}
        >
          Sub Invoice
        </Button>
      ) : (
        <div className="d-flex gap-2">
          <Button
            onClick={() => handleGetPdf("disbursement")}
            disabled={editing}
          >
            Disbursement
          </Button>
          <Button
            onClick={() => handleGetPdf("invoice")}
            disabled={editing}
          >
            Invoice
          </Button>
          <Button
            onClick={() => handleGetPdf("specification")}
            disabled={editing}
          >
            Specification
          </Button>
        </div>
      )}
    </div>
  );
};

export default PdfGenerator;
