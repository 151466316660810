export default function affiliateReducer(state, action) {
    switch (action.type) {
        case "INIT":
            return { ...action.payload, loaded: true };
        case "NAME":
            return { ...state, name: action.payload };
        case "CID":
            return { ...state, cid: action.payload };
        case "AID":
            return { ...state, aid: action.payload };
        case "EXCLUDED_TEESHEET_PROVIDERS":
            return {
                ...state, excludedTeesheetProviders: action.payload.checked ?
                    [...state.excludedTeesheetProviders.filter(x => x !== action.payload.name)] :
                    [...state.excludedTeesheetProviders, action.payload.name]
            };
        case "ISACTIVE":
            return { ...state, isActive: action.payload };
        case "MODAL":
            return { ...state, modal: action.payload };
        default: return;
    }
}