// Vendor
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Table, Input, Form } from "reactstrap";
import { Link } from "react-router-dom";
import { StatusIcon } from "./StatusIcon";

const ClubsList = ({ items, filter }) => {
  let clubs = items.filter(item => {
    if (filter === "") return true;

    let match = false;

    for (var key in item) {
      // check also if property is not inherited from prototype
      if (item.hasOwnProperty(key)) {
        var value = item[key];
        if (typeof value === "string") {
          // set as match if the string is found as part of any prop value
          if (value.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            match = true;
          }
        }
      }
    }

    return match;
  });

  return (
    <Fragment>
      <thead>
        <tr>
          <th>Name</th>
          <th>City</th>
          <th>Country</th>
          <th>Activated</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {clubs.map((club, index) => (
          <tr key={index}>
            <td>{club.name}</td>
            <td>{club.city}</td>
            <td>{club.country}</td>
            <td>
              <StatusIcon value={club.activated} />
            </td>
            <td>
              <Link to={"/club/" + club.slug}>Edit</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Fragment>
  );
};

const InvoicesList = ({ items }) => (
  <Fragment>
    <thead>
      <tr>
        <th>Invoice nr</th>
        <th>Club</th>
        <th>Date</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, index) => (
        <tr key={index}>
          <td>{item.id}</td>
          <td>{item.clubName}</td>
          <td>{item.date}</td>
          <td>{item.amount}</td>
        </tr>
      ))}
    </tbody>
  </Fragment>
);

const OrdersList = ({ items }) => (
  <Fragment>
    <thead>
      <tr>
        <th>Order ref</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>To Pay On Site</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, index) => (
        <tr key={index}>
          <td>{item.id}</td>
          <td>{item.firstName}</td>
          <td>{item.lastName}</td>
          <td>{item.toPayOnSite}</td>
          <td>{item.status}</td>
        </tr>
      ))}
    </tbody>
  </Fragment>
);

const UsersList = ({ items }) => (
  <Fragment>
    <thead>
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Language</th>
        <th>Has Account</th>
        <th>Active Account</th>
        <th>Register Date</th>
        <th>Last Activity</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, index) => (
        <tr key={index}>
          <td>{item.firstName}</td>
          <td>{item.lastName}</td>
          <td>{item.language}</td>
          <td>
            <StatusIcon value={item.hasAccount} />
          </td>
          <td>
            <StatusIcon value={item.activated} />
          </td>
          <td>{item.registerDate}</td>
          <td>{item.lastActivity}</td>
        </tr>
      ))}
    </tbody>
  </Fragment>
);

const debounce = (fn, delay) => {
  let timer = null;
  return function(...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

class ListFactory extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired
  };
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      filter: ""
    };

    this.getList = this.getList.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.updateFilter = debounce(this.updateFilter, 100);
  }

  getList() {
    const { items } = this.props;

    switch (this.props.type) {
      case "users":
        return <UsersList items={items} />;
      case "clubs":
        return <ClubsList items={items} filter={this.state.filter} />;
      case "orders":
        return <OrdersList items={items} />;
      case "invoices":
        return <InvoicesList items={items} />;
      default:
        return null;
    }
  }

  handleFilterChange(elem) {
    this.updateFilter(elem.target.value);
  }

  updateFilter(filter) {
    this.setState({ filter });
  }

  render() {
    return (
      <div>
        <Form inline>
          <Input
            placeholder="Filter by any field"
            className="mb-2"
            onChange={this.handleFilterChange}
          />
        </Form>
        <Table borderless striped>
          {this.getList()}
        </Table>
      </div>
    );
  }
}

export default ListFactory;
