// Vendor
import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
// App
import { GolfDestinationContext } from "../GolfDestination";
import { InputField } from "../../ui/FunctionalComponents";
import { INT_VALIDATOR } from "../../../common/Validators";

function KeyFigureList(props) {
  const { state, dispatch } = useContext(GolfDestinationContext);

  return (
    <div className="mb-5">
      <h3 className="mb-3">Key Figures</h3>
      <Row>
        <Col md={3}>
          <InputField
            onChange={event =>
              dispatch({
                type: "KEYFIGURES",
                payload: { key: "courses", value: event.value }
              })
            }
            targetKey="courses"
            value={state.keyFigures.courses}
            label="Golf courses"
            validate={INT_VALIDATOR}
            required
            type="number"
          />
        </Col>
        <Col md={3}>
          <InputField
            onChange={event =>
              dispatch({
                type: "KEYFIGURES",
                payload: { key: "sunnyDays", value: event.value }
              })
            }
            targetKey="sunnyDays"
            value={state.keyFigures.sunnyDays}
            label="Sunny days"
            validate={INT_VALIDATOR}
            required
            type="number"
          />
        </Col>
        <Col md={3}>
          <InputField
            onChange={event =>
              dispatch({
                type: "KEYFIGURES",
                payload: { key: "beerPrice", value: event.value }
              })
            }
            targetKey="beerPrice"
            value={state.keyFigures.beerPrice}
            label="Beer price (pint)"
            required
            type="number"
          />
        </Col>
        <Col md={3}>
          <InputField
            onChange={event =>
              dispatch({
                type: "KEYFIGURES",
                payload: { key: "taxiPrice", value: event.value }
              })
            }
            targetKey="taxiPrice"
            value={state.keyFigures.taxiPrice}
            label="Taxi (1km)"
            required
            type="number"
          />
        </Col>
      </Row>
    </div>
  );
}

export default KeyFigureList;
