// Vendor
import React, { useContext, useState, useEffect } from "react";
import { InputField, LanguageDropdown } from "../../ui/FunctionalComponents";
import { GolfDestinationContext } from "../GolfDestination";
import Switch from "react-switch";

// App
import CurrencySelect from "../../ui/CurrencySelect";
import { ImageIdCard } from "../../ui/ImageIdCard";
import FlagIcon from "../../ui/FlagIcon";
import {
  SLUG_VALIDATOR,
  CLUBNAME_VALIDATOR,
  INT_VALIDATOR
} from "../../../common/Validators";
import CountryCollection from "../../../common/CountryCollection";
import variables from "../../../styles/bootstrap/_variables.scss";

function GeneralInfo() {
  const { state, dispatch } = useContext(GolfDestinationContext);
  const {
    imgId,
    name,
    slug,
    currency,
    country,
    coordinates,
    radius,
    zoom,
    published
  } = state;

  // State hooks
  const [countryOpen, setCountryOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    let match = CountryCollection.find(c => c.name === country);
    if (match) setSelectedCountry(getCountryItem(match));
    setCountries(CountryCollection.map(c => getCountryItem(c)));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function setCurrency(event) {
    dispatch({ type: "CURRENCY", payload: event });
  }

  function handleCountryChange(item) {
    setSelectedCountry(item);
    dispatch({ type: "COUNTRY", payload: item.name });
    dispatch({ type: "COUNTRY_CODE", payload: item.code });
  }

  function getCountryItem(country) {
    return {
      name: country.name,
      code: country.code,
      jsx: (
        <>
          <FlagIcon code={country.code} className="mr-1" />
          <span>{country.name}</span>
        </>
      )
    };
  }

  function toggleCountryOpen() {
    setCountryOpen(!countryOpen);
  }

  return (
    <div className="mt-4 mb-5">
      <h3>General Information</h3>
      <div className="row mt-3">
        <div className="col-md mb-3">
          <ImageIdCard
            publicId={imgId}
            onChange={event =>
              dispatch({
                type: "IMAGE_ID",
                payload: event.value
              })
            }
            onChangeTargetKey="imgId"
          />
        </div>
        <div className="col-md">
          <InputField
            targetKey="name"
            label="Name"
            value={name}
            validate={CLUBNAME_VALIDATOR}
            onChange={event => dispatch({ type: "NAME", payload: event.value })}
          />
          <InputField
            targetKey="slug"
            label="Slug"
            value={slug}
            validate={SLUG_VALIDATOR}
            onChange={event => dispatch({ type: "SLUG", payload: event.value })}
          />

          <div className="row">
            <div className="col">
              <LanguageDropdown
                text={country}
                label="Country"
                items={countries}
                selectedItem={selectedCountry}
                onChange={handleCountryChange}
                id="destination-country"
                isOpen={countryOpen}
                toggle={toggleCountryOpen}
              />
            </div>
            <div className="col">
              <CurrencySelect
                value={currency}
                onChange={value => setCurrency(value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <InputField
                targetKey="latitude"
                value={coordinates.latitude}
                label="Latitude"
                onChange={event =>
                  dispatch({
                    type: "COORDINATES",
                    payload: { key: "latitude", value: event.value }
                  })
                }
                required
                type="number"
              />
            </div>
            <div className="col">
              <InputField
                targetKey="longitude"
                value={coordinates.longitude}
                label="Longitude"
                onChange={event =>
                  dispatch({
                    type: "COORDINATES",
                    payload: { key: "longitude", value: event.value }
                  })
                }
                required
                type="number"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <InputField
                targetKey="radius"
                value={radius}
                label="Radius (km)"
                onChange={event =>
                  dispatch({ type: "RADIUS", payload: event.value })
                }
                required
                type="number"
              />
            </div>
            <div className="col">
              <InputField
                targetKey="zoom"
                value={zoom}
                label="Zoom level (0-19)"
                onChange={event =>
                  dispatch({ type: "ZOOM", payload: event.value })
                }
                validate={INT_VALIDATOR}
                required
                type="number"
              />
            </div>
          </div>

          <div className="switch-group">
            <Switch
              id="switch-published"
              checked={published}
              onChange={() =>
                dispatch({ type: "PUBLISHED", payload: !published })
              }
              uncheckedIcon={false}
              checkedIcon={false}
              onColor={variables.primary}
              offColor={variables.gray300}
              width={50}
            />
            <label
              htmlFor="switch-published"
              className={`${published ? "" : "text-muted"}`}
            >
              {published ? "Published" : "Hidden"}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralInfo;
