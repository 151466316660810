// Vendor
import React, { useContext } from "react";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import moment from "moment";

// App
import { GolfersUsersContext } from "./GolfersUserList";
import { SortableHeader } from "../ui/table-utils/SortableHeader";
import Loader from "../ui/Loader";

/**
 *
 * @param { Object } props
 * @param { boolean } props.loading Search in transit
 * @param { { string }? } props.error Error, if any, with error message
 */
export function GolfersUserListTable({ loading, error }) {
  const {
    state: {
      users,
      hits,
      sorting: { sortOn, ascending }
    },
    dispatch
  } = useContext(GolfersUsersContext);

  function onSortChange(sortOnInput) {
    dispatch({
      type: "SET_SORTING",
      payload: {
        sortOn: sortOnInput,
        ascending: sortOnInput === sortOn ? !ascending : false
      }
    });
  }

  function MakeSortableHeader({ children, sortKey }) {
    return (
      <SortableHeader
        activeSorting={sortOn}
        ascending={ascending}
        sortOn={sortKey}
        onClick={onSortChange}
      >
        {children}
      </SortableHeader>
    );
  }

  return (
    <div className="mt-3">
      <span className="mb-2 font-weight-bold">Hits: {hits}</span>
      {loading && (
        <div className="my-2 d-flex font-weight-bold font-italic text-muted ">
          <span className="mr-2">
            <Loader size={30} />
          </span>
          Searching...
        </div>
      )}
      {error && <p className="my-2 text-danger">{error.message}</p>}
      <Table size="sm" striped hover>
        <thead>
          <tr>
            <th>Email</th>
            <MakeSortableHeader sortKey="DISTINCT_COURSES">
              Distinct courses
            </MakeSortableHeader>
            <MakeSortableHeader sortKey="ORDER_COUNT">
              Order count
            </MakeSortableHeader>
            <MakeSortableHeader sortKey="PLAYERS_PER_ORDER_AVG">
              Players/Order
            </MakeSortableHeader>
            <MakeSortableHeader sortKey="TEETIME_COUNT">
              Teetime count
            </MakeSortableHeader>
            <MakeSortableHeader sortKey="CANCELLATION_RATIO">
              Cancellation rate
            </MakeSortableHeader>
            <MakeSortableHeader sortKey="LAST_ORDER_DATE">
              Last order date
            </MakeSortableHeader>
            <th>Registered user</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody className="border">
          {users.map((user, index) => (
            <tr key={index}>
              <td>{user.email}</td>
              <td>{user.bookedCourses}</td>
              <td>{user.placedOrders}</td>
              <td>{user.avgPlayersPerOrder.toFixed(2)}</td>
              <td>{user.teetimeCount}</td>
              <td>{`${(user.cancellationRate * 100).toFixed(2)}%`}</td>
              <td>{moment(user.lastOrderDate).format("YYYY-MM-DD")}</td>
              <td>
                <FontAwesomeIcon
                  icon={user.userId !== null ? "check-circle" : "ban"}
                  className={`text-${
                    user.userId !== null ? "success" : "danger"
                  }`}
                />
              </td>
              <td>
                {user.userId && (
                  <Link to={`/golfers-user/${user.userId}`}>Inspect</Link>
                )}
                {!user.userId && (
                  <span style={{ cursor: "default" }}>Inspect</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
