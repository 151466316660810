import React, { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Auth from "../../../common/Auth";

function CommentForm({
  isOpen,
  toggle,
  onAddComment,
  onSaveEditedComment,
  editingComment
}) {
  const [value, setValue] = useState("");
  const [hasTask, setHasTask] = useState(false);

  useEffect(() => {
    if (editingComment) {
      setValue(editingComment.body);
      setHasTask(!!editingComment.task);
    } else {
      setValue("");
      setHasTask(false);
    }
  }, [editingComment]);

  function onSubmit() {
    const user = Auth.getUser();
    const comment = {
      body: value,
      userFullName: `${user.firstName} ${user.lastName}`,
      timestamp: new Date()
    };

    if (editingComment) {
      onSaveEditedComment(editingComment, value);
    } else {
      onAddComment(comment, hasTask);
    }

    setValue("");
    setHasTask(false);
    toggle();
  }

  function handleCtrlEnterSubmit(e) {
    if (e.keyCode === 13 && e.ctrlKey) {
      onSubmit();
    }
  }

  const isSubmitDisabled = editingComment && value === editingComment.body;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      onKeyDown={handleCtrlEnterSubmit}
    >
      <ModalHeader toggle={toggle}>
        {editingComment ? "Edit comment" : "New comment"}
      </ModalHeader>
      <ModalBody>
        <Input
          type="textarea"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {!editingComment && (
          <div className="amenity d-inline-block mt-4">
            <label className="d-flex checkbox-group text-black pointer">
              <span className="amenity-icon mx-auto d-inline-block">
                <FontAwesomeIcon
                  icon="flag"
                  size="lg"
                  className="text-danger"
                />
              </span>
              <span className="amenity-name d-inline-block my-0 mx-2 h5">
                Has related task
              </span>
              <input
                id="comment-has-task-checkbox-1"
                type="checkbox"
                checked={hasTask}
                onChange={(e) => setHasTask(e.target.checked)}
              />
              <label htmlFor="comment-has-task-checkbox-1" />
            </label>
          </div>
        )}
        <Button
          type="button"
          className="float-right m-3"
          onClick={onSubmit}
          disabled={isSubmitDisabled}
        >
          {editingComment ? "Save changes" : "Add comment"}
        </Button>
      </ModalBody>
    </Modal>
  );
}

export default CommentForm;
