import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import { EmailContext } from "../Email";
import Checkbox from "./Checkbox";

const EmailList = (props) => {
  const { template, setEdited, dispatch } = props;
  const { recipientList: templateRecipients } = template;
  // handlers
  const handleClicked = (checked, recipientId) => {
    setEdited(true);
    // updating the template recipients
    dispatch({
      type: "UPDATE_TEMPLATE_RECIPIENTS",
      payload: { checked, recipientId }
    });
  };

  // context
  const { emailsState } = useContext(EmailContext);

  return (
    <Table borderless striped>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Subscribed</th>
        </tr>
      </thead>
      <tbody>
        {emailsState?.recipients.map((recipient) => {
          const checked = templateRecipients.includes(recipient._id);

          return (
            <tr key={recipient._id || recipient.email}>
              <td className="align-middle">
                {recipient.name.firstName + " " + recipient.name.lastName}
              </td>
              <td className="align-middle">{recipient.email}</td>
              <td className="align-middle">
                <Checkbox
                  name={`recipient-${recipient._id}`}
                  value={checked}
                  onChange={(value) => {
                    handleClicked(value, recipient._id);
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

EmailList.propTypes = {
  template: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    emailBody: PropTypes.string.isRequired,
    recipientList: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  setEdited: PropTypes.func,
  dispatch: PropTypes.func
};

export default EmailList;
