// Vendor
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

// App
import Auth from "./Auth";
import URLHelper from "../common/URLHelper";

const api_url = URLHelper.getApiUrl();

const httpLink = new HttpLink({
  uri: api_url
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Auth.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "origin-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  };
});

const link = ApolloLink.from([
  onError(catchAuthErr),
  authLink.concat(httpLink)
]);

class ApolloClientProvider {
  constructor() {
    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache({
        addTypename: false
      })
    });
  }
}

function catchAuthErr({ graphQLErrors }) {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions.code === "UNAUTHENTICATED") {
        Auth.clearStorage();
      }
    }
  }
}

export default new ApolloClientProvider();
