import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { ClubCourseContext } from "../../ClubCourse";
import { getFormattedSeasonType } from "../SeasonHelper";
import DeleteButton from "../../../../ui/table-utils/DeleteButton";

const DayTypesModal = ({ isOpen, toggle, onClose }) => {
  // state and dispatch method from parents
  const {
    state: courseState,
    dispatch: courseDispatch,
    saveCourse
  } = useContext(ClubCourseContext);

  const initalState = {
    name: "",
    type: ""
  };

  // useRef used for info about new day type
  const dayType = useRef(initalState);
  // for toggling add input
  const [toggleAdd, setToggleAdd] = useState(false);
  // state used to trigger save handler in useEffect
  const [saveState, setSaveState] = useState(false);

  const handleChange = (ev) => {
    dayType.current.name = ev.target.value;
    dayType.current.type = getFormattedSeasonType(dayType.current.name);
  };

  const toggleNewType = () => setToggleAdd(!toggleAdd);

  const handleDelete = (id) => {
    courseDispatch({
      type: "SEASON_DAY_TYPES_DELETE",
      payload: {
        id,
        setSaveState
      }
    });
  };
  const handleAdd = () => {
    courseDispatch({
      type: "SEASON_DAY_TYPES_ADD",
      payload: {
        dayType: dayType.current,
        setSaveState
      }
    });
    toggleNewType();
  };

  useEffect(() => {
    if (saveState) {
      saveCourse();
      setSaveState(false);
    }
  }, [courseState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // reseting state when closing modal or toggling edit
    if (!isOpen || !toggleAdd) {
      setToggleAdd(false);
      dayType.current = initalState;
    }
  }, [isOpen, toggleAdd]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="Season">
      <ModalHeader toggle={toggle}>Day types</ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-end w-100">
          {!toggleAdd && (
            <button className="btn btn-primary" onClick={toggleNewType}>
              Add new
            </button>
          )}
        </div>
        {toggleAdd && (
          <div className="d-flex">
            <AvField
              required
              type="text"
              placeholder="Day type"
              name="Day type"
              onChange={handleChange}
            />
            <div className="ml-5">
              <button className="btn btn-primary mr-2" onClick={handleAdd}>
                Save
              </button>
              <button className="btn btn-danger" onClick={toggleNewType}>
                Cancel
              </button>
            </div>
          </div>
        )}

        <AvForm>
          <Table>
            <thead>
              <tr>
                <th>Day Type Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="border">
              {courseState.dayTypes.map((seasonDayType) => {
                return (
                  <tr key={seasonDayType._id}>
                    <td>
                      <div>{seasonDayType.name}</div>
                    </td>
                    <td>
                      <DeleteButton
                        itemInTransit={null}
                        id={seasonDayType._id}
                        handleDelete={handleDelete}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default DayTypesModal;
