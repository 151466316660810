import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { GET_COURSES } from "../../../common/Queries";
import { debounce } from "lodash";
import { useLazyQuery } from "@apollo/client";

const DebouncedDropdownCourses = ({ onSelect, id, className }) => {
  const [courseSearch] = useLazyQuery(GET_COURSES, {
    onError: () => console.log("Error")
  });

  const handleOnChange = (e) => {
    const course = {
      name: e?.label ?? "",
      id: e?.value ?? null
    };
    // there cannot be special characters when sending name to amadues
    // ...probably should be updated with more special characters
    if (course.name.includes("&"))
      course.name = course.name.replace("&", "and").trim();

    // max length is 35 on amadeus api for card name
    if (course.name.length > 35) {
      course.name = course.name.substring(0, 35);
    }
    onSelect(course);
  };

  const handleAction = async (searchTerm) => {
    const result = await courseSearch({ variables: { searchTerm } });
    // Process the result and return an array of options
    const options = result.data.courseSearch.courses.map((course) => ({
      label: course.name,
      value: course._id
    }));
    return options;
  };

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    handleAction(inputValue).then((options) => {
      callback(options);
    });
  }, 500); // Debounce time (in milliseconds)

  const loadOptions = (inputValue, callback) => {
    debouncedLoadOptions(inputValue, callback);
  };

  return (
    <AsyncSelect
      id={id}
      loadOptions={loadOptions}
      placeholder="Search and select a course"
      onChange={handleOnChange}
      isClearable
      className={className}
    />
  );
};

export default DebouncedDropdownCourses;

// PropTypes
DebouncedDropdownCourses.propTypes = {
  onSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string
};

// Usage:
// <DebouncedDropdownCourses
//   onSelect={(course) => {
//     setCourse(course);
//   }}
//   id="input-course-name"
//   className="mb-2"
// />
