// Vendor
import React, { useContext, useState } from "react";
import {
  Button,
  Popover,
  PopoverBody,
  PopoverHeader,
  Tooltip
} from "reactstrap";

// App
import URLHelper from "../../../common/URLHelper";
import Loader from "../../ui/Loader";
import { OrderContext } from "../Order";

export function HandleOfflineOrderButton({ orderStatus, bookingId }) {
  const [btnHover, setBtnHover] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [loading, setLoading] = useState(false);

  const { dispatch } = useContext(OrderContext);

  function toggle() {
    setShowPopover((prev) => !prev);
  }

  function handleConfirmClick() {
    setLoading(true);
    setTimeout(() => {
      setShowPopover(false);
      dispatch({ type: "TRIGGER_REFETCH" });
    }, 5000);
  }

  if (orderStatus !== "PRE_BOOKED") return null;

  return (
    <div className="mb-2">
      <Button
        id={`btn-handle-offline-booking-${bookingId}`}
        type="button"
        size="sm"
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={toggle}
      >
        Force confirm booking
      </Button>

      <Tooltip
        target={`btn-handle-offline-booking-${bookingId}`}
        isOpen={btnHover && !showPopover}
      >
        Manually confirm this offline booking
      </Tooltip>

      <Popover
        target={`btn-handle-offline-booking-${bookingId}`}
        isOpen={showPopover}
        toggle={toggle}
      >
        <PopoverHeader>Manually confirm this booking?</PopoverHeader>
        <PopoverBody className="p-2 d-flex flex-column justify-content-center">
          {loading ? (
            <>
              <Loader />
              Confirming... Please wait
            </>
          ) : (
            <>
              <p>
                <strong>NOTE: </strong>This will open the standard confirmation
                link in a new tab.
              </p>
              <a
                className="btn btn-sm btn-danger"
                href={`https://${
                  URLHelper.isProduction()
                    ? "greenfee365"
                    : "golfers-frontend-stage.onrender"
                }.com/offline-order/${bookingId}/true`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleConfirmClick}
              >
                Confirm
              </a>
            </>
          )}
        </PopoverBody>
      </Popover>
    </div>
  );
}
