export const CardStatusCollection = Object.freeze([
  { value: "", label: "Any" },
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
  { value: "DELETED", label: "Deleted" }
]);

export const CardCurrencyCollection = Object.freeze([
  { value: "", label: "Any" },
  { value: "EUR", label: "EUR" },
  { value: "USD", label: "USD" },
  { value: "GBP", label: "GBP" },
]);

export const CardValidateDates = Object.freeze([
  { value: "6_MO", label: "6 Months" },
  { value: "12_MO", label: "12 Months" }
]);

export const CardTransactionsMax = Object.freeze([
  { value: 5000, label: "5000" },
  { value: 1000, label: "1000" },
  { value: 500, label: "500" },
  { value: 10, label: "10" },
  { value: 0, label: "Single Spend" }
]);

export const CardSubTypeCollection = Object.freeze([
  {
    value: "",
    label: "any"
  },
  {
    value: "Debit",
    label: "Debit"
  },
  {
    value: "Prepaid",
    label: "Prepaid"
  },
  {
    value: "Credit",
    label: "Credit"
  }
]);

export const CardVendorCodeCollection = Object.freeze([
  {
    value: "",
    label: "Select"
  },
  {
    value: "CA",
    label: "Master Card"
  }
]);
