export const WeekdayCollection = Object.freeze([
  {
    value: "MONDAY",
    label: "Monday"
  },
  {
    value: "TUESDAY",
    label: "Tuesday"
  },
  {
    value: "WEDNESDAY",
    label: "Wednesday"
  },
  {
    value: "THURSDAY",
    label: "Thursday"
  },
  {
    value: "FRIDAY",
    label: "Friday"
  },
  {
    value: "SATURDAY",
    label: "Saturday"
  },
  {
    value: "SUNDAY",
    label: "Sunday"
  }
]);
