import React from "react";
import PropTypes from "prop-types";

export function ContentBlock({
  altGradient,
  children,
  className,
  description,
  title
}) {
  return (
    <section className={`ContentBlock ${className}`}>
      <hr
        className={`ContentBlock__separator ${
          altGradient ? "ContentBlock__separator--alt" : ""
        }`}
      />
      <h2 className={description ? "mb-1" : "mb-3 mb-md-4"}>{title}</h2>
      {description && (
        <p className="ContentBlock__description">{description}</p>
      )}
      {children}
    </section>
  );
}

ContentBlock.propTypes = {
  altGradient: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired
};

ContentBlock.defaultProps = {
  altGradient: false,
  className: ""
};
