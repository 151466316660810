import moment from "moment";

export function getTimesByDayType(dayType) {
  switch (dayType) {
    case "EARLY_BIRD":
      return {
        start: {
          hour: 0,
          minute: 0,
          second: 0
        },
        end: {
          hour: 9,
          minute: 59,
          second: 59
        }
      };
    case "TWILIGHT":
      return {
        start: {
          hour: 15,
          minute: 0,
          second: 0
        },
        end: {
          hour: 23,
          minute: 59,
          second: 59
        }
      };
    default:
      return {
        start: {
          hour: 0,
          minute: 0,
          second: 0
        },
        end: {
          hour: 23,
          minute: 59,
          second: 59
        }
      };
  }
}

export function changeRateDate(oldDate, newDate) {
  const old = moment(oldDate);
  return moment(newDate)
    .hours(old.hours())
    .minutes(old.minutes())
    .seconds(old.seconds())
    .format("YYYY-MM-DD HH:mm:ss");
}

export function getSeasonEndDateFormat(date) {
  return moment(date).hours(23).minute(59).seconds(59).format();
}

export function getSeasonStartDateFormat(date) {
  return moment(date).hours(0).minute(0).seconds(0).format();
}

//validDate used for seasons overlapping validation
/**
  @param { moment } date default date for validation (used as a startDate in datePicker validation)
  @param { array } SEASON_DATES_OCCUPIED array of { startDate:"string",endDate:"string" id:"string" } objects
  @param { string } exclude_id optional season type id used to allow season to select its own dates
  @param { moment } endDate optional param, used for season overlapping validation (used as endDate in datePicker component)
*/
export function validDate(
  date,
  SEASON_DATES_OCCUPIED,
  exclude_id,
  endDate = null
) {
  let valid = true;

  SEASON_DATES_OCCUPIED.forEach((season) => {
    if (endDate && exclude_id !== season._id) {
      if (
        date?.isBefore(season.startDate) &&
        !(
          date?.isBefore(season.startDate) && endDate.isBefore(season.startDate)
        )
      ) {
        valid = false;
      }
    }

    // [] means start,end dates are inclusive
    // checking if date is between start and end Date..
    if (
      valid &&
      date?.isBetween(season.startDate, season.endDate, "days", "[]")
    ) {
      // excluding current season days
      if (exclude_id === season._id) {
        return;
      }
      valid = false;
    }
  });
  return valid;
}

/**
  @param {string} name
  @returns formated string E.g: "Middle season" will be "MIDDLE_SEASON"
 */
export const getFormattedSeasonType = (name) =>
  name.toUpperCase().split(" ").join("_");

export const getCommissionTypeLabel = (type) => {
  if (type) {
    return type === "PERCENTAGE" ? "%" : "Fixed";
  }
  return "Select";
};
