export default function partnerLinkReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return { ...action.payload, loaded: true };
    case "NAME":
      return { ...state, name: action.payload };
    case "DISPLAYNAME":
      return { ...state, displayName: action.payload };
    case "LINKURL":
      return { ...state, linkUrl: action.payload };
    case "IMAGEURL":
      return { ...state, imageUrl: action.payload };
    default: return;
  }
}