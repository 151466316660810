import React, { useContext } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { InvoiceContext } from "../Invoice";
import PdfGenerator from "./PdfGenerator";

const SubInvoicePayment = () => {
  const { invoice } = useContext(InvoiceContext);

  return (
    <Card className="h-100">
      <CardHeader>
        <h4>Sub Invoice payment details</h4>
      </CardHeader>
      <CardBody className="d-flex align-items-center flex-column">
        <h1 className="mb-4">Total amount:</h1>
        <hr className="mb-0 mt-0 border-dark" />
        <h2>
          <b>
            <span>{invoice.amountToCharge ?? "N/A"} </span>
            {invoice.currency}
          </b>
        </h2>
      </CardBody>
      <CardFooter>
        <PdfGenerator subInvoice/>
      </CardFooter>
    </Card>
  );
};

export default SubInvoicePayment;
