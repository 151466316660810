// Vendor
import React, { useContext } from "react";
import { Row, Col, Label } from "reactstrap";

// App
import {
  CLUBNAME_VALIDATOR,
  POSITIVE_NUMBER
} from "../../../common/Validators";
import { InputField, Select } from "../../ui/FunctionalComponents";
import ProviderCollection from "../../../common/ProviderCollection";
import variables from "../../../styles/bootstrap/_variables.scss";
import Switch from "react-switch";
import ClubAccountManagerSelect from "./ClubAccountManagerSelect";
import { ClubContext } from "../Club";
import { AvField } from "availity-reactstrap-validation";

function ClubGeneralInfo() {
  const { state, dispatch } = useContext(ClubContext);
  const {
    claimed = false,
    name,
    teeSheetProvider,
    commission,
    commissionType
  } = state;

  const providerList = ProviderCollection.map((provider) => provider.label);
  const provider =
    ProviderCollection.find((p) => p.key === teeSheetProvider) ||
    ProviderCollection.find((p) => p.key === "NONE");

  return (
    <div className="ClubGeneralInfo mt-3">
      <h3>General</h3>
      <Row form>
        <Col md={4}>
          <InputField
            targetKey="name"
            label="Legal name"
            value={name}
            validate={CLUBNAME_VALIDATOR}
            onChange={(event) =>
              dispatch({ type: "NAME", payload: event.value })
            }
          />
        </Col>
        <Col md={4}>
          <Select
            label="Tee sheet provider"
            id={`club-provider`}
            value={provider.label}
            onChange={(e) => {
              let provider = ProviderCollection.find(
                (p) => p.label === e.target.value
              );
              dispatch({ type: "TEESHEET_PROVIDER", payload: provider.key });
            }}
          >
            {providerList.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Select>
        </Col>
        <Col md={4}>
          <ClubAccountManagerSelect />
        </Col>
        <Col md={4}>
          <Label>Commision Type</Label>
          <AvField
            name="commissionType"
            type="select"
            className="commissionType-select"
            onChange={(e) => {
              if (!e.target.value) return;
              dispatch({ type: "COMMISSION_TYPE", payload: e.target.value });
            }}
            value={commissionType ?? ""}
            required={typeof commission === "number" && commission >= 0}
          >
            {/* Select will be disabled if it has commissionType */}
            <option value="" disabled={commissionType}>
              Select
            </option>
            <option value="FIXED">Fixed</option>
            <option value="PERCENTAGE">%</option>
          </AvField>
        </Col>
        <Col md={4}>
          <Label>Commision value</Label>
          <InputField
            targetKey="commission"
            type="number"
            required={false}
            validate={POSITIVE_NUMBER}
            value={commission}
            onChange={(event) =>
              dispatch({ type: "COMMISSION", payload: event.value })
            }
          />
        </Col>
      </Row>
      <div className="switch-group">
        <Switch
          id="switch-claimed"
          checked={claimed}
          onChange={() => dispatch({ type: "CLAIMED", payload: !claimed })}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={variables.primary}
          offColor={variables.gray300}
          width={50}
        />
        <label
          htmlFor="switch-claimed"
          className={`${claimed ? "" : "text-muted"}`}
        >
          {claimed ? "Claimed" : "Not claimed"}
        </label>
      </div>
    </div>
  );
}

export default ClubGeneralInfo;
