export default function invoiceReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return { ...action.payload, comments: state?.comments ?? [] };
    case "STATUS":
      return { ...state, status: action.payload };
    case "COMMENTS_INIT":
      return { ...state, comments: action.payload };
    case "COMMENT_ADD":
      return {
        ...state,
        comments: [action.payload, ...state.comments]
      };
    case "COMMENTS_UPDATE":
      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment._id === action.payload._id ? action.payload : comment
        )
      };
    case "COMMENT_DELETE":
      return {
        ...state,
        comments: state.comments.filter(
          (comment) => comment._id !== action.payload._id
        )
      };
    case "COMMENT_CLOSE_TASK": {
      const commentsCopy = state.comments.map((comment) =>
        comment._id === action.payload._id ? action.payload : comment
      );
      return { ...state, comments: commentsCopy };
    }
    default:
      return state;
  }
}
