// Vendor
import React, { useState, useEffect } from "react";
import { Image } from "cloudinary-react";
import { Button } from "reactstrap";

// App
import ManagedTable from "../ManagedTable";
import HighlightForm from "./HighlightForm";

/**
 * @param { Object } props
 * @param { { _id: string, openInNew: boolean, type: "CARD"|"BANNER", url: string, imageId: string, altTag: string, title?: string, text?: string}[] } props.highlights
 * @param { function(Object[]) } props.onChange
 */
function HighlightList({ highlights, onChange }) {
  const [formattedList, setFormattedList] = useState([]);
  const [isFormOpen, setFormOpen] = useState(false);

  useEffect(() => {
    let newFormattedList = [];
    for (let item of highlights ?? []) {
      newFormattedList.push({
        id: item._id,
        value: item.type === "CARD" ? CardElement(item) : BannerElement(item)
      });
    }
    setFormattedList(newFormattedList);
  }, [highlights]);

  function formToggle() {
    setFormOpen(!isFormOpen);
  }

  function onChangeHandler(itemArray) {
    let itemList = [];
    itemArray.forEach(item => {
      let match = highlights.find(highlight => highlight._id === item.id);
      match && itemList.push(match);
    });
    onChange(itemList);
  }

  function handleNewHighlight(values) {
    onChange([...highlights, values]);
  }

  const CardElement = item => {
    const { imageId, title, text, url, altTag } = item;

    return (
      <div className="row">
        <div className="col-auto">
          <Image
            publicId={imageId}
            width="135"
            height="110"
            crop="fill"
            title={altTag}
            alt={altTag}
          />
        </div>
        <div className="col">
          <h4>{title}</h4>
          <p className="d-inline-block text-truncate vw-30 vw-lg-40 vw-xl-50">
            {text}
          </p>
          <p className="text-muted mb-0">{url}</p>
        </div>
      </div>
    );
  };

  const BannerElement = item => {
    const { imageId, url, altTag } = item;

    return (
      <div>
        <Image
          publicId={imageId}
          width="240"
          height="110"
          crop="fill"
          title={altTag}
          alt={altTag}
        />
        <p className="text-muted mb-0">{url}</p>
      </div>
    );
  };

  return (
    <div className="mb-5">
      <HighlightForm
        isOpen={isFormOpen}
        toggle={formToggle}
        onSubmit={handleNewHighlight}
      />
      <h3 className="mb-3">Highlights</h3>
      <ManagedTable onChange={onChangeHandler} listItems={formattedList} />
      <Button color="link" type="button" onClick={formToggle}>
        + Add
      </Button>
    </div>
  );
}

export default HighlightList;
