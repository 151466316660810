//Vendor
import React, { useContext } from "react";
import Papa from "papaparse";
import { useLazyQuery } from "@apollo/client";

//App
import {
  EMAIL_LIST_REGISTRATION_STATUS,
  EMAIL_REGISTRATION_STATUS
} from "../../../common/Queries";

export function AddEmails({ context }) {
  const {
    dispatch,
    state: { users }
  } = useContext(context);

  const [getNewUserList] = useLazyQuery(EMAIL_LIST_REGISTRATION_STATUS, {
    fetchPolicy: "no-cache",
    onCompleted: handleNewUserList
  });
  const [getNewUser] = useLazyQuery(EMAIL_REGISTRATION_STATUS, {
    fetchPolicy: "no-cache",
    onCompleted: handleNewUser
  });

  function handleNewUserList({ emailListRegistrationStatus }) {
    let newUsers = [];
    const oldUsers = users.filter((user) =>
      emailListRegistrationStatus.some((comp) => comp.email === user.email)
    );

    switch (context.displayName) {
      case "PartnerNetwork": {
        newUsers = emailListRegistrationStatus
          .filter((user) => !oldUsers.some((old) => old.email === user.email))
          .map((user) => ({ ...user, sendWelcomeEmail: !user.registered }));
        break;
      }
      case "TourOperator": {
        newUsers = emailListRegistrationStatus.filter(
          (user) => !oldUsers.some((old) => old.email === user.email)
        );
        break;
      }
      default: {
        break;
      }
    }

    dispatch({ type: "UPDATE_USERS", payload: [...newUsers, ...oldUsers] });
    dispatch({ type: "SET_LOADING", payload: false });
  }

  function handleNewUser({ emailRegistrationStatus }) {
    switch (context.displayName) {
      case "PartnerNetwork": {
        // add if email is not in the list
        if (
          !users.some((user) => user.email === emailRegistrationStatus.email)
        ) {
          dispatch({
            type: "ADD_SINGLE_USER",
            payload: {
              ...emailRegistrationStatus,
              sendWelcomeEmail: !emailRegistrationStatus.registered
            }
          });
        }
        dispatch({ type: "SET_LOADING", payload: false });
        break;
      }
      case "TourOperator": {
        // add if email is not in the list
        if (
          !users.some((user) => user.email === emailRegistrationStatus.email)
        ) {
          dispatch({
            type: "ADD_SINGLE_USER",
            payload: {
              ...emailRegistrationStatus
            }
          });
        }
        dispatch({ type: "SET_LOADING", payload: false });
        break;
      }
      default: {
        break;
      }
    }
  }

  function basicEmailValidation(email) {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return regex.test(email);
  }

  function handleInputKeyPress(e) {
    if (e.key === "Enter") {
      e.preventDefault();

      if (!basicEmailValidation(e.currentTarget.value.trim().toLowerCase()))
        return false;

      dispatch({ type: "SET_LOADING", payload: true });

      getNewUser({
        variables: { email: e.currentTarget.value.trim().toLowerCase() }
      });

      e.currentTarget.value = "";
    }
  }

  const showFile = async (e) => {
    e.preventDefault();

    if (e.currentTarget.files.length === 0) return;

    Papa.parse(e.currentTarget.files[0], {
      complete: function (results) {
        const [, ...emails] = results.data.flat();
        dispatch({ type: "SET_LOADING", payload: true });
        getNewUserList({ variables: { emailList: emails } });
      },
      delimiter: ";",
      skipEmptyLines: "greedy"
    });
  };

  return (
    <div className={`row py-2 mb-4`}>
      <div className="col-md-4">
        <label>Add email to list</label>
        <input
          type="text"
          className="form-control form-control-lg"
          onKeyPress={handleInputKeyPress}
        />
      </div>
      <div className="col-md-4">
        <label>Add emails from file (CSV)</label>
        <input
          className="form-control form-control-lg"
          type="file"
          onChange={showFile}
        />
      </div>
    </div>
  );
}
