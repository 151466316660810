import React, { useContext, useState } from "react";
import { Button, Table } from "reactstrap";
import EmailModal from "../EmailModal";
import { EmailContext } from "../Email";
import { useMutation } from "@apollo/client";
import {
  CREATE_EMAIL_RECIPIENT,
  DELETE_EMAIL_RECIPIENT,
  UPDATE_EMAIL_RECIPIENT
} from "../../../common/Mutations";
import { LayoutContext } from "../../app/Layout";
import DeleteButton from "../../ui/table-utils/DeleteButton";
const Recipients = () => {
  // handlers
  const handleSubmit = () => {
    const input = { ...emailRecipient };
    if (emailRecipient._id) {
      // id is not part of the input schema
      delete input._id;

      updateEmailRecipient({
        variables: {
          emailRecipientID: emailRecipient._id,
          emailRecipientInput: input
        }
      });
    } else {
      createEmailRecipient({
        variables: { emailRecipientInput: input }
      });
    }
  };
  const handleDelete = (recipientId) => {
    setitemInTransit(recipientId);
    deleteEmailRecipient({ variables: { emailRecipientID: recipientId } });
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const onClosed = () => {
    setEmailRecipient(initialData);
  };
  const handleChange = ({ key, value }) => {
    setEdited(true);
    // handling changes for nested objects
    switch (key) {
      case "firstName":
      case "lastName":
        setEmailRecipient({
          ...emailRecipient,
          name: {
            ...emailRecipient.name,
            [key]: value
          }
        });
        break;
      default:
        setEmailRecipient({
          ...emailRecipient,
          [key]: value
        });
        break;
    }
  };
  // mutation handlers
  const handleDeleteEmailRecipientCompleted = (res) => {
    if (res.deleteEmailRecipient.ok) {
      emailsDispatch({
        type: "DELETE_RECIPIENT",
        payload: res.deleteEmailRecipient.emailRecipient._id
      });
      addAlert({
        color: "success",
        message: "Email recipient deleted."
      });
    } else {
      addAlert({
        color: "danger",
        message: "Error deleting email recipient."
      });
    }
    setitemInTransit(null);
  };
  const handleAddRecipientCompleted = (res) => {
    if (res.createEmailRecipient.ok) {
      emailsDispatch({
        type: "ADD_RECIPIENT",
        payload: res.createEmailRecipient.emailRecipient
      });
      toggleModal();
      addAlert({
        color: "success",
        message: "New email recipient added."
      });
    } else {
      addAlert({
        color: "danger",
        message: res.createEmailRecipient.returnMessage
      });
      setitemInTransit(null);
    }
  };
  const handleUpdateEmailRecipientCompleted = (res) => {
    if (res.updateEmailRecipient.ok) {
      emailsDispatch({
        type: "UPDATE_RECIPIENT",
        payload: res.updateEmailRecipient.emailRecipient
      });
      toggleModal();
      addAlert({
        color: "success",
        message: "Email recipient updated."
      });
    } else
      addAlert({
        color: "danger",
        message: res.updateEmailRecipient.returnMessage
      });
  };
  // context
  const { emailsState, emailsDispatch } = useContext(EmailContext);
  const { addAlert } = useContext(LayoutContext);
  const { recipients } = emailsState;

  // states
  const initialData = { name: { firstName: "", lastName: "" }, email: "" };
  const [emailRecipient, setEmailRecipient] = useState(initialData);
  const [modalOpen, setModalOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [itemInTransit, setitemInTransit] = useState(null);

  // mutations
  const [createEmailRecipient] = useMutation(CREATE_EMAIL_RECIPIENT, {
    onCompleted: handleAddRecipientCompleted,
    onError: (err) => {
      // for network errors only
      addAlert({
        color: "danger",
        message: "Unable to update email recipient"
      });
    }
  });
  const [updateEmailRecipient] = useMutation(
    UPDATE_EMAIL_RECIPIENT,
    {
      onCompleted: handleUpdateEmailRecipientCompleted,
      onError: (err) => {
        // for network errors only
        addAlert({
          color: "danger",
          message: "Unable to update email recipient"
        });
      }
    }
  );
  const [deleteEmailRecipient] = useMutation(
    DELETE_EMAIL_RECIPIENT,
    {
      onCompleted: (res) => {
        handleDeleteEmailRecipientCompleted(res);
      },
      onError: (err) => {
        // for network errors only
        addAlert({
          color: "danger",
          message: "Unable to delete email recipient"
        });
      }
    }
  );
  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <h2>Email recipients</h2>
        <Button color="secondary" onClick={toggleModal}>
          Add new
        </Button>
      </div>
      <Table borderless striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {recipients.map((recipient) => {
            return (
              <tr key={recipient._id || recipient.email}>
                <td className="align-middle">
                  {recipient.name.firstName + " " + recipient.name.lastName}
                </td>
                <td className="align-middle">{recipient.email}</td>
                <td className="align-middle">
                  <Button
                    className="p-0 mr-1 shadow-none"
                    color="link"
                    onClick={() => {
                      setEmailRecipient(recipient);
                      toggleModal();
                    }}
                    disabled={itemInTransit ? true : false}
                  >
                    Edit
                  </Button>
                  <DeleteButton
                    handleDelete={handleDelete}
                    id={recipient._id}
                    itemInTransit={itemInTransit}
                  >
                    Delete
                  </DeleteButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <EmailModal
        isOpen={modalOpen}
        toggle={toggleModal}
        data={emailRecipient}
        onClosed={onClosed}
        onChange={handleChange}
        edited={edited}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Recipients;
