// Vendor
import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import Loader from "../ui/Loader";
import Info from "../ui/Info";

function OrderListTable(props) {
  const { orderList, loading } = props;

  if (loading) return <Loader centered />;

  return (
    <Table size="sm mt-2">
      <thead>
        <tr>
          <th>Name</th>
          <th className="text-left">Email</th>
          <th className="text-left">
            <div className="d-flex">
              Courses
              <Info value="Courses with light blue background are amadeus active" className="ml-2"/>
            </div>
          </th>
          <th className="text-left">Play dates</th>
          <th className="Col--shrink">Order value</th>
          <th>Order ref</th>
          <th className="Col--shrink">API</th>
          <th className="text-center">Voucher</th>
          <th>Created</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody className="border">
        {orderList.map((order, index) => {
          // Default to undefined or [null] may overwrite defaults
          let currency = order.clubs[0].teetimes[0]?.currency ?? undefined;
         
          return (
            <tr key={index}>
              <td>{`${order.firstName} ${order.lastName}`}</td>
              <td className="text-left">{order.email}</td>
              <td className="text-left">
                {courseBadges(order.clubs, order.teetimes)}
              </td>
              <td className="text-left">{teetimeBadges(order.clubs)}</td>
              <td className="text-right">
                {formatCurrency(order.total, currency)}
              </td>
              <td className="text-monospace">{order.orderReference}</td>
              <td
                className={`Col--shrink${order.affiliateId && " text-warning"}`}
              >
                {order.affiliateId ? order.affiliateId.name : "-"}
              </td>
              <td className="text-center">{order.voucher ? order.voucher.code : "-"}</td>
              <td className="Col--shrink">
                {moment(order.createdAt).format("YYYY-MM-DD HH:mm")}
              </td>
              <td className="Col--shrink">
                <Link to={`/order/${order._id}`}>Details</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

/**
 * Display courses booked as badges
 * @param { Array<Object> } clubArray club field on order object
 */
function courseBadges(clubArray) {
  return clubArray.map((club, i1) => {
    return club.teetimes.map((teetime, i2) => {
      const course = club.clubId?.courses?.find(
        (course) => course._id.toString() === teetime.courseId.toString()
      );
      const amadeusActive = course?.amadeusActive;
      return (
        <li
          className="d-inline-block badge badge-sm mr-2 mb-1 badge badge-light"
          style={amadeusActive ? { background: "#daf5f5" } : {}}
          key={`${i1}${i2}`}
        >
          <div style={{ cursor: "default" }}>{teetime.courseName}</div>
        </li>
      );
    });
  });
}

function teetimeBadges(clubArray) {
  return clubArray.map((club, i1) => {
    return club.teetimes.map((teetime, i2) => (
      <li
        className="d-inline-block badge badge-sm badge-light mr-2 mb-1"
        key={`${i1}${i2}`}
      >
        <div style={{ cursor: "default" }}>
          {moment(teetime.playDate).format("YYYY-MM-DD")}
        </div>
      </li>
    ));
  });
}

/**
 * Format value as currency.
 *
 * @param {number} value
 * @param {string} currency Optional. Default "EUR"
 */
function formatCurrency(value, currency = "EUR") {
  const isInt = (n) => Number(n) === n && n % 1 === 0;

  if (!value || isNaN(value)) value = 0;

  return value.toLocaleString("en-GB", {
    style: "currency",
    minimumFractionDigits: isInt(value) ? 0 : 2,
    maximumFractionDigits: 2,
    currency
  });
}

export default OrderListTable;
