// Vendor
import React, { Fragment, useContext, useState } from "react";
import { Table, Popover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { VOUCHERS } from "../../common/Queries";
import { DELETE_VOUCHER } from "../../common/Mutations";
import Loader from "../ui/Loader";
import {
    Button
} from "reactstrap";
import { LayoutContext } from "../app/Layout";

const Vouchers = () => {
    const { addAlert } = useContext(LayoutContext);
    const [popoverOpen, setPopoverOpen] = useState("");
    const [deleteVoucher] = useMutation(
        DELETE_VOUCHER,
        {
            onCompleted: res => {
                addAlert({
                    color: "success",
                    message: "Voucher deleted."
                });
            },
            onError: () =>
                addAlert({ color: "danger", message: "Could not delete Voucher." })
        }
    );

    return (
        <div className="mb-4">
            <h1>Vouchers</h1>

            <Query query={VOUCHERS} fetchPolicy="cache-and-network">
                {({ loading, error, data }) => {
                    if (loading) return <Loader />;
                    if (error)
                        return (
                            <div className="text-danger">Error fetching vouchers</div>
                        );

                    const vouchers = data.vouchers;
                    return (
                        <Table borderless striped>
                            <Fragment>
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Discount (percentage)</th>
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th>Comment</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vouchers.length > 0 ? (
                                        vouchers.map((voucher, i) => {
                                            const popoverId = `remove-voucher-popover-${i}`;
                                            return (
                                                <tr key={i}>
                                                    <td>{voucher.code}</td>
                                                    <td>{voucher.value}</td>
                                                    <td>{moment(voucher.startDate).format("YYYY-MM-DD")}</td>
                                                    <td>{moment(voucher.endDate).format("YYYY-MM-DD")}</td>
                                                    <td>{voucher.comment}</td>
                                                    <td>
                                                        <Button
                                                            className="p-0 text-muted d-inline-flex"
                                                            color="link"
                                                            id={popoverId}
                                                            onClick={() => setPopoverOpen(popoverId)}
                                                        >
                                                            <FontAwesomeIcon icon="trash-alt" />
                                                        </Button>
                                                        <Popover
                                                            target={popoverId}
                                                            isOpen={popoverOpen === popoverId}
                                                            placement="left"
                                                            toggle={() => setPopoverOpen("")}
                                                            className="p-3"
                                                        >
                                                            <PopoverBody>
                                                                Are you sure?
                                                                <Button
                                                                    color="link"
                                                                    onClick={() => {
                                                                        deleteVoucher({ variables: { voucherId: voucher._id }, refetchQueries: [{ query: VOUCHERS }] });
                                                                        setPopoverOpen("");
                                                                    }}>
                                                                    Delete
                                                                </Button>
                                                            </PopoverBody>
                                                        </Popover>
                                                        <Link className="btn btn-link" to={`/voucher/${voucher._id}`}>Edit</Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : (<tr>
                                        <td colSpan="5" className="text-muted text-center">No vouchers found</td>
                                    </tr>)}
                                </tbody>
                            </Fragment>
                        </Table>
                    );
                }}
            </Query>

            <div className="Save-container">
                <div className="Save-container__phantom" />
                <div className="Save-container__main">
                    <Link to="/voucher" id="btn-new" className="btn btn-secondary btn-lg">
                        Create new
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Vouchers;
