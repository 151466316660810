import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Col
} from "reactstrap";

const AmadeusCard = ({ data }) => {
  const { cardNumber, cardHolder, availableBalance, expiryDate } = data;

  return (
    <Col lg={4}>
      <Card className="amadeus-card">
        <CardBody>
          <h4>
            <b>Card Number</b>
          </h4>
          <CardText className="card-num">{cardNumber}</CardText>

          <div className="d-flex justify-content-between">
            <div>
              <CardTitle>
                <b>Card holder</b>
              </CardTitle>
              <CardText className="info">{cardHolder}</CardText>
            </div>
            <div>
              <CardTitle className="text-right">
                <b>Expires</b>
              </CardTitle>
              <CardText className="text-right">{expiryDate}</CardText>
            </div>
          </div>
        </CardBody>
        <CardFooter className="footer">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2 className="font-weight-bold">
                {availableBalance?.currency} {availableBalance?.amount}
              </h2>
              <div className="">Balance</div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default AmadeusCard;
