// Vendor
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Card, Input } from "reactstrap";

// App
import {
  SEND_ORDER_CONFIRMATION_EMAIL,
  SEND_ORDER_RECEIPT_EMAIL
} from "../../../common/Mutations";
import useDebounce from "../../../common/useDebounce";
import { OrderContext } from "../Order";
import { OrderSendEmailButton } from "./OrderSendEmailButton";

export function OrderEmails() {
  const [targetEmail, setTargetEmail] = useState("");
  const debouncedEmail = useDebounce(targetEmail, 750);

  const [
    sendOrderConfirmation,
    {
      data: orderConfirmRes,
      loading: orderConfirmationLoading,
      error: orderConfirmationError
    }
  ] = useMutation(SEND_ORDER_CONFIRMATION_EMAIL);
  const [
    sendOrderReceipt,
    {
      data: orderReceiptRes,
      loading: orderReceiptLoading,
      error: orderReceiptError
    }
  ] = useMutation(SEND_ORDER_RECEIPT_EMAIL);

  const {
    state: {
      formatted: order,
      targetEmail: { active }
    },
    dispatch
  } = useContext(OrderContext);

  useEffect(() => {
    dispatch({ type: "SET_TARGET_EMAIL", payload: debouncedEmail });
  }, [debouncedEmail, dispatch]);

  function handleTextOnChange(e) {
    const {
      target: {
        value,
        validity: { valid }
      }
    } = e;
    if (valid) setTargetEmail(value);
  }

  function handleSendOrderConfirmation(email) {
    sendOrderConfirmation({
      variables: {
        orderId: order._id,
        targetEmail: email
      }
    });
  }

  function handleSendOrderReceipt(email) {
    sendOrderReceipt({
      variables: {
        orderId: order._id,
        targetEmail: email
      }
    });
  }

  return (
    <Card className="p-3">
      <div className="checkbox-group">
        <input
          id="checkbox-target-specific-email"
          type="checkbox"
          checked={active}
          onChange={() =>
            dispatch({ type: "TARGET_EMAIL_ACTIVE", payload: !active })
          }
        />
        <label htmlFor="checkbox-target-specific-email">
          Target specific email address
        </label>
      </div>

      <Input
        className="text-input"
        type="email"
        placeholder="Target email"
        onChange={handleTextOnChange}
        disabled={!active}
      />

      <div className="my-2 d-flex">
        <OrderSendEmailButton
          id="btn-send-order-confirmation"
          loading={orderConfirmationLoading}
          error={orderConfirmationError}
          success={orderConfirmRes?.sendOrderConfirmation.success}
          onClick={handleSendOrderConfirmation}
        >
          Send order confirmation
        </OrderSendEmailButton>
        {order.payNowTotal > 0 && (
          <OrderSendEmailButton
            className="ml-2"
            id="btn-send-order-receipt"
            loading={orderReceiptLoading}
            error={orderReceiptError}
            success={orderReceiptRes?.sendOrderReceipt.success}
            onClick={handleSendOrderReceipt}
          >
            Send order receipt
          </OrderSendEmailButton>
        )}
      </div>
    </Card>
  );
}
