import {
  getSeasonStartDateFormat,
  getSeasonEndDateFormat
} from "./SeasonHelper";

/**
 * @param { Season } state
 * @param { Object } action
 * @param { SeasonReducerDispatchType } action.type
 * @param { any } [action.payload={}]
 * @returns { Season }
 */
export default function seasonReducer(state, action) {
  switch (action.type) {
    case "INIT":
    case "UPDATE":
      return action.payload;
    case "SET_DATES":
      return {
        ...state,
        startDate: getSeasonStartDateFormat(action.payload.startDate),
        endDate: getSeasonEndDateFormat(action.payload.endDate)
      };
    case "SET_SEASON_TYPE": {
      const newType = { ...action.payload };
      delete newType._id;
      return {
        ...state,
        seasonType: action.payload
      };
    }
    case "SET_STANDARD_RATES": {
      let weekendRates = state.rates.filter(
        (rate) => rate.scheduleType === "WEEKEND"
      );
      let rates = weekendRates.length
        ? weekendRates.concat(action.payload)
        : action.payload;
      return {
        ...state,
        rates
      };
    }
    case "SET_WEEKEND_RATES": {
      let standardRates = state.rates.filter(
        (rate) => rate.scheduleType === "STANDARD"
      );
      return {
        ...state,
        rates: standardRates.concat(action.payload)
      };
    }
    case "SEASON_TYPE_NAME":
      return {
        ...state,
        name: action.payload
      };
    case "SEASON_TYPE_COLOR":
      return {
        ...state,
        color: action.payload
      };
    case "RESET":
      return action.payload;
    default:
      return state;
  }
}
