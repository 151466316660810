export default function golfersUserSearchReducer(state, action) {
  let filterCopy;
  switch (action.type) {
    case "SEARCH_RESULTS":
      return {
        ...state,
        hits: action.payload.hits,
        users: action.payload.users
      };
    case "SET_SORTING":
      return { ...state, sorting: action.payload };
    case "SET_PAGE_INDEX":
      return { ...state, page: action.payload };
    case "USER_EMAIL":
      return {
        ...state,
        filter: { ...state.filter, email: action.payload }
      };
    case "REGISTERED":
      if (action.payload === null) {
        const { registered, ...rest } = state.filter;
        filterCopy = rest;
      } else filterCopy = { ...state.filter, registered: action.payload };
      return {
        ...state,
        filter: filterCopy
      };

    case "PLACED_ORDERS":
      if (!action.payload) {
        const { placedOrders, ...rest } = state.filter;
        filterCopy = rest;
      } else filterCopy = { ...state.filter, placedOrders: action.payload };
      return {
        ...state,
        filter: filterCopy
      };
    case "AVERAGE_PLAYER_COUNT":
      if (!action.payload) {
        const { avgPlayersPerOrder, ...rest } = state.filter;
        filterCopy = rest;
      } else
        filterCopy = { ...state.filter, avgPlayersPerOrder: action.payload };
      return {
        ...state,
        filter: filterCopy
      };
    case "CANCELLATION_RATE":
      if (!action.payload) {
        const { cancellationRate, ...rest } = state.filter;
        filterCopy = rest;
      } else filterCopy = { ...state.filter, cancellationRate: action.payload };
      return {
        ...state,
        filter: filterCopy
      };

    case "LAST_ORDER_DATE":
      if (!action.payload.startDate && !action.payload.endDate) {
        const { lastOrderDate, ...rest } = state.filter;
        filterCopy = rest;
      } else {
        filterCopy = { ...state.filter, lastOrderDate: action.payload };
      }
      return {
        ...state,
        filter: filterCopy
      };

    case "INCLUDE_COURSES":
      return {
        ...state,
        filter: { ...state.filter, coursesInclude: action.payload ?? [] }
      };
    case "EXCLUDE_COURSES":
      return {
        ...state,
        filter: { ...state.filter, coursesExclude: action.payload ?? [] }
      };
    default:
      return state;
  }
}
