import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown
} from "reactstrap";
import Loader from "../ui/Loader";
import FreezeCardModal from "./modals/FreezeCardModal";

const TableAmadeus = ({ list, loading }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [showFreezeModal, setShowFreezeModal] = useState(false);

  if (loading) return <Loader centered />;
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Card Holder</th>
            <th>Card Number</th>
            <th>Card External Ref</th>
            <th>Card State</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {list?.map((card) => {
            return (
              <tr key={card.externalReference}>
                <td>{card.cardHolder}</td>
                <td>{card.cardNumber}</td>
                <td>{card.externalReference}</td>
                <td>{card.status}</td>
                <td>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link
                      to={`/amadeus/${card.externalReference}`}
                    >
                      View
                    </Link>
                    <UncontrolledDropdown>
                      <DropdownToggle color="link" caret>
                        ...
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setSelectedCard(card);
                            setShowFreezeModal(true);
                          }}
                          disabled={card.status === "DELETED"}
                        >
                          Freeze card
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <FreezeCardModal
        isOpen={showFreezeModal}
        toggle={() => setShowFreezeModal(!showFreezeModal)}
        defaultStatus={selectedCard?.status}
        amadeusReference={selectedCard?.cardReference}
        externalReference={selectedCard?.externalReference}
        cardData={selectedCard}
      />
    </div>
  );
};

export default TableAmadeus;
