// Vendor
import React, { useState } from "react";
import { Button, ButtonGroup, Col, Input, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import * as moment from "moment";

// App
import useDebounce from "../../common/useDebounce";
import URLHelper from "../../common/URLHelper";
import DateRangeFacet from "../ui/facet/DateRangeFacet";
import SitesSelect from "../../components/ui/SitesSelect";
import { PartnerNetworkSelect } from "../ui/PartnerNetworkSelect";
import { TourOperatorSelect } from "../ui/TourOperatorSelect";
import useUpdateUrlSearchParams from "../../common/useUpdateUrlSearchParams";
import { AffiliateSelect } from "../ui/AffiliateSelect";

function OrderSearch() {
  const history = useHistory();
  const urlParams = URLHelper.queryToState(history.location);

  const [userSearchText, setUserSearchText] = useState(urlParams.userText);
  const [orderSearchText, setOrderSearchText] = useState(urlParams.orderText);
  const [clubName, setClubName] = useState(urlParams.clubName);
  const [hasOpenTask, setHasOpenTask] = useState(
    urlParams.hasOpenTask === undefined
      ? undefined
      : urlParams.hasOpenTask === "true"
  );
  const [createdDate, setCreatedDate] = useState({
    startDate: urlParams.createdFrom ? moment(urlParams.createdFrom) : null,
    endDate: urlParams.createdTo ? moment(urlParams.createdTo) : null
  });
  const [playDate, setPlayDate] = useState({
    startDate: urlParams.teetimeFrom ? moment(urlParams.teetimeFrom) : null,
    endDate: urlParams.teetimeTo ? moment(urlParams.teetimeTo) : null
  });
  const [sites, setSites] = useState(urlParams.sites);
  const [networks, setNetworks] = useState(urlParams.networks);
  const [tourOperators, setTourOperators] = useState(urlParams.tourOperators);
  const [affiliates, setAffiliates] = useState(urlParams.affiliates);
  const [hasActiveBooking, setHasActiveBooking] = useState(
    urlParams.hasActiveBooking === undefined
      ? undefined
      : urlParams.hasActiveBooking === "true"
  );

  const debouncedUserSearchText = useDebounce(userSearchText, 750);
  const debouncedOrderSearchText = useDebounce(orderSearchText, 750);
  const debouncedClubName = useDebounce(clubName, 750);
  const debouncedCreatedDate = useDebounce(createdDate, 750);
  const debouncedPlayDate = useDebounce(playDate, 750);
  const debouncedOpenTask = useDebounce(hasOpenTask, 750);
  const debouncedSites = useDebounce(sites, 750);
  const debouncedNetworks = useDebounce(networks, 750);
  const debouncedTourOperators = useDebounce(tourOperators, 750);
  const debouncedAffiliates = useDebounce(affiliates, 750);
  const debouncedHasActiveBooking = useDebounce(hasActiveBooking, 750);

  /**
   * Update query params on any param change (debounced)
   */
  useUpdateUrlSearchParams(
    history,
    [
      { key: "userText", value: debouncedUserSearchText },
      { key: "orderText", value: debouncedOrderSearchText },
      { key: "clubName", value: debouncedClubName },
      { key: "sites", value: debouncedSites },
      { key: "networks", value: debouncedNetworks },
      { key: "tourOperators", value: debouncedTourOperators },
      { key: "hasOpenTask", value: debouncedOpenTask },
      { key: "affiliates", value: debouncedAffiliates },
      { key: "hasActiveBooking", value: debouncedHasActiveBooking },
      ...URLHelper.formatDateQueryBothDates("created", debouncedCreatedDate),
      ...URLHelper.formatDateQueryBothDates("teetime", debouncedPlayDate)
    ],
    [
      debouncedClubName,
      debouncedOrderSearchText,
      debouncedUserSearchText,
      debouncedCreatedDate,
      debouncedPlayDate,
      debouncedOpenTask,
      debouncedSites,
      debouncedNetworks,
      debouncedTourOperators,
      debouncedAffiliates,
      debouncedHasActiveBooking
    ]
  );

  function handleOpenTaskChange(_hasOpenTask) {
    if (hasOpenTask === _hasOpenTask) setHasOpenTask(null);
    else setHasOpenTask(_hasOpenTask);
  }

  function handleActiveBookingChange(_hasActiveBooking) {
    if (hasActiveBooking === _hasActiveBooking) setHasActiveBooking(null);
    else setHasActiveBooking(_hasActiveBooking);
  }

  // Constant function to allow past dates in the daterange pickers
  const falseFunc = () => false;

  const HasOpenTaskButtons = () => (
    <div>
      <label htmlFor="btn-group-has-open-task">Has any open task</label>
      <ButtonGroup id="btn-group-has-open-task">
        <Button
          color={`${hasOpenTask ? "primary" : "outline-light"}`}
          onClick={() => handleOpenTaskChange(true)}
        >
          Yes
        </Button>
        <Button
          color={`${hasOpenTask === false ? "primary" : "outline-light"}`}
          onClick={() => handleOpenTaskChange(false)}
        >
          No
        </Button>
      </ButtonGroup>
    </div>
  );

  const HasActiveBookingCheckbox = () => (
    <div>
      <label htmlFor="btn-group-has-active-booking">
        Has any active booking
      </label>
      <ButtonGroup id="btn-group-has-active-booking">
        <Button
          color={`${hasActiveBooking === false ? "primary" : "outline-light"}`}
          onClick={() => handleActiveBookingChange(false)}
        >
          Active
        </Button>
        <Button
          color={`${hasActiveBooking ? "primary" : "outline-light"}`}
          onClick={() => handleActiveBookingChange(true)}
        >
          Cancelled
        </Button>
      </ButtonGroup>
    </div>
  );

  return (
    <div>
      <Row className="mb-3">
        <Col className="col-12 col-lg-2 mb-2">
          <label htmlFor="input-customer-info">User information</label>
          <Input
            id="input-customer-info"
            className="mb-2"
            type="text"
            placeholder="Name, email"
            onChange={(e) => setUserSearchText(e.target.value)}
            defaultValue={urlParams.userText}
          />
        </Col>
        <Col className="col-12 col-lg-2 mb-2">
          <label htmlFor="input-club-course-name">Club/course name</label>
          <Input
            id="input-club-course-name"
            className="mb-2"
            type="text"
            placeholder="Club/course"
            onChange={(e) => setClubName(e.target.value)}
            defaultValue={urlParams.clubName}
          />
        </Col>
        <Col className="col-12 col-lg-2 mb-2">
          <label htmlFor="input-order">Order</label>
          <Input
            id="input-order"
            className="mb-2"
            type="text"
            placeholder="Order ref, objectID"
            onChange={(e) => setOrderSearchText(e.target.value)}
            defaultValue={urlParams.orderText}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-3 mb-2">
          <label>Created date</label>
          <div className="DateRangePicker__custom-style">
            <DateRangeFacet
              small
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              startDate={createdDate.startDate}
              startDateId="orderCreatedFrom"
              endDate={createdDate.endDate}
              endDateId="orderCreatedTo"
              onDatesChange={({ startDate, endDate }) =>
                setCreatedDate({ startDate, endDate })
              }
              showClearDates
              isOutsideRange={falseFunc}
            />
          </div>
        </Col>
        <Col className="col-12 col-md-6 col-lg-3 mb-2">
          <label>Play date</label>
          <div className="DateRangePicker__custom-style">
            <DateRangeFacet
              small
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              startDate={playDate.startDate}
              startDateId="playDateFrom"
              endDate={playDate.endDate}
              endDateId="playDateTo"
              onDatesChange={({ startDate, endDate }) =>
                setPlayDate({ startDate, endDate })
              }
              showClearDates
              isOutsideRange={falseFunc}
            />
          </div>
        </Col>
        <Col className="col-12 col-md-6 col-lg-2 mb-2">
          <HasOpenTaskButtons />
        </Col>
        <Col>
          <HasActiveBookingCheckbox />
        </Col>
        <Col className="col-12 col-md-6 col-lg-4 mb-2">
          <SitesSelect
            id="sites-selector"
            label="Sites"
            onChange={(value) => setSites(value)}
            value={sites}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-3 mb-2">
          <PartnerNetworkSelect
            id="partner-network-selector"
            label="Partner networks"
            onChange={(value) => setNetworks(value)}
            value={networks}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-3 mb-2">
          <TourOperatorSelect
            id="tour-operator-selector"
            label="Tour operators"
            onChange={(value) => setTourOperators(value)}
            value={tourOperators}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-3 mb-2">
          <AffiliateSelect
            label="Booked by affiliate"
            onChange={(value) => setAffiliates(value)}
            value={affiliates}
          />
        </Col>
      </Row>
    </div>
  );
}

export default OrderSearch;
