import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from "react";
import PropTypes from "prop-types";
import { Alert, Button, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLazyQuery } from "@apollo/client";

import { IMAGES_BY_TAG } from "../../../../common/Queries";
import { appendScript } from "../../../../common/Scripts";
import Loader from "../../../ui/Loader";
import ClubCourseImageRow from "./ClubCourseImageRow";
import { ClubCourseContext } from "../ClubCourse";

const initialState = null;

function alertReducer(_, action) {
  switch (action.type) {
    case "delete":
      return {
        color: action.payload ? "success" : "danger",
        message: action.payload
          ? "The image has been removed"
          : "Failed to remove image"
      };
    case "rename":
      return {
        color: action.payload ? "success" : "danger",
        message: action.payload
          ? "The image has been renamed"
          : "Failed to rename image"
      };
    case "reset":
      return initialState;
    default:
      throw new Error("No action type found");
  }
}

function ClubCourseImageList(props) {
  //#region State & queries
  const [alert, dispatch] = useReducer(alertReducer, initialState);
  const [widget, setWidget] = useState(null);
  const [images, setImages] = useState([]);
  const {
    state: { slug }
  } = useContext(ClubCourseContext);

  const [fetchImages, { loading, error: loadImagesError }] = useLazyQuery(
    IMAGES_BY_TAG,
    {
      onCompleted({ ImagesByTag }) {
        setImages(ImagesByTag);
      },
      fetchPolicy: "network-only"
    }
  );
  //#endregion

  //#region Handlers & helper methods
  function enqueueHideAlert() {
    setTimeout(() => {
      dispatch({ type: "reset" });
    }, 5000);
  }

  function changeHandler(event) {
    dispatch({ type: event.name, payload: event.success });
    enqueueHideAlert();
  }

  /**
   * @returns { string }
   */
  const memoizedPublicId = useCallback(() => {
    if (!images.some(img => img.publicId === slug)) return slug;

    for (let suffix = 1; suffix < Number.MAX_SAFE_INTEGER; suffix++) {
      if (!images.some(img => img.publicId === `${slug}-${suffix}`)) {
        return `${slug}-${suffix}`;
      }
    }

    throw new Error("Error: Cannot give a larger image id suffix");
  }, [images, slug]);
  //#endregion

  //#region Widget
  function initWidget() {
    const options = {
      cloudName: "greenfee365",
      uploadPreset: "preset-marshalls-course-image-upload",
      sources: ["local"],
      folder: `courses/${slug}`,
      publicId: memoizedPublicId(),
      tags: [slug],
      maxImageFileSize: 700000 // 700 kB
      // minImageWidth: 1200,
      // minImageHeight: 400
    };
    setWidget(
      // Refresh the image list on closing widget
      window.cloudinary.createUploadWidget(options, (error, result) => {
        if (!error && result.event === "success") {
          fetchImages({ variables: { tag: slug } });
        }
      })
    );
  }

  function loadWidget() {
    // if script is already loaded
    if (window.cloudinary) initWidget();
    else {
      appendScript(
        "https://widget.cloudinary.com/v2.0/global/all.js",
        "cloudinary-upload-widget"
      )
        .then(initWidget)
        .catch(err => {
          console.log(err);
          return err;
        });
    }
  }
  //#endregion

  //#region Effect handlers
  useEffect(() => {
    if (slug) loadWidget();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchImages({ variables: { tag: slug } });
  }, [slug, fetchImages]);

  useEffect(() => {
    if (widget) {
      widget.update({ publicId: memoizedPublicId() });
    }
  }, [widget, images, memoizedPublicId]);
  //#endregion

  return (
    <div className="ClubCourseImageList mt-5">
      <h3>Images</h3>
      {!slug ? (
        <Alert color="warning">Change slug to load images</Alert>
      ) : loading ? (
        <Loader />
      ) : loadImagesError ? (
        <Alert color="danger">Could not load course images</Alert>
      ) : (
        <>
          {alert && <Alert color={alert.color}>{alert.message}</Alert>}
          <Table responsive>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th className="text-left">publicId</th>
                <th>Dimensions</th>
                <th>Format</th>
                <th>Size</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {images.length ? (
                images.map(image => (
                  <ClubCourseImageRow
                    key={image.publicId}
                    image={image}
                    imageSize={props.imageSize}
                    onChange={changeHandler}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center text-muted">
                    No images found for this course
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Button
            color="light"
            outline
            onClick={() => {
              if (widget) widget.open();
            }}
          >
            <FontAwesomeIcon icon="upload" className="mr-1" /> Upload
          </Button>
        </>
      )}
    </div>
  );
}

ClubCourseImageList.propTypes = {
  imageSize: PropTypes.number
};

ClubCourseImageList.defaultProps = {
  imageSize: 40
};

export default ClubCourseImageList;
