// Vendor
import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

// App
import ProviderCollection from "../../../common/ProviderCollection";
import { RateMismatchContext } from "../RateMismatchComponent";
import { RateMismatchTableScheduleColumn as ScheduleColumn } from "./RateMismatchTable_Schedule";
import {
  MISSING_RATE_DELETE,
  MISSING_RATE_TOGGLE_MUTE
} from "../../../common/Mutations";

function RateMismatchTable() {
  const {
    state: {
      missingRateCourses,
      filter: { muteFilter, regexFilter, providerFilter }
    },
    dispatch
  } = useContext(RateMismatchContext);

  // Mutations
  const [toggleMute, { error: muteError }] = useMutation(
    MISSING_RATE_TOGGLE_MUTE,
    {
      onCompleted: data => handleMuteComplete(data)
    }
  );
  const [deleteMissingRate, { error: deleteError }] = useMutation(
    MISSING_RATE_DELETE,
    {
      onCompleted: data => handleDeleteComplete(data)
    }
  );

  // Handlers
  function handleMuteComplete({ missingRateToggleMute: results }) {
    results.ok && dispatch({ type: "MISMATCH_MUTE", payload: results.rateId });
  }
  function handleDeleteComplete({ missingRateDelete: results }) {
    results.ok &&
      dispatch({ type: "MISMATCH_DELETE", payload: results.rateId });
  }

  function handleToggleMute(id) {
    toggleMute({ variables: { id } });
  }
  function handleDelete(id) {
    deleteMissingRate({ variables: { id } });
  }

  /**
   * Apply filter constraints to rate list
   * @param {{muted: boolean}} rate
   * @param {{courseName: string, clubName: string, provider: string}} course
   */
  function rateFilterFunc(rate, course) {
    return (
      rate.muted === muteFilter &&
      course.provider.match(providerFilter) &&
      (course.courseName.match(regexFilter) ||
        course.clubName.match(regexFilter))
    );
  }

  // Error guard
  if (muteError) return <p className="text-danger">{muteError.message}</p>;
  if (deleteError) return <p className="text-danger">{deleteError.message}</p>;

  return (
    <Table striped borderless hover className="text-left">
      <thead>
        <tr>
          <th>Course</th>
          <th>Club</th>
          <th>Rate name</th>
          <th className="Col--shrink">Rate id</th>
          <th>Provider</th>
          <th>Schedule</th>
          <th>Encounter range</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody className="border">
        {missingRateCourses.map((course, i) =>
          course.missingRateSchedules.map((sched, k) =>
            sched.missingRates
              .filter(r => rateFilterFunc(r, course))
              .map((rate, j) => (
                <tr key={`${i}-${k}-${j}`}>
                  <td>
                    <Link
                      to={`/club/${course.clubSlug}?course=${course.courseSlug}`}
                    >
                      {course.courseName}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/club/${course.clubSlug}`}>
                      {course.clubName}
                    </Link>
                  </td>
                  <td className="Col--shrink">{rate.name}</td>
                  <td className="Col--shrink">{rate.rateTypeId}</td>
                  <td className="Col--shrink">
                    {
                      ProviderCollection.find(p => p.key === course.provider)
                        .label
                    }
                  </td>
                  <td className="Col--shrink">
                    <ScheduleColumn
                      schedule={sched}
                      course={course}
                      id={`schedule-${i}-${k}-${j}`}
                    />
                  </td>
                  <td className="Col--shrink">
                    {/* Encountered times */}
                    {moment(rate.encounterSpan.first).format("YYYY-MM-DD")} -
                    {moment(rate.encounterSpan.last).format("YYYY-MM-DD")}
                    <br />
                    {moment(rate.encounterSpan.first).format("HH:mm")} -
                    {moment(rate.encounterSpan.last).format("HH:mm")}
                  </td>
                  <td className="Col--shrink">
                    <FontAwesomeIcon
                      icon="times"
                      className="text-danger cursor-pointer"
                      onClick={() => handleDelete(rate._id)}
                    />
                  </td>
                  <td className="Col--shrink">
                    <FontAwesomeIcon
                      icon={rate.muted ? "bell" : "bell-slash"}
                      className="text-secondary cursor-pointer"
                      onClick={() => handleToggleMute(rate._id)}
                    />
                  </td>
                </tr>
              ))
          )
        )}
      </tbody>
    </Table>
  );
}

export default RateMismatchTable;
