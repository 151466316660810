// Vendor
import React, { Component } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import Users from "../users/Users";
import OrderList from "../orders/OrderList";
import { ClubSearch } from "../club-search/ClubSearch";
import NoMatch from "../ui/NoMatch";
import Club from "../clubs/Club";
import StyleTest from "../style-test/StyleTest";
import GolfDestinationList from "../golf-destinations/GolfDestinationList";
import GolfDestination from "../golf-destinations/GolfDestination";
import StartPage from "../start-page/StartPage";
import Order from "../orders/Order";
import GolfersUserList from "../golfers-users/GolfersUserList";
import { GolfersUser } from "../golfers-users/GolfersUser";
import Vouchers from "../vouchers/Vouchers";
import Voucher from "../vouchers/Voucher";
import Affiliates from "../affiliates/Affiliates";
import Affiliate from "../affiliates/Affiliate";
import Sites from "../sites/Sites";
import Site from "../sites/Site";
import PartnerNetworks from "../partner-networks/PartnerNetworks";
import PartnerNetwork from "../partner-networks/PartnerNetwork";
import TourOperators from "../tour-operators/TourOperators";
import TourOperator from "../tour-operators/TourOperator";
import PartnerLinks from "../partner-link/PartnerLinks";
import PartnerLink from "../partner-link/PartnerLink";
import Email from "../emails/Email";
import InvoiceList from "../invoices/InvoiceList";
import Invoice from "../invoices/Invoice";
import AmadeusList from "../amadeus/AmadeusList";
import Amadeus from "../amadeus/Amadeus";
class Routes extends Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/orders" component={OrderList} />
        <Route path="/order/:id" component={Order} />
        <Route path="/clubs" component={ClubSearch} />
        <Route path="/club/:slug" component={Club} />
        <Route path="/club" component={Club} />
        <Route path="/golf-destinations" component={GolfDestinationList} />
        <Route path="/golf-destination/:slug?" component={GolfDestination} />
        <Route path="/invoices" component={InvoiceList} />
        <Route path="/invoice/:id" component={Invoice} />
        <Route path="/emails" component={Email} />
        <Route path="/amadeus/:cardRef" component={Amadeus} />
        <Route path="/amadeus" component={AmadeusList} />
        <Route path="/golfers-users" component={GolfersUserList} />
        <Route path="/golfers-user/:id" component={GolfersUser} />
        <Route path="/users" component={Users} />
        <Route path="/start" component={StartPage} />
        <Route path="/vouchers" component={Vouchers} />
        <Route path="/voucher/:id?" component={Voucher} />
        <Route path="/partner-networks" component={PartnerNetworks} />
        <Route path="/partner-network/:id?" component={PartnerNetwork} />
        <Route path="/tour-operators" component={TourOperators} />
        <Route path="/tour-operator/:id?" component={TourOperator} />
        <Route path="/emails" component={Email} />
        <Route path="/partner-links" component={PartnerLinks} />
        <Route path="/partner-link/:id?" component={PartnerLink} />
        <Route path="/affiliates" component={Affiliates} />
        <Route path="/affiliate/:id?" component={Affiliate} />
        <Route path="/sites" component={Sites} />
        <Route path="/site/:id?" component={Site} />
        <Route path="/style-test" component={StyleTest} />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default withRouter(Routes);
