// Vendor
import React, { Fragment } from "react";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Link } from "react-router-dom";

// App
import Loader from "../ui/Loader";
import { StatusIcon } from "../ui/StatusIcon";
import ProviderCollection from "../../common/ProviderCollection";

export function ClubSearchTable(props) {
  const { sorting, onSortingChange, loading, error, data } = props;

  if (loading) return <Loader />;
  if (error) return <p className="mt-2 text-danger">{error.message}</p>;

  const SortableHeader = ({ children, sortOn }) => (
    <th>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => onSortingChange(sortOn)}
      >
        {children}
        {sorting.sortOn === sortOn && (
          <FontAwesomeIcon
            icon={`sort-${sorting.ascending ? "up" : "down"}`}
            className="ml-1"
          />
        )}
      </div>
    </th>
  );

  const CourseBadges = ({ club }) => (
    <Fragment>
      {club.courses.map((course, i) => (
        <li
          className={`d-inline-block badge badge-sm badge-${
            course.active ? "info" : "light"
          } mr-2 mb-1`}
          key={`${i}`}
        >
          <div className="cursor--default">{course.name}</div>
        </li>
      ))}
    </Fragment>
  );

  return (
    <div>
      <Table borderless striped>
        <thead>
          <tr>
            <SortableHeader sortOn="NAME">Name</SortableHeader>
            <th>Courses</th>
            <SortableHeader sortOn="PROVIDER">Provider</SortableHeader>
            <SortableHeader sortOn="COUNTRY">Country</SortableHeader>
            <SortableHeader sortOn="ID">Created</SortableHeader>
            <SortableHeader sortOn="LAST_UPDATED">Updated</SortableHeader>
            <th>Claimed</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data.clubSearchPage.map((club, index) => (
            <tr key={index}>
              <td>{club.name}</td>
              <td>
                <CourseBadges club={club} />
              </td>
              <td>
                {ProviderCollection.find(p => p.key === club.teeSheetProvider)
                  ?.label ?? "None"}
              </td>
              <td>{club.country}</td>
              <td>
                {moment(TimestampFromId(club._id)).format("YYYY-MM-DD HH:mm")}
              </td>
              <td>{moment(club.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
              <td>
                <StatusIcon value={club.claimed} />
              </td>
              <td>
                <Link to={"/club/" + club.slug}>Edit</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

/**
 * Extract the timestamp from an ObjectId and return as Date
 * @param {string} id ObjectId string representation
 * @returns {Date} Timestamp
 */
function TimestampFromId(id) {
  return new Date(parseInt(id.substring(0, 8), 16) * 1000);
}
