import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Image } from "cloudinary-react";

function InputCityTable(props) {
  function handleAdd() {
    const { onChange } = props;
    onChange([...items, inputValues]);
    setInputValues(getDefaultValues());

    // reset form
    ref && ref.reset();
  }

  function handleRemove(pill) {
    const { items, onChange } = props;
    onChange(items.filter(item => item !== pill));
  }

  function getDefaultValues() {
    let initialValues = {};
    // add empty values for all field keys
    props.fields.forEach(field => {
      initialValues[field.key] = "";
    });
    return initialValues;
  }

  const [ref, setRef] = useState(null);
  const { fields, items } = props;
  const [inputValues, setInputValues] = useState(getDefaultValues());

  return (
    <div className="InputTable">
      <AvForm ref={ref => setRef(ref)} onValidSubmit={() => handleAdd()}>
        <div className="d-flex align-items-start mb-2">
          {fields.map(field => (
            <AvField
              key={field.key}
              groupAttrs={{ className: "d-inline-block mb-0 mr-2" }}
              name={`input-${field.key}`}
              value={inputValues[field.key]}
              placeholder={field.label}
              onChange={e => {
                let values = { ...inputValues };
                values[field.key] = e.target.value;
                setInputValues(values);
              }}
              onKeyPress={e => {
                if (e.charCode === 13) {
                  e.target.blur();
                }
              }}
              type="text"
              validate={field.validator}
              required
            />
          ))}
          <div>
            <Button color="primary">Add</Button>
          </div>
        </div>
      </AvForm>
      <div className="ConnectedCityList">
        {items.map((item, i) => (
          <li
            className="d-block mb-3 pill pill--active pill--dismissable pill--image mr-2"
            key={i}
          >
            <Image
              className="mr-1"
              publicId={item.imgId}
              width="35"
              height="35"
              crop="fill"
              radius="max"
            />
            {item.name}
            <button
              className="pill__close"
              type="button"
              onClick={() => handleRemove(item)}
            >
              x
            </button>
          </li>
        ))}
      </div>
    </div>
  );
}

InputCityTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      validator: PropTypes.shape.isRequired,
      label: PropTypes.string
    })
  ).isRequired,
  imageField: PropTypes.string
};
InputCityTable.defaultProps = {
  items: [],
  fields: [],
  imageField: ""
};

export default InputCityTable;
