// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import { Button, PopoverBody, Popover } from "reactstrap";
import { DynamicTransTableContext } from "../DynamicTransTable/DynamicTransTable";

function DynamicTransTableRow(props) {
  const {
    dispatch,
    languages,
    translationField,
    additionalFields,
    openPopoverId
  } = useContext(DynamicTransTableContext);
  const [editing, setEditing] = useState(props.isNew);
  const [edits, setEdits] = useState(props.isNew ? { ...props.item } : {});
  const [focusedKey, setFocusedKey] = useState(undefined);

  function togglePopover() {
    dispatch({
      type: "POPOVER_TOGGLE",
      payload: openPopoverId === popoverId ? null : popoverId
    });
  }

  function edit() {
    setEdits({ ...props.item });
    setEditing(true);
    setFocusedKey(undefined);
  }

  function onFocus(key) {
    // set focus if not already in focus
    if (key !== focusedKey) setFocusedKey(key);
  }

  function onKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    } else if (e.key === "Escape") {
      e.preventDefault();
      reset();
    }
  }

  function onTranslationChange(value, lang) {
    let editsClone = { ...edits };
    if (lang === "en-GB") {
      editsClone[translationField] = value;
    } else {
      editsClone.i18n = { ...editsClone.i18n };
      editsClone.i18n[lang] = { ...editsClone.i18n[lang] };
      editsClone.i18n[lang][translationField] = value;
    }
    setEdits(editsClone);
  }

  function onTermChange(key, value) {
    let editClone = { ...edits };
    editClone[key] = value;
    setEdits(editClone);
  }

  function remove() {
    togglePopover();
    dispatch({ type: "DELETE", payload: props.index, trigger: true });
  }

  function reset() {
    setEditing(false);
    if (props.isNew)
      dispatch({ type: "DELETE", payload: props.index, trigger: false });
  }

  function save() {
    dispatch({
      type: "UPDATE",
      payload: { index: props.index, value: edits },
      trigger: true
    });
    setEditing(false);
  }

  const { item, popoverId } = props;
  return (
    <tr>
      {editing ? (
        <>
          {additionalFields.map((field, i) => (
            <td key={i} style={{ minWidth: "175px" }}>
              <input
                autoFocus={focusedKey === field.key}
                onFocus={() => onFocus(field.key)}
                value={edits[field.key]}
                onKeyDown={onKeyDown}
                onChange={(e) => onTermChange(field.key, e.target.value)}
                className="pl-2 w-100 form-control"
              />
            </td>
          ))}
          {languages.map((lang, i) => {
            let value =
              lang === "en-GB"
                ? edits[translationField]
                : edits.i18n[lang][translationField];
            let autoFocus = focusedKey === lang;
            return (
              <td key={i}>
                <input
                  style={autoFocus ? { minWidth: "200px" } : null}
                  autoFocus={autoFocus}
                  onFocus={() => onFocus(lang)}
                  value={value}
                  onKeyDown={onKeyDown}
                  onChange={(e) => onTranslationChange(e.target.value, lang)}
                  className="pl-2 w-100 form-control"
                />
              </td>
            );
          })}
          <td style={{ minWidth: "150px" }}>
            <Button
              className="d-inline-flex py-0 px-2 mr-2"
              color="primary"
              onClick={save}
            >
              OK
            </Button>
            <Button
              className="d-inline-flex py-0 px-2 btn-outline-light"
              onClick={reset}
            >
              Cancel
            </Button>
          </td>
        </>
      ) : (
        <>
          {additionalFields.map((field, i) => (
            <td key={i}>{item[field.key]}</td>
          ))}
          {languages.map((lang, i) => {
            const isEnglish = lang === "en-GB";
            const value = isEnglish
              ? item[translationField]
              : item.i18n[lang][translationField];
            const hasValue = value !== "" && value !== undefined;

            return isEnglish ? (
              <td key={i}>{value}</td>
            ) : (
              <td key={i}>
                <FontAwesomeIcon
                  icon={hasValue ? "check-circle" : "ban"}
                  className={`ml-2 mr-1 ${
                    hasValue ? "text-success" : "text-danger"
                  }`}
                />
              </td>
            );
          })}
          <td className="text-right" style={{ minWidth: "75px" }}>
            <Button
              className="p-0 d-inline-flex mr-3"
              color="link"
              onClick={edit}
            >
              <FontAwesomeIcon icon="pencil-alt" />
            </Button>
            <Button
              className="p-0 text-muted d-inline-flex"
              color="link"
              id={popoverId}
              onClick={togglePopover}
            >
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
            <Popover
              placement="left"
              target={popoverId}
              isOpen={openPopoverId === popoverId}
              toggle={togglePopover}
              className="p-3"
            >
              <PopoverBody>
                Are you sure?
                <Button
                  className="ml-2"
                  color="danger"
                  size="sm"
                  type="button"
                  onClick={remove}
                >
                  Yes
                </Button>
              </PopoverBody>
            </Popover>
          </td>
        </>
      )}
    </tr>
  );
}

export const DynamicTransTableRowPropType = PropTypes.shape({
  key: PropTypes.string,
  i18n: PropTypes.shape({
    "es-ES": PropTypes.object,
    "sv-SE": PropTypes.object,
    "de-DE": PropTypes.object,
    "da-DK": PropTypes.object,
    "fi-FI": PropTypes.object,
    "fr-FR": PropTypes.object,
    "it-IT": PropTypes.object,
    "nl-NL": PropTypes.object,
    "nb-NO": PropTypes.object,
    "ru-RU": PropTypes.object,
    "cs-CZ": PropTypes.object
  })
});

DynamicTransTableRow.propTypes = {
  item: DynamicTransTableRowPropType,
  popoverId: PropTypes.string
};

export default DynamicTransTableRow;
