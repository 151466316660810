// Vendor
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Input, Form, ButtonGroup, Button, Label, Row, Col } from "reactstrap";

// App
import CountryCollection from "../../common/CountryCollection";
import ProviderCollection from "../../common/ProviderCollection";

import { debounce } from "lodash";

function ClubSearchInputForm({ handleSubmit }) {
  const [searchParams, setSearchParams] = useState({
    name: "",
    provider: [],
    country: [],
    active: null,
    amadeusActive: null
  });

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSubmit(formatSearchParams());
    }
  }, [searchParams]);

  function handleSearchParamChange(key, value) {
    if (key === "active" || key === "amadeusActive") {
      setSearchParams((prev) => ({
        ...prev,
        [key]: prev[key] === value ? null : value
      }));
    } else {
      setSearchParams((prev) => ({
        ...prev,
        [key]: value
      }));
    }
  }

  const debouncedHandleSearchParamChange = useRef(
    debounce((field, value) => handleSearchParamChange(field, value), 500)
  ).current;

  function formatSearchParams() {
    const params = {
      name: searchParams.name,
      provider: searchParams.provider.map((p) => p.value),
      country: searchParams.country.map((c) => c.label),
      ...(searchParams.active !== null && { active: searchParams.active }),
      ...(searchParams.amadeusActive !== null && {
        amadeusActive: searchParams.amadeusActive
      })
    };
    return params;
  }

  function handleNameChange(e) {
    debouncedHandleSearchParamChange("name", e.target.value);
  }

  const FilterButtons = ({ title, id, handleFilterChange, active }) => (
    <div className="d-inline-block mr-2 mb-2">
      <Label for={id}>{title}</Label>
      <ButtonGroup id={id}>
        <Button
          color={`${active === true ? "primary" : "outline-light"}`}
          onClick={() => handleFilterChange(true)}
        >
          Yes
        </Button>
        <Button
          color={`${active === false ? "primary" : "outline-light"}`}
          onClick={() => handleFilterChange(false)}
        >
          No
        </Button>
      </ButtonGroup>
    </div>
  );

  return (
    <Form>
      <Row>
        <Col>
          <label htmlFor="nameLike">Club/course name</label>
          <Input
            placeholder="Club name"
            id="nameLike"
            onChange={handleNameChange}
          />
        </Col>
        <Col>
          <label htmlFor="providers">Providers</label>
          <Select
            id="providers"
            options={ProviderCollection.map((p) => ({
              label: p.label,
              value: p.key
            }))}
            value={searchParams.provider}
            onChange={(selectedProviders) =>
              handleSearchParamChange("provider", selectedProviders)
            }
            isMulti
          />
        </Col>
        <Col>
          <label htmlFor="countries">Countries</label>
          <Select
            id="countries"
            options={CountryCollection.map((c) => ({
              label: c.name,
              value: c.code
            }))}
            value={searchParams.country}
            onChange={(selectedCountries) =>
              handleSearchParamChange("country", selectedCountries)
            }
            isMulti
          />
        </Col>
        <Col>
          <FilterButtons
            id="btn-group-active-1"
            title="Claimed"
            handleFilterChange={(value) =>
              handleSearchParamChange("active", value)
            }
            active={searchParams.active}
          />
          <FilterButtons
            id="btn-group-active-2"
            title="Amadeus Active"
            handleFilterChange={(value) =>
              handleSearchParamChange("amadeusActive", value)
            }
            active={searchParams.amadeusActive}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default ClubSearchInputForm;
