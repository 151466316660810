// Vendor
import { useEffect, useRef } from "react";

/**
 * ResizeObserver wrapped for hook usage
 * @param { Object } element Element to observe
 * @param { function } callback Observer callback
 */
const useResizeObserver = (element, callback = () => {}) => {
  const current = element?.current;
  const observer = useRef(null);

  useEffect(() => {
    const elementCurrent = element?.current;
    const observe = () => {
      if (elementCurrent && observer?.current) {
        observer.current.observe(elementCurrent);
      }
    };

    if (observer?.current && current) {
      observer.current.unobserve(current);
    }
    observer.current = new ResizeObserver(callback);

    observe();

    return () => {
      if (observer?.current && elementCurrent) {
        observer.current.unobserve(elementCurrent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, element]);
};

export { useResizeObserver };
