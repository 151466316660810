export default function templateReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return action.payload;
    case "NAME":
      return { ...state, name: action.payload };
    case "SUBJECT":
      return { ...state, subject: action.payload };
    case "EMAIL_BODY":
      return { ...state, emailBody: action.payload };
    // This case will either add or remove a recipient from the template's recipient list
    case "UPDATE_TEMPLATE_RECIPIENTS": {
      const { checked, recipientId } = action.payload;
      const { recipientList } = state;
      if (checked) {
        // add recipient to list if it's not already included
        if (!recipientList.includes(recipientId)) {
          return {
            ...state,
            recipientList: [...recipientList, recipientId]
          };
        } else {
          // return state if recipient is already included... should never happen
          return { ...state };
        }
      } else {
        // remove recipient from list
        return {
          ...state,
          recipientList: recipientList.filter((id) => id !== recipientId)
        };
      }
    }
    default:
      return state;
  }
}
