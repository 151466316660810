// Vendor
import React from "react";
import { FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import IdGeneratorHelper from "../../../../common/IdGeneratorHelper";

function ClubCourseContactSelect(props) {
  const id = IdGeneratorHelper.getRandomId();
  return (
    <div className="mt-5">
      <h3>Contact Info</h3>
      <FormGroup tag="fieldset">
        <FormGroup check inline>
          <Label check>
            <input
              className="form-check-input"
              type="radio"
              value="option1"
              name={id}
              checked={!props.hasOwnContactInfo}
              onChange={() => {
                props.onChange(false);
              }}
            />
            Use club's contact info
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <input
              className="form-check-input"
              type="radio"
              value="option2"
              name={id}
              checked={props.hasOwnContactInfo}
              onChange={() => {
                props.onChange(true);
              }}
            />
            Other contact info
          </Label>
        </FormGroup>
      </FormGroup>
    </div>
  );
}

ClubCourseContactSelect.propTypes = {
  hasOwnContactInfo: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ClubCourseContactSelect;
