// Vendor
import React, { useContext } from "react";
import { useMutation } from "@apollo/client";

// App
import {
  CLUB_ADD_COMMENT,
  CLUB_CLOSE_COMMENT_TASK,
  CLUB_DELETE_COMMENT,
  CLUB_EDIT_COMMENT
} from "../../../common/Mutations";
import { ClubContext } from "../Club";
import { LayoutContext } from "../../app/Layout";
import { CommentListBase } from "../../ui/Comments/CommentListBase";

function ClubComments({ comments }) {
  const { addAlert } = useContext(LayoutContext);
  const { state, dispatch, silentDispatch } = useContext(ClubContext);

  const [createComment, { loading: createCommentLoading }] = useMutation(
    CLUB_ADD_COMMENT,
    {
      onCompleted: (data) =>
        dispatch({ type: "COMMENT_ADD", payload: data.addClubComment.comment }),
      onError: (err) => handleError(err.message)
    }
  );
  const [editComment, { loading: editCommentLoading }] = useMutation(
    CLUB_EDIT_COMMENT,
    {
      onCompleted: (data) => {
        triggerCommentListRefresh(data.editClubComment.comment);
      },
      onError: (err) => {
        handleError(err, "Error: Could not edit comment");
      }
    }
  );
  const [deleteComment, { loading: deleteCommentLoading }] = useMutation(
    CLUB_DELETE_COMMENT,
    {
      onCompleted: (data) => {
        dispatch({
          type: "COMMENT_DELETE",
          payload: data.deleteClubComment.comment
        });
      },
      onError: (err) => handleError(err, err.message)
    }
  );
  const [closeCommentTask, { loading: closeCommentTaskLoading }] = useMutation(
    CLUB_CLOSE_COMMENT_TASK,
    {
      onCompleted: (data) =>
        triggerCommentListRefresh(data.closeClubCommentTask),
      onError: (err) => handleError(err, "Error: Could not close comment task")
    }
  );

  function handleError(error, alertMessage) {
    console.log(error);
    addAlert({
      color: "danger",
      message: alertMessage
    });
  }

  function triggerCommentListRefresh(comments) {
    silentDispatch({ type: "COMMENTS_UPDATE", payload: comments });
  }

  function handleNewComment(input) {
    createComment({
      variables: {
        clubId: state._id,
        ...input
      }
    });
  }

  function handleEditComment(input) {
    console.log(input);
    editComment({
      variables: {
        clubId: state._id,
        ...input
      }
    });
  }

  function handleDeleteComment(comment) {
    deleteComment({
      variables: {
        clubId: state._id,
        ...comment
      }
    });
  }

  function handleCloseCommentTask(input) {
    closeCommentTask({
      variables: {
        clubId: state._id,
        ...input
      }
    });
  }

  return (
    <CommentListBase
      comments={comments}
      onCloseTask={handleCloseCommentTask}
      onNewComment={handleNewComment}
      onSaveEditComment={handleEditComment}
      onDeleteComment={handleDeleteComment}
      newCommentLoading={createCommentLoading}
      closeTaskLoading={closeCommentTaskLoading}
    />
  );
}

export default ClubComments;
