// Vendor
import React, { useEffect, useReducer, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import {
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";

// APP
import AffiliateReducer from './affiliateReducer';
import {
  AFFILIATE
} from "../../common/Queries";
import { InputField } from "../ui/FunctionalComponents";
import ProviderCollection from "../../common/ProviderCollection";
import { CREATE_AFFILIATE, UPDATE_AFFILIATE, UPDATE_AFFILIATE_PASSWORD } from "../../common/Mutations";
import { LayoutContext } from "../app/Layout";


const Affiliate = (props) => {
  const { addAlert } = useContext(LayoutContext);

  const [state, dispatch] = useReducer(AffiliateReducer, {
    _id: "",
    aid: "",
    cid: "",
    name: "",
    excludedTeesheetProviders: [],
    isActive: false,
    loaded: false,
    modal: null
  });

  const onSave = () => {
    const affiliateInput = {
      aid: state.aid,
      cid: state.cid,
      name: state.name,
      excludedTeesheetProviders: state.excludedTeesheetProviders,
      isActive: state.isActive
    };

    state._id ? updateAffiliate({ variables: { input: affiliateInput, affiliateId: state._id } }) : createAffiliate({ variables: { input: affiliateInput } });
  }

  const alertError = (message) => addAlert({ color: "danger", message });

  const displayModal = (password, username) => dispatch({ type: "MODAL", payload: { password, username, open: true } });

  const initAffiliate = (affiliate) => dispatch({ type: "INIT", payload: affiliate });

  const onMutateAffiliateCompleted = ({ mutateAffiliate: { ok, affiliate, password } }) => {
    if (!ok) {
      return alertError("Could not create or update Affiliate.");
    }

    initAffiliate(affiliate);
    addAlert({
      color: "success",
      message: "Affiliate created/updated successfully."
    });

    if (password) {
      displayModal(password, affiliate.username);
    }
  }

  const onPasswordUpdateCompleted = ({ updateAffiliatePassword: { ok, password } }) => {
    if (!ok) {
      return addAlert({ color: "danger", message: "Could not update Affiliate password." });
    }

    displayModal(password, state.username);
  };

  const [updateAffiliate, { loading: updateLoading }] = useMutation(
    UPDATE_AFFILIATE,
    {
      onCompleted: onMutateAffiliateCompleted,
      onError: () => alertError("Could not update Affiliate.")
    }
  );

  const [createAffiliate, { loading: createLoading }] = useMutation(
    CREATE_AFFILIATE,
    {
      onCompleted: onMutateAffiliateCompleted,
      onError: () => alertError("Could not create Affiliate.")
    }
  );

  const [updateAffiliatePassword, { loading: passwordLoading }] = useMutation(
    UPDATE_AFFILIATE_PASSWORD,
    {
      onCompleted: onPasswordUpdateCompleted,
      onError: () => alertError("Could not update Affiliate password.")
    }
  );

  const [getAffiliate] = useLazyQuery(AFFILIATE, {
    onCompleted: (data) => initAffiliate(data.affiliate)
  });

  useEffect(() => {
    if (props.match.params.id && state._id !== props.match.params.id) {
      getAffiliate({ variables: { id: props.match.params.id } });
    }

  }, [getAffiliate, props.match.params.id, state._id]);

  if (props.match.params.id && !state.loaded) return null;

  return (
    <>
      <AvForm
        beforeSubmitValidation={event => event.stopPropagation()}
        onValidSubmit={onSave}
        className="Affiliate"
      >
        <div className="row">
          {state._id && <div className="col-md-2">
            <label>UserName</label>
            <div className="float-input-group">
              <input readOnly value={state.username} />
            </div>
          </div>}
          <div className="col-md-2">
            <label>Name</label>
            <InputField
              targetKey="name"
              value={state.name}
              onChange={changeEvent =>
                dispatch({ type: "NAME", payload: changeEvent.value })
              }
              options={{ required: true, type: "string" }}
            />
          </div>
          <div className="col-md-2">
            <label>Cid</label>
            <InputField
              targetKey="cid"
              value={state.cid}
              onChange={changeEvent =>
                dispatch({ type: "CID", payload: changeEvent.value })
              }
              options={{ required: true, type: "string" }}
            />
          </div>
          <div className="col-md-2">
            <label>Aid</label>
            <InputField
              targetKey="aid"
              value={state.aid}
              onChange={changeEvent =>
                dispatch({ type: "AID", payload: changeEvent.value })
              }
              options={{ required: true, type: "string" }}
            />
          </div>
          <div className="col-md-2">
            <label>Teesheetproviders</label>
            {ProviderCollection.filter(x => x.key !== "NONE").map(provider =>
              <AffiliateCheckBox
                key={provider.key}
                name={provider.key}
                label={provider.label}
                onChange={(e) => dispatch({ type: "EXCLUDED_TEESHEET_PROVIDERS", payload: { name: provider.key, checked: e.currentTarget.checked } })}
                checked={!state.excludedTeesheetProviders.includes(provider.key)}
              />)}
          </div>
          <div className="col-md-2">
            <label htmlFor="IsActive">IsActive</label>
            <AffiliateCheckBox
              name="IsActive"
              onChange={(e) => dispatch({ type: "ISACTIVE", payload: e.currentTarget.checked })}
              checked={state.isActive}
            />
          </div>


        </div>
        <div className="Save-container">
          <div className="Save-container__phantom" />
          <div className="Save-container__main">
            <FormGroup>
              <Button disabled={createLoading || updateLoading} size="lg" type="submit"> Save </Button>
            </FormGroup>
          </div>
        </div>
      </AvForm >
      {state._id && <div className="col-md-12">
        <Button disabled={passwordLoading} color="primary" className="float-right" onClick={() => updateAffiliatePassword({ variables: { affiliateId: state._id } })}>Generate new password</Button>
      </div>}
      {state.modal &&
        <Modal isOpen={state.modal.open} toggle={() => dispatch({ type: "MODAL", payload: { open: !state.modal.open } })} >
          <ModalHeader>Save this information</ModalHeader>
          <ModalBody>
            UserName: {state.modal.username}<br />
                        Password: {state.modal.password}<br />
                        Url: {process.env.REACT_APP_AFFILIATE_URL}
          </ModalBody>

        </Modal>}
    </>
  );
}

const AffiliateCheckBox = (props) => {

  return (
    <div>
      <input
        className="checkbox-margin"
        id={props.name}
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
      />
      {props.label ? <label className="checkbox-label" htmlFor={props.name}>{props.label}</label> : ""}
    </div>);
}

export default withRouter(Affiliate);