import React, { useContext } from "react";
import { ZOOM_VALIDATOR } from "../../../common/Validators";
import { InputField } from "../../ui/FunctionalComponents";
import { StartPageContext } from "../StartPage";

export const MapFields = () => {
  const {
    state: { startPageMap },
    dispatch
  } = useContext(StartPageContext);
  let stateMap = startPageMap ? startPageMap : {};

  return (
    <div className="mb-5">
      <h3 className="mb-3">Map</h3>
      <div className="row">
        <div className="col">
          <InputField
            targetKey="latitude"
            value={stateMap.latitude}
            label="Latitude"
            onChange={event =>
              dispatch({
                type: "MAP",
                payload: { ...stateMap, latitude: event.value }
              })
            }
            required
            type="number"
          />
        </div>
        <div className="col">
          <InputField
            targetKey="longitude"
            value={stateMap.longitude}
            label="Longitude"
            onChange={event =>
              dispatch({
                type: "MAP",
                payload: { ...stateMap, longitude: event.value }
              })
            }
            required
            type="number"
          />
        </div>
        <div className="col">
          <InputField
            targetKey="zoom"
            value={stateMap?.zoom}
            label="Zoom level (0-19)"
            onChange={event =>
              dispatch({
                type: "MAP",
                payload: { ...stateMap, zoom: event.value }
              })
            }
            validate={ZOOM_VALIDATOR}
            required
            type="number"
          />
        </div>
      </div>
    </div>
  );
};
