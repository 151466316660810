export const EMAIL_VALIDATOR = {
  email: {
    value: true,
    errorMessage: `Not a valid email.`
  }
};

export const NAME_VALIDATOR = {
  pattern: {
    value: "^[A-zÀ-ÿ- ]+$",
    errorMessage: `Only letters and spaces allowed.`
  },
  maxLength: { value: 25 }
};

export const ALPHANUMERIC_VALIDATOR = {
  pattern: {
    value: "^[A-zÀ-ÿ-0-9 ]+$",
    errorMessage: `Only spaces and alphanumerical characters allowed.`
  }
};

export const SLUG_VALIDATOR = {
  required: { value: true, errorMessage: "Is required." },
  pattern: {
    value: "^[a-z0-9]+(-[a-z0-9]+)*$",
    errorMessage: "Not a valid slug."
  }
};

export const PUBLIC_ID_VALIDATOR = {
  required: { value: true, errorMessage: "Is required." },
  pattern: {
    value: "^[a-z0-9-_]+(/[a-z0-9-_]+)*$",
    errorMessage: "Not a valid slug."
  }
};

export const NUM_DECIMAL_VALIDATOR = {
  pattern: {
    value: "^-?(0|([1-9][0-9]*))((.|,)[0-9]+)?$",
    errorMessage: "Not a number."
  }
};

export const NUM_DECIMAL_VALIDATOR_REQ = {
  required: { value: true, errorMessage: "Is required." },
  pattern: {
    value: /^-?(0|([1-9][0-9]*))((.|,)[0-9]+)?$/,
    errorMessage: "Not a number."
  }
};

export const INT_VALIDATOR = {
  pattern: {
    value: "^(0|([1-9][0-9]*))$",
    errorMessage: "Not an integer."
  }
};
export const ZOOM_VALIDATOR = {
  pattern: {
    value: "^(0|1[0-9]|[1-9])$",
    errorMessage: "Must be between 0 and 19"
  }
};

export const CLUBNAME_VALIDATOR = {
  required: { value: true, errorMessage: "Is required." },
  maxLength: { value: 50 }
};

export const PHONE_VALIDATOR = {
  pattern: {
    value: "^\\+(?:[0-9] ?){6,14}[0-9]$",
    errorMessage: "Invalid format."
  }
};

export const CLOUDINARY_PATH_VALIDATOR = {
  pattern: {
    value: /^\w+(-\w+)*(\/\w+(-\w+)*)*$/,
    errorMessage: "Invalid format."
  }
};

export const URL_VALIDATOR = {
  pattern: {
    value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/,
    errorMessage: "Not a valid url."
  }
};

export const VAT_VALIDATOR = {
  pattern: {
    value: /^[A-Z]{2}\w{2,12}$/,
    errorMessage:
      "You must enter a valid VAT format. 4-14 characters long, the first 2 characters should be CAPITAL letters. The rest can be either letters or digits."
  }
};

export const BIC_VALIDATOR = {
  pattern: {
    value: /^[A-Z]{6}\w{2,5}$/,
    errorMessage:
      "You must enter a valid BIC format. 8-11 characters long, the first 6 characters should be CAPITAL letters. The rest can be either letters or digits."
  }
};

export const IBAN_VALIDATOR = {
  pattern: {
    value: /^[A-Z]{2}\d{2}\d{1,30}$/,
    errorMessage:
      "You must enter a valid IBAN format. 5-34 characters long, the first 2 characters should be CAPITAL letters followed by 2 digits. The rest can be either letters or digits."
  }
};

export const TARGET_RATE_ID_VALIDATOR = {
  pattern: {
    value: /^[0-9]+(,?[0-9]+)*$/,
    errorMessage:
      "Enter digits 0-9 separated by commas, no white spaces or trailing commas."
  }
};
export const POSITIVE_NUMBER = {
  min: { value: 0, errorMessage: "Please enter a positive number" }
};

export const OFFSET_VALIDATOR = {
  pattern: {
    value: "^[1-9][0-9]*$", // This regex ensures the number is positive and not zero
    errorMessage: "Please enter a positive number greater than zero."
  },
  min: {
    value: 1, // Minimum value is 1, which excludes zero and negative numbers
    errorMessage: "Please enter a positive number greater than zero."
  }
};

export const EMAIL_REGEX= /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;