const CourseTypes = Object.freeze([
  "Parkland",
  "Heathland",
  "Links",
  "Desert",
  "Tropical",
  "Mountain",
  "Oceanfront",
  "Tundra"
]);

export default CourseTypes;
