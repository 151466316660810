// Vendor
import React, { useContext } from "react";
import { Button } from "reactstrap";

// App
import ClubAmenityCollection from "../collections/ClubCourseAmenityCollection";
import ClubCourseAmenity from "../ClubCourseAmenity";
import { ClubCourseContext } from "../ClubCourse";

function ClubCourseAmenityList(props) {
  function setAllCheckboxes(set) {
    dispatch({
      type: "AMENITIES",
      payload: set ? [...ClubAmenityCollection.items] : []
    });
  }

  function handleAmenityClick(item) {
    let amenityList = amenities.includes(item.name)
      ? amenities.filter(a => a !== item.name)
      : [...amenities, item.name];

    dispatch({ type: "AMENITIES", payload: amenityList });
  }

  const { state, dispatch } = useContext(ClubCourseContext);
  const amenities = state.amenities || [];

  const amenityElements = ClubAmenityCollection.items.map((amenity, index) => (
    <div className="text-left col-md-4 my-4 my-md-3 my-sm-2" key={index}>
      <ClubCourseAmenity
        name={amenity}
        active={amenities.includes(amenity)}
        onClick={handleAmenityClick}
      />
    </div>
  ));

  return (
    <div className="ClubCourseAmenityList mt-5">
      <h3>{"Amenities"}</h3>
      <div className="form-row">{amenityElements}</div>
      <Button
        className="border-right"
        color="link"
        onClick={() => {
          setAllCheckboxes(true);
        }}
      >
        Select all
      </Button>
      <Button
        color="link"
        onClick={() => {
          setAllCheckboxes(false);
        }}
      >
        Select none
      </Button>
    </div>
  );
}

export default ClubCourseAmenityList;
