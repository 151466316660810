// Vendor
import React, { useContext } from "react";
import { Row, Col } from "reactstrap";

// App
import { ClubCourseContext } from "../ClubCourse";
import { InputField } from "../../../ui/FunctionalComponents";
import { CourseKeyValueStore } from "./ClubCourseKeyValueStore";

function ClubCourseProviderDetails({ teeSheetProvider }) {
  const { state, dispatch } = useContext(ClubCourseContext);
  const { teeSheetCourseId, teeSheetClubId } = state;

  if (!teeSheetProvider || teeSheetProvider === "NONE") return null;

  return (
    <div className="mt-3">
      <h3>Provider integration details</h3>
      <Row>
        {teeSheetProvider !== "EZ_LINKS" &&
          teeSheetProvider !== "NETGOLF" &&
          teeSheetProvider !== "GF365_SIMPLE" && (
            <Col>
              <div className="float-input-group">
                <input
                  id="course-teesheet-club-id"
                  value={teeSheetClubId}
                  readOnly
                />
                <label htmlFor="course-teesheet-club-id">
                  Teesheet Club ID
                </label>
              </div>
            </Col>
          )}
        {teeSheetProvider !== "GF365_SIMPLE" && (
          <Col>
            <InputField
              targetKey="teeSheetCourseId"
              label="Teesheet Course ID"
              value={teeSheetCourseId}
              onChange={(e) =>
                dispatch({ type: "TEESHEET_COURSE_ID", payload: e.value })
              }
            />
          </Col>
        )}
        <CourseKeyValueStore teeSheetProvider={teeSheetProvider} />
      </Row>
    </div>
  );
}

export default ClubCourseProviderDetails;
