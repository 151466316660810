export default function teeTimeTableListReducer(teeTimeTables, action) {
  switch (action.type) {
    case "INIT":
      return action.payload;

    case "TEETIMETABLE_DELETE": {
      return teeTimeTables.filter((t) => t._id !== action.payload);
    }
    case "TEETIMETABLE_UPSERT": {
      let teeTimeTablesCopy = [...teeTimeTables];
      let idx = teeTimeTablesCopy.findIndex(
        (s) => s._id === action.payload._id
      );
      idx === -1
        ? teeTimeTablesCopy.push(action.payload)
        : (teeTimeTablesCopy[idx] = action.payload);
      return teeTimeTablesCopy;
    }

    default:
      return teeTimeTables;
  }
}
