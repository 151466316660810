import React, { useContext } from "react";
import { Card, CardBody, CardFooter, CardHeader, Table } from "reactstrap";
import { InvoiceContext } from "../Invoice";
import PdfGenerator from "./PdfGenerator";

const PaymentDetails = () => {
  const { invoice } = useContext(InvoiceContext);

  return (
    <Card className="h-100">
      <CardHeader>
        <h4>Payment details</h4>
      </CardHeader>
      <CardBody>
        <Table bordered className="m-0">
          <tbody>
            <tr style={{ borderBottom: "2px solid gray" }}>
              <td>Subtotal:</td>
              <td>{invoice.total ?? "N/A"}</td>
              <td>{invoice.currency ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Basis (Excl. VAT):</td>
              <td>{invoice.basis ?? "N/A"}</td>
              <td>{invoice.currency ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Commission:</td>
              <td>{invoice.totalCommission ?? "N/A"}</td>
              <td>{invoice.currency ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Discount:</td>
              <td>{invoice.invoiceRows?.[0].discountAmount ?? "N/A"}</td>
              <td>{invoice.currency ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Fees:</td>
              <td>{invoice.stripeFees ?? "N/A"}</td>
              <td>{invoice.currency ?? "N/A"}</td>
            </tr>
            <tr>
              <td>
                <b>Amount to charge:</b>
              </td>
              <td>
                <b>{invoice.amountToCharge ?? "N/A"}</b>
              </td>
              <td>
                <b>{invoice.currency ?? "N/A"}</b>
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
      <CardFooter>
        <PdfGenerator />
      </CardFooter>
    </Card>
  );
};

export default PaymentDetails;
