// Vendor
import React, { PureComponent } from "react";
import { Button, Collapse, Row, Col, Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import ReactMarkdown from "react-markdown";

// App
import LocaleHelper from "../../../../common/LocaleHelper";
import { LanguageButton } from "../../../ui/FunctionalComponents";
import debounce from "../../../../common/debounce";

class ClubCoursePresentation extends PureComponent {
  constructor(props) {
    super(props);
    const { defaultCollapse } = this.props;

    this.locales = LocaleHelper.getLocales();
    this.editorRef = React.createRef();

    this.simplemdeOptions = {
      toolbar: [
        "bold",
        "italic",
        "heading",
        "|",
        "quote",
        "link",
        "|",
        "unordered-list",
        "ordered-list",
        "|",
        "guide"
      ],
      spellChecker: false,
      shortcuts: {
        toggleFullScreen: null,
        toggleSideBySide: null
      },
      autoRefresh: true
    };

    this.state = {
      collapse: defaultCollapse,
      selectedLang: this.locales[0].lang
    };

    // Bind handlers
    this.debounceHandler = this.debounceHandler.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.resetCurrentLanguage = this.resetCurrentLanguage.bind(this);
    this.updateEditorState = this.updateEditorState.bind(this);
  }

  debounceHandler = debounce((e) => {
    this.handleTextChange(e);
  }, 1000);

  toggleCollapse() {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  }

  /**
   * Pass a copy of the i18n prop with changes to the onChange callback.
   * @param {string} value Text value passed from <SimpleMDE />
   */
  handleTextChange(value) {
    const { selectedLang } = this.state;
    const { onChange, i18n } = this.props;

    if (selectedLang === "en-GB") {
      onChange({ key: "description", value });
    } else {
      let i18nCopy = { ...i18n };
      i18nCopy[selectedLang] = { ...i18nCopy[selectedLang] };
      i18nCopy[selectedLang].description = value;
      onChange({ key: "i18n", value: i18nCopy });
    }
  }

  handleLanguageChange(lang) {
    this.setState({ selectedLang: lang }, () =>
      // Force the editor to load the correct text
      this.updateEditorState()
    );
  }

  /**
   * Pass the fallback value to onChange callback for current language to reset it.
   */
  resetCurrentLanguage() {
    const { selectedLang } = this.state;
    const { onChange, i18n, i18nOld, descriptionOld } = this.props;

    if (selectedLang === "en-GB") {
      onChange({ key: "description", value: descriptionOld });
    } else {
      let i18nCopy = { ...i18n };
      i18nCopy[selectedLang] = { ...i18nCopy[selectedLang] };
      i18nCopy[selectedLang].description = i18nOld[selectedLang].description;
      onChange({ key: "i18n", value: i18nCopy });
    }
  }

  // Changing or resetting language requires manual update of editor component state
  updateEditorState() {
    const { selectedLang } = this.state;
    const { i18n, description } = this.props;

    const value =
      selectedLang === "en-GB" ? description : i18n[selectedLang].description;

    this.editorRef.current.updater.enqueueSetState(this.editorRef.current, {
      value: value
    });
  }

  render() {
    const { collapse, selectedLang } = this.state;
    const { i18n, i18nOld, description, descriptionOld } = this.props;
    const currentText =
      selectedLang === "en-GB" ? description : i18n[selectedLang].description;

    return (
      <div className="ClubCoursePresentation mt-5">
        <div className="ClubCoursePresentation__header d-flex">
          <h3>Club presentation</h3>
          <Button
            className="ml-2"
            color="link"
            onClick={this.toggleCollapse}
            style={{
              position: "relative",
              bottom: "3px",
              padding: 0
            }}
          >
            {collapse ? "Show" : "Hide"}
          </Button>
        </div>

        <div className="ClubCoursePresentation__flag-btns mb-2">
          <LanguageButton
            flagIcon="gb"
            label="EN"
            selected={selectedLang === "en-GB"}
            ok={descriptionOld !== ""}
            edited={description !== descriptionOld}
            onClick={() => this.handleLanguageChange("en-GB")}
          />
          {this.locales.map(
            (locale, index) =>
              i18n[locale.lang] && (
                <LanguageButton
                  flagIcon={locale.flagIcon}
                  label={locale.lang.substr(0, 2).toUpperCase()}
                  key={index}
                  selected={locale.lang === selectedLang}
                  ok={i18nOld[locale.lang].description !== ""}
                  edited={
                    i18n[locale.lang].description !==
                    i18nOld[locale.lang].description
                  }
                  onClick={() => this.handleLanguageChange(locale.lang)}
                />
              )
          )}
        </div>

        <Collapse
          className="ClubCoursePresentation__editor-container"
          isOpen={!collapse}
        >
          <Row form className="mb-2">
            <Col md={6}>
              <SimpleMDE
                ref={this.editorRef}
                onChange={this.debounceHandler}
                value={currentText}
                options={this.simplemdeOptions}
              />
            </Col>
            <Col md={6}>
              <Card className="h-100 border-0">
                <div className="card-header bg-light border border-bottom-0">
                  <strong>Preview</strong>
                </div>
                <CardBody className="pb-0 border">
                  <ReactMarkdown source={currentText} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Button
            className="ClubCoursePresentation__reset-lang btn-danger"
            onClick={this.resetCurrentLanguage}
          >
            Reset
          </Button>
        </Collapse>
      </div>
    );
  }
}

ClubCoursePresentation.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultCollapsed: PropTypes.bool,
  description: PropTypes.string,
  descriptionOld: PropTypes.string,
  i18n: PropTypes.object,
  i18nOld: PropTypes.object
};

ClubCoursePresentation.defaultProps = {
  defaultCollapse: true,
  description: "",
  descriptionOld: "",
  i18n: LocaleHelper.generateI18nObject(["description"]),
  i18nOld: LocaleHelper.generateI18nObject(["description"])
};

export default ClubCoursePresentation;
