import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table, Input, FormGroup } from "reactstrap";

function InputTable(props) {
  function handleAdd() {
    if (validateAll()) {
      const { onChange } = props;
      onChange([...items, inputValues]);
      setInputValues(getDefaultValues());
    }
  }

  function handleRemove(pill) {
    const { items, onChange } = props;
    onChange(items.filter(item => item !== pill));
  }

  function getDefaultValues() {
    let initialValues = {};
    // add empty values for all field keys
    props.fields.forEach(field => {
      initialValues[field.key] = "";
    });
    return initialValues;
  }

  const { fields, items } = props;
  const [inputValues, setInputValues] = useState(getDefaultValues());
  const [invalidInputs, setInvalidInputs] = useState([]);

  function handleOnChange(value, key) {
    let values = { ...inputValues };
    values[key] = value;
    setInputValues(values);
  }

  /**
   * Check that all required inputs has a value
   * @returns { boolean } Return true if all inputs are valid
   */
  function validateAll() {
    let invalidInputList = [];
    for (let field of fields) {
      if (field.required && inputValues[field.key].length === 0)
        invalidInputList.push(field.key);
    }

    setInvalidInputs(invalidInputList);

    return invalidInputList.length === 0;
  }

  return (
    <div className="InputTable">
      <div className="d-flex align-items-start mb-2">
        {fields.map(field => (
          <FormGroup className="d-inline-block mb-0 mr-2" key={field.key}>
            <Input
              name={`input-${field.key}`}
              value={inputValues[field.key]}
              placeholder={field.label}
              onChange={e => handleOnChange(e.target.value, field.key)}
              onKeyPress={e => {
                if (e.charCode === 13) {
                  e.preventDefault();
                  handleAdd();
                }
              }}
              type="text"
              invalid={invalidInputs.includes(field.key)}
            />
          </FormGroup>
        ))}
        <div>
          <Button color="primary" type="button" onClick={handleAdd}>
            Add
          </Button>
        </div>
      </div>
      <Table responsive striped className="border-0">
        <thead>
          <tr>
            {fields.map((field, index) => (
              <th className="text-left" key={index}>
                {field.label}
              </th>
            ))}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody className="border">
          {items.length > 0 ? (
            items.map((item, i) => (
              <tr key={i}>
                {fields.map(field => (
                  <td key={field.key} className="text-left">
                    {item[field.key]}
                  </td>
                ))}
                <td className="text-right">
                  <Button
                    color="link"
                    className="text-danger py-0"
                    onClick={() => handleRemove(item)}
                  >
                    <FontAwesomeIcon icon="trash-alt" />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={fields.length + 1}>No items added</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

InputTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      required: PropTypes.bool,
      label: PropTypes.string
    })
  ).isRequired,
  imageField: PropTypes.string
};
InputTable.defaultProps = {
  items: [],
  fields: [],
  imageField: ""
};

export default InputTable;
