// Vendor
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";
import SVG from "react-inlinesvg";

// App
import rateIconEarlyBird from "../../../images/rates/rate-early_bird.svg";
import rateIconTwilight from "../../../images/rates/rate-twilight.svg";
import rateIconStandard from "../../../images/rates/rate-standard.svg";

/**
 *
 * @param {{
 *  schedule: {
 *    _id: string,
 *    seasonType: string,
 *    dayType: string,
 *    scheduleType: string,
 *    seasonStartDate: Date
 *  },
 *  course: { clubSlug: string, courseSlug: string },
 *  id: string
 * }} props
 */
export function RateMismatchTableScheduleColumn({ schedule, course, id }) {
  const {
    seasonId,
    seasonType,
    dayType,
    scheduleType,
    seasonStartDate
  } = schedule;
  const { clubSlug, courseSlug } = course;

  const dayTypeIcon =
    dayType === "EARLY_BIRD"
      ? rateIconEarlyBird
      : dayType === "TWILIGHT"
      ? rateIconTwilight
      : rateIconStandard;
  const dayTypeLabel =
    dayType === "EARLY_BIRD"
      ? "Early bird"
      : dayType === "TWILIGHT"
      ? "Twilight"
      : "Standard";
  const seasonColor =
    seasonType === "HIGH_SEASON"
      ? "danger"
      : seasonType === "LOW_SEASON"
      ? "light"
      : "primary";
  const scheduleTypeLabel = `${scheduleType.charAt(0)}${scheduleType
    .slice(1)
    .toLowerCase()} schedule`;

  return (
    <div>
      <Link
        id={id}
        to={`club/${clubSlug}?course=${courseSlug}&seasonId=${seasonId}`}
      >
        <Badge color={seasonColor}>
          {moment(seasonStartDate).format("YYYY-MM-DD")}
        </Badge>
        <div className="icon-container mx-1">
          <SVG className="icon icon--active h-100 w-100" src={dayTypeIcon} />
        </div>
        {scheduleType === "STANDARD" ? "STD" : "WKD"}
      </Link>
      <UncontrolledTooltip target={id}>
        {seasonType === "HIGH_SEASON"
          ? "High"
          : seasonType === "LOW_SEASON"
          ? "Low"
          : "Standard"}{" "}
        season
        <br />
        {scheduleTypeLabel}
        <br />
        {dayTypeLabel}
        <div className="border w-100" style={{ height: "1px" }} />
        <strong>Click to edit season on course page</strong>
      </UncontrolledTooltip>
    </div>
  );
}
