export default function partnerNetworkReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return {
        ...action.payload,
        users: action.payload.users.map((user) => ({
          ...user,
          sendWelcomeEmail: false
        })),
        fallback: [...action.payload.users],
        loaded: true,
        loading: false
      };
    case "NAME":
      return { ...state, name: action.payload };
    case "VALUE":
      return { ...state, value: action.payload };
    case "TOGGLE_SEND_EMAIL": {
      const targetIndex = state.users.findIndex(
        (user) => user.email === action.payload
      );
      return {
        ...state,
        users: state.users.map((user, i) =>
          i === targetIndex
            ? { ...user, sendWelcomeEmail: !user.sendWelcomeEmail }
            : { ...user }
        )
      };
    }
    case "SEND_EMAIL_SET_ALL":
      return {
        ...state,
        users: state.users.map((u) => ({
          ...u,
          sendWelcomeEmail: action.payload
        }))
      };
    case "REMOVE_EMAIL":
      return {
        ...state,
        users: [...state.users.filter((user) => user.email !== action.payload)]
      };

    case "UPDATE_USERS":
      return {
        ...state,
        users: action.payload
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload
      };

    case "ADD_SINGLE_USER":
      return {
        ...state,
        users: [{ ...action.payload }, ...state.users]
      };

    default:
      return state;
  }
}
