// Vendor
import React from "react";

// App
import { TourOperatorContext } from "../TourOperator";
import { AddEmails } from "../../ui/table-utils/AddEmails";

export function TourOperatorAddEmails() {
  TourOperatorContext.displayName = "TourOperator";
  return <AddEmails context={TourOperatorContext} />;
}
