import React, { useContext, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import InfoRow from "./InfoRow";
import SingleDateWrapper from "../../ui/SingleDateWrapper";
import { LayoutContext } from "../../app/Layout";
import { useMutation } from "@apollo/client";
import { SCHEDULE_VIRTUAL_CARD } from "../../../common/Mutations";
import { AmadeusContext } from "../Amadeus";
import moment from "moment";
import { useHistory } from "react-router-dom";

const ScheduledLoads = () => {
  const { addAlert } = useContext(LayoutContext);
  const history = useHistory();
  const { state } = useContext(AmadeusContext);
  const { cardReference, externalReference, availableBalance } = state;
  // states
  const [scheduledLoad, setScheduledLoad] = useState({
    schedulingDate: "",
    amount: 0,
    decimalPlaces: 0
  });
  const [errors, setErrors] = useState([]);
  const handleSaveChanges = () => {
    const errorMessages = [];
    if (!scheduledLoad.amount) {
      errorMessages.push("amount");
    }
    if (
      (!Number(scheduledLoad.decimalPlaces) &&
        scheduledLoad.decimalPlaces !== 0) ||
      scheduledLoad.decimalPlaces < 0
    ) {
      errorMessages.push("decimalPlaces");
    }

    if (!scheduledLoad.schedulingDate) {
      errorMessages.push("schedulingDate");
    }
    setErrors(errorMessages);

    if (errorMessages.length === 0)
      ScheduleVirtualCardLoad({
        variables: {
          payload: {
            References: [
              {
                Reference: {
                  Type: "External",
                  _Reference: externalReference
                }
              },
              {
                Reference: {
                  Type: "Amadeus",
                  _Reference: cardReference
                }
              }
            ],
            FundsTransfer: {
              Action: "Add",
              Value: {
                Amount: Number(scheduledLoad.amount),
                DecimalPlaces: Number(scheduledLoad.decimalPlaces),
                CurrencyCode: availableBalance.currency
              },
              Scheduling: {
                Date: scheduledLoad.schedulingDate
              }
            },
            Reason: {
              _Reason: `Scheduled load #1`,
              Language: "en"
            }
          }
        }
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (errors.includes(name)) {
      setErrors(errors.filter((error) => error !== name));
    }

    setScheduledLoad({
      ...scheduledLoad,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    if (errors.includes("schedulingDate")) {
      setErrors(errors.filter((error) => error !== "schedulingDate"));
    }

    setScheduledLoad({
      ...scheduledLoad,
      schedulingDate: date?.format("YYYY-MM-DD")
    });
  };

  const onScheduleCardCompleted = (data) => {
    if (data.ScheduleVirtualCardLoad.ok) {
      addAlert({
        color: "success",
        message: "Successfully scheduled card"
      });
      return setScheduledLoad({
        schedulingDate: "",
        amount: 0,
        decimalPlaces: 0
      });
    }
    addAlert({
      color: "danger",
      message:
        data.ScheduleVirtualCardLoad.returnMessage ||
        "Error while scheduling card"
    });
  };
  const onScheduleCardError = (er) => {
    addAlert({ color: "danger", message: "Error while scheduling card" });
  };
  const handleCancel = () => {
    history.push("/amadeus");
  };
  // mutation
  const [ScheduleVirtualCardLoad] = useMutation(SCHEDULE_VIRTUAL_CARD, {
    onCompleted: onScheduleCardCompleted,
    onError: onScheduleCardError
  });

  return (
    <>
      <h4>
        <b>Scheduled loads</b>
      </h4>
      {/* Single Load */}
      <Row>
        <Col xs="12" lg="6" className="d-flex flex-column align-items">
          <InfoRow
            label="Load Amount"
            value={
              <FormGroup>
                <div style={{ width: "70%" }}>
                  <div>
                    <Label for="amount-1">Amount</Label>
                    <Input
                      id="amount-1"
                      type="number"
                      name="amount"
                      value={scheduledLoad.amount}
                      className={
                        errors.includes("amount") ? "border-danger" : ""
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  {errors.includes("amount") && (
                    <div className="text-danger">Load amount is required</div>
                  )}
                </div>
                <div style={{ width: "30%" }}>
                  <div>
                    <Label for="decimal-places">Decimal Places</Label>
                    <Input
                      id="decimal-places"
                      type="number"
                      name="decimalPlaces"
                      value={scheduledLoad.decimalPlaces}
                      onChange={(e) => handleInputChange(e)}
                      className={
                        errors.includes("decimalPlaces") ? "border-danger" : ""
                      }
                    />
                  </div>
                  {errors.includes("decimalPlaces") && (
                    <div className="text-danger text-nowrap">
                      Decimal places are required
                    </div>
                  )}
                </div>
              </FormGroup>
            }
          />
        </Col>
        <Col xs="12" lg="6" className="d-flex align-items-center">
          <InfoRow
            label="Load date"
            value={
              <>
                <SingleDateWrapper
                  small
                  date={
                    scheduledLoad.schedulingDate
                      ? moment(scheduledLoad.schedulingDate)
                      : null
                  }
                  onDateChange={(date) => handleDateChange(date)}
                  numberOfMonths={1}
                  className={errors.includes("loadAmount") ? "is-invalid" : ""}
                  isOutsideRange={(date) =>
                    date.isSameOrBefore(moment(), "day")
                  }
                />
                {errors.includes("schedulingDate") && (
                  <div className="text-danger text-nowrap">
                    Load date is required
                  </div>
                )}
              </>
            }
          />
        </Col>
      </Row>
      <div>
        {scheduledLoad.amount !== 0 && (
          <h4>
            Amount to be loaded on card is:{" "}
            {isNaN(scheduledLoad.amount) ||
            isNaN(scheduledLoad.decimalPlaces) ||
            scheduledLoad.decimalPlaces < 0 ? (
              <div className="text-danger">Decimal places are required</div>
            ) : (
              (
                scheduledLoad.amount / Math.pow(10, scheduledLoad.decimalPlaces)
              ).toFixed(scheduledLoad.decimalPlaces)
            )}
          </h4>
        )}
      </div>

      {/* End Single Load */}
      <p className="py-5">
        Scheduled load will be created only for Load Amount# with filled
        amount,decimal places and date
      </p>
      <hr />
      <Row className="custom-row">
        <div className="d-flex w-100 justify-content-center">
          <Button color="primary" className="mr-2" onClick={handleSaveChanges}>
            Save changes
          </Button>
          <Button
            color="secondary"
            outline
            className="mr-2"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Row>
    </>
  );
};

export default ScheduledLoads;
