import React, { useContext, useEffect, useReducer, useState } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AmadeusCard from "./subcomponents/AmadeusCard";
import AmadeusDetails from "./subcomponents/AmadeusDetails";
import ScheduledLoads from "./subcomponents/ScheduledLoads";
import { GET_VIRTUAL_CARD_DETAILS } from "../../common/Queries";
import { useQuery } from "@apollo/client";
import Loader from "../ui/Loader";
import amadeusReducer from "./amadeusReducer";
import DeleteCardModal from "./modals/DeleteCardModal";
import { LayoutContext } from "../app/Layout";
import { useHistory } from "react-router-dom";

// create context
export const AmadeusContext = React.createContext(null);

const Amadeus = (props) => {
  const { cardRef } = props.match.params;
  const { addAlert } = useContext(LayoutContext);
  const history = useHistory();

  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [state, dispatch] = useReducer(amadeusReducer, {});

  const toggleDeleteCardModal = () => {
    setShowDeleteCardModal(!showDeleteCardModal);
  };

  // handlers
  const handleOnCompleted = (data) => {
    if (data.GetVirtualCardDetails.ok) {
      return dispatch({
        type: "INIT",
        payload: data.GetVirtualCardDetails.data
      });
    }
    addAlert({ color: "danger", message: "Error while getting card details" });
  };

  // making a reqeust with the invoice id
  const { loading: amadeusCardLoading } = useQuery(GET_VIRTUAL_CARD_DETAILS, {
    variables: {
      References: [{ Reference: { Type: "External", _Reference: cardRef } }]
    },
    onCompleted: handleOnCompleted,
    onError: (err) => {
      addAlert({
        color: "danger",
        message: "Error while getting card details"
      });
    }
  });

  // useEffect to scroll to top of page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  if (amadeusCardLoading) return <Loader fullscreen />;

  return (
    <AmadeusContext.Provider value={{ state }}>
      <div className="page amadeus-info">
        <div className="d-flex w-100 justify-content-between mb-4">
          <h1>Card Details</h1>
          <Button
            color="danger"
            className="h-fit"
            onClick={toggleDeleteCardModal}
            disabled={state.status === "DELETED"}
          >
            <FontAwesomeIcon icon="trash-alt" className="mr-1" />
            Delete Card
          </Button>
        </div>
        <div className="d-flex justify-content-center w-100 mb-5">
          <AmadeusCard data={state} />
        </div>
        <hr />
        <AmadeusDetails />
        <hr />
        <ScheduledLoads />
      </div>
      <DeleteCardModal
        isOpen={showDeleteCardModal}
        toggle={toggleDeleteCardModal}
      />
    </AmadeusContext.Provider>
  );
};

export default Amadeus;
