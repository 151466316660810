import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";

/**
 * Info Component
 *
 * Renders an information icon that triggers a tooltip on hover.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.value - The content of the tooltip.
 * @param {string} props.id - The unique identifier for the tooltip. Required when multiple Info components are used on the same page.
 * @param {string} props.className - Classes passed to the wrapper
 *
 * @returns {JSX.Element} The rendered Info component.
 */

const Info = ({ value, id = "", placement = "top", className }) => {
  const tooltipId = `tooltip-id-${id}`;

  return (
    <div className={className ?? ""}>
      <FontAwesomeIcon icon={faInfoCircle} size="lg" id={tooltipId} />
      <UncontrolledTooltip
        target={tooltipId}
        id="info-tooltip"
        arrowClassName="black-arrow"
        placement={placement}
        modifiers={{preventOverflow: {boundariesElement: 'window'}}} 
      >
        {value}
      </UncontrolledTooltip>
    </div>
  );
};

export default Info;
