import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Query } from "@apollo/client/react/components";
import { Table } from "reactstrap";
import Loader from "../ui/Loader";
import { AFFILIATES } from "../../common/Queries";
import ProviderCollection from "../../common/ProviderCollection";
import { Link } from "react-router-dom";

const Affiliates = () => {

    return (
        <div className="mb-4">
            <h1>Affiliates</h1>

            <Query query={AFFILIATES} fetchPolicy="cache-and-network">
                {({ loading, error, data }) => {
                    if (loading) return <Loader />;
                    if (error)
                        return (
                            <div className="text-danger">Error fetching affiliates</div>
                        );

                    const affiliates = data.affiliates;

                    return (<Table borderless striped>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Name</th>
                                <th>Cid</th>
                                <th>Aid</th>
                                <th>TeesheetProviders</th>
                                <th>IsActive</th>
                            </tr>
                        </thead>
                        <tbody>
                            {affiliates.length > 0 ? (
                                affiliates.map((affiliate, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{affiliate.username}</td>
                                            <td>{affiliate.name}</td>
                                            <td>{affiliate.cid}</td>
                                            <td>{affiliate.aid}</td>
                                            <td>{ProviderCollection
                                                .filter(x =>
                                                    (!affiliate.excludedTeesheetProviders || !affiliate.excludedTeesheetProviders.includes(x.key))
                                                    && x.key !== "NONE")
                                                .map(t => t.label).join(', ')}</td>
                                            <td>{affiliate.isActive ? <FontAwesomeIcon icon="check" size="lg" /> : ''}</td>

                                            <td><Link className="btn btn-link" to={`/affiliate/${affiliate._id}`}>Edit</Link></td>

                                        </tr>)
                                })) : <p>No data</p>
                            }
                        </tbody>
                    </Table>)
                }}
            </Query>
            <div className="Save-container">
                <div className="Save-container__phantom" />
                <div className="Save-container__main">
                    <Link to="/affiliate" id="btn-new" className="btn btn-secondary btn-lg">
                        Create new
                    </Link>
                </div>
            </div>
        </div>)
}

export default Affiliates;