import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import DebouncedDropdownCourses from "../../amadeus/subcomponents/DebouncedDropdownCourses";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangeFacet from "../../ui/facet/DateRangeFacet";
import { useLazyQuery } from "@apollo/client";
import { GET_INVOICE_REPORT_PER_COURSE } from "../../../common/Queries";
import { openDocumentInNewWindow } from "../../../common/DocumentHelper";
import { LayoutContext } from "../../app/Layout";
import Loader from "../../ui/Loader";

const InvoiceReportModal = ({ isOpen, toggle, onClose }) => {
  const [date, setDate] = useState({});
  const [course, setCourse] = useState({});
  const [errors, setErrors] = useState({});
  const { addAlert } = useContext(LayoutContext);

  const invalidInput = () => {
    let errorsObj = {};
    if (!date.startDate) errorsObj.startDate = true;
    if (!date.endDate) errorsObj.endDate = true;
    if (!course.id) errorsObj.course = true;

    if (Object.keys(errorsObj).length > 0) {
      setErrors({ ...errorsObj });
      return false;
    }
    return true;
  };
  const handleSubmit = () => {
    if (!invalidInput()) return null;

    getInvoicesReportPerCourse({
      variables: {
        filter: {
          courseId: course.id,
          payoutDate: {
            startDate: date.startDate,
            endDate: date.endDate
          }
        }
      }
    });
  };
  const onInvoiceReportCompleted = ({ getInvoicesByCoursePDF }) => {
    if (!getInvoicesByCoursePDF.ok) {
      return addAlert({
        message:
          getInvoicesByCoursePDF.returnMessage ??
          "Error while generating report",
        color: "danger"
      });
    }

    openDocumentInNewWindow(
      getInvoicesByCoursePDF.file.encoding,
      "application/pdf"
    );
  };
  const [getInvoicesReportPerCourse, { loading }] = useLazyQuery(
    GET_INVOICE_REPORT_PER_COURSE,
    {
      onCompleted: onInvoiceReportCompleted,
      onError: () =>
        addAlert({ message: "Error while generating report", color: "danger" })
    }
  );
  useEffect(() => {
    if (course._id && errors.course) setErrors({ ...errors, course: false });
    if (date.startDate && errors.startDate)
      setErrors({ ...errors, startDate: false });
    if (date.endDate && errors.endDate)
      setErrors({ ...errors, endDate: false });
  }, [date, course]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader className="px-5 py-4">
        Generate Report <FontAwesomeIcon icon="pencil-alt" className="ml-2" />
      </ModalHeader>
      <ModalBody className="py-4 px-5 border">
        <div className="mb-2">
          <p>
            The report will be generated for the chosen course and selected date
            range, <b>including</b> both start and end dates.
          </p>
          <p>
            This generated report contains the all{" "}
            <span style={{ color: "#02c39a" }}>completed</span> invoices
          </p>
        </div>
        <div className="mb-1 d-flex flex-column">
          <div>
            <Label for="month-dropdown">Month</Label>
            <DateRangeFacet
              small
              onDatesChange={({ startDate, endDate }) =>
                setDate({
                  startDate,
                  endDate
                })
              }
              isOutsideRange={() => {}}
              showClearDates={true}
              startDateId="start-date"
              endDateId="end-date"
            />
          </div>
          {(errors.startDate || errors.endDate) && (
            <span className="text-danger">
              {errors.startDate && errors.endDate
                ? "Date Range "
                : errors.endDate
                ? "End Date "
                : "Start date "}
              is required
            </span>
          )}
        </div>
        <div>
          <Label for="course-dropdown">Course</Label>
          <DebouncedDropdownCourses
            className="w-100"
            id="course-dropdown"
            onSelect={(course) => setCourse({ ...course })}
          />
          {errors.course && (
            <span className="text-danger">Course is required</span>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="p-3 d-flex">
        <Button color="danger" onClick={onClose}>
          Close
        </Button>
        {loading ? (
          <Button color="link">
            <Loader size={30} />
          </Button>
        ) : (
          <Button color="primary" onClick={handleSubmit}>
            Create Report
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default InvoiceReportModal;
