import React, { useContext, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { useMutation } from "@apollo/client";
import { DELETE_VIRTUAL_CARD } from "../../../common/Mutations";
import { LayoutContext } from "../../app/Layout";
import { AmadeusContext } from "../Amadeus";

const DeleteCardModal = ({ isOpen, toggle }) => {
  // context
  const { addAlert } = useContext(LayoutContext);
  const { state } = useContext(AmadeusContext);
  const { cardReference, externalReference } = state;
  // state
  const [reason, setReason] = useState("");

  // handlers
  const handleSubmit = () => {
    const variables = {
      reason,
      amadeusReference: cardReference,
      externalReference
    };
    DeleteVritualCard({
      variables
    });
  };
  const onDeleteCardCompleted = (data) => {
    if (data.DeleteVirtualCard.ok) {
      addAlert({ color: "success", message: "Successfully deleted card" });
      window.location.reload(true); // refresh the page
      return toggle();
    }
    addAlert({ color: "danger", message: "Error while deleting card" });
  };
  const onDeleteCardError = (er) => {
    console.log(er);
    addAlert({ color: "danger", message: "Error while deleting card" });
  };

  // mutation
  const [DeleteVritualCard] = useMutation(DELETE_VIRTUAL_CARD, {
    onCompleted: onDeleteCardCompleted,
    onError: onDeleteCardError
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Load Card Modal</ModalHeader>
      <ModalBody>
        <AvForm onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="amadeusReason">Reason</Label>
            <AvInput
              name="amadeusReason"
              id="amadeusReason"
              type="text"
              required
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </FormGroup>
          <div className="d-flex justify-content-center mt-2">
            <Button type="submit" color="primary" className="center">
              Delete Card
            </Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default DeleteCardModal;
