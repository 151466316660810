import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";

const SingleDateWrapper = (props) => {
  const {
    numberOfMonths,
    small,
    onDateChange,
    showClearDate,
    required,
    isOutsideRange,
    id
  } = props;

  const [focused, setFocused] = useState(false);
  const [date, setDate] = useState(null);
  return (
    <SingleDatePicker
      numberOfMonths={numberOfMonths}
      focused={focused}
      id={id}
      onFocusChange={({ focused }) => setFocused(focused)}
      date={date}
      onDateChange={(ev) => {
        setDate(ev);
        onDateChange(ev);
      }}
      small={small}
      placeholder="Select date"
      required={required}
      isOutsideRange={isOutsideRange}
      showClearDate={showClearDate}
    />
  );
};

export default SingleDateWrapper;
