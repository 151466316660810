// Vendor
import React from "react";
import SVG from "react-inlinesvg";

// App
import RatesHelper from "../../../../../common/RatesHelper";
import rateIconEarlyBird from "../../../../../images/rates/rate-early_bird.svg";
import rateIconStandard from "../../../../../images/rates/rate-standard.svg";
import rateIconTwilight from "../../../../../images/rates/rate-twilight.svg";

export function RateDayTypeIcons({ rates, scheduleType }) {
  function getIconClass(active) {
    const baseClass = "icon";
    let className = `${baseClass} h-100 w-100 `;
    className += active ? `${baseClass}--active` : `${baseClass}--inactive`;
    return className;
  }

  const hasEarlyBird =
    RatesHelper.filter(rates, {
      scheduleType,
      dayType: "EARLY_BIRD"
    }).length > 0;
  const hasStandard =
    RatesHelper.filter(rates, {
      scheduleType,
      dayType: "STANDARD"
    }).length > 0;
  const hasTwilight =
    RatesHelper.filter(rates, {
      scheduleType,
      dayType: "TWILIGHT"
    }).length > 0;

  return (
    <>
      <div className="icon-container">
        <SVG className={getIconClass(hasEarlyBird)} src={rateIconEarlyBird} />
      </div>
      <div className="icon-container">
        <SVG className={getIconClass(hasStandard)} src={rateIconStandard} />
      </div>
      <div className="icon-container">
        <SVG className={getIconClass(hasTwilight)} src={rateIconTwilight} />
      </div>
    </>
  );
}
