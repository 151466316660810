// Vendor
import React, { Fragment, useContext, useState } from "react";
import { Table, Popover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { TOUR_OPERATORS } from "../../common/Queries";
import { DELETE_TOUR_OPERATOR } from "../../common/Mutations";
import Loader from "../ui/Loader";
import { Button } from "reactstrap";
import { LayoutContext } from "../app/Layout";

const TourOperators = () => {
  const { addAlert } = useContext(LayoutContext);
  const [popoverOpen, setPopoverOpen] = useState("");
  const [deleteTourOperator] = useMutation(DELETE_TOUR_OPERATOR, {
    onCompleted: (res) => {
      addAlert({
        color: "success",
        message: "TourOperator deleted."
      });
    },
    onError: () =>
      addAlert({ color: "danger", message: "Could not delete TourOperator." })
  });

  return (
    <div className="mb-4">
      <h1>Tour operators</h1>

      <Query query={TOUR_OPERATORS} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error)
            return (
              <div className="text-danger">Error fetching TourOperators</div>
            );

          const tourOperators = data.tourOperators;
          return (
            <Table borderless striped>
              <Fragment>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Commission (%)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tourOperators.length > 0 ? (
                    tourOperators.map((tourOperator, i) => {
                      const popoverId = `remove-tourOperator-popover-${i}`;
                      return (
                        <tr key={i}>
                          <td className="align-middle">{tourOperator.name}</td>
                          <td className="align-middle">{tourOperator.value}</td>
                          <td className="align-middle">
                            <Button
                              className="p-0 text-muted d-inline-flex"
                              color="link"
                              id={popoverId}
                              onClick={() => setPopoverOpen(popoverId)}
                            >
                              <FontAwesomeIcon icon="trash-alt" />
                            </Button>
                            <Popover
                              target={popoverId}
                              isOpen={popoverOpen === popoverId}
                              placement="left"
                              toggle={() => setPopoverOpen("")}
                              className="p-3"
                            >
                              <PopoverBody>
                                Are you sure?
                                <Button
                                  color="link"
                                  onClick={() => {
                                    deleteTourOperator({
                                      variables: {
                                        tourOperatorId: tourOperator._id
                                      },
                                      refetchQueries: [
                                        { query: TOUR_OPERATORS }
                                      ]
                                    });
                                    setPopoverOpen("");
                                  }}
                                >
                                  Delete
                                </Button>
                              </PopoverBody>
                            </Popover>
                            <Link
                              className="btn btn-link"
                              to={`/tour-operator/${tourOperator._id}`}
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-muted text-center">
                        No tourOperators found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Fragment>
            </Table>
          );
        }}
      </Query>

      <div className="Save-container">
        <div className="Save-container__phantom" />
        <div className="Save-container__main">
          <Link
            to="/tour-operator"
            id="btn-new"
            className="btn btn-secondary btn-lg"
          >
            Create new
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TourOperators;
