// Vendor
import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { appendScript } from "../../common/Scripts";

function PartnerLinkImageUploader({ onImageUpload, partnerLinkId, prefix }) {
  const [uploadWidget, setUploadWidget] = useState(null);



  // Update upload widget public id on debounced change
  useEffect(() => {
    function initWidget() {
      setUploadWidget(
        window.cloudinary.createUploadWidget({
          cloudName: "greenfee365",
          uploadPreset: "preset-marshalls-course-image-upload",
          sources: ["local"],
          folder: `partner-link/${partnerLinkId}`,
          publicId: prefix + "-" + partnerLinkId + "-" + Date.now(),
          tags: [prefix, prefix + "-" + partnerLinkId],
          maxImageFileSize: 700000, // 700 kB
          multiple: false
        },
          (error, result) => {
            if (result && result.event === "success" && result.info.public_id.includes(prefix)) {
              onImageUpload(result.info.public_id);
            }
          }
        )
      );
    }

    if (partnerLinkId) {
      // if script is already loaded
      if (window.cloudinary) initWidget();
      else {
        appendScript(
          "https://widget.cloudinary.com/v2.0/global/all.js",
          "cloudinary-upload-widget"
        )
          .then(initWidget)
          .catch(err => {
            console.log(err);
            return err;
          });
      }
    }
  }, [onImageUpload, partnerLinkId, prefix]);

  return (
    <div className="row mt-3">
      <div className="col-auto">
        {!uploadWidget && <div> You need to save before you can upload an image. </div>}<Button
          color={!uploadWidget ? "gray" : "light"}
          type="button"
          disabled={!uploadWidget}
          outline
          onClick={() => uploadWidget.open()}
        >
          <FontAwesomeIcon icon="upload" className="mr-1" /> Upload new
        </Button>
      </div>
    </div>
  );
}

export default PartnerLinkImageUploader;
