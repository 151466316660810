export default function dynamicTransTableReducer(state, action) {
  let items;
  const { trigger } = action;

  switch (action.type) {
    case "INIT":
      return { ...state, items: action.payload, trigger };

    case "ADD":
      items = [...state.items];
      items.push(action.payload);
      return { ...state, items, trigger };

    case "UPDATE":
      items = [...state.items];
      items[action.payload.index] = action.payload.value;
      return { ...state, items, trigger };

    case "DELETE":
      items = [...state.items];
      items.splice(action.payload, 1);
      return { ...state, items, trigger };

    case "POPOVER_TOGGLE":
      return {
        ...state,
        openPopoverId: action.payload,
        trigger
      };

    default:
      return state;
  }
}
