// Vendor
import React, { useContext, Fragment, useState, useEffect } from "react";
import { Col } from "reactstrap";

// App
import { ClubContext } from "../Club";
import { Select, InputField } from "../../ui/FunctionalComponents";
import { KeyValueStoreHelper } from "../../../common/KeyValueStoreHelper";

export function ClubKeyValueStoreFields() {
  const { state, dispatch } = useContext(ClubContext);
  const { teeSheetProvider, keyValueStore: clubKeyValueStore } = state;
  const [keyValueStore, setKeyValueStore] = useState({});

  useEffect(() => {
    let newKvs =
      KeyValueStoreHelper.getClubKeyValueStoreFields(teeSheetProvider);
    if (clubKeyValueStore) {
      clubKeyValueStore.forEach((kv) => (newKvs[kv.key] = kv.value));
    }

    setKeyValueStore(newKvs);
  }, [clubKeyValueStore, teeSheetProvider]);

  /**
   * @param { { key: string, value: string }} payload
   */
  function handleDispatch(payload) {
    dispatch({ type: "KEY_VALUE_STORE", payload });
  }

  if (teeSheetProvider === "GOLF_MANAGER") {
    return (
      <Fragment>
        <Col>
          <Select
            label="Target server"
            id="club-target-server"
            value={keyValueStore["TARGET_SERVER"]}
            onChange={(e) =>
              handleDispatch({ key: "TARGET_SERVER", value: e.target.value })
            }
          >
            <option value="" disabled>
              Select target
            </option>
            <option value="APP_SERVER">APP_SERVER (Deprecated)</option>
            <option value="COM_SERVER">COM_SERVER</option>
            <option value="COM_EU_SERVER">COM_EU_SERVER</option>
            <option value="COM_AMERICA_SERVER">COM_AMERICA_SERVER</option>
            <option value="MT_GS_SERVER">
              MT_GS_SERVER (Golf Santander exclusive)
            </option>
            <option value="MT_AWS_EU_SERVER">MT_AWS_EU_SERVER</option>
            <option value="MT_AWS_EU_SERVER_NEW">MT_AWS_EU_SERVER_NEW</option>
            <option value="MT_AWS_MARRIOTT_SERVER">
              MT_AWS_MARRIOTT_SERVER
            </option>
          </Select>
        </Col>
      </Fragment>
    );
  }

  if (teeSheetProvider === "CONCEPT") {
    return (
      <Fragment>
        <Col>
          <InputField
            targetKey="WEBSERVICE_TOKEN"
            label="Token"
            required={false}
            value={keyValueStore["WEBSERVICE_TOKEN"]}
            onChange={handleDispatch}
          />
        </Col>
        <Col>
          <InputField
            targetKey="WEBSERVICE_CUSTOMER_NUMBER"
            label="Customer Nr"
            required={false}
            value={keyValueStore["WEBSERVICE_CUSTOMER_NUMBER"]}
            onChange={handleDispatch}
          />
        </Col>
        <Col>
          <InputField
            targetKey="WEBSERVICE_BASE_URL"
            label="Club API url"
            required={false}
            value={keyValueStore["WEBSERVICE_BASE_URL"]}
            onChange={handleDispatch}
          />
        </Col>
        <Col>
          <InputField
            targetKey="WEBSERVICE_MTYPE"
            label="MType"
            required={false}
            value={keyValueStore["WEBSERVICE_MTYPE"]}
            onChange={handleDispatch}
          />
        </Col>
      </Fragment>
    );
  }

  if (teeSheetProvider === "INTELLIGENT_GOLF") {
    return (
      <Fragment>
        <Col>
          <InputField
            targetKey="CLUB_CODE"
            label="Club code"
            required={false}
            value={keyValueStore["CLUB_CODE"]}
            onChange={handleDispatch}
          />
        </Col>
        <Col>
          <InputField
            targetKey="CLUB_API_KEY"
            label="Club API key"
            required={false}
            value={keyValueStore["CLUB_API_KEY"]}
            onChange={handleDispatch}
          />
        </Col>
      </Fragment>
    );
  }

  if (teeSheetProvider === "NETGOLF") {
    return (
      <Fragment>
        <Col>
          <InputField
            targetKey="WEBSERVICE_BASE_URL"
            label="Club API url"
            required={false}
            value={keyValueStore["WEBSERVICE_BASE_URL"]}
            onChange={handleDispatch}
          />
        </Col>
      </Fragment>
    );
  }

  // Default if provider has no KeyValueStore fields
  return null;
}
