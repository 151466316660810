// vendor
import React, { useContext } from "react";

// app
import { ClubCourseContext } from "../ClubCourse";
import InputTable from "../../../ui/InputTable";

function ClubCourseTeeList(props) {
  const { state, dispatch } = useContext(ClubCourseContext);
  return (
    <div className="ClubCourseTeeList mt-5">
      <h3>Tees</h3>
      <InputTable
        items={state.tees || []}
        fields={[
          { key: "name", label: "Name", required: true },
          { key: "targetId", label: "Id", required: true }
        ]}
        onChange={tees => {
          dispatch({ type: "TEE", payload: tees });
        }}
      />
    </div>
  );
}

export default ClubCourseTeeList;
