import React, { useEffect } from "react";
import IdGeneratorHelper from "../../../../../../../common/IdGeneratorHelper";
import { useState } from "react";
import DayType from "./DayType";
import { TargetRateIdEdit } from "./TargetRateId";
import { DropDownEdit, RateNumberEdit, RateNumberRead } from "./PriceNumber";
import { DynamicPriceEdit } from "./DynamicPrice";

/**
 * @param { Object } props
 * @param { string } props.ratePriceName
 * @param { RatePrice } props.ratePrice
 * @param { DayType } props.dayType
 * @param { VoidCallback<{key: string, value:(string|string[])}> } props.onChange
 */
function EditCells(props) {
  const { ratePriceName, ratePrice, dayType, onChange } = props;

  /**
   * @param { string } name
   * @returns { string }
   */
  function getInputId(name) {
    let id = `${dayType.type}-${ratePriceName.replace(/\W/g, "")}-${name}`;
    return id.toLowerCase();
  }

  // State
  /** @type { [ string[], React.Dispatch<React.SetStateAction<string[]>> ] } */
  const [targetRateId, setTargetRateId] = useState([]);
  const [price, setPrice] = useState("");
  const [rackPrice, setRackPrice] = useState("");
  const [dynamic, setDynamic] = useState(false);
  const [addCommissionMarkup, setAddCommissionMarkup] = useState(false);
  const [commission, setCommision] = useState("");
  const [commissionType, setCommisionType] = useState("");
  const [isOverridableByCourse, setIsOverridableByCourse] = useState(false);

  const dynamicPriceId = IdGeneratorHelper.getRandomId();

  useEffect(() => {
    if (ratePrice) {
      setTargetRateId(ratePrice.targetRateId);
      setPrice(ratePrice.price);
      setRackPrice(ratePrice.rackPrice);
      setDynamic(ratePrice.useTeeSheetProviderPricing || false);
      setCommision(ratePrice.commission);
      setCommisionType(ratePrice.commissionType);
      setIsOverridableByCourse(ratePrice.isOverridableByCourse);
      setAddCommissionMarkup(ratePrice.addCommissionMarkup);
    }
  }, [ratePrice]);

  return (
    <>
      <td className="text-left" style={{ whiteSpace: "nowrap" }}>
        <p className="m-0">
          <DayType dayType={dayType} />
        </p>
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        <div className="d-block">
          <TargetRateIdEdit
            name="targer-rate-id"
            targetRateId={targetRateId}
            onChange={(value) => {
              setTargetRateId(value);
              onChange({
                key: "targetRateId",
                value
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberEdit
            id={getInputId("price")}
            name="price"
            value={price}
            onChange={(value) => {
              let price = parseFloat(value);
              // if price is 0 or lower setting it to NaN which will delete it later from prices array
              if (typeof price === "number") {
                if (price <= 0) {
                  price = NaN;
                }
              }
              setPrice(price);
              onChange({
                key: "price",
                value: price
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberEdit
            id={getInputId("rackprice")}
            name="rack-price"
            value={rackPrice}
            validateValue={price}
            onChange={(value) => {
              const rackPrice = parseFloat(value);
              setRackPrice(rackPrice);
              onChange({
                key: "rackPrice",
                value: rackPrice
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberRead ratePrice={ratePrice} field="multiplier" />
        </div>
      </td>
      <td>
        <div className="d-block">
          <DynamicPriceEdit
            name={dynamicPriceId}
            value={dynamic}
            onChange={(value) => {
              setDynamic(value);
              if (addCommissionMarkup && !value) {
                setAddCommissionMarkup(false);
                onChange({
                  key: "addCommissionMarkup",
                  value: false
                });
              }
              onChange({
                key: "useTeeSheetProviderPricing",
                value
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block d-flex justify-content-center">
          <DynamicPriceEdit
            name={`markup-${IdGeneratorHelper.getRandomId()}`}
            value={addCommissionMarkup}
            disabled={!dynamic}
            onChange={(value) => {
              setAddCommissionMarkup(value);
              onChange({
                key: "addCommissionMarkup",
                value
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberEdit
            id={getInputId("commission")}
            name="commission"
            value={commission}
            validateValue={commission}
            disabled={isOverridableByCourse}
            positiveNumbersOnly
            required={
              !isOverridableByCourse && commissionType?.length > 0
                ? true
                : false
            }
            onChange={(value) => {
              const commission = parseFloat(value);
              setCommision(commission);
              onChange({
                key: "commission",
                value: commission
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <DropDownEdit
            id={getInputId("commissionType")}
            isOverridableByCourse={isOverridableByCourse}
            value={commissionType}
            required={
              !isOverridableByCourse && commission && commission > 0
                ? true
                : false
            }
            onChange={(value) => {
              setCommisionType(value);
              onChange({
                key: "commissionType",
                value: value
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <DynamicPriceEdit
            name={`isOverridableByCoruse-${dynamicPriceId}`}
            value={isOverridableByCourse}
            className="d-flex justify-content-center"
            onChange={(value) => {
              setIsOverridableByCourse(value);
              onChange({
                key: "isOverridableByCourse",
                value
              });
            }}
          />
        </div>
      </td>
    </>
  );
}

export default EditCells;
