import { useQuery } from "@apollo/client";
import React from "react";
import ReactSelect from "react-select";
import { GET_TEMPLATES } from "../../common/Queries";
import Loader from "./Loader";
import PropTypes from "prop-types";

const TemplatesSelect = ({ onChange,defaultValue }) => {
  const { loading, error, data } = useQuery(GET_TEMPLATES);

  if (loading) return <Loader />;
  if (error) return <p className="text-danger">Could not load templates.</p>;

  return (
    <ReactSelect
      className="TemplateSelect"
      options={
        data.getTemplates.ok
          ? data.getTemplates.templates?.map((template) => ({
              label: template.name,
              value: template.name
            }))
          : []
      }
      isClearable={true}
      onChange={onChange}
      defaultValue={defaultValue}
      placeholder="Select a template"
    />
  );
};

export default TemplatesSelect;

// proptypes
TemplatesSelect.propTypes = {
  onChange: PropTypes.func.isRequired
};

// default
TemplatesSelect.defaultProps = {
  onChange: () => {},
  defaultValue: null
};
