import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StatusIcon = ({ value }) => {
  return value ? (
    <FontAwesomeIcon icon="check" className="text-success" />
  ) : (
    <FontAwesomeIcon icon="ban" className="text-danger" />
  );
};
