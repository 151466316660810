import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function DynamicPriceEdit({ value, name, onChange, className,disabled }) {
  return (
    <div className={`checkbox-group ${className ?? ""}`}>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={(event) => {
          onChange(event.target.checked);
        }}
      />
      <label htmlFor={name} className="mb-0"></label>
    </div>
  );
}

export function Indicator(props) {
  const { ratePrice, attribute } = props;
  const value = ratePrice && ratePrice[attribute];

  return ratePrice !== undefined ? (
    <FontAwesomeIcon
      icon={value ? "check" : "ban"}
      className={value ? "text-success" : "text-danger"}
    />
  ) : (
    <span className="text-muted">-</span>
  );
}
