// Vendor
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";

function CommentListMinimized({ onClick, comments }) {
  const [commentCount, setCommentCount] = useState(0);
  const [openTasks, setOpenTasks] = useState(0);

  useEffect(() => {
    let _openTasks = 0;
    for (let comment of comments) {
      if (comment.task?.taskOpen) _openTasks++;
    }
    setCommentCount(comments.length);
    setOpenTasks(_openTasks);
  }, [comments]);

  const CommentCountLabel = ({
    count,
    bgColor = "bg-info",
    className = "",
    id,
    tooltip
  }) => (
    <>
      <span
        className={`CommentCountIcon text-white ${bgColor} ${className}`}
        id={id}
      >
        {count}
      </span>
      <UncontrolledTooltip
        delay={{ show: 0, hide: 0 }}
        placement="auto"
        target={id}
      >
        {tooltip}
      </UncontrolledTooltip>
    </>
  );

  return (
    <div className="CommentsContainer CommentsContainer__minimized">
      <button className="my-3 btn btn-link" type="button" onClick={onClick}>
        <FontAwesomeIcon icon="comments" size="lg" id="comments-icon" />
        <UncontrolledTooltip
          delay={{ show: 0, hide: 0 }}
          placement="auto"
          target="comments-icon"
        >
          Show comments
        </UncontrolledTooltip>
      </button>
      <div className="d-flex h-auto align-items-baseline">
        <CommentCountLabel
          count={commentCount}
          className="mr-1"
          id="comment-count-label"
          tooltip="Comment count"
        />
        {openTasks > 0 && (
          <CommentCountLabel
            count={openTasks}
            bgColor="bg-danger"
            id="open-tasks-count-label"
            tooltip="Open tasks"
          />
        )}
      </div>
    </div>
  );
}

export default CommentListMinimized;
