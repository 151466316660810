// Vendor
import React, {
  useEffect,
  useState,
  useMemo,
  useReducer,
  useContext,
  useCallback,
  createContext
} from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { useQuery, useMutation } from "@apollo/client";

// App
import Loader from "../ui/Loader";
import { ImageIdCard } from "../ui/ImageIdCard";
import DynamicTransTable from "../ui/DynamicTransTable/DynamicTransTable";
import { START_PAGE, LINKABLE_COURSES } from "../../common/Queries";
import { UPDATE_START_PAGE } from "../../common/Mutations";
import { GOLF_AREAS } from "../../common/Queries";
import SelectTable from "../ui/SelectTable";
import startPageReducer from "./startPageReducer";
import { LayoutContext } from "../app/Layout";
import HighlightList from "../ui/Highlights/HighlightList";
import { MapFields } from "./mapFields/MapFields";

export const StartPageContext = createContext();

function StartPage(props) {
  function handleSave() {
    // convert into StartPageInput format
    const input = {
      headerImageSlug: state.headerImageSlug,
      golfCourses: state.courses.map(course => course._id),
      highlights: state.highlights,
      golfDestinations: state.golfDestinations.map(dest => dest._id),
      testimonials: state.testimonials,
      startPageMap: state.startPageMap,
      golfDestinationPills: state.golfDestinationPills.map(pill => ({
        ...pill,
        paramValue: encodeURI(pill.text)
      }))
    };

    updateStartPage({
      variables: {
        input
      }
    });
  }

  // Context
  const { addAlert } = useContext(LayoutContext);

  // the state to pass down to children, and dispatch method to change it
  const [state, dispatch] = useReducer(startPageReducer, {});

  //state
  const [hasChanged, setHasChanged] = useState(false);

  function redirectWarning() {
    if (hasChanged) return "This will discard all unsaved edits. Are you sure?";
  }

  const middleware = useCallback(
    event => {
      if (!hasChanged) setHasChanged(true);
      dispatch(event);
    },
    [hasChanged]
  );
  // memoize so we only trigger reloads of children when the state changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(
    () => ({ state, dispatch: middleware }),
    [state, middleware]
  );

  // Query
  const { loading: queryLoading, error: queryError } = useQuery(START_PAGE, {
    onCompleted: data => dispatch({ type: "INIT", payload: data.startPage })
  });

  // Mutation
  const [updateStartPage, { loading: mutationLoading }] = useMutation(
    UPDATE_START_PAGE,
    {
      onCompleted: res => {
        dispatch({ type: "INIT", payload: res.updateStartPage.startPage });
        setHasChanged(false);
        addAlert({
          color: "success",
          message: "Start page successfully saved."
        });
      },
      onError: () =>
        addAlert({ color: "danger", message: "Could not save start page." })
    }
  );

  useEffect(() => {
    window.onbeforeunload = redirectWarning;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Guards
  if (queryLoading) return <Loader fullscreen={true} />;
  if (queryError)
    return <p className="text-danger">Error loading start page</p>;

  return (
    <StartPageContext.Provider value={contextValue}>
      <div className="page">
        <h1>Edit Start Page</h1>
        <AvForm
          onValidSubmit={handleSave}
          onInvalidSubmit={() =>
            addAlert({ color: "danger", message: "Invalid inputs." })
          }
        >
          <div className="my-5">
            <h3>Header Image</h3>
            <Row>
              <Col md={8} lg={6}>
                <ImageIdCard
                  publicId={state.headerImageSlug}
                  onChange={event =>
                    middleware({
                      type: "HEADER_IMAGE_SLUG",
                      payload: event.value
                    })
                  }
                  onChangeTargetKey="headerImageSlug"
                />
              </Col>
            </Row>
          </div>

          <SelectTable
            onChange={value =>
              middleware({ type: "GOLF_COURSES", payload: value })
            }
            selectQuery={LINKABLE_COURSES}
            listItems={state.courses}
            selectContainerClass="col-6"
            listContainerClass="col-12"
            title={"Golf Courses"}
          />

          <MapFields />

          <HighlightList
            highlights={state.highlights}
            onChange={newHighlightList =>
              middleware({ type: "HIGHLIGHTS", payload: newHighlightList })
            }
          />

          <SelectTable
            onChange={value =>
              middleware({ type: "GOLF_DESTINATIONS", payload: value })
            }
            selectQuery={GOLF_AREAS}
            selectQueryOptions={{ variables: { publishStatus: "PUBLISHED" } }}
            listItems={state.golfDestinations}
            selectContainerClass="col-6"
            listContainerClass="col-12"
            title={"Golf Destinations"}
          />

          <div className="my-5">
            <h3>Golf Destination Pills</h3>
            <DynamicTransTable
              onChange={value =>
                middleware({ type: "GOLF_DESTINATION_PILLS", payload: value })
              }
              translationField={{
                key: "text",
                header: "Area"
              }}
              additionalFields={[{ key: "param", header: "Param" }]}
              items={state.golfDestinationPills}
            />
          </div>

          <div className="my-5">
            <h3>Testimonials</h3>
            <DynamicTransTable
              onChange={value =>
                middleware({ type: "TESTIMONIALS", payload: value })
              }
              translationField={{
                key: "testimonial",
                header: "Testimonial"
              }}
              additionalFields={[{ key: "name", header: "Name" }]}
              items={state.testimonials}
            />
          </div>

          <div className="Save-container">
            <div className="Save-container__phantom" />
            <div className="Save-container__main">
              <FormGroup>
                <Button
                  size="lg"
                  color={hasChanged ? "secondary" : "gray"}
                  disabled={!hasChanged || mutationLoading}
                  type="submit"
                >
                  Save
                </Button>
              </FormGroup>
            </div>
          </div>
        </AvForm>
      </div>
    </StartPageContext.Provider>
  );
}

export default StartPage;
