export default function clubReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return action.payload;
    case "ACCOUNT_MANAGER":
      return { ...state, accountManager: action.payload };
    case "ADDRESS":
      return { ...state, address: action.payload };
    case "CITY":
      return { ...state, city: action.payload };
    case "CLAIMED":
      return { ...state, claimed: action.payload };
    case "COMMENT_ADD":
      return {
        ...state,
        comments: [action.payload, ...state.comments]
      };
    case "COMMENTS_UPDATE":
      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment._id === action.payload._id ? action.payload : comment
        )
      };
    case "COMMENT_DELETE":
      return {
        ...state,
        comments: state.comments.filter(
          (comment) => comment._id !== action.payload._id
        )
      };
    case "CONTACTS":
      return { ...state, contacts: action.payload };
    case "COUNTRY":
      return { ...state, country: action.payload };
    case "COUNTRY_CODE":
      return { ...state, countryCode: action.payload };
    case "COMMISSION":
      return { ...state, commission: action.payload };
    case "COMMISSION_TYPE":
      return { ...state, commissionType: action.payload };
    case "COURSES_SAVE_NEW": {
      let courses = [...state.courses];
      const courseIndex = courses.findIndex((c) => c._id === undefined);
      courses[courseIndex] = action.payload;
      return { ...state, courses };
    }
    case "COURSE_ADD":
      return { ...state, courses: [...state.courses, action.payload] };
    case "CURRENCY":
      return { ...state, currency: action.payload };
    case "DAILY_ORDER_EMAIL":
      return { ...state, dailyOrderEmail: action.payload };
    case "INVOICE_EMAIL":
      return { ...state, invoiceEmail: action.payload };
    case "ADD_INVOICE_EMAIL": {
      const previousInvoiceEmails = state.invoiceEmails
        ? [...state.invoiceEmails]
        : [];
      return {
        ...state,
        invoiceEmails: [...previousInvoiceEmails, action.payload]
      };
    }
    case "REMOVE_INVOICE_EMAIL": {
      const invoiceEmails = state.invoiceEmails.filter(
        (email) => email !== action.payload
      );
      return { ...state, invoiceEmails };
    }
    case "NAME":
      return { ...state, name: action.payload };
    case "PHONE":
      return { ...state, phone: action.payload };
    case "POSTAL_CODE":
      return { ...state, postalCode: action.payload };
    case "STATE":
      return { ...state, state: action.payload };
    case "TEESHEET_CLUB_ID":
      return { ...state, teeSheetClubId: action.payload };
    case "TEESHEET_PROVIDER":
      return { ...state, teeSheetProvider: action.payload };
    case "WEBSITE":
      return { ...state, website: action.payload };
    case "KEY_VALUE_STORE": {
      let storeCopy = [...state.keyValueStore];
      let keyValueIndex = storeCopy.findIndex(
        (v) => v.key === action.payload.key
      );
      if (keyValueIndex !== -1)
        storeCopy[keyValueIndex].value = action.payload.value;
      else storeCopy.push(action.payload);
      return { ...state, keyValueStore: storeCopy };
    }
    case "ADD_CLUB_PANEL_USER": {
      const userExists = state.clubPanelUsers.find(
        (user) => user._id === action.payload._id
      );
      if (!userExists) {
        return {
          ...state,
          clubPanelUsers: [...state.clubPanelUsers, action.payload]
        };
      }
      return state;
    }
    case "REMOVE_CLUB_PANEL_USER": {
      const users = state.clubPanelUsers.filter(
        (user) => user.email !== action.payload
      );
      return {
        ...state,
        clubPanelUsers: users
      };
    }
    default:
      return state;
  }
}
