export default function clubCourseReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return action.payload;

    case "AMENITIES":
      return { ...state, amenities: action.payload };
    case "AREAS":
      return { ...state, areas: action.payload };
    case "CURRENCY":
      return { ...state, currency: action.payload };
    case "COURSE_NAME":
      return { ...state, name: action.payload };
    case "ACTIVE":
      return { ...state, active: action.payload };
    case "ADDRESS":
      return { ...state, address: action.payload };
    case "ARCHITECT":
      return { ...state, architect: action.payload };
    case "CANCELLATION_HOURS":
      return { ...state, cancellationHours: action.payload };
    case "PAYOUT_HOURS":
      return { ...state, payoutHours: action.payload };
    case "DAYS_OFFSET":
      return { ...state, daysOffset: action.payload };
    case "CITY":
      return { ...state, city: action.payload };
    case "COUNTRY":
      return { ...state, country: action.payload };
    case "COUNTRY_CODE":
      return { ...state, countryCode: action.payload };
    case "COURSE_TYPE":
      return { ...state, courseType: action.payload };
    case "DAILY_ORDER_EMAIL":
      return { ...state, dailyOrderEmail: action.payload };
    case "INVOICE_EMAIL":
      return { ...state, invoiceEmail: action.payload };
    case "ADD_INVOICE_EMAIL": {
      const previousInvoiceEmails = state.invoiceEmails
        ? [...state.invoiceEmails]
        : [];
      return {
        ...state,
        invoiceEmails: [...previousInvoiceEmails, action.payload]
      };
    }
    case "DESCRIPTION":
      return { ...state, description: action.payload };
    case "READ_MORE":
      return { ...state, readMore: action.payload };
    case "HOLES":
      return { ...state, holes: action.payload };
    case "I18N":
      return { ...state, i18n: action.payload };
    case "LOC":
      return { ...state, loc: action.payload };
    case "LOCAL_TIME_ZONE":
      return { ...state, localTimeZone: action.payload };
    case "MEMBERSHIP":
      return { ...state, membership: action.payload };
    case "MULTI_FIELD_SET": {
      return { ...state, ...action.payload };
    }
    case "PAR":
      return { ...state, par: action.payload };
    case "VAT_RATE":
      return { ...state, vatRate: action.payload };
    case "VAT_IDENTIFICATION_NUMBER":
      return { ...state, vatIdentificationNumber: action.payload };
    case "COMMISSION_TYPE":
      return { ...state, commissionType: action.payload };
    case "COMMISSION":
      return { ...state, commission: action.payload };
    case "PHONE":
      return { ...state, phone: action.payload };
    case "POSTAL_CODE":
      return { ...state, postalCode: action.payload };
    case "PLACE_ID":
      return { ...state, placeId: action.payload };
    case "SEASON_CREATE":
      return { ...state, seasonRates: [...state.seasonRates, action.payload] };
    case "SEASON_DELETE":
      return {
        ...state,
        seasonRates: state.seasonRates.filter((s) => s._id !== action.payload)
      };
    case "SEASON_UPDATE": {
      let seasonRates = [...state.seasonRates];
      let index = seasonRates.findIndex((s) => s._id === action.payload._id);
      seasonRates[index] = action.payload;
      return { ...state, seasonRates };
    }
    case "SLUG":
      return { ...state, slug: action.payload };
    case "STATE":
      return { ...state, state: action.payload };
    case "TEE":
      return { ...state, tees: action.payload };
    case "TEESHEET_COURSE_ID":
      return { ...state, teeSheetCourseId: action.payload };
    case "WEBSITE":
      return { ...state, website: action.payload };
    case "PAY_ON_SITE":
      return { ...state, payOnSite: true, payOnBooking: false };
    case "PAY_ON_BOOKING":
      return { ...state, payOnSite: false, payOnBooking: true };
    case "AMADEUS_ACTIVE":
      return {
        ...state,
        amadeusActive: action.payload
      };
    case "AMADEUS_EXTERNAL_REFERENCE":
      return {
        ...state,
        amadeusCard: {
          ...state.amadeusCard,
          externalReference: action.payload.trim()
        }
      };
    case "HIDDEN_FOR_SITES":
      return { ...state, hiddenForSites: action.payload };
    case "INDEX":
      return { ...state, index: action.payload };
    case "SEASON_TYPES_ADD":
      if (state.seasonTypes?.length > 0) {
        // Setting state to true from payload to save course in useEffect
        action.payload.setSaveState(true);
        return {
          ...state,
          seasonTypes: [...state.seasonTypes, action.payload.seasonType]
        };
      } else {
        // Setting state to true from payload to save course in useEffect
        action.payload.setSaveState(true);
        return {
          ...state,
          seasonTypes: [action.payload.seasonType]
        };
      }
    case "SEASON_TYPES_UPDATE": {
      const seasonTypes = [...state.seasonTypes];
      let seasonTypeIndex = seasonTypes.findIndex((seasonType) => {
        return seasonType._id === action.payload.data._id;
      });
      seasonTypes[seasonTypeIndex] = action.payload.data;
      // Setting state to true from payload to save course in useEffect
      action.payload.setSaveState(true);
      return { ...state, seasonTypes };
    }

    case "SEASON_TYPES_DELETE": {
      let season_types = [...state.seasonTypes];
      season_types = season_types.filter(
        (seasonType) => seasonType._id !== action.payload.id
      );
      // Setting state to true from payload to save course in useEffect
      action.payload.setSaveState(true);
      return { ...state, seasonTypes: season_types };
    }
    case "SEASON_DAY_TYPES_DELETE": {
      let season_day_types = [...state.dayTypes];
      season_day_types = season_day_types.filter(
        (seasonDayType) => seasonDayType._id !== action.payload.id
      );
      // Setting state to true from payload to save course in useEffect
      action.payload.setSaveState(true);
      return { ...state, dayTypes: season_day_types };
    }
    case "SEASON_DAY_TYPES_ADD":
      // Setting state to true from payload to save course in useEffect
      action.payload.setSaveState(true);
      if (state.dayTypes?.length > 0)
        return {
          ...state,
          dayTypes: [...state.dayTypes, action.payload.dayType]
        };
      else
        return {
          ...state,
          dayTypes: [action.payload.dayType]
        };

    case "KEY_VALUE_STORE": {
      let storeCopy = [...(state.keyValueStore ?? [])];
      let keyValueIndex = storeCopy.findIndex(
        (v) => v.key === action.payload.key
      );
      if (keyValueIndex !== -1)
        storeCopy[keyValueIndex].value = action.payload.value;
      else storeCopy.push(action.payload);
      return { ...state, keyValueStore: storeCopy };
    }
    // this case is used to remove the season type from the course.seasonTypes when season type is not saved
    case "REMOVE_SEASON_TYPE": {
      let seasonTypes = [...state.seasonTypes];
      seasonTypes = seasonTypes.filter((seasonType) => seasonType._id);
      return { ...state, seasonTypes };
    }
    case "IS_COURSE_FEATURED_ALGOLIA": {
      return { ...state, isFeaturedOnAlgolia: !!action.payload };
    }
    default:
      return state;
  }
}
