import React, { useMemo } from "react";
import Select from "react-select";
import { useQuery } from "@apollo/client";
import { CLUB_PANEL_USERS } from "../../common/Queries";

const ClubPanelUsersSelect = (props) => {
  const { value, onChange, className } = props;

  const { error, data } = useQuery(CLUB_PANEL_USERS);

  const memoizedValue = useMemo(() => {
    if (!data || !props.value) {
      return null;
    }

    return props.value.reduce((result, selectedId) => {
      const v = data.getClubPanelUsers.find((x) => x._id === selectedId);
      if (!v) return result;
      return [...result, { label: v.name, value: v._id }];
    }, []);
  }, [data, value]);

  /**
    Handles options change.
    @param {Array<{value: any, label: string}>|{value: any, label: string}|null} value - The new value(s) for the options.
    If value is null, it represents no selection.
    */
  const handleOptionsChange = (user) => {
    onChange(user);
  };

  if (error) return <p className="text-danger">Could not load users.</p>;

  return (
    <Select
      id="input-status"
      onChange={handleOptionsChange}
      options={
        data?.getClubPanelUsers
          ? data.getClubPanelUsers.map((user) => ({
              label: user.email,
              value: user._id
            }))
          : []
      }
      value={memoizedValue}
      className={className}
    />
  );
};

export default ClubPanelUsersSelect;
