import { AvForm, AvInput } from "availity-reactstrap-validation";
import React, { useContext, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { Button, Label, PopoverBody, UncontrolledPopover } from "reactstrap";
import { EmailContext } from "../Email";
import { useMutation } from "@apollo/client";
import {
  CREATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE
} from "../../../common/Mutations";
import { LayoutContext } from "../../app/Layout";
import EmailList from "./EmailList";
import templateReducer from "./templateReducer";
import { ImportantEmails } from "../../../common/Enums";

const Template = (props) => {
  const { data } = props;

  // handlers
  const handleSubmit = () => {
    const input = { ...template };
    if (template._id) {
      // not part of the input schema
      delete input._id;

      updateEmailTemplate({
        variables: { emailTemplateID: template._id, emailTemplateInput: input }
      });
      return;
    }
    createEmailTemplate({ variables: { emailTemplateInput: input } });
  };
  const handleDelete = (id) => {
    deleteEmailTemplate({ variables: { emailTemplateID: id } });
  };

  // mutation handlers
  const handleUpdateEmailTemplateCompleted = (res) => {
    if (res.updateEmailTemplate.ok) {
      emailsDispatch({
        type: "UPDATE_TEMPLATE",
        payload: res.updateEmailTemplate.emailTemplate
      });
      addAlert({ color: "success", message: "Template updated successfully" });
      setEdited(false);
    } else {
      addAlert({
        color: "danger",
        message: res.updateEmailTemplate.returnMessage
      });
    }
  };
  const handleCreateEmailTemplateCompleted = (res) => {
    if (res.createEmailTemplate.ok) {
      // calling UPDATE_TEMPLATE instead of ADD_TEMPLATE because the template is already in the state
      emailsDispatch({
        type: "UPDATE_DUMMY_TEMPLATE",
        payload: res.createEmailTemplate.emailTemplate
      });
      addAlert({ color: "success", message: "Template saved successfully" });
    } else {
      addAlert({
        color: "danger",
        message: res.createEmailTemplate.returnMessage
      });
    }
  };
  const handleDeleteEmailTemplateCompleted = (res) => {
    if (res.deleteEmailTemplate.ok) {
      emailsDispatch({
        type: "DELETE_TEMPLATE",
        payload: res.deleteEmailTemplate.emailTemplate._id
      });
      addAlert({ color: "success", message: "Template deleted successfully" });
      // redirect to first tab
      handleNavLinkClick(1);
    } else {
      addAlert({
        color: "danger",
        message: res.deleteEmailTemplate.returnMessage
      });
    }
  };

  // context
  const { emailsDispatch, handleNavLinkClick } = useContext(EmailContext);
  const { addAlert } = useContext(LayoutContext);

  // reducer
  const [template, dispatch] = useReducer(templateReducer, data);

  // states
  const [edited, setEdited] = useState(false);
  // mutations
  const [createEmailTemplate] = useMutation(CREATE_EMAIL_TEMPLATE, {
    onCompleted: handleCreateEmailTemplateCompleted,
    onError: (error) => {
      addAlert({
        color: "danger",
        message: "Error while creating template"
      });
    }
  });
  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE, {
    onCompleted: handleUpdateEmailTemplateCompleted,
    onError: (error) => {
      addAlert({
        color: "danger",
        message: "Error while updating template"
      });
    }
  });
  const [deleteEmailTemplate] = useMutation(DELETE_EMAIL_TEMPLATE, {
    onCompleted: (res) => handleDeleteEmailTemplateCompleted(res),
    onError: (error) => {
      addAlert({
        color: "danger",
        message: "Error while deleting template"
      });
    }
  });

  useEffect(() => {
    if (data) {
      dispatch({ type: "INIT", payload: data });
    }
  }, [data]);

  const isImportantTemplate = ImportantEmails.includes(template.name);

  return (
    <div>
      <AvForm onValidSubmit={handleSubmit}>
        <div className="d-flex w-full justify-content-between">
          <h2>Template settings</h2>
          {template._id && (
            <Button
              color="danger"
              disabled={isImportantTemplate}
              id={`email-template-${template._id}`}
            >
              Delete template
              <UncontrolledPopover
                className="p-2"
                target={`email-template-${template._id}`}
              >
                <PopoverBody>
                  Are you sure?
                  <Button
                    type="button"
                    className="ml-2"
                    color="danger"
                    size="sm"
                    onClick={() => handleDelete(template._id)}
                  >
                    Yes
                  </Button>
                </PopoverBody>
              </UncontrolledPopover>
            </Button>
          )}
        </div>
        <Label>Template Name</Label>
        <AvInput
          className="mb-2"
          type="text"
          name="template-name"
          value={template.name}
          onChange={(event) => {
            setEdited(true);
            dispatch({ type: "NAME", payload: event.target.value });
          }}
          disabled={isImportantTemplate}
        />
        <Label>Subject</Label>
        <AvInput
          className="mb-2"
          type="text"
          name="email-subject"
          maxLength="70"
          value={template.subject}
          onChange={(event) => {
            setEdited(true);
            dispatch({ type: "SUBJECT", payload: event.target.value });
          }}
        />
        <Label>Email Body</Label>
        <AvInput
          className="email-text-area mb-2"
          type="textarea"
          name="email-body"
          maxLength="1000"
          value={template.emailBody}
          onChange={(event) => {
            setEdited(true);
            dispatch({ type: "EMAIL_BODY", payload: event.target.value });
          }}
        />
        <EmailList
          template={template}
          setEdited={setEdited}
          dispatch={dispatch}
        />
        <Button className="btn btn-primary" type="submit" disabled={!edited}>
          Save template
        </Button>
      </AvForm>
    </div>
  );
};

Template.TemplatePropTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    emailBody: PropTypes.string.isRequired,
    recipientList: PropTypes.arrayOf(PropTypes.string)
  })
};

export default Template;
