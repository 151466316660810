import { useMutation } from "@apollo/client";
import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput
} from "availity-reactstrap-validation";
import React, { useContext, useState } from "react";
import { Button, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { CREATE_INVOICE } from "../../../common/Mutations";
import { LayoutContext } from "../../app/Layout";
import { useHistory } from "react-router-dom";
import { InvoiceContext } from "../Invoice";
/**
 * @param {object} props
 * @param {boolean} props.isOpen boolean to check if modal is open
 * @param {function} props.toggle function to toggle modal
 */

const SubinvoiceModal = ({ isOpen, toggle }) => {
  // context
  const { addAlert } = useContext(LayoutContext);
  const { invoice } = useContext(InvoiceContext);

  // getting data from parent
  const [parentInvoiceId, parentInvoiceRef] = [invoice._id, invoice.reference];

  //navigation
  const history = useHistory();

  // states
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);

  // handlers
  const handleSubmit = () => {
    if (description === "" || amount === 0) return null;

    const input = {
      description,
      amountToCharge: amount,
      referenceTo: {
        _id: parentInvoiceId,
        reference: parentInvoiceRef
      }
    };
    createInvoice({
      variables: {
        invoiceInput: input
      }
    });
  };
  // mutation
  const [createInvoice] = useMutation(CREATE_INVOICE, {
    onCompleted: (data) => {
      if (data.createInvoice.ok) {
        addAlert({
          color: "success",
          message: "Subinvoice created successfuly."
        });
        history.push("/invoice/" + data.createInvoice.invoice._id);
      } else {
        addAlert({
          color: "danger",
          message: "Subinvoice creation failed."
        });
      }
    },
    onError: (error) => {
      console.log(error);
      addAlert({
        color: "danger",
        message: "Subinvoice creation failed."
      });
    }
  });
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="SubInvoiceModal">
      <ModalHeader toggle={toggle}>Create new sub invoice</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          <AvGroup>
            <Label for="subinvoice-description">Sub invoice Description</Label>
            <AvInput
              name="subinvoice-description"
              className="w-100 min-h-75"
              id="Subinvoice-textarea"
              type="textarea"
              required
              onChange={(e) => setDescription(e.target.value)}
            />
            <AvFeedback>Please enter description!</AvFeedback>
          </AvGroup>
          <AvGroup className="mt-3">
            <Label for="subinvoice-amount">Sub invoice Amount</Label>
            <AvInput
              name="subinvoice-amount"
              className="w-100"
              type="number"
              required
              onChange={(e) => setAmount(Number(e.target.value))}
            />
            <AvFeedback>Please enter amount!</AvFeedback>
          </AvGroup>
          <Button className="mt-3" color="primary">
            Create sub invoice
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default SubinvoiceModal;
