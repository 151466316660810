/**
 * Opens a PDF file in a new browser window.
 *
 * @param {string} encoding - The base64-encoded representation of the PDF file.
 * @param {string} type - The MIME type of the PDF file (e.g., "application/pdf").
 * @returns {void}
 */
export const openDocumentInNewWindow = (encoding, type) => {
  // Decode the base64 string into a pdf file using Buffer from
  const decodedPdf = Buffer.from(encoding, "base64");
  // Create a blob of the document file
  const blob = new Blob([decodedPdf], { type: type });
  // Create a blob URL for the file
  const url = URL.createObjectURL(blob);
  // Open the file in a new tab
  window.open(url, "_blank");
};

export const saveBlobFile = (blob, fileName) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};