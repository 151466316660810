import qs from "qs";

class URLHelper {
  static getParam = (location, param) => {
    const urlParams = URLHelper.queryToState(location);
    return urlParams[param];
  };

  static queryToState = (location) => qs.parse(location.search.slice(1));

  static queryFromState = (state) => `?${qs.stringify(state)}`;

  static isLocalhost() {
    return (
      window.location.origin.match(
        /^(http)+s?(:\/\/localhost)+|(http)+s?(:\/\/127.0.0.1)+/g
      ) !== null
    );
  }

  static isProduction() {
    return window.location.origin.match(/(www+\.)?greenfee365\.com$/) !== null;
  }

  static isPR() {
    return window.location.origin.match(
      /marshalls-frontend-(stage-)?pr-\d+\.onrender\.com$/
    );
  }

  static isStage() {
    return window.location.origin.match(
      /marshalls-frontend-stage\.onrender\.com$/
    );
  }

  static getApiUrl() {
    const BACKEND_SERVER_URL = "backendServerUrl";

    if (URLHelper.isProduction()) {
      return process.env.REACT_APP_MARSHALLS_BACKEND_URL;
    }

    // Use target backend override if set
    let localStoreOverride = localStorage.getItem(BACKEND_SERVER_URL);
    if (localStoreOverride !== null) {
      return localStoreOverride;
    }

    if (URLHelper.isLocalhost()) {
      return "http://localhost:4000";
    }

    if (URLHelper.isStage()) {
      return window.location.origin.replace(
        "frontend-stage",
        "backend-staging-dev"
      );
    }

    if (URLHelper.isPR()) {
      return window.location.origin.replace(
        "frontend-stage",
        "backend-staging-dev"
      );
    }

    //return "https://stg-greenfee-backend.zendev.se";

    // throw new Error("Unable to set backend target.");

    // return URLHelper.isProduction()
    //   ? process.env.REACT_APP_MARSHALLS_BACKEND_URL
    //   : URLHelper.isLocalhost()
    //   ? "http://localhost:4000"
    //   : localStorage.getItem(BACKEND_SERVER_URL);
  }

  /**
   * Sets individual dates when singleDate, startDate, or endDate are present.
   *
   * @param {string} baseKey The base key for the output objects.
   * @param {Object} dates The start and end dates.
   * @param {moment} dates.startDate The start date.
   * @param {moment} dates.endDate The end date.
   * @param {boolean} singleDate Specifies whether the date range should be a single date or a range of dates.
   * If set to true, the function will consider a single date, otherwise, it will consider the start and end dates separately.
   * @returns {Object[]} An array containing the formatted date queries.
   *
   * @example
   * formatDateQueryIndividualDates("payoutDate", { startDate: moment(), endDate: moment() }, true)
   * // returns [{ key: "payoutDate", value: "2023-01-01" }]
   */

  static formatDateQueryIndividualDates(baseKey, dates, singleDate) {
    const { startDate, endDate } = dates;
    let arr = [
      { key: `${baseKey}From`, value: null },
      { key: `${baseKey}To`, value: null }
    ];

    if (singleDate) {
      arr[0].key = baseKey;
      arr[0].value = startDate?.format("YYYY-MM-DD");
      arr.splice(1, 1);
      return arr;
    }

    if (startDate) {
      arr[0].value = startDate?.format("YYYY-MM-DD");
    }

    if (endDate) {
      arr[1].value = endDate?.format("YYYY-MM-DD");
    }

    return arr;
  }
  /**
   * Sets dates only when both startDate and endDate are present.
   *
   * @param {string} baseKey The base key for the output objects.
   * @param {Object} dates The start and end dates.
   * @param {moment} dates.startDate The start date.
   * @param {moment} dates.endDate The end date.
   * @returns {Object[]} An array containing the formatted date queries.
   *
   * @example
   * formatDateQueryBothDates("payoutDate", { startDate: moment(), endDate: moment() })
   * // returns [{ key: "payoutDateFrom", value: "2023-01-01" }, { key: "payoutDateTo", value: "2023-02-01" }]
   */
  // Sets dates only when both startDate and endDate are present
  static formatDateQueryBothDates(baseKey, dates) {
    const { startDate, endDate } = dates;
    let arr = [
      { key: `${baseKey}From`, value: null },
      { key: `${baseKey}To`, value: null }
    ];

    if (startDate && endDate) {
      arr[0].value = startDate.format("YYYY-MM-DD");
      arr[1].value = endDate.format("YYYY-MM-DD");
    }

    return arr;
  }
}

export default URLHelper;
