import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends Component {
  static propTypes = {
    text: PropTypes.string,
    global: PropTypes.bool,
    showReloadBtn: PropTypes.bool
  };

  static defaultProps = {
    global: false,
    text:
      "An error occured in the application. Please try to reload the page or send contact development if the problem persists.",
    showErrorReportBtn: false,
    showReloadBtn: false
  };

  constructor(props) {
    super(props);

    this.state = { error: null, eventId: null };

    this.reportError = this.reportError.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  reportError(error, errorInfo) {
    const { context } = this.props;

    this.setState({ error });

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      if (context) scope.setTag("context", context);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  componentDidCatch(error, errorInfo) {
    this.reportError(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        error: false
      });
    }
  }

  render() {
    const { global, children } = this.props;

    const ReloadBtn = () => (
      <Button
        color="secondary"
        className="d-inline-block"
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload the page
      </Button>
    );
    const GlobalError = () => (
      <>
        <h1 className="mt-4">Whoops!</h1>
        <p>{this.props.text}</p>
        <div>{this.props.showReloadBtn && <ReloadBtn />}</div>
      </>
    );

    const LocalError = () => (
      <>
        <FontAwesomeIcon
          icon="exclamation-triangle"
          className="text-danger d-block mx-auto mb-2 fa-2x"
        />
        <p className="mb-3">{this.props.text}</p>
        <div>{this.props.showReloadBtn && <ReloadBtn />}</div>
      </>
    );

    if (this.state.error) {
      return (
        <div
          className={`my-6 container restricted-width-md d-flex flex-column text-center align-items-center ${
            global ? "" : "Body justify-content-center"
          }`}
        >
          {global ? <GlobalError /> : <LocalError />}
        </div>
      );
    } else return children;
  }
}

export default withRouter(ErrorBoundary);
