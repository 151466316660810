import {
  faCalendarDay,
  faCalendarWeek
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const InvoiceStatusExplanation = ({ options }) => (
  <div>
    {options.map((status, i) => (
      <div
        key={i}
        className="py-2 d-flex align-items-center"
        style={{ borderBottom: "1px solid #666565" }}
      >
        <span className="font-weight-bold text-left text-nowrap mr-1">
          {status.label} -
        </span>
        <p className="text-left mb-0">{status.info}</p>
      </div>
    ))}
  </div>
);

export const ToggleIconsExplanation = () => {
  return (
    <div className="d-flex flex-column">
      <span className="w-100 mb-2">
        Clicking on the toggle icon will toggle the component between a{" "}
        <b>single date picker</b> and a <b>date range picker.</b>
      </span>
      <div className="d-flex flex-column w-100 align-items-center">
        <div className="font-weight-bold text-left mb-2 ">
          <FontAwesomeIcon icon={faCalendarWeek} className="mr-2" />
          Date range picker
        </div>
        <div className="text-left font-weight-bold mb-0 ">
          <FontAwesomeIcon icon={faCalendarDay} className="mr-2" />
          Single Date Picker
        </div>
      </div>
    </div>
  );
};
