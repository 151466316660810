// Vendor
import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import Select from "react-select";

// App
import { PARTNER_NETWORKS } from "../../common/Queries";
import Loader from "./Loader";

/**
 * @param { Object } props
 * @param { string } [props.id="partner-network-select"]
 * @param { string } props.label
 * @param { string[] } [props.value]
 * @param { VoidCallback<string[] | null>} props.onChange
 */
export function PartnerNetworkSelect(props) {
  /** @type {import("@apollo/client").QueryResult<PartnerNetworkData>} */
  const { loading, error, data, refetch } = useQuery(PARTNER_NETWORKS);

  const value = useMemo(() => {
    return data?.partnerNetworks
      .filter(a => props.value?.some(v => v === a._id))
      .map(b => ({ value: b._id, label: b.name }));
  }, [data, props.value]);

  if (loading) return <Loader />;
  if (error)
    return (
      <p className="text-danger">
        Could not load partner networks.{" "}
        <button color="link" type="button" onClick={refetch}>
          Retry
        </button>
      </p>
    );

  return (
    <>
      <label htmlFor={props.id}>{props.label}</label>
      <Select
        id={props.id}
        options={data.partnerNetworks.map(p => ({
          value: p._id,
          label: p.name
        }))}
        value={value}
        onChange={val => props.onChange(val.map(v => v.value))}
        isMulti
      />
    </>
  );
}

/**
 * @typedef PartnerNetworkData
 * @property { Object[] } partnerNetworks
 * @property { string } partnerNetworks[]._id
 * @property { string } partnerNetworks[].name
 * @property { number } partnerNetworks[].value
 * @property { string[] } partnerNetworks[].emails
 */
