import React, { useContext, useState } from "react";
import Switch from "react-switch";
import { ClubCourseContext } from "../ClubCourse";
import { InputField } from "../../../ui/FunctionalComponents";
import { Button, Col, Collapse, Row, Table } from "reactstrap";
import variables from "../../../../styles/bootstrap/_variables.scss";
import moment from "moment";

const ClubCourseAmadeus = () => {
  const { state, dispatch } = useContext(ClubCourseContext);
  const [collapse, setCollapse] = useState(false);
  const { amadeusActive, amadeusCard, previousCardReferences } = state;

  return (
    <div className="mt-3">
      <h3>Amadeus Course info</h3>
      <Row>
        <Col md={4}>
          <InputField
            targetKey="externalReference"
            label="Amadeus External Reference"
            value={amadeusCard?.externalReference}
            onChange={(event) =>
              dispatch({
                type: "AMADEUS_EXTERNAL_REFERENCE",
                payload: event.value
              })
            }
            required={false}
          />
        </Col>
        <div className="switch-group">
          <Switch
            id="switch-claimed"
            checked={!!amadeusActive}
            onChange={() =>
              dispatch({ type: "AMADEUS_ACTIVE", payload: !amadeusActive })
            }
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={variables.primary}
            offColor={variables.gray300}
            width={50}
          />
          <label
            htmlFor="switch-claimed"
            className={`${amadeusActive ? "" : "text-muted"}`}
          >
            {amadeusActive ? "Amadeus Active" : "Amadeus Inactive"}
          </label>
        </div>
      </Row>
      <Row>
        <Col md={12}>
          <Button
            className="ml-0"
            color="link"
            onClick={() => setCollapse(!collapse)}
          >
            Previous Card References
          </Button>
          <Collapse isOpen={collapse}>
            <Table responsive>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Card Reference</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {previousCardReferences?.map((card, index) => (
                  <tr key={card._id}>
                    <td>{index + 1}</td>
                    <td>{card.cardReference}</td>
                    <td>{moment(card.createdAt).format("YYYY-MM-DD")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export default ClubCourseAmadeus;
