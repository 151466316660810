import React, { useMemo } from "react";
import Select from "react-select";

const InvoiceStatusSelect = (props) => {
  const {
    value,
    options,
    isMulti = true,
    onChange,
    isDisabled,
    defaultValue
  } = props;
  const memoizedValue = useMemo(() => {
    if (!value) {
      return undefined;
    }

    if (isMulti) {
      return value.reduce((result, selectedStatus) => {
        const status = options.find(
          (option) => selectedStatus === option.value
        );

        if (!status) return result;
        return [...result, { label: status.label, value: status.value }];
      }, []);
    }
    // for single values
    const status = options.find((option) => value === option.value);
    return status;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /**
    Handles options change.
    @param {Array<{value: any, label: string}>|{value: any, label: string}|null} value - The new value(s) for the options.
    If isMulti is true, an array of objects with properties value and label is expected.
    If isMulti is false, a single object with properties value and label is expected.
    If value is null, it represents no selection.
    */
  const handleOptionsChange = (value) => {
    if (isMulti) return onChange(value ? value.map((x) => x.value) : null);
    onChange(value?.value ?? null);
  };

  return (
    <Select
      id="input-status"
      onChange={handleOptionsChange}
      options={options}
      defaultValue={defaultValue}
      value={memoizedValue}
      isMulti={isMulti}
      isDisabled={isDisabled}
    />
  );
};

export default InvoiceStatusSelect;
