// Vendor
import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useDeepCompareEffect } from "react-use";

// Common
import { ORDER_LIST, ORDER_SEARCH } from "../../common/Queries";
import URLHelper from "../../common/URLHelper";

// App
import CondensedNavButtonBar from "../ui/CondensedNavButtonBar";
import OrderSearch from "./OrderSearch";
import OrderListTable from "./OrderListTable";
import { LayoutContext } from "../app/Layout";

function OrderList() {
  const { addAlert } = useContext(LayoutContext);
  const history = useHistory();
  const urlQueryParams = URLHelper.queryToState(history.location);
  const {
    page,
    userText,
    orderText,
    clubName,
    createdFrom,
    createdTo,
    teetimeFrom,
    teetimeTo,
    hasOpenTask,
    sites,
    networks,
    tourOperators,
    affiliates,
    hasActiveBooking
  } = urlQueryParams;

  // Hooks
  const [pageCount, setPageCount] = useState(1);
  const [matchCount, setMatchCount] = useState(0);
  const [orderValueSum, setOrderValueSum] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [teetimesCount, setTeetimesCount] = useState(0);
  const [playersCount, setPlayersCount] = useState(0);

  const [getOrderList, { loading: orderListLoading, data }] = useLazyQuery(
    ORDER_LIST
  );
  const [getPageCount, { loading: pageCountLoading }] = useLazyQuery(
    ORDER_SEARCH,
    {
      onCompleted({ orderSearch }) {
        setPageCount(orderSearch.pageCount);
        setMatchCount(orderSearch.matchCount);
        setTeetimesCount(orderSearch.teeTimesCount);
        setPlayersCount(orderSearch.playersCount);
        setOrderValueSum(
          Object.entries(orderSearch.orderValueSum)
            .map(([k, v]) => `${k}: ${v.toLocaleString()}`)
            .join(" - ")
        );
      }
    }
  );

  // Effect hooks
  useDeepCompareEffect(() => {
    // Get page count on changed search params
    getPageCount({
      variables: {
        filter: buildFilter()
      }
    });

    // Ensure page index 0
    if (pageIndex === 0) {
      fetchOrders();
    } else pageBtnClickHandler(0);
  }, [
    userText,
    orderText,
    clubName,
    createdFrom,
    createdTo,
    teetimeFrom,
    teetimeTo,
    hasOpenTask,
    sites,
    networks,
    tourOperators,
    affiliates,
    hasActiveBooking
  ]);

  useEffect(() => {
    // Update page index from url if changed
    if (page) setPageIndex(Number(page) - 1);
  }, [page]);

  useEffect(() => {
    // Fetch new list on pageIndex change
    fetchOrders();
  }, [pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  function fetchOrders() {
    getOrderList({
      variables: {
        pageIndex,
        filter: buildFilter()
      }
    });
  }

  function pageBtnClickHandler(newIndex) {
    newIndex = Number(newIndex);
    if (newIndex > 499) {
      addAlert({
        color: "warning",
        message:
          "Page limited to 500. Result set too large, reduce set through search parameters"
      });
      newIndex = 499;
    }
    urlQueryParams.page = newIndex + 1;
    history.push({
      search: URLHelper.queryFromState(urlQueryParams)
    });
  }

  function buildFilter() {
    let filter = {
      userSearchText: userText,
      orderSearchText: orderText,
      clubName,
      sites,
      networks,
      tourOperators,
      affiliates
    };
    if (hasOpenTask) {
      filter.hasOpenTask = hasOpenTask === "true"; // Convert from string
    }
    if (hasActiveBooking) {
      filter.hasActiveBooking = hasActiveBooking === "true";
    }
    if (createdFrom) {
      filter.createdDate = {
        start: createdFrom,
        end: createdTo
      };
    }
    if (teetimeFrom) {
      filter.playDate = {
        start: teetimeFrom,
        end: teetimeTo
      };
    }
    return filter;
  }

  const orderList = data ? data.orderList : null;

  return (
    <div className="page">
      <h1 className="mb-4">Orders</h1>

      <OrderSearch />

      <div className="d-inline-block mr-2">Pages: {pageCount}</div>
      <div className="d-inline-block mr-2">Matches: {matchCount}</div>
      <br />
      <div className="d-inline-block mr-2">Tee times:{teetimesCount}</div>
      <div className="d-inline-block">Rounds:{playersCount}</div>
      <br />
      <div className="d-inline-block mr-2">{orderValueSum}</div>

      <OrderListTable
        orderList={orderList}
        loading={pageCountLoading || orderListLoading || !data}
      />

      <CondensedNavButtonBar
        value={pageIndex}
        onBtnClick={pageBtnClickHandler}
        pageCount={pageCount}
      />
    </div>
  );
}

export default OrderList;
