import React, { useState } from "react";
import { Col, Input, Row } from "reactstrap";
import Select from "react-select";
import {
  CardStatusCollection,
  CardCurrencyCollection,
  CardSubTypeCollection,
  CardVendorCodeCollection
} from "../../common/AmadeusCollections";
import DateRangeFacet from "../ui/facet/DateRangeFacet";
import { useHistory } from "react-router-dom";
import useUpdateUrlSearchParams from "../../common/useUpdateUrlSearchParams";
import useDebounce from "../../common/useDebounce";
import URLHelper from "../../common/URLHelper";
import moment from "moment";

const AmadeusSearch = () => {
  const history = useHistory();
  const urlParams = URLHelper.queryToState(history.location);

  // url param states
  const [status, setStatus] = useState(urlParams.status || "ACTIVE");
  const [cardHolder, setCardHolder] = useState(urlParams.cardHolder);
  const [currency, setCurrency] = useState(urlParams.currency);
  const [vendorCode, setVendorCode] = useState(urlParams.vendorCode);
  const [subType, setSubType] = useState(urlParams.subType);
  const [periodDate, setPeriodDate] = useState({
    startDate: urlParams.periodFrom
      ? moment(urlParams.periodFrom)
      : moment("2023-05-17"),
    endDate: urlParams.periodTo ? moment(urlParams.periodTo) : moment()
  });
  const [amountRange, setAmountRange] = useState({
    min: urlParams.amountFrom ?? null,
    max: urlParams.amountTo ?? null
  });

  // debounced values
  const debouncedStatus = useDebounce(status, 750);
  const debouncedCardHolder = useDebounce(cardHolder, 750);
  const debouncedCurrency = useDebounce(currency, 750);
  const debouncedPeriodDate = useDebounce(periodDate, 750);
  const debouncedAmountRange = useDebounce(amountRange, 750);
  const debouncedSubType = useDebounce(subType, 750);
  const debouncedVendorCode = useDebounce(vendorCode, 750);

  /**
   * Update query params on any param change (debounced)
   */
  useUpdateUrlSearchParams(
    history,
    [
      { key: "status", value: debouncedStatus },
      { key: "cardHolder", value: debouncedCardHolder },
      { key: "currency", value: debouncedCurrency },
      { key: "amountFrom", value: debouncedAmountRange.min },
      { key: "amountTo", value: debouncedAmountRange.max },
      { key: "subType", value: debouncedSubType },
      { key: "vendorCode", value: debouncedVendorCode },
      ...URLHelper.formatDateQueryBothDates("period", debouncedPeriodDate)
    ],
    [
      debouncedStatus,
      debouncedCardHolder,
      debouncedCurrency,
      debouncedPeriodDate,
      debouncedAmountRange,
      debouncedSubType,
      debouncedVendorCode
    ]
  );

  return (
    <Row className="mb-3">
      {/* Card Holder */}
      <Col lg={2} className="mb-2">
        <label htmlFor="input-card-name">Card Holder</label>
        <Input
          id="input-card-name"
          placeholder="Card Holder"
          onChange={(e) => setCardHolder(e.target.value)}
          defaultValue={urlParams.cardHolder}
        />
      </Col>

      {/* Vendor Code */}
      <Col lg={2} className="mb-2">
        <label htmlFor="input-card-name">Vendor Code</label>
        <Select
          id="input-vendor-code"
          options={CardVendorCodeCollection}
          onChange={(e) => setVendorCode(e.value)}
          defaultValue={
            urlParams.vendorCode
              ? CardVendorCodeCollection.find(
                  (vendorCode) => vendorCode.value === urlParams.vendorCode
                )
              : CardVendorCodeCollection[0]
          }
        />
      </Col>

      {/* Card Brand */}
      <Col lg={2} md={4} className="mb-2">
        <label htmlFor="card-brand">Card Brand</label>
        <Select
          id="card-brand"
          options={CardSubTypeCollection}
          onChange={(e) => setSubType(e.value)}
          defaultValue={
            urlParams.subType
              ? CardSubTypeCollection.find(
                  (subType) => subType.value === urlParams.subType
                )
              : CardSubTypeCollection[0]
          }
        />
      </Col>

      {/* Card Status */}
      <Col lg={2} md={4} className="mb-2">
        <label htmlFor="input-select-status">Status</label>
        <Select
          id="input-select-status"
          className="mb-2"
          onChange={(e) => {
            setStatus(e.value);
          }}
          defaultValue={CardStatusCollection[1]}
          options={CardStatusCollection}
        />
      </Col>

      {/* Card Currency */}
      <Col lg={2} md={4} className="mb-2">
        <label htmlFor="input-select-status">Currency</label>
        <Select
          id="input-select-status"
          className="mb-2"
          onChange={(e) => {
            setCurrency(e.value);
          }}
          defaultValue={
            urlParams.currency
              ? CardCurrencyCollection.find(
                  (currency) => currency.value === urlParams.currency
                )
              : CardCurrencyCollection[0]
          }
          options={CardCurrencyCollection}
        />
      </Col>

      <Col lg={4} className="mb-2">
        <label htmlFor="date-creation-from">Date of Creation</label>
        <div className="DateRangePicker__custom-style">
          <DateRangeFacet
            small
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            startDate={periodDate?.startDate}
            startDateId="periodDateFrom"
            endDate={periodDate?.endDate}
            endDateId="periodDateTo"
            onDatesChange={({ startDate, endDate }) =>
              setPeriodDate({ startDate, endDate })
            }
            showClearDates
            isOutsideRange={() => false}
            canPickSingleDate
          />
        </div>
      </Col>

      {/* Balance to */}
      <Col xl={2} lg={4} md={6} sm={6} xs={6}>
        <label htmlFor="balance-from">Available Balance From</label>
        <Input
          id="balance-from"
          className="mb-2"
          type="number"
          placeholder="Balance from"
          defaultValue={urlParams.amountFrom}
          onChange={(e) => {
            setAmountRange({
              ...amountRange,
              min: e.target.value
            });
          }}
        />
      </Col>

      {/* Balance from */}
      <Col xl={2} lg={4} md={6} sm={6} xs={6}>
        <label htmlFor="balance-to">Available Balance To</label>
        <Input
          id="balance-to"
          className="mb-2"
          type="number"
          placeholder="Balance to"
          defaultValue={urlParams.amountTo}
          onChange={(e) => {
            setAmountRange({
              ...amountRange,
              max: e.target.value
            });
          }}
        />
      </Col>
    </Row>
  );
};

export default AmadeusSearch;
