// Vendor
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";

// App
import Loader from "../Loader";

/**
 * @param { Object } props
 * @param { string } props.id
 * @param { string } [props.itemInTransit] (Optional) ID of row currently being modified by backend
 * @param { VoidCallback<string> } props.handleDelete On delete callback
 */
export default function DeleteButton(props) {
  const { id, itemInTransit, handleDelete } = props;

  if (itemInTransit !== null) {
    if (itemInTransit === id) {
      return (
        <Button className="cursor-default" color="link" type="button">
          <Loader size={25} />
        </Button>
      );
    } else {
      return (
        <Button
          className="cursor-default"
          color="link"
          type="button"
          disabled
          id={`item-delete-base-${id}`}
        >
          <FontAwesomeIcon icon="trash-alt" className="mr-1" />
        </Button>
      );
    }
  }
  return (
    <>
      <Button
        type="button"
        color="link"
        className="text-danger"
        id={`item-delete-base-${id}`}
      >
        <FontAwesomeIcon icon="trash-alt" className="mr-1" />
      </Button>
      <UncontrolledPopover className="p-2" target={`item-delete-base-${id}`}>
        <PopoverBody>
          Are you sure?
          <Button
            type="button"
            className="ml-2"
            color="danger"
            size="sm"
            onClick={() => handleDelete(id)}
          >
            Yes
          </Button>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
