const ClubKeyValueStoreVariants = Object.freeze({
  CONCEPT: [
    { key: "WEBSERVICE_TOKEN", default: "" },
    { key: "WEBSERVICE_CUSTOMER_NUMBER", default: "" },
    { key: "WEBSERVICE_BASE_URL", default: "" },
    { key: "WEBSERVICE_MTYPE", default: "" }
  ],
  GOLF_MANAGER: [{ key: "TARGET_SERVER", default: "" }],
  INTELLIGENT_GOLF: [
    { key: "CLUB_CODE", default: "" },
    { key: "CLUB_API_KEY", default: "" }
  ]
});
const CourseKeyValueStoreVariants = Object.freeze({
  GOLF_MANAGER: [{ key: "ID_RESOURCE_TYPE", default: "1" }],
  ZEST: [{ key: "PROVIDER_COURSE_NAME", default: "" }]
});

export class KeyValueStoreHelper {
  /**
   * @param { string } teeSheetProvider
   */
  static hasClubKeyValueStore(teeSheetProvider) {
    return Object.keys(ClubKeyValueStoreVariants).includes(teeSheetProvider);
  }
  /**
   * @param { string } teeSheetProvider
   */
  static hasCourseKeyValueStore(teeSheetProvider) {
    return Object.keys(CourseKeyValueStoreVariants).includes(teeSheetProvider);
  }

  /**
   * @param { string } teeSheetProvider
   * @returns { { [key: string]: string } } Collection of fields with default values
   */
  static getClubKeyValueStoreFields(teeSheetProvider) {
    let fields = {};
    if (this.hasClubKeyValueStore(teeSheetProvider)) {
      for (let field of ClubKeyValueStoreVariants[teeSheetProvider]) {
        fields[field.key] = field.default;
      }
    }

    return fields;
  }

  /**
   * @param { string } teeSheetProvider
   * @returns { { [key: string]: string } } Collection of fields with default values
   */
  static getCourseKeyValueStoreFields(teeSheetProvider) {
    let fields = {};
    if (this.hasCourseKeyValueStore(teeSheetProvider)) {
      for (let field of CourseKeyValueStoreVariants[teeSheetProvider]) {
        fields[field.key] = field.default;
      }
    }

    return fields;
  }
}
