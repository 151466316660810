// Vendor
import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import Select from "react-select";

// App
import { TOUR_OPERATORS } from "../../common/Queries";
import Loader from "./Loader";

/**
 * @param { Object } props
 * @param { string } [props.id="tour-operator-select"]
 * @param { string } props.label
 * @param { string[] } [props.value]
 * @param { VoidCallback<string[] | null>} props.onChange
 */
export function TourOperatorSelect(props) {
  /** @type {import("@apollo/client").QueryResult<TourOperatorData>} */
  const { loading, error, data, refetch } = useQuery(TOUR_OPERATORS);

  const value = useMemo(() => {
    return data?.tourOperators
      .filter((a) => props.value?.some((v) => v === a._id))
      .map((b) => ({ value: b._id, label: b.name }));
  }, [data, props.value]);

  if (loading) return <Loader />;
  if (error)
    return (
      <p className="text-danger">
        Could not load tour operators.{" "}
        <button color="link" type="button" onClick={refetch}>
          Retry
        </button>
      </p>
    );

  return (
    <>
      <label htmlFor={props.id}>{props.label}</label>
      <Select
        id={props.id}
        options={data.tourOperators.map((to) => ({
          value: to._id,
          label: to.name
        }))}
        value={value}
        onChange={(val) => props.onChange(val.map((v) => v.value))}
        isMulti
      />
    </>
  );
}

/**
 * @typedef TourOperatorData
 * @property { Object[] } tourOperators
 * @property { string } tourOperators[]._id
 * @property { string } tourOperators[].name
 * @property { number } tourOperators[].value
 * @property { string[] } tourOperators[].emails
 */
