import React, { useEffect, useReducer, useContext } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AvForm } from "availity-reactstrap-validation";
import DateRangeFacet from "../ui/facet/DateRangeFacet";
import {
    VOUCHER
} from "../../common/Queries";
import {
    Button,
    FormGroup
} from "reactstrap";
import voucherReducer from "./VoucherReducer";
import { InputField } from "../ui/FunctionalComponents";
import { UPDATE_VOUCHER, CREATE_VOUCHER } from "../../common/Mutations";
import { LayoutContext } from "../app/Layout";

const Voucher = (props) => {
    const { addAlert } = useContext(LayoutContext);
    const onSave = () => {
        const voucherInput = {
            code: state.code,
            value: parseFloat(state.value),
            startDate: state.startDate,
            endDate: state.endDate,
            comment: state.comment
        };

        state._id ? updateVoucher({ variables: { input: voucherInput, voucherId: state._id } }) : createVoucher({ variables: { input: voucherInput } });
    }

    const [state, dispatch] = useReducer(voucherReducer, {
        _id: "",
        code: "",
        value: "",
        startDate: moment().startOf("day").format("YYYY-MM-DD"),
        endDate: moment().endOf("day").add(1, "day").format("YYYY-MM-DD"),
        comment: "",
        loaded: false
    });

    const [updateVoucher, { loading: mutationLoading }] = useMutation(
        UPDATE_VOUCHER,
        {
            onCompleted: res => {
                if (!res.updateVoucher.ok) {
                    return addAlert({ color: "danger", message: "Could not save Voucher." });
                }
                dispatch({ type: "INIT", payload: res.updateVoucher.voucher });
                addAlert({
                    color: "success",
                    message: "Voucher updated successfully saved."
                });
            },
            onError: () =>
                addAlert({ color: "danger", message: "Could not save Voucher." })
        }
    );


    const [createVoucher, { loading: createLoading }] = useMutation(
        CREATE_VOUCHER,
        {
            onCompleted: res => {
                if (!res.createVoucher.ok) {
                    return addAlert({ color: "danger", message: "Could not create Voucher." });
                }
                dispatch({ type: "INIT", payload: res.createVoucher.voucher });
                addAlert({
                    color: "success",
                    message: "Voucher created successfully."
                });
            },
            onError: () =>
                addAlert({ color: "danger", message: "Could not create Voucher." })
        }
    );


    const onLoadingData = (data) => {
        dispatch({ type: "INIT", payload: data.voucher });
    }

    const [getVoucher] = useLazyQuery(VOUCHER, {
        onCompleted: onLoadingData
    });
    useEffect(() => {
        if (props.match.params.id && state._id !== props.match.params.id) {
            getVoucher({ variables: { id: props.match.params.id } });
        }

    }, [getVoucher, props.match.params.id, state._id]);

    if (props.match.params.id && !state.loaded) return null;

    return (
        <AvForm
            beforeSubmitValidation={event => event.stopPropagation()}
            onValidSubmit={onSave}
            className="Voucher"
        >
            <div className="row">
                <div className="col-md-4">
                    <label>Code</label>
                    <InputField
                        targetKey="code"
                        value={state.code}
                        onChange={changeEvent =>
                            dispatch({ type: "CODE", payload: changeEvent.value })
                        }
                        options={{ required: true, type: "string" }}
                    />
                </div>
                <div className="col-md-4">
                    <label>Value (Percentage)</label>
                    <InputField
                        targetKey="value"
                        value={state.value}
                        onChange={changeEvent =>
                            dispatch({ type: "VALUE", payload: changeEvent.value })
                        }
                        options={{ required: true, type: "number", max: 100, min: 0 }}
                    />
                </div>
                <div className="col-md-4">
                    <label>Comment</label>
                    <InputField
                        targetKey="comment"
                        value={state.comment}
                        onChange={changeEvent =>
                            dispatch({ type: "COMMENT", payload: changeEvent.value })
                        }
                        required={false}
                        options={{ type: "string" }}
                    />
                </div>
                <div className="col-md-4">
                    <label>Active range</label>
                    <DateRangeFacet
                        startDateId="startFrom"
                        endDateId="endFrom"
                        startDate={moment(state.startDate)}
                        endDate={moment(state.endDate)}
                        onDatesChange={(dateRange) => dispatch({ type: "DATE", payload: { startDate: dateRange.startDate, endDate: dateRange.endDate } })}
                    />
                </div>
            </div>
            <div className="Save-container">
                <div className="Save-container__phantom" />
                <div className="Save-container__main">
                    <FormGroup>
                        <Button disabled={mutationLoading || createLoading} size="lg" type="submit"> Save </Button>
                    </FormGroup>
                </div>
            </div>
        </AvForm >
    );

}

export default withRouter(Voucher);