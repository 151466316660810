// Vendor
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

// App
import useDebounce from "../../common/useDebounce";
import { useResizeObserver } from "../../hooks/useResizeObserver";

/**
 *
 * @param {*} props
 * @param { string } [props.className] Optional classname
 * @param { number } props.width Initial panel width
 * @param { number } props.height Initial panel height
 * @param { function } [props.onResize] Optional callback on panel resize. Called with argument ({ width, height })
 * @param { number } [props.options.minWidth=100] Minimum allowed width. Default 100px
 * @param { number } [props.options.minHeight=100] Minimum allowed height. Default 100px
 * @param { number } [props.options.maxWidth] Maximum allowed width. Default unrestricted
 * @param { number } [props.options.maxHeight] Maximum allowed height. Default unrestricted
 * @param { "both"|"horizontal"|"vertical" } [props.options.lockDirection] Allow only size change in set direction. Default "both". [ "both" | "horizontal" | "vertical" ]
 * @param { number } [props.options.onResizeDebounceDelay=200] Debounce onResize event delay in ms. Default 200ms
 */
function ResizablePanel({
  className = "",
  width,
  height,
  onResize,
  options = {
    minWidth: 100,
    minHeight: 100,
    maxWidth: undefined,
    maxHeight: undefined,
    lockDirection: "both",
    onResizeDebounceDelay: 200
  },
  children
}) {
  const defaultOpts = {
    minWidth: 100,
    minHeight: 100,
    maxWidth: undefined,
    maxHeight: undefined,
    lockDirection: "both",
    onResizeDebounceDelay: 100
  };
  options = { ...defaultOpts, ...options };

  // +2 added to all dimensions to account for 1px wide border
  const style = {
    width: `${options.width + 2}px`,
    height: `${options.height + 2}px`,
    minWidth: `${options.minWidth + 2}px`,
    minHeight: `${options.minHeight + 2}px`
  };
  if (options.maxWidth) style.maxWidth = `${options.maxWidth + 2}px`;
  if (options.maxHeight) style.maxHeight = `${options.maxHeight + 2}px`;

  const [dimensions, setDimension] = useState({ width, height });
  const debouncedDimensions = useDebounce(
    dimensions,
    options.onResizeDebounceDelay
  );
  const myRef = useRef();

  const observerCallback = entries => {
    for (let entry of entries) {
      if (entry.contentBoxSize) {
        // Firefox implements `contentBoxSize` as a single content rect, rather than an array
        const contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0]
          : entry.contentBoxSize;

        setDimension({
          width: contentBoxSize.inlineSize,
          height: contentBoxSize.blockSize
        });
      } else {
        setDimension({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        });
      }
    }
  };
  useResizeObserver(myRef, observerCallback);

  useEffect(() => {
    onResize(debouncedDimensions);
  }, [debouncedDimensions, onResize]);

  return (
    <div
      ref={myRef}
      className={`ResizablePanel ${options.lockDirection} ${className} border`}
      style={style}
    >
      {children}
    </div>
  );
}

ResizablePanel.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onResize: PropTypes.func,
  options: PropTypes.shape({
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    lockDirection: PropTypes.oneOf(["both", "horizontal", "vertical"]),
    onResizeDebounceDelay: PropTypes.number
  })
};

ResizablePanel.defaultProps = {
  className: "",
  onResize: () => {},
  options: {
    minWidth: 100,
    minHeight: 100,
    maxWidth: undefined,
    maxHeight: undefined,
    lockDirection: "both",
    onResizeDebounceDelay: 200
  }
};

export { ResizablePanel };
