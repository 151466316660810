// Vendor
import React from "react";
import { Card, CardBody, Col } from "reactstrap";

// App
import CardRow from "../../ui/CardRow";
import { TeeTimeDetails } from "./TeeTimeDetails";
import URLHelper from "../../../common/URLHelper";

/** @param { { course: OrderCourse } } props */
export function OrderCourseDetails({ course }) {
  const ErrorLabel = () => {
    let errorStatus = "ERROR";
    let anyErr = course.teetimes.some((tt) => tt.bookingErrors?.length > 0);
    if (!anyErr) return null;

    if (course.teetimes.some((tt) => !tt.bookingErrors?.length > 0))
      errorStatus = "PARTIAL";

    let textColor = errorStatus === "ERROR" ? "danger" : "warning";

    let text = errorStatus === "ERROR" ? "Failed" : "Partially failed";

    return (
      <p className={`text-uppercase font-weight-bold text-${textColor} mb-0`}>
        {text}
      </p>
    );
  };

  return (
    <Col md={6} lg={4} xl={3}>
      <Card className="p-2 mt-2">
        <CardBody className="text-center">
          <h3>
            <a
              href={`${window.location.origin}/club/${course.clubSlug}?course=${course.slug}`}
              rel="noopener noreferrer"
            >
              {course.name}
            </a>
          </h3>
          <ErrorLabel />

          <dl>
            <div className="d-flex justify-content-center">
              <CardRow label="Currency" value={course.currency} />
              <CardRow label="Total" value={course.total} />
              <CardRow label="Prepaid" value={course.payNowTotal} />
            </div>
            <div className="d-flex justify-content-center">
              {course.payNowTotal > 0 && (
                <CardRow label="Stripe service fee" value={course.stripeFee} />
              )}
              <CardRow label="VAT rate" value={course.vatRate} />
            </div>

            <CardRow label="Provider" value={course.teeSheetProvider} />
            <div className="d-flex justify-content-center">
              <CardRow
                label="Provider club ID"
                value={course.teeSheetClubId ?? "N/A"}
              />
              <CardRow
                label="Provider course ID"
                value={course.teeSheetCourseId ?? "N/A"}
              />
            </div>
          </dl>
          {course.teetimes.map((tt, index) => (
            <TeeTimeDetails
              localTimeZone={course.localTimeZone}
              teetime={tt}
              clubBookingId={course.clubBookingId}
              provider={course.teeSheetProvider}
              key={`tt-details-${index}`}
            />
          ))}
        </CardBody>
      </Card>
    </Col>
  );
}
