/**
 * Collection for all API providers
 */
const ProviderCollection = Object.freeze([
  { key: "NONE", label: "None" },
  { key: "CHRONO_GOLF", label: "Chronogolf" },
  { key: "CONCEPT", label: "Concept" },
  { key: "EZ_LINKS", label: "EZ Links" },
  { key: "GF365_SIMPLE", label: "GF365 Offline" },
  { key: "GOLFSPAIN", label: "Golfspain" },
  { key: "GOLF_MANAGER", label: "Golfmanager" },
  { key: "INTELLIGENT_GOLF", label: "Intelligent Golf" },
  { key: "PC_CADDIE", label: "PC Caddie" },
  { key: "SYS_GOLF", label: "SysGolf" },
  { key: "NETGOLF", label: "Netgolf" },
  { key: "NEXXCHANGE", label: "Nexxchange" },
  { key: "ZEST", label: "Zest" }
]);

export default ProviderCollection;
