// Vendor
import React, { useContext } from "react";
import { Row, Col } from "reactstrap";

// App
import { TeeTimeTableContext } from "../TeeTimeTable";
import { InputField } from "../../../../ui/FunctionalComponents";
import TimeDropdownGroup from "../../../../ui/TimeDropdownGroup";
import { DayAvailabilityList } from "./DayAvailabilityList";

export function DefaultAvailabilitySettings() {
  const { teeTimeTable, dispatch } = useContext(TeeTimeTableContext);

  return (
    <div className="mt-3">
      <h6>Default settings</h6>
      <hr />
      <div className="d-flex flex-column">
        <Row>
          <Col md={9}>
            <InputField
              targetKey="interval"
              label="Interval(minutes between each flight)"
              type="number"
              value={teeTimeTable.interval}
              onChange={event =>
                dispatch({ type: "INTERVAL", payload: event.value })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TimeDropdownGroup
              label="Start time"
              date={teeTimeTable.startTime}
              validateDate={teeTimeTable.endTime}
              field="STARTTIME"
              className="TimeTable__start-time-input mr-2"
              onChange={event =>
                dispatch({ type: event.field, payload: event.value })
              }
            />
            <TimeDropdownGroup
              label="End time"
              field="ENDTIME"
              date={teeTimeTable.endTime}
              validateDate={teeTimeTable.startTime}
              validateBefore
              className="TimeTable__end-time-input"
              onChange={event =>
                dispatch({ type: event.field, payload: event.value })
              }
            />
          </Col>
        </Row>

        <div className="mt-2">
          <h6>Custom fallback availability</h6>
          <DayAvailabilityList
            availabilityList={teeTimeTable?.daySplitOverride ?? []}
            onChange={value =>
              dispatch({ type: "DAY_SPLIT_OVERRIDE_UPDATE", payload: value })
            }
          />
        </div>
      </div>
    </div>
  );
}
