// Vendor
import React from "react";

// App
import { PartnerNetworkContext } from "../PartnerNetwork";
import { AddEmails } from "../../ui/table-utils/AddEmails";

export function PartnerNetworkAddEmails() {
  PartnerNetworkContext.displayName = "PartnerNetwork";
  return <AddEmails context={PartnerNetworkContext} />;
}
