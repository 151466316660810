import React from "react";
import { Badge } from "reactstrap";

/**
 * @param { Object } season Season or SeasonType object
 * @param { Object } seasonType object
 * @param { name } season.name
 */
export function SeasonBadge({ season }) {
  const name = season.seasonType?.name ?? season.name;
  const color = season.color || season.seasonType?.color;
  return (
    // Badge color prop supports only bootstrap default classes so inline style is must
    <Badge style={{ backgroundColor: color }} className="badge">
      {name}
    </Badge>
  );
}
