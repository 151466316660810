// vendor
import { Image } from "cloudinary-react";
import { graphql } from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// app
import { IMAGES_DELETE, IMAGE_RENAME } from "../../../../common/Mutations";
import IdGeneratorHelper from "../../../../common/IdGeneratorHelper";
import Loader from "../../../ui/Loader";
import { InputField } from "../../../ui/FunctionalComponents";

function ClubCourseImageRow(props) {
  function getTitle(publicId) {
    return publicId.substring(publicId.lastIndexOf("/") + 1);
  }

  async function deleteImage(publicId) {
    const { ok } = (await props.deleteImages({
      variables: {
        input: {
          publicIdList: [publicId]
        }
      }
    })).data.deleteImages;
    if (ok) setDeleted(true);
    props.onChange({ name: "delete", success: ok });
  }

  async function renameImage() {
    const { publicId } = image;
    const path = publicId.substring(0, publicId.lastIndexOf("/") + 1);
    const newPublicId = path + newTitle;

    // show loader
    setLoading(true);

    const { ok } = (await props.renameImage({
      variables: {
        input: {
          publicId,
          newPublicId
        }
      }
    })).data.renameImage;

    // hide loader
    setLoading(false);

    props.onChange({ name: "rename", success: ok });
    toggleEdit();
  }

  function toggle() {
    setPopoverOpen(!popoverOpen);
  }

  function toggleEdit() {
    setTitle(newTitle);
    setEditing(!editing);
  }

  const { image, imageSize } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);

  const [title, setTitle] = useState(getTitle(image.publicId));
  const [newTitle, setNewTitle] = useState(getTitle(image.publicId));

  useEffect(() => {
    setId(IdGeneratorHelper.getRandomId());
  }, []);

  if (deleted) return null;

  return (
    <tr>
      <td className="align-middle" style={{ width: imageSize + 20 }}>
        <Image
          publicId={image.publicId}
          width={imageSize}
          height={imageSize}
          crop="fill"
          radius="max"
        />
      </td>
      <td className="text-left align-middle">{title}</td>
      <td className="text-muted align-middle">
        {image.width} x {image.height} px
      </td>
      <td className="text-muted text-uppercase align-middle">{image.format}</td>
      <td className="text-muted align-middle">{`${Math.round(
        image.bytes / 1000
      )} kB`}</td>
      <td className="align-middle" style={{ whiteSpace: "nowrap" }}>
        <Button color="link" type="button" onClick={toggleEdit} size="sm">
          <FontAwesomeIcon icon="pencil-alt" />
        </Button>
        <Button
          color="link"
          className="text-danger"
          type="button"
          id={id}
          size="sm"
        >
          <FontAwesomeIcon icon="trash-alt" />
        </Button>
        {id && (
          <Popover
            isOpen={popoverOpen}
            toggle={toggle}
            placement="left"
            target={id}
          >
            <PopoverBody>
              Are you sure?
              <Button
                className="ml-2"
                color="danger"
                size="sm"
                onClick={() => {
                  toggle();
                  deleteImage(image.publicId);
                }}
              >
                Yes
              </Button>
            </PopoverBody>
          </Popover>
        )}
        <Modal isOpen={editing} toggle={toggleEdit}>
          <AvForm
            beforeSubmitValidation={event => event.stopPropagation()}
            onValidSubmit={renameImage}
          >
            <ModalHeader toggle={toggleEdit}>Edit Image</ModalHeader>
            <ModalBody>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Image
                    publicId={image.publicId}
                    responsive
                    width="auto"
                    crop="scale"
                    className="w-100"
                  />
                  <InputField
                    containerClassName="mt-3 mb-0"
                    targetKey="course-image-title"
                    label="Image title"
                    value={newTitle}
                    onChange={res => setNewTitle(res.value)}
                  />
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="light" outline onClick={toggleEdit}>
                Cancel
              </Button>
              <FormGroup className="mb-0">
                <Button disabled={newTitle === title} color="secondary">
                  Save
                </Button>
              </FormGroup>
            </ModalFooter>
          </AvForm>
        </Modal>
      </td>
    </tr>
  );
}

const ClubCourseImageRowWithMutation = compose(
  graphql(IMAGES_DELETE, {
    name: "deleteImages"
  }),
  graphql(IMAGE_RENAME, {
    name: "renameImage"
  })
)(ClubCourseImageRow);

export default ClubCourseImageRowWithMutation;
