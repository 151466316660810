import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useContext, useState } from "react";
import { Button, FormGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import { LayoutContext } from "../../app/Layout";
import { useMutation } from "@apollo/client";
import { UPDATE_VIRTUAL_CARD } from "../../../common/Mutations";
import { CardStatusCollection } from "../../../common/AmadeusCollections";

const FreezeCardModal = ({
  isOpen,
  toggle,
  amadeusReference,
  externalReference,
  defaultStatus,
  cardData
}) => {
  const { addAlert } = useContext(LayoutContext);
  const [status, setStatus] = useState();

  const handleSubmit = (event, errors) => {
    if (errors.length === 0) {
      if (status === defaultStatus) {
        addAlert({
          color: "danger",
          message: "Selected status cannot be the same as current status"
        });
      } else {
        UpdateVritualCard({
          variables: {
            payload: {
              VirtualCard: {
                References: [
                  {
                    Reference: {
                      Type: "External",
                      _Reference: externalReference
                    }
                  }
                  //We do not use this reference anymore. There is no need for it.
                  // {
                  //   Reference: {
                  //     Type: "Amadeus",
                  //     _Reference: amadeusReference
                  //   }
                  // }
                ],
                CardStatus: status
              }
            }
          }
        });
      }
    }
  };
  const onUpdateCardCompleted = (data) => {
    if (data.UpdateVirtualCard.ok) {
      addAlert({ color: "success", message: "Successfully Updated card" });
      window.location.reload(true); // refresh the page
      return toggle();
    }
    addAlert({
      color: "danger",
      message:
        data.UpdateVirtualCard.returnMessage || "Error while freezing card"
    });
  };
  const onUpdateCardError = (er) => {
    addAlert({ color: "danger", message: "Error while freezing card" });
  };

  // mutation
  const [UpdateVritualCard] = useMutation(UPDATE_VIRTUAL_CARD, {
    onCompleted: onUpdateCardCompleted,
    onError: onUpdateCardError
  });
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Freeze Card</ModalHeader>
      <ModalBody>
        <AvForm onSubmit={handleSubmit}>
          <h4>Current card status is: {defaultStatus}</h4>
          <FormGroup>
            <AvField
              label="State"
              type="select"
              name="state"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please select a status"
                }
              }}
            >
              {CardStatusCollection.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={option.value === "DELETED"}
                >
                  {option.label}
                </option>
              ))}
            </AvField>
          </FormGroup>
          <div className="w-100 d-flex justify-content-end">
            <Button type="submit" color="primary">
              Freeze
            </Button>
            <Button color="secondary" onClick={toggle} className="ml-2">
              Cancel
            </Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default FreezeCardModal;
