// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";
import React, { Component } from "react";

// App
import pullCart from "../../../images/amenities/pull-cart.svg";
import buggy from "../../../images/amenities/buggy.svg";
import caddie from "../../../images/amenities/caddie.svg";
import practice from "../../../images/amenities/practice.svg";
import clubsRental from "../../../images/amenities/clubs-rental.svg";
import IdGeneratorHelper from "../../../common/IdGeneratorHelper";

class ClubCourseAmenity extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func
  };

  static defaultProps = {
    onClick: () => {}
  };

  constructor(props) {
    super(props);
    const { name, active } = this.props;
    this.state = {
      name,
      active
    };

    this.id = IdGeneratorHelper.getRandomId();
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.state.active) {
      this.setState({ active: nextProps.active });
    }
  }

  handleCheckboxChange() {
    this.setState(
      prevProps => ({ active: !prevProps.active }),
      () => {
        this.props.onClick({
          name: this.state.name,
          active: this.state.active
        });
      }
    );
  }

  getIcon(title) {
    switch (title) {
      // switch (this.props.slug) {
      case "Buggy":
        // case "buggy":
        return <SVG className="d-inline-block" src={buggy} />;
      case "Caddie hire":
        // case "caddie-hire":
        return <SVG className="d-inline-block" src={caddie} />;
      case "Changing room":
        // case "changing-room":
        return <FontAwesomeIcon fixedWidth icon="shower" title={title} />;
      case "Chipping green":
        // case "chipping-green":
        return <SVG className="d-inline-block" src={practice} />;
      case "Driving range":
        // case "driving-range":
        return <FontAwesomeIcon fixedWidth icon="golf-ball" title={title} />;
      case "Golf clubs rental":
        // case "golf-clubs-rental":
        return <SVG className="d-inline-block" src={clubsRental} />;
      case "Golf lessons":
        // case "golf-lessons":
        return (
          <FontAwesomeIcon fixedWidth icon="user-graduate" title={title} />
        );
      case "Lockers":
        // case "lockers":
        return <FontAwesomeIcon fixedWidth icon="shower" title={title} />;
      case "Practice bunker":
        // case "practice-bunker":
        return <SVG className="d-inline-block" src={practice} />;
      case "Pro shop":
        // case "pro-shop":
        return <FontAwesomeIcon fixedWidth icon="store" title={title} />;
      case "Pull cart":
        // case "pull-cart":
        return <SVG className="d-inline-block" src={pullCart} />;
      case "Putting green":
        // case "putting-green":
        return <SVG className="d-inline-block" src={practice} />;
      case "Reception hall":
        // case "reception-hall":
        return (
          <FontAwesomeIcon fixedWidth icon="concierge-bell" title={title} />
        );
      case "Restaurant":
        // case "restaurant":
        return <FontAwesomeIcon fixedWidth icon="utensils" title={title} />;
      default:
        return <SVG className="d-inline-block" src={practice} />;
    }
  }

  render() {
    const { name, active } = this.state;
    return (
      <div className="amenity d-block">
        <span className="amenity-icon mx-auto d-inline-block">
          {this.getIcon(name)}
        </span>
        <span className="amenity-name d-inline-block mt-1 mt-md-0 ml-3 ml-md-0">
          {name}
        </span>
        <div className="checkbox-group d-inline-block float-right pt-1">
          <input
            id={this.id}
            type="checkbox"
            checked={active}
            onClick={this.handleCheckboxChange}
            readOnly
          />
          <label htmlFor={this.id} />
        </div>
      </div>
    );
  }
}

export default ClubCourseAmenity;
