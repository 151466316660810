// Vendor
import React, { useState, useReducer, useEffect, useContext } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { AvForm } from "availity-reactstrap-validation";
import { Button, FormGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { GOLF_AREA_BY_SLUG, LINKABLE_COURSES } from "../../common/Queries";
import Loader from "../ui/Loader";
import GolfDestinationReducer from "./GolfDestinationReducer";
import { LayoutContext } from "../app/Layout";
import {
  UPDATE_GOLF_AREA,
  CREATE_GOLF_AREA,
  DELETE_GOLF_AREA
} from "../../common/Mutations";
import NoMatch from "../ui/NoMatch";
import DeleteModal from "../ui/DeleteModal";
import LocaleHelper from "../../common/LocaleHelper";
import { SingleTranslationTable } from "../ui/TranslationTable/SingleTranslationTable";
import SelectTable from "../ui/SelectTable";
import HighlightList from "../ui/Highlights/HighlightList";

// Feature
import CategoryList from "./categories/CategoryList";
import CityList from "./categories/CityList";
import GeneralInfo from "./categories/GeneralInfo";
import KeyFigureList from "./categories/KeyFigureList";
export const GolfDestinationContext = React.createContext();

const i18nFallback = LocaleHelper.generateI18nObject([
  "description",
  "summary"
]);
function GolfDestination(props) {
  function init(data) {
    let destination;
    if (!data)
      destination = {
        coordinates: {
          latitude: 0.0,
          longitude: 0.0
        },
        keyFigures: {
          courses: 0,
          sunnyDays: 0,
          taxiPrice: 0.0,
          beerPrice: 0.0
        },
        categories: [],
        published: false,
        highlights: []
      };
    else destination = data.GolfArea;

    dispatch({ type: "INIT", payload: destination });
  }

  function handleSave() {
    const areaId = state._id;
    let input = { ...state };

    delete input.active;

    if (input.fullCoursesObjects) {
      input.courses = input.fullCoursesObjects.map((c) => c._id);
      delete input.fullCoursesObjects;
    }
    // not part of schema
    delete input._id;

    if (slug)
      updateGolfDestination({
        variables: {
          input,
          areaId
        }
      });
    else createGolfDestination({ variables: { input } });
  }
  function handleSaveCompleted(data) {
    if (data.ok) {
      setHasChanged(false);
      // Update URL if slug has changed
      if (data.golfArea.slug !== slug)
        props.history.replace(`/golf-destination/${data.golfArea.slug}`);

      dispatch({ type: "INIT", payload: data.golfArea });
      addAlert({
        color: "success",
        message: "Golf destination successfully saved"
      });
    } else {
      handleSaveError();
    }
  }

  function handleSaveError() {
    addAlert({
      color: "danger",
      message: "Failed to save golf destination"
    });
  }

  function redirectWarning() {
    if (hasChanged) return "This will discard all unsaved edits. Are you sure?";
  }

  // Context
  const { addAlert } = useContext(LayoutContext);

  // Reducer
  const [state, dispatch] = useReducer(GolfDestinationReducer, null);

  function middleware(event) {
    if (!hasChanged) setHasChanged(true);
    dispatch(event);
  }
  const { slug } = props.match.params;

  const [getDestination, { loading, error }] = useLazyQuery(GOLF_AREA_BY_SLUG, {
    variables: { slug },
    onCompleted: init
  });

  // Mutation
  const [updateGolfDestination, { loading: updateDestinationLoading }] =
    useMutation(UPDATE_GOLF_AREA, {
      onCompleted: (data) => handleSaveCompleted(data.updateGolfArea),
      onError: handleSaveError
    });
  const [createGolfDestination, { loading: createDestinationLoading }] =
    useMutation(CREATE_GOLF_AREA, {
      onCompleted: (data) => handleSaveCompleted(data.createGolfArea),
      onError: handleSaveError
    });
  const mutationLoading = updateDestinationLoading || createDestinationLoading;

  const [hasChanged, setHasChanged] = useState(false);
  const [description] = useState("");
  const [summary] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // effects
  useEffect(() => {
    window.onbeforeunload = redirectWarning;

    if (slug) getDestination();
    else init(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  if (loading) return <Loader fullscreen={true} />;
  if (error) return <NoMatch />;

  if (!state) return null;

  return (
    <GolfDestinationContext.Provider value={{ dispatch: middleware, state }}>
      <DeleteModal
        isOpen={modalOpen}
        toggle={toggleModal}
        mutation={DELETE_GOLF_AREA}
        variables={{ areaId: state._id }}
        redirect={{ pathname: "/golf-destinations", search: "?deleted=true" }}
      />
      <div className="page">
        <h1>Golf Destination</h1>
        <AvForm
          onValidSubmit={handleSave}
          onInvalidSubmit={() =>
            addAlert({ color: "danger", message: "Invalid inputs." })
          }
        >
          <GeneralInfo />

          <div className="mb-5">
            <h3 className="mb-2">Description</h3>
            <SingleTranslationTable
              targetKey="description"
              placeholderText="Description"
              value={state.description || description}
              i18n={state.i18n || i18nFallback}
              onChange={(event) => {
                const type =
                  event.key === "description" ? "DESCRIPTION" : "I18N";
                middleware({ type, payload: event.value });
              }}
            />
          </div>

          <div className="mb-5">
            <h3 className="mb-2">Summary</h3>
            <SingleTranslationTable
              targetKey="summary"
              placeholderText="Summary"
              value={state.summary || summary}
              i18n={state.i18n || i18nFallback}
              onChange={(event) => {
                const type = event.key === "summary" ? "SUMMARY" : "I18N";
                middleware({ type, payload: event.value });
              }}
            />
          </div>
          <KeyFigureList />
          <CategoryList
            categories={state.categories}
            onChange={(event) =>
              middleware({ type: "CATEGORIES", payload: event.value })
            }
          />

          <SelectTable
            onChange={(value) =>
              middleware({ type: "FULL_COURSE_OBJECTS", payload: value })
            }
            selectQuery={LINKABLE_COURSES}
            listItems={state.fullCoursesObjects}
            selectContainerClass="col-6"
            listContainerClass="col-12"
            title="Golf Courses"
          />

          <div className="Save-container">
            <div className="Save-container__phantom" />
            <div className="Save-container__main">
              <FormGroup>
                <Button
                  size="lg"
                  color={hasChanged ? "secondary" : "gray"}
                  disabled={!hasChanged || mutationLoading}
                  type="submit"
                >
                  Save
                </Button>
              </FormGroup>
            </div>
          </div>
        </AvForm>
        <CityList />
        <HighlightList
          highlights={state.highlights}
          onChange={(newHighlightList) =>
            middleware({ type: "HIGHLIGHTS", payload: newHighlightList })
          }
        />
        <div className="d-flex w-100 p-1">
          <Button
            className="btn-lg mx-auto mb-sm-4"
            color="danger"
            type="button"
            onClick={toggleModal}
            disabled={!state._id}
          >
            <FontAwesomeIcon icon="trash-alt" className="mr-2" />
            Remove golf destination
          </Button>
        </div>
      </div>
    </GolfDestinationContext.Provider>
  );
}
export default GolfDestination;
