// Vendor
import React, { useContext, useState, useEffect } from "react";
import { Table, Button, FormGroup, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { TeeTimeTableContext } from "../TeeTimeTable";
import { DayAvailabilityList } from "./DayAvailabilityList";
import { WeekdayCollection } from "../collections/WeekdayCollection";

export function WeekdayOverrideList() {
  const { teeTimeTable, dispatch } = useContext(TeeTimeTableContext);
  const [coveredDays, setCoveredDays] = useState([]);

  useEffect(() => {
    let usedDays =
      teeTimeTable.weekdayOverride?.reduce(
        (acc, override) => acc.concat(override.days),
        []
      ) ?? [];

    setCoveredDays(usedDays);
  }, [teeTimeTable.weekdayOverride]);

  function handleOnChange(itemIndex, field, value) {
    let objectCopy = { ...teeTimeTable.weekdayOverride[itemIndex] };
    objectCopy[field] = value;
    dispatch({
      type: "WEEKDAY_OVERRIDE_UPDATE",
      payload: {
        index: itemIndex,
        value: objectCopy
      }
    });
  }

  function handleAddOverride() {
    dispatch({
      type: "WEEKDAY_OVERRIDE_ADD",
      payload: {
        closed: true,
        days: [],
        dayAvailability: []
      }
    });
  }

  function handleWeekdayCheckedOnChange(index, day, checked) {
    let daysCopy = [...teeTimeTable.weekdayOverride[index].days];
    if (checked && !daysCopy.some(d => d === day)) {
      daysCopy.push(day);
    } else {
      daysCopy = daysCopy.filter(d => d !== day);
    }

    handleOnChange(index, "days", daysCopy);
  }

  return (
    <div className="mt-3">
      <h6>Day overrides</h6>
      <hr />
      <Table className="border-0">
        {teeTimeTable.weekdayOverride?.length > 0 && (
          <thead>
            <tr>
              <th>Closed</th>
              <th>Availability</th>
              <th>Days affected</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
        )}
        <tbody
          className={teeTimeTable.weekdayOverride?.length > 0 ? "border" : ""}
        >
          {!teeTimeTable.weekdayOverride?.length && (
            <tr>
              <td className="text-muted text-center border-0">
                No items added
              </td>
            </tr>
          )}
          {teeTimeTable.weekdayOverride?.map((override, index) => (
            <tr key={index}>
              <td className="Col--shrink">
                <div className="d-block pl-2">
                  {/* Invisible label for alignment purposes */}
                  <label className="text-white">Padding</label>
                  <div className="checkbox-group">
                    <input
                      id={`weekday-override-is-closed-${index}`}
                      name={`weekday-override-is-closed-${index}`}
                      type="checkbox"
                      checked={override.closed}
                      onChange={event =>
                        handleOnChange(index, "closed", event.target.checked)
                      }
                    />
                    <label
                      htmlFor={`weekday-override-is-closed-${index}`}
                      className="mb-0"
                    ></label>
                  </div>
                </div>
              </td>
              <td>
                <DayAvailabilityList
                  availabilityList={override.dayAvailability ?? []}
                  onChange={value =>
                    handleOnChange(index, "dayAvailability", value)
                  }
                />
              </td>
              <td>
                <div className="d-block">
                  <FormGroup tag="fieldset" className="text-left">
                    {WeekdayCollection.map((day, i) => (
                      <FormGroup check key={i}>
                        <Label check>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={`weekday-check-${day.value.toLowerCase()}`}
                            checked={override.days.some(d => d === day.value)}
                            disabled={
                              !override.days.some(d => d === day.value) &&
                              coveredDays.some(d => d === day.value)
                            }
                            onChange={event =>
                              handleWeekdayCheckedOnChange(
                                index,
                                day.value,
                                event.target.value
                              )
                            }
                          />
                          {day.label}
                        </Label>
                      </FormGroup>
                    ))}
                  </FormGroup>
                </div>
              </td>
              <td>
                <Button
                  color="link"
                  className="text-danger pt-3"
                  id={`delete-day-override-${index}`}
                  onClick={() =>
                    dispatch({
                      type: "WEEKDAY_OVERRIDE_REMOVE",
                      payload: index
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon="trash-alt"
                    size="lg"
                    className="mr-1"
                  />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button color="link" onClick={handleAddOverride}>
        + Add day override
      </Button>
    </div>
  );
}
