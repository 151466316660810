export default function (state, action) {
  switch (action.type) {
    case "INIT_TEMPLATES":
      return { ...state, templates: action.payload };
    case "INIT_RECIPIENTS":
      return { ...state, recipients: action.payload };
    case "ADD_RECIPIENT": {
      return {
        ...state,
        recipients: [...state.recipients, action.payload]
      };
    }
    case "UPDATE_RECIPIENT": {
      const recipient = action.payload;

      const recipientCopy = state.recipients.map((recipientCopy) => {
        if (recipient._id === recipientCopy._id) {
          return recipient;
        }
        return recipientCopy;
      });
      return { ...state, recipients: recipientCopy };
    }
    case "ADD_DUMMY_TEMPLATE": {
      // adding a template with only name: "New template"
      return { ...state, templates: [...state.templates, action.payload] };
    }
    case "UPDATE_DUMMY_TEMPLATE": {
      const template = action.payload;
      const copyTemplates = state.templates.map((copyTemplate) => {
        if (!copyTemplate._id) {
          return template;
        }
        return copyTemplate;
      });
      return { ...state, templates: copyTemplates };
    }
    case "UPDATE_TEMPLATE": {
      const template = action.payload;
      const copyTemplates = state.templates.map((copyTemplate) => {
        if (template._id === copyTemplate._id) {
          return template;
        }
        return copyTemplate;
      });
      return { ...state, templates: copyTemplates };
    }
    case "DELETE_RECIPIENT": {
      const recipientId = action.payload;
      //filter out the deleted recipient
      const recipientsCopy = state.recipients.filter((recipientCopy) => {
        return recipientCopy._id !== recipientId;
      });

      //filter out the deleted recipient from the templates as well
      const templatesCopy = state.templates.map((templateCopy) => {
        // going through the recipientList of each template and removing the deleted recipient if it is there
        if (templateCopy.recipientList.includes(recipientId)) {
          const recipientListCopy = templateCopy.recipientList.filter(
            (idCopy) => {
              return idCopy !== recipientId;
            }
          );
          return {
            ...templateCopy,
            recipientList: recipientListCopy
          };
        } else {
          // if the deleted recipient is not in the recipientList of the template, just return the template
          return templateCopy;
        }
      });
      return { templates: templatesCopy, recipients: recipientsCopy };
    }
    case "DELETE_TEMPLATE": {
      const templateId = action.payload;
      const templatesCopy = state.templates.filter((templateCopy) => {
        return templateCopy._id !== templateId;
      });
      return { ...state, templates: templatesCopy };
    }
    default:
      return state;
  }
}
