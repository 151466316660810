/**
 * @param {*} state Current context state
 * @param {{ type: string, payload: any }} action
 */
export function rateMismatchReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        missingRateCourses: action.payload,
        providers: action.payload.reduce(
          (acc, course) =>
            acc.includes(course.provider) ? acc : [...acc, course.provider],
          []
        )
      };
    case "FILTER_TEXT":
      return {
        ...state,
        filter: {
          muteFilter: state.filter.muteFilter,
          regexFilter: action.payload,
          providerFilter: state.filter.providerFilter
        }
      };
    case "FILTER_MUTE":
      return {
        ...state,
        filter: {
          muteFilter: action.payload,
          regexFilter: state.filter.regexFilter,
          providerFilter: state.filter.providerFilter
        }
      };
    case "FILTER_PROVIDER":
      return {
        ...state,
        filter: {
          muteFilter: state.filter.muteFilter,
          regexFilter: state.filter.regexFilter,
          providerFilter: action.payload
        }
      };
    case "MISMATCH_MUTE":
      return {
        ...state,
        missingRateCourses: state.missingRateCourses.map(course => ({
          ...course,
          missingRateSchedules: course.missingRateSchedules.map(schedule => ({
            ...schedule,
            missingRates: schedule.missingRates.map(rate =>
              rate._id === action.payload
                ? { ...rate, muted: !rate.muted }
                : rate
            )
          }))
        }))
      };
    case "MISMATCH_DELETE":
      return {
        ...state,
        missingRateCourses: state.missingRateCourses.map(course => ({
          ...course,
          missingRateSchedules: course.missingRateSchedules.map(schedule => ({
            ...schedule,
            missingRates: schedule.missingRates.filter(
              rate => rate._id !== action.payload
            )
          }))
        }))
      };
    default:
      return state;
  }
}
