// Vendor
import moment from "moment";
import React, { Fragment, useContext } from "react";
import { Button, Table, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import { AvForm } from "availity-reactstrap-validation";

// App
import { SeasonContext } from "../../Season";
import SeasonRatePriceCollection from "../../collections/SeasonRatePriceCollection";
import RatesHelper from "../../../../../../common/RatesHelper";
import EditCells from "./categories/EditCells";
import ReadCells from "./categories/ReadCells";
import { SeasonScheduleContext } from "../SeasonSchedule";
import { ClubCourseContext } from "../../../ClubCourse";

/**
 * @param { Object } props
 * @param { ScheduleType } props.scheduleType
 * @param { "18H" | "9H" } props.roundType
 */
function SeasonTable(props) {
  function cancelEdit() {
    toggleEdit();
    seasonScheduleDispatch({ type: "RESET" });
  }

  /**
   * @param { Rate[] } rates
   * @param { RateType } rateType
   * @param { DayType } dayType
   * @returns { RatePrice }
   */
  function getRatePrice(rates, rateType, dayType) {
    let ratePrices = rates
      .filter((rate) => {
        return (
          rate.dayType.type === dayType &&
          rate.scheduleType === props.scheduleType
        );
      })
      .map((rate) => rate.prices);
    let flattened = [].concat(...ratePrices);
    return flattened.find((price) => price.rateType === rateType);
  }

  const isCommissionValid = (type, value) => {
    if (
      (type === "FIXED" || type === "PERCENTAGE") &&
      typeof value === "number" &&
      value > 0
    ) {
      return true;
    }
    return false;
  };

  function handleTableChange(event) {
    const { dayType, rateType, key, value } = event;
    const { scheduleType } = props;
    const ratePrice = getRatePrice(currentState, rateType, dayType);
    let rate = RatesHelper.find(currentState, { dayType, scheduleType });
    let newEdits = [...currentState];
    // No rate exists so we create it
    if (!rate) {
      const multiplier = SeasonRatePriceCollection.find(
        (ratePrice) => ratePrice.rateType === rateType
      )?.multiplier;
      newEdits.push({
        dayType,
        scheduleType,
        prices: [
          {
            [key]: value,
            rateType,
            multiplier
          }
        ],
        buggyIncluded: false,
        startDate: moment(season.startDate).format(),
        endDate: moment(season.endDate).format()
      });
    }
    // A rate exists so we can either edit the current ratePrice or add a new ratePrice
    else {
      let rateIndex = currentState.indexOf(rate);
      let ratePriceIndex =
        rateIndex > -1 ? currentState[rateIndex].prices.indexOf(ratePrice) : -1;

      // replace rate price
      if (ratePriceIndex > -1) {
        rate.prices[ratePriceIndex] = { ...ratePrice, [key]: value };
      }
      // add rate price
      else {
        const multiplier = SeasonRatePriceCollection.find(
          (ratePrice) => ratePrice.rateType === rateType
        )?.multiplier;
        rate.prices.push({
          [key]: value,
          rateType,
          multiplier
        });
      }
      // replace rate
      newEdits[rateIndex] = rate;
    }

    seasonScheduleDispatch({ type: "UPDATE", payload: newEdits });
  }

  function handleValidSubmit() {
    const { scheduleType } = props;
    if (invalidFields.length) {
      alert("Invalid fields error");
      return;
    }
    toggleEdit();

    let ratesToSubmit = RatesHelper.filter(currentState, { scheduleType });

    ratesToSubmit = ratesToSubmit.map((rate) => ({
      ...rate,
      prices: rate.prices
      .filter((price) => RatesHelper.isValidNumericProperty(price, "price"))
      .filter((price) => RatesHelper.isValidNumericProperty(price, "rackPrice"))
      .filter((price) => RatesHelper.isValidNumericProperty(price, "multiplier"))
    }));

    // fill commissions only if course commissions are valid
    if (isCommissionValid(courseState.commissionType, courseState.commission))
      ratesToSubmit = RatesHelper.fillCommissions(ratesToSubmit, {
        commissionType: courseState.commissionType,
        commission: courseState.commission
      });

    seasonDispatch({
      type: `SET_${scheduleType}_RATES`,
      payload: ratesToSubmit
    });
  }

  function toggleEdit() {
    setEditing(!editing);
  }

  // Contexts
  const { dispatch: seasonDispatch, season } = useContext(SeasonContext);

  const {
    editing,
    setEditing,
    invalidFields,
    enabledDayTypes,
    state: { master, currentState },
    dispatch: seasonScheduleDispatch
  } = useContext(SeasonScheduleContext);

  const { state: courseState } = useContext(ClubCourseContext);

  if (!master) return null;

  const activeRatePrices =
    props.roundType === "18H"
      ? SeasonRatePriceCollection.filter((rp) => !/.*_9H$/.test(rp.rateType))
      : SeasonRatePriceCollection.filter((rp) => /.*_9H$/.test(rp.rateType));
  return (
    <AvForm
      beforeSubmitValidation={(event) => event.stopPropagation()}
      className="SeasonTable"
      onValidSubmit={handleValidSubmit}
    >
      <Table responsive className="border-0">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>External IDs</th>
            <th>Sell price</th>
            <th>Rack price</th>
            <th>Multiplier</th>
            <th>Dyn.</th>
            <th>Add Markup</th>
            <th>Commision value</th>
            <th>Commision type</th>
            <th>Overridable by course</th>
          </tr>
        </thead>
        <tbody className="border">
          {activeRatePrices.map((price) => (
            <Fragment key={price.rateType}>
              <tr>
                <td className="bg-light p-2" colSpan="10">
                  {price.name}
                </td>
              </tr>
              {enabledDayTypes?.map((dayType) => (
                <tr key={dayType._id}>
                  {editing ? (
                    <EditCells
                      onChange={(event) => {
                        handleTableChange({
                          rateType: price.rateType,
                          dayType: dayType.type,
                          key: event.key,
                          value: event.value
                        });
                      }}
                      ratePriceName={price.name}
                      ratePrice={getRatePrice(
                        currentState,
                        price.rateType,
                        dayType.type
                      )}
                      dayType={dayType}
                    />
                  ) : (
                    <ReadCells
                      ratePrice={getRatePrice(
                        master,
                        price.rateType,
                        dayType.type
                      )}
                      dayType={dayType}
                    />
                  )}
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </Table>
      <div>
        {!editing && enabledDayTypes.length > 0 && (
          <Button color="link" onClick={toggleEdit}>
            Edit
          </Button>
        )}
        {editing && (
          <>
            <FormGroup>
              <Button color="link" className="mr-2" onClick={cancelEdit}>
                Cancel
              </Button>
              <Button disabled={invalidFields.length > 0} color="secondary">
                OK
              </Button>
            </FormGroup>
          </>
        )}
      </div>
    </AvForm>
  );
}

SeasonTable.propTypes = {
  sheduleType: PropTypes.oneOf(["STANDARD", "WEEKEND"]),
  roundType: PropTypes.oneOf(["18H", "9H"])
};

export default SeasonTable;
