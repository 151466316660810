// Vendor
import React, { Component } from "react";

class NoMatch extends Component {
  render() {
    return (
      <div className="NoMatch">
        <h1>Page Not Found</h1>
        <p className="text-black-50">
          The page you are trying to reach does not exist.
        </p>
      </div>
    );
  }
}

export default NoMatch;
