export const getHours = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push({
      label: `${i}`.padStart(2, "0"),
      value: i
    });
  }
  return hours;
};

export const getMinutes = () => {
  const minutes = [];
  for (let i = 0; i < 60; i++) {
    minutes.push({
      label: `${i}`.padStart(2, "0"),
      value: i
    });
  }
  return minutes;
};
