// Vendor
import React, { useEffect, useReducer, useContext, createContext } from "react";
import { withRouter } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AvForm } from "availity-reactstrap-validation";
import { Button, FormGroup } from "reactstrap";

// App
import { TOUR_OPERATOR } from "../../common/Queries";
import {
  UPDATE_TOUR_OPERATOR,
  CREATE_TOUR_OPERATOR
} from "../../common/Mutations";
import tourOperatorReducer from "./TourOperatorReducer";
import { InputField } from "../ui/FunctionalComponents";
import { LayoutContext } from "../app/Layout";
import { TourOperatorUserTable } from "./subcomponents/TourOperatorUserTable";
import { TourOperatorAddEmails } from "./subcomponents/TourOperatorAddEmails";

const defaultState = {
  _id: "",
  name: "",
  value: 0,
  users: [],
  fallback: [],
  loaded: false,
  loading: false
};
export const TourOperatorContext = createContext(defaultState);

const TourOperator = (props) => {
  const { addAlert } = useContext(LayoutContext);
  const onSave = () => {
    const tourOperatorInput = {
      name: state.name,
      value: parseFloat(state.value),
      emails: state.users.map(({ email }) => ({
        email
      }))
    };

    state._id
      ? updateTourOperator({
          variables: { input: tourOperatorInput, tourOperatorId: state._id }
        })
      : createTourOperator({ variables: { input: tourOperatorInput } });
  };

  const [state, dispatch] = useReducer(tourOperatorReducer, defaultState);

  const [updateTourOperator, { loading: mutationLoading }] = useMutation(
    UPDATE_TOUR_OPERATOR,
    {
      onCompleted: (res) => {
        if (!res.updateTourOperator.ok) {
          return addAlert({
            color: "danger",
            message: "Could not save TourOperator."
          });
        }
        dispatch({
          type: "INIT",
          payload: res.updateTourOperator.tourOperator
        });
        addAlert({
          color: "success",
          message: "TourOperator updated successfully saved."
        });
      },
      onError: () =>
        addAlert({ color: "danger", message: "Could not save TourOperator." })
    }
  );

  const [createTourOperator, { loading: createLoading }] = useMutation(
    CREATE_TOUR_OPERATOR,
    {
      onCompleted: (res) => {
        if (!res.createTourOperator.ok) {
          return addAlert({
            color: "danger",
            message: "Could not create TourOperator."
          });
        }
        dispatch({
          type: "INIT",
          payload: res.createTourOperator.tourOperator
        });
        addAlert({
          color: "success",
          message: "TourOperator created successfully."
        });
      },
      onError: () =>
        addAlert({
          color: "danger",
          message: "Could not create TourOperator."
        })
    }
  );

  const onLoadingData = (data) => {
    dispatch({ type: "INIT", payload: data.tourOperator });
  };

  const [getTourOperator] = useLazyQuery(TOUR_OPERATOR, {
    onCompleted: onLoadingData
  });
  useEffect(() => {
    if (props.match.params.id && state._id !== props.match.params.id) {
      dispatch({ type: "SET_LOADING", payload: true });
      getTourOperator({ variables: { id: props.match.params.id } });
    }
  }, [getTourOperator, props.match.params.id, state._id]);

  if (props.match.params.id && !state.loaded) return null;

  return (
    <div className="page">
      <TourOperatorContext.Provider value={{ state, dispatch }}>
        <AvForm
          beforeSubmitValidation={(event) => event.stopPropagation()}
          onValidSubmit={onSave}
          className="TourOperator"
        >
          <div className="row">
            <div className="col-md-4">
              <label>Name</label>
              <InputField
                targetKey="name"
                containerClassName="input-field-lg"
                value={state.name}
                onChange={(changeEvent) =>
                  dispatch({ type: "NAME", payload: changeEvent.value })
                }
                options={{ required: true, type: "string" }}
              />
            </div>
            <div className="col-md-4">
              <label>Value (Percentage)</label>
              <InputField
                targetKey="value"
                containerClassName="input-field-lg"
                value={state.value}
                onChange={(changeEvent) =>
                  dispatch({ type: "VALUE", payload: changeEvent.value })
                }
                options={{ required: true, type: "number", max: 100, min: 0 }}
              />
            </div>
          </div>
          <TourOperatorAddEmails />
          <div>
            <TourOperatorUserTable />
          </div>
          <div className="Save-container">
            <div className="Save-container__phantom" />
            <div className="Save-container__main">
              <FormGroup>
                <Button
                  disabled={mutationLoading || createLoading}
                  size="lg"
                  type="submit"
                >
                  {" "}
                  Save{" "}
                </Button>
              </FormGroup>
            </div>
          </div>
        </AvForm>
      </TourOperatorContext.Provider>
    </div>
  );
};

export default withRouter(TourOperator);
