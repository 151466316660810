// Vendor
import React, { useState, useRef, useEffect } from "react";
import { Button, Collapse, Row, Col, Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import ReactMarkdown from "react-markdown";

// App
import LocaleHelper from "../../../../common/LocaleHelper";
import { LanguageButton } from "../../../ui/FunctionalComponents";
import debounce from "../../../../common/debounce";

const ClubCourseReadMore = ({
  onChange,
  defaultCollapse = true,
  description = "",
  descriptionOld = "",
  i18n = LocaleHelper.generateI18nObject(["readMore"]),
  i18nOld = LocaleHelper.generateI18nObject(["readMore"])
}) => {
  const [collapse, setCollapse] = useState(defaultCollapse);
  const [locales] = useState(LocaleHelper.getLocales());
  const [selectedLang, setSelectedLang] = useState(
    LocaleHelper.getLocales()[0].lang
  );
  const editorRef = useRef();

  const simplemdeOptions = {
    toolbar: [
      "bold",
      "italic",
      "heading",
      "|",
      "quote",
      "link",
      "|",
      "unordered-list",
      "ordered-list",
      "|",
      "guide"
    ],
    spellChecker: false,
    shortcuts: {
      toggleFullScreen: null,
      toggleSideBySide: null
    },
    autoRefresh: true
  };

  const debounceHandler = debounce((value) => {
    handleTextChange(value);
  }, 1500);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  const handleTextChange = (value) => {
    if (selectedLang === "en-GB") {
      onChange({ key: "readMore", value });
    } else {
      let i18nCopy = { ...i18n };
      i18nCopy[selectedLang] = { ...i18nCopy[selectedLang] };
      i18nCopy[selectedLang].readMore = value;
      onChange({ key: "i18n", value: i18nCopy });
    }
  };

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
  };

  const resetCurrentLanguage = () => {
    if (selectedLang === "en-GB") {
      onChange({ key: "readMore", value: descriptionOld });
    } else {
      let i18nCopy = { ...i18n };
      i18nCopy[selectedLang] = { ...i18nCopy[selectedLang] };
      i18nCopy[selectedLang].readMore = i18nOld[selectedLang].readMore;
      onChange({ key: "i18n", value: i18nCopy });
    }
  };

  useEffect(() => {
    // Changing or resetting language requires manual update of editor component state
    const value =
      selectedLang === "en-GB" ? description : i18n[selectedLang].readMore;
    if (editorRef.current) {
      editorRef.current.updater.enqueueSetState(editorRef.current, {
        value: value
      });
    }
  }, [selectedLang, description, i18n]);

  const currentText =
    selectedLang === "en-GB" ? description : i18n[selectedLang].readMore;

  return (
    <div className="ClubCoursePresentation mt-5">
      <div className="ClubCoursePresentation__header d-flex">
        <h3>Course Read More</h3>
        <Button
          className="ml-2"
          color="link"
          onClick={toggleCollapse}
          style={{
            position: "relative",
            bottom: "3px",
            padding: 0
          }}
        >
          {collapse ? "Show" : "Hide"}
        </Button>
      </div>

      <div className="ClubCoursePresentation__flag-btns mb-2">
        <LanguageButton
          flagIcon="gb"
          label="EN"
          selected={selectedLang === "en-GB"}
          ok={descriptionOld !== ""}
          edited={description !== descriptionOld}
          onClick={() => handleLanguageChange("en-GB")}
        />
        {locales.map(
          (locale, index) =>
            i18n[locale.lang] && (
              <LanguageButton
                flagIcon={locale.flagIcon}
                label={locale.lang.substr(0, 2).toUpperCase()}
                key={index}
                selected={locale.lang === selectedLang}
                ok={i18nOld[locale.lang].readMore !== ""}
                edited={
                  i18n[locale.lang].readMore !== i18nOld[locale.lang].readMore
                }
                onClick={() => handleLanguageChange(locale.lang)}
              />
            )
        )}
      </div>

      <Collapse
        className="ClubCoursePresentation__editor-container"
        isOpen={!collapse}
      >
        <Row form className="mb-2">
          <Col md={6}>
            <SimpleMDE
              ref={editorRef}
              onChange={(event) => debounceHandler(event)}
              value={currentText}
              options={simplemdeOptions}
            />
          </Col>
          <Col md={6}>
            <Card className="h-100 border-0">
              <div className="card-header bg-light border border-bottom-0">
                <strong>Preview</strong>
              </div>
              <CardBody className="pb-0 border">
                <ReactMarkdown source={currentText} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Button
          className="ClubCoursePresentation__reset-lang btn-danger"
          onClick={resetCurrentLanguage}
        >
          Reset
        </Button>
      </Collapse>
    </div>
  );
};

ClubCourseReadMore.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultCollapse: PropTypes.bool,
  description: PropTypes.string,
  descriptionOld: PropTypes.string,
  i18n: PropTypes.object,
  i18nOld: PropTypes.object
};

export default ClubCourseReadMore;
