import React from "react";
import { Col, Row } from "reactstrap";

const InfoRow = ({ label, value='N/A' }) => {
  return (
    <Row className="info-row">
      <Col xs="6" lg={6} className="info-row-label">
        {label}
      </Col>
      <Col xs="6" lg={6} className="info-row-value">
        {value}
      </Col>
    </Row>
  );
};
export default InfoRow;
