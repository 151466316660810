import React, { useContext, useEffect, useState } from "react";
import InvoiceListTable from "./InvocieListTable";
import InvoiceSearch from "./InvoiceSearch";
import { useLazyQuery } from "@apollo/client";
import { GET_INVOICE_LIST, INVOICE_SEARCH } from "../../common/Queries";
import { useDeepCompareEffect } from "react-use";
import { useHistory } from "react-router-dom";
import URLHelper from "../../common/URLHelper";
import { LayoutContext } from "../app/Layout";
import CondensedNavButtonBar from "../ui/CondensedNavButtonBar";

const InvoiceList = () => {
  const { addAlert } = useContext(LayoutContext);
  const history = useHistory();
  const urlQueryParams = URLHelper.queryToState(history.location);
  const {
    page,
    invoiceId,
    invoiceName,
    courseName,
    payoutDate,
    payoutDateFrom,
    payoutDateTo,
    cancellationDate,
    cancellationDateFrom,
    cancellationDateTo,
    orderReference,
    invoiceReference,
    status,
    transferred
  } = urlQueryParams;
  const [pageCount, setPageCount] = useState(1);
  const [matchCount, setMatchCount] = useState(0);
  // queries
  const [
    getInvoiceList,
    { loading: invoiceListLoading, data, refetch: refetchInvoiceList }
  ] = useLazyQuery(GET_INVOICE_LIST);
  const [getPageCount, { loading: pageCountLoading }] = useLazyQuery(
    INVOICE_SEARCH,
    {
      onCompleted({ invoiceSearch }) {
        setPageCount(invoiceSearch.pageCount);
        setMatchCount(invoiceSearch.matchCount);
      },
      onError(error) {
        console.log("error", error);
      }
    }
  );
  // Clicking on course name in InvoiceListTable will change the course name in InvoiceSearch as well
  const [courseNameState, setCourseName] = useState(courseName);
  const [pageIndex, setPageIndex] = useState(0);

  useDeepCompareEffect(() => {
    // Get page count on changed search params
    fetchPageCount();
    // Ensure page index 0
    if (pageIndex === 0) {
      fetchInvoices();
    } else {
      pageBtnClickHandler(0);
    }
  }, [
    invoiceId,
    invoiceName,
    courseName,
    payoutDate,
    payoutDateFrom,
    payoutDateTo,
    cancellationDate,
    cancellationDateFrom,
    cancellationDateTo,
    orderReference,
    invoiceReference,
    status,
    transferred
  ]);
  useEffect(() => {
    // Update page index from url if changed
    if (page) {
      setPageIndex(Number(page) - 1);
    }
  }, [page]);

  useEffect(() => {
    // Fetch new list on pageIndex change
    fetchInvoices();
  }, [pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps
  // Handlers
  const fetchInvoices = () => {
    getInvoiceList({
      variables: {
        pageIndex,
        filter: buildFilter()
      }
    });
  };
  const fetchPageCount = () => {
    getPageCount({
      variables: {
        filter: buildFilter()
      }
    });
  };

  const pageBtnClickHandler = (newIndex) => {
    newIndex = Number(newIndex);
    if (newIndex > 499) {
      addAlert({
        color: "warning",
        message:
          "Page limited to 500. Result set too large, reduce set through search parameters"
      });
      newIndex = 499;
    }
    urlQueryParams.page = newIndex + 1;
    history.push({
      search: URLHelper.queryFromState(urlQueryParams)
    });
  };

  const buildFilter = () => {
    let filter = {
      invoiceId,
      invoiceName,
      clubOrCourseName: courseNameState,
      orderReference,
      invoiceReference,
      status
    };

    if (transferred) {
      filter.fundsTransferred = transferred;
    }

    if (payoutDate || payoutDateFrom || payoutDateTo) {
      if (payoutDate) {
        filter.payoutDate = {
          singleDate: payoutDate
        };
      } else {
        filter.payoutDate = {
          startDate: payoutDateFrom,
          endDate: payoutDateTo
        };
      }
    }

    if (cancellationDateTo || cancellationDateFrom || cancellationDate) {
      if (cancellationDate) {
        filter.cancellationDate = {
          singleDate: cancellationDate
        };
      } else {
        filter.cancellationDate = {
          startDate: cancellationDateFrom,
          endDate: cancellationDateTo
        };
      }
    }

    return filter;
  };

  const invoiceList = data ? data.invoiceList : [];
  const loading = invoiceListLoading || !data || pageCountLoading;

  return (
    <div className="page">
      <h1 className="mb-4">Invoices</h1>
      <InvoiceSearch
        invoiceList={invoiceList}
        setCourseName={setCourseName}
        courseName={courseNameState}
        pageCount={pageCount}
        matchCount={matchCount}
        buildFilter={buildFilter}
        refetchInvoices={refetchInvoiceList}
      />
      <InvoiceListTable
        invoiceList={invoiceList}
        loading={loading}
        setCourseName={setCourseName}
        buildFilter={buildFilter}
      />
      <CondensedNavButtonBar
        loading={loading}
        value={pageIndex}
        onBtnClick={pageBtnClickHandler}
        pageCount={pageCount}
      />
    </div>
  );
};

export default InvoiceList;
