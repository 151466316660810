/**
 * @param { Partial<Rate> } filter
 * @param { Rate } rate
 * @returns { boolean }
 */
function foundMatch(filter, rate) {
  let pass = true;
  Object.keys(filter).forEach((key) => {
    // Hardcoded if to adapt the swtich from datyType(string) to dayType(object)
    if (typeof rate[key] === "object") {
      if (!rate[key] === undefined || rate[key]?.type !== filter[key]) {
        pass = false;
      }
    } else {
      if (rate[key] === undefined || rate[key] !== filter[key]) pass = false;
    }
  });
  return pass;
}

class RatesHelper {
  /**
   * @param { Rate[] } rates
   * @param { Partial<Rate> } filter
   * @returns  { Rate[] }
   */
  static filter(rates, filter) {
    let filtered = rates.filter((rate) => foundMatch(filter, rate));
    return filtered.length ? filtered : [];
  }
  /**
   * @param { Rate[] } rates
   * @param { Partial<Rate> } filter
   * @returns { Rate | undefined } Found match or undefined
   */
  static find(rates, filter) {
    return rates.find((rate) => foundMatch(filter, rate));
  }

  /**
   * @param { Rate[] } rates
   * @param { Object } commissionObj
   * @param { float } commissionValue
   * @param { string } commissionType
   * @param { Object } commissionObj
   * @returns { Rate[] } updated rates with commissions
   */
  static fillCommissions(rates, commissionObj) {
    const { commissionType, commission } = commissionObj;
    if (rates?.length < 0) {
      return rates;
    }
    // for each rate check if they are editable and update them
    const newRates = rates.map((rate) => {
      const newPrices = rate.prices.map((price) => {
        if (price.isOverridableByCourse) {
          return { ...price, commissionType, commission };
        } else return price;
      });
      return { ...rate, prices: newPrices };
    });
    return newRates;
  }

  static isValidNumericProperty(ratePrice, prop) {
    return (
      ratePrice[prop] !== undefined &&
      ratePrice[prop] !== null &&
      !isNaN(ratePrice[prop])
    );
  }
}

export default RatesHelper;
