import { useEffect } from "react";

/**
 * Hook that detects clicks outside a given ref or an array of refs.
 * @param {React.RefObject|Array<React.RefObject>} ref - The ref or an array of refs to be monitored for clicks outside.
 * @param {function} callback - The callback function to be called when a click outside is detected.
 */
const useClickOutside = (ref, callback) => {
  const handleClickOutside = (event) => {
    if (Array.isArray(ref)) {
      // Check if any of the refs in the array contain the target
      if (
        !ref.some((currentRef) => currentRef.current?.contains(event.target))
      ) {
        callback();
      }
    } else {
      // Check if the single ref contains the target
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback]);
};

export default useClickOutside;
