// Vendor
import React, { useState, useContext } from "react";
import { Button } from "reactstrap";

// App
import ContactCard from "../../contacts/ContactCard";
import ContactForm from "../../contacts/ContactForm";
import { ClubContext } from "../Club";

function ClubContactPersonList(props) {
  const { state, dispatch } = useContext(ClubContext);
  const [showForm, setShowForm] = useState(false);

  function handleContactChange(index, contact) {
    let contactsCopy = [...state.contacts];
    contactsCopy[index] = contact.value;
    dispatch({ type: "CONTACTS", payload: contactsCopy });
  }

  function handleContactDelete(index) {
    let filtered = state.contacts.filter((_, i) => i !== index);
    dispatch({ type: "CONTACTS", payload: filtered });
  }

  function handleNewContact(value) {
    if (value.name !== "" || value.title !== "" || value.email !== "") {
      dispatch({ type: "CONTACTS", payload: [...state.contacts, value] });
    }
    setShowForm(false);
  }

  return (
    <div className="ClubContactPersons mt-3">
      {showForm && (
        <ContactForm
          onClose={handleNewContact}
          contact={{ name: "", title: "", email: "" }}
        />
      )}

      <h3>Contact persons</h3>
      <div>
        {state.contacts.map((item, index) => (
          <ContactCard
            key={index}
            contact={item}
            onChange={value => handleContactChange(index, value)}
            onDelete={() => handleContactDelete(index)}
          />
        ))}
      </div>
      <Button color="link" onClick={() => setShowForm(true)}>
        +Add new contact
      </Button>
    </div>
  );
}

export default ClubContactPersonList;
