import React from "react";
import { Table } from "reactstrap";

const EmailsList = ({tableClassNames="", label="Email", emailList, onDelete }) => {
  return (
    <Table className={tableClassNames}>
      <thead>
        <tr>
          <th>{label}</th>
          {emailList.length > 0 && <th> Actions</th>}
        </tr>
      </thead>
      <tbody>
        {emailList && emailList.length > 0 ? (
          emailList.map((email) => (
            <tr key={email}>
              <td>{email}</td>
              <td>
                <div className="btn btn-danger" onClick={() => onDelete(email)}>
                  Delete
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={2}>NO EMAILS</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default EmailsList;
