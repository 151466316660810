// Promise Interface can ensure load the script only once.
export const appendScript = (src, id, async = true, defer = false) => {
  return new Promise(function(resolve, reject) {
    var script = document.createElement("script");
    script.src = src;
    script.id = id;
    script.async = async;
    script.defer = defer;
    script.onload = res => {
      resolve(res);
    };
    script.onerror = e => {
      reject(e);
    };
    document.body.appendChild(script);
  });
};
