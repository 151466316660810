import React, { useEffect, useReducer, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Image } from "cloudinary-react";
import { AvForm } from "availity-reactstrap-validation";
import {
  PARTNER_LINK
} from "../../common/Queries";
import partnerLinkReducer from "./PartnerLinkReducer";
import { InputField } from "../ui/FunctionalComponents";
import { UPDATE_PARTNER_LINK, CREATE_PARTNER_LINK } from "../../common/Mutations";
import { LayoutContext } from "../app/Layout";
import PartnerLinkImageUploader from "./PartnerLinkImageUploader";

const PartnerLink = (props) => {
  const { addAlert } = useContext(LayoutContext);
  const onSave = () => {
    const partnerLinkInput = {
      name: state.name,
      displayName: state.displayName,
      linkUrl: state.linkUrl,
      imageUrl: state.imageUrl,
    };

    state._id ? updatePartnerLink({ variables: { input: partnerLinkInput, partnerLinkId: state._id } }) : createPartnerLink({ variables: { input: partnerLinkInput } });
  }

  const onImageUpload = useCallback((imageUrl) => { dispatch({ type: "IMAGEURL", payload: imageUrl }); }, []);

  const [state, dispatch] = useReducer(partnerLinkReducer, {
    _id: "",
    name: "",
    displayName: "",
    linkUrl: "",
    imageUrl: "",

    loaded: false
  });

  const [updatePartnerLink, { loading: mutationLoading }] = useMutation(
    UPDATE_PARTNER_LINK,
    {
      onCompleted: res => {
        if (!res.updatePartnerLink.ok) {
          return addAlert({ color: "danger", message: "Could not save PartnerLink." });
        }
        dispatch({ type: "INIT", payload: res.updatePartnerLink.partnerLink });
        addAlert({
          color: "success",
          message: "PartnerLink updated successfully saved."
        });
      },
      onError: () =>
        addAlert({ color: "danger", message: "Could not save PartnerLink." })
    }
  );


  const [createPartnerLink, { loading: createLoading }] = useMutation(
    CREATE_PARTNER_LINK,
    {
      onCompleted: res => {
        if (!res.createPartnerLink.ok) {
          return addAlert({ color: "danger", message: "Could not create PartnerLink." });
        }
        dispatch({ type: "INIT", payload: res.createPartnerLink.partnerLink });
        addAlert({
          color: "success",
          message: "PartnerLink created successfully."
        });
      },
      onError: () =>
        addAlert({ color: "danger", message: "Could not create PartnerLink." })
    }
  );


  const onLoadingData = (data) => {
    dispatch({ type: "INIT", payload: data.partnerLink });
  }

  const [getPartnerLink] = useLazyQuery(PARTNER_LINK, {
    onCompleted: onLoadingData
  });
  useEffect(() => {
    if (props.match.params.id && state._id !== props.match.params.id) {
      getPartnerLink({ variables: { id: props.match.params.id } });
    }

  }, [getPartnerLink, props.match.params.id, state._id]);

  if (props.match.params.id && !state.loaded) return null;

  return (
    <AvForm
      beforeSubmitValidation={event => event.stopPropagation()}
      onValidSubmit={onSave}
      className="PartnerLink"
    >
      <div className="row">
        <div className="col-md-4">
          <label>Name</label>
          <InputField
            targetKey="name"
            value={state.name}
            onChange={changeEvent =>
              dispatch({ type: "NAME", payload: changeEvent.value })
            }
            options={{ required: true, type: "string" }}
          />
        </div>
        <div className="col-md-4">
          <label>Display Name</label>
          <InputField
            targetKey="displayName"
            value={state.displayName}
            onChange={changeEvent =>
              dispatch({ type: "DISPLAYNAME", payload: changeEvent.value })
            }
            options={{ required: true, type: "text"}}
          />
        </div>
        <div className="col-md-4">
          <label>Link Url</label>
          <InputField
            targetKey="linkUrl"
            value={state.linkUrl}
            onChange={changeEvent =>
              dispatch({ type: "LINKURL", payload: changeEvent.value })
            }
            options={{ required: true, type: "text"}}
          />
        </div>

        <Row>
            <Col md={8} lg={6}>
              <label>Partner Image</label>
              <PartnerLinkImageUploader partnerLinkId={state._id} onImageUpload={onImageUpload} />

              {state.imageUrl && (
                <div className="d-flex w-100">
                  <Image
                    className="rounded mt-3"
                    width={550}
                    height="230"
                    publicId={state.imageUrl}
                  />
                </div>
              )}
            </Col>
          </Row>

      </div>
      <div className="Save-container">
        <div className="Save-container__phantom" />
        <div className="Save-container__main">
          <FormGroup>
            <Button disabled={mutationLoading || createLoading || !state.name || !state.displayName || !state.linkUrl} size="lg" type="submit"> Save </Button>
          </FormGroup>
        </div>
      </div>
    </AvForm >
  );

}

export default withRouter(PartnerLink);