// vendor
import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
// app
import { SITES } from "../../common/Queries";
import Loader from "./Loader";
import Select from "react-select";
import variables from "../../styles/bootstrap/_variables.scss";
function SitesSelect(props) {
  const { loading, error, data } = useQuery(SITES);

  const vbalue = useMemo(() => {
    if (!data || !props.value) {
      return null;
    }

    return props.value.reduce((result, selectedId) => {
      const v = data.sites.find(x => x._id === selectedId);
      if (!v) return result;
      return [...result, { label: v.name, value: v._id }]
    }, []);
  }, [data, props.value]);

  if (loading) return <Loader />;
  if (error) return <p className="text-danger">Could not load sites.</p>;

  return (
    <>
      <label htmlFor={props.id}>{props.label}</label>
      <Select
        id={props.id}
        options={
          data.sites ? data.sites.map(site => ({ label: site.name, value: site._id })) :
            []
        }
        value={vbalue}
        onChange={value =>
          props.onChange(value ? value.map(x => x.value) : null)
        }
        style={props.value === null ? { color: variables.gray400 } : null}
        isMulti
      />
    </>
  );
}
export default SitesSelect;
