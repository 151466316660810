// Vendor
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

// App
import RatesHelper from "../../../../common/RatesHelper";
import Season, { SeasonPropTypes } from "./Season";
import { SEASON_RATE_DELETE } from "../../../../common/Mutations";
import { ClubCourseContext } from "../ClubCourse";
import { LayoutContext } from "../../../app/Layout";
import { RateDayTypeIcons } from "./ui/RateDayTypeIcons";
import { SeasonBadge } from "./ui/SeasonBadge";
import SeasonEditTypesModal from "./editModal/SeasonEditTypeModal";
import DeleteButton from "../../../ui/table-utils/DeleteButton";

function ClubCourseSeasonList() {
  const { addAlert } = useContext(LayoutContext);
  const { state: courseState, dispatch: courseDispatch } =
    useContext(ClubCourseContext);
  const { _id: courseId, seasonRates: seasons } = courseState;
  const [selectedSeason, setSeason] = useState(null);
  const [seasonOpen, setOpen] = useState(false);
  const [seasonEditOpen, setEditOpen] = useState(false);
  const [seasonInTransit, setSeasonInTransit] = useState(null);

  // If URL params include a seasonId, open that for editing & remove from URL
  const history = useHistory();
  useEffect(() => {
    const { search } = history.location;
    if (search.match("seasonId")) {
      const seasonId = search.slice(search.indexOf("seasonId=") + 9);
      const season = seasons.find((s) => s._id === seasonId);
      if (season) {
        setSeason(season);
        setOpen(true);
      }

      history.replace({
        search: search.substr(0, search.indexOf("&seasonId"))
      });
    }
    // Should only run once on mounting
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [deleteSeasonRate] = useMutation(SEASON_RATE_DELETE, {
    onCompleted() {
      courseDispatch(
        { type: "SEASON_DELETE", payload: seasonInTransit },
        false
      );
      setSeasonInTransit(null);
      addAlert({ color: "success", message: "Season deleted" });
    },
    onError() {
      addAlert({ color: "danger", message: "Failed to delete season" });
      setSeasonInTransit(null);
    }
  });

  function handleDelete(seasonId) {
    setSeasonInTransit(seasonId);
    deleteSeasonRate({ variables: { seasonRateId: seasonId, courseId } });
  }

  function toggleEditSeason() {
    setEditOpen(!seasonEditOpen);
  }

  // getting occupied season dates
  const SEASON_DATES_OCCUPIED = seasons.map((season) => {
    return {
      _id: season._id,
      startDate: season.startDate,
      endDate: season.endDate
    };
  });
  return (
    <div className="ClubCourseSeasonList mt-5">
      <h3>Seasons</h3>

      <Table responsive className="border-0">
        <thead>
          <tr>
            <th colSpan="3">&nbsp;</th>
            <th colSpan="2" className="text-center">
              Standard
            </th>
            <th colSpan="2" className="text-center">
              Weekend
            </th>
            <th colSpan="2">&nbsp;</th>
          </tr>
          <tr>
            <th>Type</th>
            <th className="text-left">From</th>
            <th className="text-left">To</th>
            <th>Rates</th>
            <th>Day types</th>
            <th>Rates</th>
            <th>Day types</th>
          </tr>
        </thead>
        <tbody className="border">
          {!seasons.length && (
            <tr>
              <td colSpan="8" className="text-muted text-center">
                No seasons defined for this course
              </td>
            </tr>
          )}
          {seasons.length > 0 &&
            seasons.map((season) => {
              return (
                <tr key={season._id}>
                  <td>
                    {" "}
                    <SeasonBadge season={season} />{" "}
                  </td>
                  <td className="text-left" style={{ whiteSpace: "nowrap" }}>
                    <small>
                      {moment(season.startDate).format("YYYY-MM-DD")}
                    </small>
                  </td>
                  <td className="text-left" style={{ whiteSpace: "nowrap" }}>
                    <small>{moment(season.endDate).format("YYYY-MM-DD")}</small>
                  </td>
                  <td>
                    {
                      RatesHelper.filter(season.rates, {
                        scheduleType: "STANDARD"
                      }).length
                    }
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {/* TODO: this should include the custom day types as well */}
                    <RateDayTypeIcons
                      rates={season.rates}
                      scheduleType="STANDARD"
                    />
                  </td>
                  <td>
                    {
                      RatesHelper.filter(season.rates, {
                        scheduleType: "WEEKEND"
                      }).length
                    }
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {/* TODO: this should include the custom day types as well */}
                    <RateDayTypeIcons
                      rates={season.rates}
                      scheduleType="WEEKEND"
                    />
                  </td>
                  <td>
                    <Button
                      className="p-0"
                      color="link"
                      onClick={() => {
                        setSeason(season);
                        setOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                  <td>
                    <DeleteButton
                      id={season._id}
                      itemInTransit={seasonInTransit}
                      handleDelete={handleDelete}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <Button
        color="link"
        onClick={() => {
          const newSeason = {
            seasonType: {
              name: "",
              type: "",
              color: ""
            },
            rates: []
          };
          setSeason(newSeason);
          setOpen(true);
        }}
      >
        + Add season
      </Button>
      <Button
        color="link"
        className="text-warning shadow-none"
        onClick={() => {
          setEditOpen(true);
        }}
      >
        Edit season types
      </Button>
      <Season
        open={seasonOpen}
        season={selectedSeason}
        onDuplicate={() => {
          let clone = JSON.parse(JSON.stringify(selectedSeason));
          delete clone._id;

          setOpen(false);

          setTimeout(() => {
            setSeason(clone);
            setOpen(true);
          }, 1000);
        }}
        onClose={() => {
          setOpen(false);
          setSeason(null);
        }}
        season_dates_occupied={SEASON_DATES_OCCUPIED}
      />
      <SeasonEditTypesModal
        isOpen={seasonEditOpen}
        toggle={toggleEditSeason}
        onClose={() => setEditOpen(false)}
      />
    </div>
  );
}

ClubCourseSeasonList.propTypes = {
  seasonRates: PropTypes.arrayOf(PropTypes.shape(SeasonPropTypes))
};
ClubCourseSeasonList.defaultProps = {
  seasonRates: []
};

export default ClubCourseSeasonList;
