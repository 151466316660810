// Vendor
import React, { Component } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// App
import Auth from "../../common/Auth";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  async handleValidSubmit(event, values) {
    this.setState({ error: false });
    try {
      await Auth.login(values.loginEmail, values.loginPassword);
      this.props.onLogin();
    } catch (err) {
      console.log(err);
      this.setState({ error: true });
    }
  }

  render() {
    return (
      <div className="LoginPage">
        <Card>
          <CardBody>
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              onInvalidSubmit={() => this.setState({ error: true })}
            >
              {this.state.error && (
                <div className="d-flex align-items-center text-danger">
                  <span className="ml-3 text-left">Login failed</span>
                </div>
              )}
              <AvField
                name="loginEmail"
                label="Email"
                validate={{ email: true, required: true }}
              />
              <AvField
                type="password"
                name="loginPassword"
                label="Password"
                validate={{ required: true, minLength: { value: 8 } }}
              />
              <Button>Login</Button>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default LoginPage;
