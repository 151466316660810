// Vendor
import React from "react";
import { Query } from "@apollo/client/react/components";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

// App
import Loader from "./Loader";
import { COURSES_EXPIRING_RATES } from "../../common/Queries";

export const RatesExpiringTable = () => (
  <div className="mt-5">
    <h3>Internal Rates Expires</h3>
    <Query query={COURSES_EXPIRING_RATES} fetchPolicy="cache-and-network">
      {({ loading, error, data }) => {
        if (error) return <p className="text-danger">{error.message}</p>;
        if (loading || !data) return <Loader />;
        let courses = [...data.ratesExpirationStatus];
        return (
          <Table striped borderless hover>
            <thead>
              <tr>
                <th>Course</th>
                <th>Provider</th>
                <th className="Col--shrink">EXPIRES IN</th>
              </tr>
            </thead>
            <tbody className="border">
              {courses
                .sort((a, b) => a.ratedDaysLeft - b.ratedDaysLeft)
                .map((club, i) => (
                  <tr key={i}>
                    <td>
                      <Link
                        to={`/club/${club.clubSlug}?course=${club.courseSlug}`}
                      >
                        {club.courseName}
                      </Link>
                    </td>
                    <td>{club.provider}</td>
                    <td
                      className={`Col--shrink ${
                        club.ratedDaysLeft < 1
                          ? "text-danger"
                          : club.ratedDaysLeft < 7
                          ? "text-warning"
                          : ""
                      }`}
                    >
                      {club.ratedDaysLeft < 1
                        ? "EXPIRED"
                        : `${club.ratedDaysLeft} days`}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        );
      }}
    </Query>
  </div>
);
