import React, { useState } from "react";
import CommentListMinimized from "./CommentListMinimized";
import CommentForm from "./CommentForm";
import Auth from "../../../common/Auth";
import CommentListExpanded from "./CommentListExpanded";

export function CommentListBase({
  comments,
  onCloseTask,
  onNewComment,
  onSaveEditComment,
  onDeleteComment,
  newCommentLoading,
  closeTaskLoading
}) {
  const [expanded, setExpanded] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [editingComment, setEditingComment] = useState(null);
  const user = Auth.getUser();

  function toggleForm() {
    setFormOpen((prev) => !prev);
    setEditingComment(null);
  }

  function handleOnCloseTask(commentId) {
    onCloseTask({ commentId, username: `${user.firstName} ${user.lastName}` });
  }

  function handleNewComment(newComment, hasTask) {
    onNewComment({
      input: newComment,
      hasTask
    });
  }

  function handleSaveEditComment(comment, editedCommentBody) {
    onSaveEditComment({
      input: {
        body: editedCommentBody,
        userFullName: `${user.firstName} ${user.lastName}`,
        timestamp: new Date()
      },
      commentId: comment._id
    });
    setEditingComment(null);
  }

  function handleDeleteComment(comment) {
    onDeleteComment({
      commentId: comment._id,
      userFullName: comment.userFullName
    });
  }

  function handleEditCommentClick(comment) {
    setEditingComment(comment);
    setFormOpen(true);
  }

  if (!expanded) {
    return (
      <CommentListMinimized
        onClick={() => setExpanded(true)}
        comments={comments}
      />
    );
  }

  return (
    <>
      <CommentForm
        comments={comments}
        isOpen={formOpen}
        toggle={toggleForm}
        onAddComment={handleNewComment}
        onSaveEditedComment={handleSaveEditComment}
        editingComment={editingComment}
      />
      <CommentListExpanded
        comments={comments}
        toggle={() => setExpanded(false)}
        onCloseTask={handleOnCloseTask}
        user={user}
        onAddCommentClick={() => setFormOpen(true)}
        onSaveEditedComment={handleSaveEditComment}
        onEditCommentClick={handleEditCommentClick}
        onDeleteComment={handleDeleteComment}
        newCommentLoading={newCommentLoading}
        closeTaskLoading={closeTaskLoading}
      />
    </>
  );
}
