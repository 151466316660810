// Vendor
import React from "react";
import { InputGroup, Input } from "reactstrap";

/**
 * @param { { min?: number, max?: number, value?: {min?: number, max?: number }, onChange: function, label: string } } props
 */
export function NumberRangeFacet({
  min = 0,
  max = null,
  step = 1,
  value,
  onChange,
  label
}) {
  return (
    <div className="NumberRangeFacet mb-2 d-inline-block">
      <label>{label}</label>
      <InputGroup size="sm">
        <div className="NumberRangeFacet__edge input-group-prepend">
          <Input
            type="number"
            step={step}
            value={value?.min ?? min}
            min={min}
            max={value?.max || max}
            onChange={e =>
              e.target.validity.valid &&
              onChange({ from: e.target.value, to: value?.max })
            }
            placeholder="From"
          />
        </div>
        <div
          className="NumberRangeFacet__divider input-group-append input-group-prepend position-relative"
          style={{ marginLeft: -2, marginRight: -1, zIndex: 3 }}
        >
          <div className="input-group-text justify-content-center">
            <p className="p-0 m-0">:</p>
          </div>
        </div>
        <div className="NumberRangeFacet__edge input-group-append">
          <Input
            type="number"
            step={step}
            value={value?.max ?? ""}
            min={value?.min || min}
            max={max}
            onChange={e =>
              e.target.validity.valid &&
              onChange({ from: value?.min, to: e.target.value })
            }
            placeholder="To"
          />
        </div>
      </InputGroup>
    </div>
  );
}
