/**
 * @param { { master: Rate[], currentState: Rate[] } } state
 * @param { Object } action
 * @param { SeasonTableReducerActionTypes } action.type
 * @param { any } [action.payload={}]
 * @returns { { master: Rate[], currentState: Rate[] } }
 */
export default function seasonTableReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return {
        master: action.payload,
        currentState: JSON.parse(JSON.stringify(action.payload))
      };
    case "RESET":
      return {
        ...state,
        currentState: JSON.parse(JSON.stringify(state.master))
      };
    case "UPDATE":
      return { ...state, currentState: action.payload };
    default:
      return state;
  }
}
