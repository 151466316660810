// Vendor
import React, { useState } from "react";
import { Image } from "cloudinary-react";

// App
import { ContentBlock } from "../ContentBlock";
import { ResizablePanel } from "../ResizablePanel";

/**
 *
 * @param {*} props
 * @param { string } props.imageType
 * @param { string } props.imageId
 * @returns
 */
export function HighlightImagePreview({ imageType, imageId = "" }) {
  const isCard = imageType === "CARD";
  const minWidth = isCard ? 288 : 290;
  const maxWidth = isCard ? 508 : 570;

  const [width, setWidth] = useState(minWidth);

  if (!imageId) return null;

  return (
    <div>
      <ContentBlock
        title={`Preview - Width ${width}px`}
        description={`Range ${minWidth} - ${maxWidth}`}
      >
        <div className="row justify-content-center">
          <ResizablePanel
            width={minWidth}
            height={230}
            onResize={dimensions => setWidth(dimensions.width)}
            options={{
              lockDirection: "horizontal",
              minWidth: minWidth,
              maxWidth: maxWidth
            }}
          >
            <Image
              publicId={imageId}
              height="230"
              width={width}
              crop="pad"
              responsive
              aspectRatio={`${width}:230`}
            />
          </ResizablePanel>
        </div>
      </ContentBlock>
    </div>
  );
}
