// Vendor
import React, { useState, useEffect } from "react";
import { AvInput } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { appendScript } from "../../../common/Scripts";
import useDebounce from "../../../common/useDebounce";

function HighlightImageUploader({ onImageUpload }) {
  const [uploadWidget, setUploadWidget] = useState(null);
  const [imageId, setImageId] = useState("");
  const debouncedImgId = useDebounce(imageId, 750);

  const widgetOptions = publicId => ({
    cloudName: "greenfee365",
    uploadPreset: "preset-marshalls-course-image-upload",
    sources: ["local"],
    folder: `highlights`,
    publicId: publicId,
    tags: ["highlight", publicId],
    maxImageFileSize: 700000, // 700 kB
    minImageWidth: 280,
    minImageHeight: 230,
    multiple: false
  });

  // Update upload widget public id on debounced change
  useEffect(() => {
    function initWidget() {
      setUploadWidget(
        window.cloudinary.createUploadWidget(
          widgetOptions(debouncedImgId),
          (error, result) => {
            if (result && result.event === "success") {
              onImageUpload(result.info.public_id);
            }
          }
        )
      );
    }

    if (debouncedImgId.length) {
      // if script is already loaded
      if (window.cloudinary) initWidget();
      else {
        appendScript(
          "https://widget.cloudinary.com/v2.0/global/all.js",
          "cloudinary-upload-widget"
        )
          .then(initWidget)
          .catch(err => {
            console.log(err);
            return err;
          });
      }
    }
  }, [debouncedImgId, onImageUpload]);

  function handleImageIdInput(e) {
    // Ignore invalid id values
    let regTxt = e.target.pattern.substr(1, e.target.pattern.length - 2);
    let regex = RegExp(regTxt);
    if (regex.test(e.target.value)) setImageId(e.target.value);
  }

  return (
    <div className="row mt-3">
      <div className="col-auto">
        <Button
          color={!uploadWidget ? "gray" : "light"}
          type="button"
          disabled={!uploadWidget}
          outline
          onClick={() => uploadWidget.open()}
        >
          <FontAwesomeIcon icon="upload" className="mr-1" /> Upload new
        </Button>
      </div>
      <div className="col">
        <AvInput
          name="newImageId"
          type="text"
          placeholder="New Cloudinary image id"
          onChange={handleImageIdInput}
          validate={{
            pattern: { value: /^[A-Za-z0-9_]+(-[A-Za-z0-9_]+)*$/ }
          }}
        />
      </div>
    </div>
  );
}

export default HighlightImageUploader;
