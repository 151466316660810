// Vendor
import React, { PureComponent } from "react";
import { Alert } from "reactstrap";
import PropTypes from "prop-types";

export class TimedAlert extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    delay: PropTypes.number.isRequired,
    color: PropTypes.string,
    onClose: PropTypes.func,
    dismissable: PropTypes.bool
  };
  static defaultProps = {
    dismissable: false,
    onClose: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const { delay, onClose } = this.props;
    setTimeout(
      () =>
        this.setState(
          {
            isOpen: false
          },
          onClose
        ),
      delay
    );
  }

  toggle() {
    this.setState({ isOpen: false }, this.props.onClose);
  }

  render() {
    const { className, dismissable, color, children } = this.props;
    const { isOpen } = this.state;

    const props = {
      className,
      isOpen,
      toggle: dismissable ? this.toggle : null,
      color
    };

    return <Alert {...props}>{children}</Alert>;
  }
}
