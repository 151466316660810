export const STATUS_COLORS = {
  pending: "#dbbf07",
  queued: "#ff7b00",
  queue_failed: "#e67074",
  load_failed: "#992225",
  completed: "#02c39a",
  deprecated: "#2980B9",
  cancelled: "#e0090f",
  ready_to_invoice: "#69de1b"
};

export const STATUS_OPTIONS = [
  {
    label: "Pending",
    value: "pending",
    info: "Invoice was created"
  },
  {
    label: "Queued",
    value: "queued",
    info: "Currently in the payment queue and awaiting automatic payout"
  },
  {
    label: "Ready To Invoice",
    value: "ready_to_invoice",
    info: "Awaiting email sendouts for scheduled invoice"
  },
  {
    label: "Completed",
    value: "completed",
    info: "Scheduled to amadeus and emails sent out"
  },
  {
    label: "Queue failed",
    value: "queue_failed",
    info: "Failed to enter in payment queue"
  },
  {
    label: "Load failed",
    value: "load_failed",
    info: "Failed to to load on amadeus vcc"
  },
  { label: "Cancelled", value: "cancelled", info: "Booking was cancelled" },
  {
    label: "Deprecated",
    value: "deprecated",
    info: "These invoices should be ignored"
  }
];

export const TRANSFERED_OPTIONS = [
  {
    value: "transferred",
    label: "Transferred",
    info: "The funds are transferred from Swedbank to Amadeus"
  },
  {
    value: "notTransferred",
    label: "Not Transferred",
    info: "The funds aren't transferred from Swedbank to Amadeus."
  },
  {
    value: "settled",
    label: "Settled",
    info: "The money has been redistributed"
  },
  {
    value: "kawaii",
    label: "Kawaii",
    info: "The invoice is handled by the kawaii system"
  }
];

export const TRANSFERRED_COLORS = {
  transferred: "#02c39a",
  notTransferred: "#ff595e",
  settled: "#ff7b00",
  kawaii: "#000000"
};

export class InvoiceStatusHelper {
  /**
   * Returns the status object from `STATUS_OPTIONS` based on the provided value.
   * @param {string} value - The status value
   * @returns {Object|null} - The status object { label: string, value: string, info: string } or null if not found
   */
  static findStatusByValue(value) {
    return STATUS_OPTIONS.find((option) => option.value === value) || null;
  }

  /**
   * Returns the color code from `STATUS_COLORS` for the given status.
   * @param {string} status - The status value
   * @returns {string|null} - The color code or null if not found
   */
  static getStatusColor(status) {
    return STATUS_COLORS[status] || null;
  }

  /**
   * Returns the color code from `STATUS_COLORS` for the given transferred status.
   * @param {string} status - The transferred status value
   * @returns {string|null} - The color code or null if not found
   */
  static getTransferredStatusColor(status) {
    return TRANSFERRED_COLORS[status] || null;
  }

  /**
   * Returns the status object from `TRANSFERED_OPTIONS` based on the provided value.
   * @param {string} value - The status value
   * @returns {Object|null} - The status object { label: string, value: string, info: string } or null if not found
   */
  static findTransferredOption(value) {
    return TRANSFERED_OPTIONS.find((option) => option.value === value);
  }
}
