// Vendor
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "reactstrap";
import PropTypes from "prop-types";

function ManagedTable({ listItems, onChange }) {
  /**
   * Shift item one step in array
   * @param { Object } item Item to move.
   * @param { boolean } moveUp Move to array index i -= 1
   */
  function moveItem(item, moveUp) {
    let curIndex = listItems.findIndex(c => c.id === item.id);

    // item not found
    if (curIndex === -1) return;

    const shiftValue = moveUp ? -1 : 1;
    const targetIndex = curIndex + shiftValue;

    // Cannot move out of bounds
    if (targetIndex < 0 || targetIndex >= listItems.length) return;

    // Copy array and then move item
    let reordered = [...listItems];
    let itemFromArr = reordered.splice(curIndex, 1)[0];
    reordered.splice(targetIndex, 0, itemFromArr);

    onChange(reordered);
  }

  function removeItem(item) {
    onChange(listItems.filter(c => c.id !== item.id));
  }

  const ArrowCell = ({ item, isUp, disabled }) => (
    <td className="Col--shrink">
      <FontAwesomeIcon
        icon={`arrow-${isUp ? "up" : "down"}`}
        onClick={() => !disabled && moveItem(item, isUp)}
        className={`d-inline-flex mr-2 ${
          disabled ? "text-muted" : "cursor-pointer text-body"
        }`}
      />
    </td>
  );

  const Row = ({ item, index }) => {
    const first = index === 0;
    const last = listItems.length - 1 === index;
    const single = listItems.length === 1;

    return (
      <tr>
        <ArrowCell item={item} isUp={false} disabled={single || last} />
        <ArrowCell item={item} isUp={true} disabled={single || first} />
        <td className="text-left">{item.value}</td>
        <td className="text-right">
          <FontAwesomeIcon
            className="text-muted cursor-pointer"
            icon="trash-alt"
            onClick={() => removeItem(item)}
          />
        </td>
      </tr>
    );
  };

  return (
    <Table striped borderless>
      <tbody className="border">
        {listItems?.length ? (
          <>
            {listItems.map((item, i) => (
              <Row key={i} index={i} item={item} />
            ))}
          </>
        ) : (
          <tr>
            <td className="text-muted text-center">No items added</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

ManagedTable.propTypes = {
  onChange: PropTypes.func.isRequired,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    })
  )
};
ManagedTable.defaultProps = {
  listItems: []
};

export default ManagedTable;
