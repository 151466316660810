// Vendor
import React, { useContext } from "react";

// App
import { GolfDestinationContext } from "../GolfDestination";
import { ALPHANUMERIC_VALIDATOR } from "../../../common/Validators";
import InputCityTable from "./InputCityList";
function CityList(props) {
  const { state, dispatch } = useContext(GolfDestinationContext);

  // function validateNoDuplicates(name) {
  //   const { cities } = this.props;
  //   return !cities.some(c => c.name === name);
  // }

  return (
    <div className="mb-5">
      <h3 className="mb-3">Cities</h3>
      <InputCityTable
        items={state.cities || []}
        fields={[
          { key: "name", label: "Name", validator: ALPHANUMERIC_VALIDATOR },
          { key: "imgId", label: "Image Id" }
        ]}
        onChange={cities => {
          dispatch({ type: "CITIES", payload: cities });
        }}
      />
    </div>
  );
}

export default CityList;
