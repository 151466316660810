// Vendor
import React, { useState, useReducer } from "react";
import { useQuery } from "@apollo/client";
import { Table, Button } from "reactstrap";
import moment from "moment";

import { COURSE_TIMETABLES } from "../../../../common/Queries";
import Loader from "../../../ui/Loader";
import TeeTimeTable from "./TeeTimeTable";
import teeTimeTableListReducer from "./teeTimeTableListReducer";

export const TeeTimeTableListContext = React.createContext(null);

function ClubCourseTeeTimeTableList({ courseId }) {
  const [selectedTeeTimeTable, setTeeTimeTable] = useState(null);
  const [teeTimeTableOpen, setOpen] = useState(false);
  const [teeTimeTables, dispatch] = useReducer(teeTimeTableListReducer, null);

  const { loading, error } = useQuery(COURSE_TIMETABLES, {
    variables: { id: courseId },
    onCompleted({ getTeeSheetsByCourseId }) {
      dispatch({
        type: "INIT",
        payload: formatInit(getTeeSheetsByCourseId)
      });
    }
  });

  function formatInit(teetimetableList) {
    let formattedArr = [];
    teetimetableList.forEach(ttt => {
      let base = formatStringsToDate(ttt);
      base.daySplitOverride = base.daySplitOverride.map(o =>
        formatStringsToDate(o)
      );
      base.weekdayOverride = base.weekdayOverride.map(wkdo => {
        wkdo = formatStringsToDate(wkdo);
        wkdo.dayAvailability = wkdo.dayAvailability.map(da =>
          formatStringsToDate(da)
        );
        return wkdo;
      });
      base.dateOverride = base.dateOverride.map(dto => {
        dto = formatStringsToDate(dto);
        dto.dayAvailability = dto.dayAvailability.map(da =>
          formatStringsToDate(da)
        );
        return dto;
      });

      formattedArr.push(base);
    });
    return formattedArr;
  }

  /**
   * Traverse object and convert datestrings to Date
   *
   * Example:
   *
   * Input: { fieldA: "foo", formattedDate: "2020-08-22T12:00:00" }
   *
   * Output: { fieldA: "foo", date: Date("2020-08-22T12:00:00Z") }
   * @param { Object } object
   */
  function formatStringsToDate(object) {
    let formattedObject = {};
    Object.keys(object).forEach(key => {
      if (/^formatted.+/.test(key)) {
        let newKey = key.replace("formatted", "");
        newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
        if (Array.isArray(object[key])) {
          formattedObject[newKey] = object[key].map(d => new Date(d));
        } else formattedObject[newKey] = new Date(object[key]);
      } else formattedObject[key] = object[key];
    });

    return formattedObject;
  }

  // Guard
  if (!teeTimeTables) return null;

  if (loading) return <Loader />;
  if (error) return <p className="text-danger">Could not load time tables.</p>;

  return (
    <TeeTimeTableListContext.Provider value={{ teeTimeTables, dispatch }}>
      <div className="TeeTimeTableTable mt-5">
        <h3>Timetables</h3>

        <Table responsive className="border-0">
          <thead>
            <tr>
              <th>&nbsp;</th>
            </tr>
            <tr>
              <th>Start date</th>
              <th>End date</th>
              <th>Start time</th>
              <th>End time</th>
              <th>Interval(minutes between each flight)</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody className="border">
            {!teeTimeTables?.length && (
              <tr>
                <td colSpan="8" className="text-muted text-center">
                  No tee time schema defined for this course
                </td>
              </tr>
            )}
            {teeTimeTables?.length > 0 &&
              teeTimeTables?.map((teetimetable, i) => (
                <tr key={i}>
                  <td>
                    <small>
                      {moment(teetimetable.startDate).format("YYYY-MM-DD")}
                    </small>
                  </td>
                  <td>
                    <small>
                      {moment(teetimetable.endDate).format("YYYY-MM-DD")}
                    </small>
                  </td>
                  <td>
                    <small>
                      {moment(teetimetable.startTime).format("HH:mm")}
                    </small>
                  </td>
                  <td>
                    <small>
                      {moment(teetimetable.endTime).format("HH:mm")}
                    </small>
                  </td>
                  <td>
                    <small>{teetimetable.interval}</small>
                  </td>
                  <td>
                    <Button
                      className="p-0"
                      color="link"
                      onClick={() => {
                        setTeeTimeTable(teetimetable);
                        setOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Button
          color="link"
          onClick={() => {
            const newTimeTable = {
              startDate: moment().startOf("day").toDate(),
              endDate: moment().endOf("day").add(1, "day").toDate(),
              startTime: moment().hour(8).startOf("hour").toDate(),
              endTime: moment().hour(17).startOf("hour").toDate(),
              interval: 10,
              courseId,
              daySplitOverride: [],
              weekdayOverride: [],
              dateOverride: []
            };
            setTeeTimeTable(newTimeTable);
            setOpen(true);
          }}
        >
          + Add Tee Time Table
        </Button>

        <TeeTimeTable
          open={teeTimeTableOpen}
          teeTimeTable={selectedTeeTimeTable}
          onClose={() => {
            setOpen(false);
            setTeeTimeTable(null);
          }}
        />
      </div>
    </TeeTimeTableListContext.Provider>
  );
}

export default ClubCourseTeeTimeTableList;
