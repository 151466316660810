// Vendor
import React from "react";
import { useQuery } from "@apollo/client";
import { Card, Table } from "reactstrap";
import moment from "moment";

// App
import { GOLFERS_USER } from "../../common/Queries";
import Loader from "../ui/Loader";

export function GolfersUser(props) {
  const { id } = props.match.params;
  const { data, loading, error } = useQuery(GOLFERS_USER, {
    variables: { id }
  });

  if (error) return <p className="text-danger">{error.message}</p>;
  if (loading || !data) return <Loader fullscreen />;

  const {
    golfersUser: {
      email,
      firstName,
      lastName,
      language,
      newsletter,
      emailConfirmed,
      deleted,
      deletedAt,
      createdAt,
      updatedAt,

      generalInfo,
      courseBookings,
      currencySums
    }
  } = data;

  const CardRow = ({
    className = "",
    label,
    value,
    valueClass = "key-figure mb-0"
  }) => (
    <div className={`mx-3 mt-2 mb-2 ${className}`}>
      <dt className="text-black-50 small mb-0">{label}</dt>
      <dd className={valueClass}>{value}</dd>
    </div>
  );

  /**
   * @param { Object } props
   * @param { string } props.field
   */
  const ListCurrencyFields = ({ field }) => (
    <div className="d-inline-block">
      {currencySums.map(cs => (
        <p key={`${cs.currency}-${field}`} className="mb-0">
          {cs[field]}
        </p>
      ))}
    </div>
  );

  return (
    <div className="page">
      <h1>
        User {firstName} {lastName}
      </h1>
      <h2>ID: {id}</h2>

      <h2 className="mt-3 mb-0">General info</h2>
      <Card className="p-2">
        <div className="d-flex">
          <CardRow label="Email" value={email} />
          <CardRow label="Language" value={language} />
          <CardRow
            label="Newsletter subscription"
            value={newsletter ? "Yes" : "No"}
          />
          <CardRow
            label="Email confirmed"
            value={emailConfirmed ? "Yes" : "No"}
          />
          <CardRow label="Deleted" value={deleted ? "Yes" : "No"} />
          {deleted && (
            <CardRow
              label="Deleted at"
              value={moment(deletedAt).format("YYYY-MM-DD")}
            />
          )}
          <CardRow
            label="Registry date"
            value={moment(createdAt).format("YYYY-MM-DD")}
          />
          <CardRow
            label="Updated at"
            value={moment(updatedAt).format("YYYY-MM-DD")}
          />
        </div>
      </Card>

      <h2 className="mt-3 mb-0">General order history</h2>
      <Card className="p-2">
        <div className="d-flex">
          <CardRow label="Order count" value={generalInfo.orderCount} />
          <CardRow label="Teetimes count" value={generalInfo.teetimeCount} />
          <CardRow
            label="Cancellation rate"
            value={`${(generalInfo.cancellationRate * 100).toFixed(2)}%`}
          />
          <CardRow
            label="Last order date"
            value={moment(generalInfo.lastOrderDate).format("YYYY-MM-DD")}
          />
        </div>

        <p className="mb-0 mt-3 ml-3">Sales total</p>
        <div className="d-flex">
          <CardRow
            label="Currency"
            value={<ListCurrencyFields field="currency" />}
          />
          <CardRow label="Total" value={<ListCurrencyFields field="sum" />} />
          <CardRow
            label="Not cancelled"
            value={<ListCurrencyFields field="liveSum" />}
          />
        </div>
      </Card>

      <h2 className="mt-3 mb-0">Course order history</h2>
      <Card className="p-2">
        <Table hover size="sm" striped className="w-auto">
          <thead>
            <tr>
              <th className="Col--shrink">Course name</th>
              <th className="Col--shrink">Teetimes</th>
              <th className="Col--shrink">Not cancelled</th>
              <th className="Col--shrink">Players</th>
              <th className="Col--shrink">Not cancelled</th>
            </tr>
          </thead>
          <tbody>
            {courseBookings.map((cb, i) => (
              <tr key={i}>
                <td className="Col--shrink">{cb.courseName}</td>
                <td className="Col--shrink">{cb.teetimeCount}</td>
                <td className="Col--shrink">{cb.liveTeetimes}</td>
                <td className="Col--shrink">{cb.playerCount}</td>
                <td className="Col--shrink">{cb.livePlayers}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </div>
  );
}
