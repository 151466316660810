// Vendor
import React, {
  useState,
  useContext,
  createContext,
  useReducer,
  useEffect
} from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
  Button,
  Popover,
  PopoverBody
} from "reactstrap";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

// App
import { TeeTimeTableListContext } from "./TeeTimeTableList";
import { LayoutContext } from "../../../app/Layout";
import teeTimeTableReducer from "./teeTimeTableReducer";
import {
  TEE_TIME_TABLES_UPSERT,
  TEE_TIME_TABLES_DELETE
} from "../../../../common/Mutations";
import Loader from "../../../ui/Loader";
import { DefaultAvailabilitySettings } from "./categories/DefaultAvailabilitySettings";
import DateRangeFacet from "../../../ui/facet/DateRangeFacet";
import { WeekdayOverrideList } from "./categories/WeekdayOverrideList";
import { DateOverrideList } from "./categories/DateOverrideList";

export const TeeTimeTableContext = createContext(null);

function TeeTimeTable({ open, teeTimeTable: propsTeeTimeTable, onClose }) {
  // State & context
  const { dispatch: tableListDispatch } = useContext(TeeTimeTableListContext);
  const { addAlert } = useContext(LayoutContext);
  const [teeTimeTable, dispatch] = useReducer(teeTimeTableReducer, null);

  const [popoverOpen, setPopover] = useState(false);
  const [error, setError] = useState(false);

  //#region Mutation hooks
  const [upsertTeeTimeTable, { loading: upsertLoading }] = useMutation(
    TEE_TIME_TABLES_UPSERT,
    {
      onCompleted: handleUpsertSuccess,
      onError: () => handleFail("Failed to update tee time table")
    }
  );
  const [deleteTeeTimeTable, { loading: deleteLoading }] = useMutation(
    TEE_TIME_TABLES_DELETE,
    {
      onCompleted: handleDeleteSuccess,
      onError: () => handleFail("Failed to delete tee time table.")
    }
  );
  //#endregion

  //#region Effect hooks
  useEffect(() => {
    setError(false);

    if (open) {
      // Init table context on modal open
      dispatch({
        type: "INIT",
        // Create a copy object to avoid passing changes
        // by ref to object provided in props
        payload: JSON.parse(JSON.stringify(propsTeeTimeTable))
      });
    }
  }, [open, propsTeeTimeTable]);
  //#endregion

  //#region Lifecycle handlers
  function toggle() {
    setPopover(false);
    onClose();
  }

  function togglePopover() {
    setPopover(!popoverOpen);
  }
  //#endregion

  //#region Mutation handlers
  function handleDelete() {
    deleteTeeTimeTable({
      variables: { id: propsTeeTimeTable._id }
    });
  }

  function handleDeleteSuccess() {
    togglePopover();
    toggle();
    tableListDispatch({
      type: "TEETIMETABLE_DELETE",
      payload: teeTimeTable._id
    });
    addAlert({
      color: "success",
      message: "Tee Time Table successfully deleted"
    });
  }

  function handleSubmit() {
    let { _id: id, ...input } = teeTimeTable;
    upsertTeeTimeTable({ variables: { input, id } });
  }

  function handleUpsertSuccess(res) {
    toggle();
    tableListDispatch({
      type: "TEETIMETABLE_UPSERT",
      payload: formatReturnPayload(res.upsertTeeTimeTable)
    });
    addAlert({
      color: "success",
      message: "Tee Time Table successfully updated"
    });
  }

  function handleFail(message) {
    var element = document.getElementById("tee-time-table-modal");
    element.scrollIntoView();
    setError(message);
  }
  //#endregion

  //#region On change handlers
  function handleDateChange(dates) {
    // only update dates when both have changed
    if (dates.startDate && dates.endDate) {
      const startDate = dates.startDate.format();
      const endDate = dates.endDate.format();
      dispatch({ type: "SET_DATES", payload: { startDate, endDate } });
    }
  }
  //#endregion

  //#region Helper methods
  /**
   * To avoid timezone related issues backend passes any dates as strings.
   *
   * This method traverses the received [teetimetable] and converts date strings back into Date objects
   * @param {*} teetimetable
   */
  function formatReturnPayload(teetimetable) {
    let formattedTeeTimeTable = formatStringsToDate(teetimetable);
    formattedTeeTimeTable.daySplitOverride = formattedTeeTimeTable.daySplitOverride.map(
      o => formatStringsToDate(o)
    );
    formattedTeeTimeTable.weekdayOverride = formattedTeeTimeTable.weekdayOverride.map(
      wkdo => {
        wkdo = formatStringsToDate(wkdo);
        wkdo.dayAvailability = wkdo.dayAvailability.map(da =>
          formatStringsToDate(da)
        );
        return wkdo;
      }
    );
    formattedTeeTimeTable.dateOverride = formattedTeeTimeTable.dateOverride.map(
      dto => {
        dto = formatStringsToDate(dto);
        dto.dayAvailability = dto.dayAvailability.map(da =>
          formatStringsToDate(da)
        );
        return dto;
      }
    );

    return formattedTeeTimeTable;
  }

  /**
   * Traverse object and convert datestrings to Date
   *
   * Example:
   *
   * Input: { fieldA: "foo", formattedDate: "2020-08-22T12:00:00" }
   *
   * Output: { fieldA: "foo", date: Date("2020-08-22T12:00:00Z") }
   * @param { Object } object
   */
  function formatStringsToDate(object) {
    let formattedObject = {};
    Object.keys(object).forEach(key => {
      if (/^formatted.+/.test(key)) {
        let newKey = key.replace("formatted", "");
        newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
        if (Array.isArray(object[key])) {
          formattedObject[newKey] = object[key].map(d => new Date(d));
        } else formattedObject[newKey] = new Date(object[key]);
      } else formattedObject[key] = object[key];
    });

    return formattedObject;
  }
  //#endregion

  // Guard
  if (!teeTimeTable) return null;

  return (
    <TeeTimeTableContext.Provider
      value={{
        teeTimeTable,
        dispatch
      }}
    >
      <Modal
        isOpen={open}
        toggle={toggle}
        id="tee-time-table-modal"
        className="TeeTimeTable"
      >
        <ModalHeader toggle={toggle}>
          {teeTimeTable._id
            ? "Edit Tee Time Table"
            : "Create New Tee Time Table"}
        </ModalHeader>

        <ModalBody>
          {(upsertLoading || deleteLoading) && <Loader fullscreen />}
          {error && <Alert color="danger">{error}</Alert>}

          <div className="d-flex align-items-start">
            <div className="position-relative" style={{ zIndex: 4 }}>
              <label>Start/End Dates</label>
              <DateRangeFacet
                enableDatesOutsideRange
                showCalendarIcon
                small
                startDate={moment(teeTimeTable.startDate)}
                endDate={moment(teeTimeTable.endDate)}
                id="teetimetable-span-default"
                startDateId="teetimetable-span-default-start-date"
                endDateId="teetimetable-span-default-end-date"
                onDatesChange={handleDateChange}
                isOutsideRange={() => false}
              />
            </div>
          </div>

          <DefaultAvailabilitySettings />

          <WeekdayOverrideList />

          <DateOverrideList />
        </ModalBody>

        <ModalFooter>
          {teeTimeTable._id && (
            <>
              <Button
                color="link"
                className="text-danger"
                onClick={togglePopover}
                id="popover-season"
              >
                <FontAwesomeIcon icon="trash-alt" className="mr-1" />
                Delete Tee Time Table
              </Button>
              <Popover
                className="p-3"
                placement="top"
                style={{ maxWidth: "200px" }}
                isOpen={popoverOpen}
                target="popover-season"
                toggle={togglePopover}
              >
                <PopoverBody>
                  Are you sure?
                  <Button
                    className="ml-2"
                    color="danger"
                    size="sm"
                    onClick={handleDelete}
                  >
                    Yes
                  </Button>
                </PopoverBody>
              </Popover>
            </>
          )}
          <Button color="secondary" onClick={handleSubmit}>
            Save Time table
          </Button>
        </ModalFooter>
      </Modal>
    </TeeTimeTableContext.Provider>
  );
}

const DayAvailabilityArrayShape = PropTypes.arrayOf(
  PropTypes.shape({
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    interval: PropTypes.number
  })
);

TeeTimeTable.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  teeTimeTable: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    interval: PropTypes.number,
    courseId: PropTypes.any,
    daySplitOverride: DayAvailabilityArrayShape,
    weekdayOverride: PropTypes.arrayOf(
      PropTypes.shape({
        days: PropTypes.arrayOf(PropTypes.string),
        closed: PropTypes.bool,
        dayAvailability: DayAvailabilityArrayShape
      })
    ),
    dateOverride: PropTypes.arrayOf(
      PropTypes.shape({
        dates: PropTypes.arrayOf(PropTypes.any),
        closed: PropTypes.bool,
        dayAvailability: DayAvailabilityArrayShape
      })
    )
  })
};

export default TeeTimeTable;
