class IdGeneratorHelper {
  static getRandomId() {
    // Convert a random number to base 36 (numbers + letters),
    // and grab the first 9 characters after the decimal.
    return (
      "_" +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  }
}

export default IdGeneratorHelper;
