// Vendor
import React, { useContext } from "react";
import { Card } from "reactstrap";
import moment from "moment";
import { useQuery } from "@apollo/client";

// App
import CardRow from "../../ui/CardRow";
import URLHelper from "../../../common/URLHelper";
import { OrderContext } from "../Order";
import { SITES } from "../../../common/Queries";

export function OrderGeneralInfo() {
  const golfersURL = `https://${
    URLHelper.isProduction() ? "greenfee365" : "golfers-frontend-stage.onrender"
  }.com`;

  const {
    state: { formatted: order }
  } = useContext(OrderContext);
  const { data: sitesResult } = useQuery(SITES);
  if (!order) return null;

  return (
    <Card className="p2">
      <div className="d-flex">
        <CardRow className="mx-3" label="Order _id" value={order._id} />
        <CardRow
          className="mx-3"
          label="Order reference"
          value={order.orderReference}
        />
        <CardRow
          className="mx-3"
          label="Order confirmation page"
          value={
            <a
              href={`${golfersURL}/order-confirmation/${order._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Greenfee365.com
            </a>
          }
        />
      </div>

      <div className="d-flex">
        <CardRow
          className="mx-3"
          label="First name"
          value={order.firstName}
        />
        <CardRow
          className="mx-3"
          label="Last name"
          value={order.lastName}
        />
        <CardRow className="mx-3" label="Email" value={order.email} />
      </div>

      <div className="d-flex">
        <CardRow
          className="mx-3"
          label="Currency"
          value={order.currency}
        />
        <CardRow className="mx-3" label="Total" value={order.total} />
        <CardRow
          className="mx-3"
          label="Prepaid"
          value={order.payNowTotal}
        />
        <CardRow
          className="mx-3"
          label="Stripe service fee"
          value={order.stripeFee}
        />
        {order?.voucher && (
          <CardRow
            className="mx-3"
            label="Voucher"
            value={"(" + order.voucher.code + ") " + order.voucher.value + "%"}
          />
        )}

        {sitesResult?.sites && (
          <CardRow
            className="mx-3"
            label="Site"
            value={
              sitesResult.sites.find((x) => x._id === order.siteId)?.name ?? ""
            }
          />
        )}

        {order.partnerNetwork && (
          <CardRow
            className="mx-3"
            label="Partner network"
            value={order.partnerNetwork.name}
          />
        )}

        {order.tourOperator && (
          <>
            <CardRow
              className="mx-3"
              label="Tour operator"
              value={order.createdBy.tourOperator.name}
            />

            {order.createdBy && (
              <CardRow
                className="mx-3"
                label="Order created by"
                value={
                  <a
                    href={`/golfers-user/${order.createdBy.user._id}`}
                    rel="noopener noreferrer"
                  >
                    {order.createdBy.user.email}
                  </a>
                }
              />
            )}
          </>
        )}

        {order.affiliateId && (
          <>
            <CardRow
              className="mx-3"
              label="API booking from"
              value={order.affiliateId.name}
            />
          </>
        )}
      </div>

      <div className="d-flex">
        <CardRow
          className="mx-3"
          label="Created at"
          value={moment(order.createdAt).format("YYYY-MM-DD HH:mm")}
        />
        <CardRow
          className="mx-3"
          label="Updated at"
          value={moment(order.updatedAt).format("YYYY-MM-DD HH:mm")}
        />
      </div>
    </Card>
  );
}
