// Vendor
import React, { useContext } from "react";
import { Input, Row, Col, ButtonGroup, Button } from "reactstrap";

// App
import ProviderCollection from "../../common/ProviderCollection";
import { RateMismatchContext } from "./RateMismatchComponent";

/**
 * Inputs to filter on club/course names, provider & muted
 */
function RateMismatchFilterInput() {
  const {
    state: {
      providers,
      filter: { muteFilter, providerFilter }
    },
    dispatch
  } = useContext(RateMismatchContext);

  function onTextFilterChange(e) {
    const { value } = e.target;
    dispatch({ type: "FILTER_TEXT", payload: RegExp(value, "i") });
  }

  function onMuteFilterChange(_muteFilter) {
    if (_muteFilter !== muteFilter) {
      dispatch({ type: "FILTER_MUTE", payload: _muteFilter });
    }
  }

  function onProviderFilterChange({ target: { value } }) {
    dispatch({ type: "FILTER_PROVIDER", payload: value });
  }

  return (
    <Row>
      <Col>
        <Input
          type="text"
          placeholder="Filter on club or course name"
          onChange={onTextFilterChange}
        />
      </Col>
      <Col>
        <div className="form-control dropdown p-0 border-0" id="dropdown">
          <select
            id="provider-filter"
            className="d-block btn btn-outline-light dropdown-toggle w-100 h-100"
            onChange={onProviderFilterChange}
            value={providerFilter}
          >
            <option value="">Any provider</option>
            {providers.map(provider => (
              <option value={provider} key={provider}>
                {ProviderCollection.find(p => p.key === provider).label}
              </option>
            ))}
          </select>
        </div>
      </Col>
      <Col>
        <ButtonGroup className="float-right">
          <Button
            onClick={() => onMuteFilterChange(false)}
            color={muteFilter ? "outline-light" : "primary"}
          >
            Active
          </Button>
          <Button
            onClick={() => onMuteFilterChange(true)}
            color={muteFilter ? "primary" : "outline-light"}
          >
            Muted
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
}

export default RateMismatchFilterInput;
