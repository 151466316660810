// Vendor
import React, { useContext } from "react";
import { Row, Col } from "reactstrap";

// App
import { ClubContext } from "../Club";
import { InputField } from "../../ui/FunctionalComponents";
import { ClubKeyValueStoreFields } from "./ClubKeyValueStoreFields";

function ClubProviderDetails() {
  const { state, dispatch } = useContext(ClubContext);
  const { teeSheetProvider, teeSheetClubId } = state;

  // Hide if not applicable
  if (
    !teeSheetProvider ||
    teeSheetProvider === "NONE" ||
    teeSheetProvider === "EZ_LINKS" ||
    teeSheetProvider === "GF365_SIMPLE"
  )
    return null;

  if (teeSheetProvider === "NETGOLF")
    return (
      <div className="mt-3">
        <h3>Provider integration details</h3>
        <Row form>
          <ClubKeyValueStoreFields />
        </Row>
      </div>
    );

  return (
    <div className="mt-3">
      <h3>Provider integration details</h3>
      <Row form>
        <Col>
          <InputField
            targetKey="teeSheetClubId"
            label="Teesheet Club ID"
            value={teeSheetClubId}
            onChange={e =>
              dispatch({ type: "TEESHEET_CLUB_ID", payload: e.value })
            }
          />
        </Col>
        <ClubKeyValueStoreFields />
      </Row>
    </div>
  );
}

export default ClubProviderDetails;
