// Vendor
import React, { Fragment, useContext, useState } from "react";
import { Table, Popover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

// App
import { PARTNER_NETWORKS } from "../../common/Queries";
import { DELETE_PARTNER_NETWORK } from "../../common/Mutations";
import Loader from "../ui/Loader";
import { LayoutContext } from "../app/Layout";

const PartnerNetworks = () => {
  const { addAlert } = useContext(LayoutContext);
  const [popoverOpen, setPopoverOpen] = useState("");
  const [deletePartnerNetwork] = useMutation(DELETE_PARTNER_NETWORK, {
    onCompleted: (res) => {
      addAlert({
        color: "success",
        message: "PartnerNetwork deleted."
      });
    },
    onError: () =>
      addAlert({ color: "danger", message: "Could not delete PartnerNetwork." })
  });

  return (
    <div className="mb-4">
      <h1>Partner networks</h1>

      <Query query={PARTNER_NETWORKS} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error)
            return (
              <div className="text-danger">Error fetching partner networks</div>
            );

          const partnerNetworks = data.partnerNetworks;
          return (
            <Table borderless striped>
              <Fragment>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Emails</th>
                    <th>Registered users</th>
                    <th>Discount (%)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {partnerNetworks.length > 0 ? (
                    partnerNetworks.map((partnerNetwork, i) => {
                      const popoverId = `remove-partnerNetwork-popover-${i}`;
                      return (
                        <tr key={i}>
                          <td className="align-middle">
                            {partnerNetwork.name}
                          </td>
                          <td className="align-middle">
                            {partnerNetwork.users.length}
                          </td>
                          <td className="align-middle">
                            {
                              partnerNetwork.users.filter((u) => u.registered)
                                .length
                            }
                          </td>
                          <td className="align-middle">
                            {partnerNetwork.value}
                          </td>
                          <td className="align-middle">
                            <Button
                              className="p-0 text-muted d-inline-flex"
                              color="link"
                              id={popoverId}
                              onClick={() => setPopoverOpen(popoverId)}
                            >
                              <FontAwesomeIcon icon="trash-alt" />
                            </Button>
                            <Popover
                              target={popoverId}
                              isOpen={popoverOpen === popoverId}
                              placement="left"
                              toggle={() => setPopoverOpen("")}
                              className="p-3"
                            >
                              <PopoverBody>
                                Are you sure?
                                <Button
                                  color="link"
                                  onClick={() => {
                                    deletePartnerNetwork({
                                      variables: {
                                        partnerNetworkId: partnerNetwork._id
                                      },
                                      refetchQueries: [
                                        { query: PARTNER_NETWORKS }
                                      ]
                                    });
                                    setPopoverOpen("");
                                  }}
                                >
                                  Delete
                                </Button>
                              </PopoverBody>
                            </Popover>
                            <Link
                              className="btn btn-link"
                              to={`/partner-network/${partnerNetwork._id}`}
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-muted text-center">
                        No partnerNetworks found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Fragment>
            </Table>
          );
        }}
      </Query>

      <div className="Save-container">
        <div className="Save-container__phantom" />
        <div className="Save-container__main">
          <Link
            to="/partner-network"
            id="btn-new"
            className="btn btn-secondary btn-lg"
          >
            Create new
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PartnerNetworks;
