// Vendor
import React, { Fragment } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { Query } from "@apollo/client/react/components";

// App
import { SITES } from "../../common/Queries";
import Loader from "../ui/Loader";
const Sites = () => {
  return (
    <div className="mb-4">
      <h1>Sites</h1>
      <Query query={SITES} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error)
            return <div className="text-danger">Error fetching sites</div>;

          const sites = data.sites;
          return (
            <Table borderless striped>
              <Fragment>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {sites.length > 0 ? (
                    sites.map((site, i) => {
                      return (
                        <tr key={i}>
                          <td>{site._id}</td>
                          <td>{site.name}</td>
                          <td>
                            <Link
                              className="btn btn-link"
                              to={`/site/${site._id}`}
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-muted text-center">
                        No sites found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Fragment>
            </Table>
          );
        }}
      </Query>

      <div className="Save-container">
        <div className="Save-container__phantom" />
        <div className="Save-container__main">
          <Link to="/site" id="btn-new" className="btn btn-secondary btn-lg">
            Create new
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sites;
