import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput
} from "availity-reactstrap-validation";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table
} from "reactstrap";
import CurrencySelect from "../../ui/CurrencySelect";
import { GET_CURRENCIES } from "../../../common/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_CURRENCY, UPDATE_CURRENCY } from "../../../common/Mutations";
import { LayoutContext } from "../../app/Layout";

const CurrencyModal = ({ isOpen, toggle }) => {
  const { addAlert } = useContext(LayoutContext);
  const [showEditInput, setshowEditInput] = useState(false);
  const [showAddNewInput, setshowAddNewInput] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const handleAddSubmit = (event, values) => {
    CreateCurrency({
      variables: {
        input: {
          name: values.name,
          value: +values.value
        }
      }
    });
  };

  const handleEditSubmit = (event, values) => {
    UpdateCurrency({
      variables: {
        input: {
          name: selectedCurrency.name,
          value: +values.value
        }
      }
    });
  };

  // query
  const { data, refetch } = useQuery(GET_CURRENCIES);

  // mutation
  const [CreateCurrency] = useMutation(CREATE_CURRENCY, {
    onCompleted: (data) => {
      addAlert({
        color: "success",
        message: "Currency added successfully!"
      });
      setSelectedCurrency({});
      setshowAddNewInput(false);
      refetch();
    }
  });
  const [UpdateCurrency] = useMutation(UPDATE_CURRENCY, {
    onCompleted: () => {
      addAlert({
        color: "success",
        message: "Currency updated successfully!"
      });
      setSelectedCurrency({});
      setshowEditInput(false);
      refetch();
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="SubInvoiceModal"
      onClosed={() => {
        setshowEditInput(false);
        setshowAddNewInput(false);
        setSelectedCurrency(null);
      }}
    >
      <ModalHeader toggle={toggle}>Currency Modal</ModalHeader>
      <ModalBody>
        <Button
          onClick={() => {
            setshowAddNewInput(true);
            setshowEditInput(false);
            setSelectedCurrency(null);
          }}
        >
          Add new currency
        </Button>
        <Table striped>
          <thead>
            <tr>
              <th>Currency</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.getCurrencies.map((currency) => {
              return (
                <tr key={currency.name}>
                  <td>{currency.name}</td>
                  <td>{currency.value}</td>
                  <td>
                    <Button
                      onClick={() => {
                        setshowEditInput(true);
                        setshowAddNewInput(false);
                        setSelectedCurrency(currency);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        {showAddNewInput && (
          <AvForm onValidSubmit={handleAddSubmit}>
            <CurrencySelect
              onChange={(value) => {
                setSelectedCurrency({
                  ...selectedCurrency,
                  name: value
                });
              }}
            />
            <AvGroup>
              <Label>Currency</Label>
              <div className="d-flex align-items-end">
                <AvInput name="value" className="w-75" required />
                <AvInput
                  className="font-weight-bold ml-2 w-25"
                  disabled
                  name="name"
                  value={selectedCurrency?.name || ""}
                />
              </div>
              <AvFeedback>Please enter value!</AvFeedback>
            </AvGroup>

            <Button className="mt-3" color="primary">
              Submit values
            </Button>
          </AvForm>
        )}
        {showEditInput && (
          <AvForm onValidSubmit={handleEditSubmit}>
            <AvGroup>
              <Label>Currency</Label>
              <div className="d-flex align-items-end">
                <AvInput
                  name="value"
                  className="w-75"
                  required
                  value={selectedCurrency?.value || ""}
                />
                <AvInput
                  className="font-weight-bold ml-2 w-25"
                  disabled
                  name="name"
                  value={selectedCurrency?.name || ""}
                />
              </div>
              <AvFeedback>Please enter value!</AvFeedback>
            </AvGroup>
            <Button className="mt-3" color="primary">
              Submit values
            </Button>
          </AvForm>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CurrencyModal;
