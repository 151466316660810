import React, { useContext } from "react";
import { Table } from "reactstrap";
import { ClubCourseContext } from "../../ClubCourse";
import TableCell from "./TableCell";

const SeasonTypeList = ({ handleEdit, handleDelete }) => {
  const { state } = useContext(ClubCourseContext);
  const { seasonTypes: season_types } = state;

  return (
    <Table responsive className="border-0">
      <thead>
        <tr>
          <th>Season Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="border">
        {!season_types.length && (
          <tr>
            <td colSpan="8" className="text-muted text-center">
              No season types defined for this course
            </td>
          </tr>
        )}
        {season_types.length > 0 &&
          season_types.map((season_type, index) => {
            // read cells or write cells
            return (
              <TableCell
                key={season_type._id || index}
                //deleting type from state, that's why null is passed
                seasonInTransit={null}
                seasonType={season_type}
                handleDelete={handleDelete}
                handleUpdate={handleEdit}
              />
            );
          })}
      </tbody>
    </Table>
  );
};

export default SeasonTypeList;
