// Vendor
import React, { useReducer, useEffect, useState, createContext } from "react";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useHistory } from "react-router-dom";

// App
import { GolfersUserListTable } from "./GolfersUserListTable";
import { GolfersUserSearch } from "./GolfersUserSearch";
import golfersUserSearchReducer from "./golfersUserSearchReducer";
import { GOLFERS_USERS_SEARCH } from "../../common/Queries";
import useDebounce from "../../common/useDebounce";
import URLHelper from "../../common/URLHelper";
import CondensedNavButtonBar from "../ui/CondensedNavButtonBar";

export const GolfersUsersContext = createContext({});

const defaultState = {
  users: [],
  hits: 0,
  page: 0,
  sorting: {
    sortOn: "LAST_ORDER_DATE",
    ascending: false
  }
};
const PAGE_SIZE = 20;

function GolfersUserList() {
  const history = useHistory();
  const [urlParams, setUrlParams] = useState(
    URLHelper.queryToState(history.location)
  );

  const [state, dispatch] = useReducer(golfersUserSearchReducer, {
    ...defaultState,
    filter: formatFilterFromUrl()
  });
  const { filter, page, sorting, hits } = state;

  const debouncedFilter = useDebounce(filter, 750);
  const [activeFilter, setActiveFilter] = useState(filter);

  const [searchUsers, { loading, error }] = useLazyQuery(GOLFERS_USERS_SEARCH, {
    onCompleted: handleSearchComplete
  });

  function handleSearchComplete({ golfersUserList }) {
    dispatch({ type: "SEARCH_RESULTS", payload: golfersUserList });
  }

  useEffect(() => {
    searchUsers({
      variables: {
        page,
        pageSize: PAGE_SIZE,
        sorting,
        filter: activeFilter
      }
    });
  }, [activeFilter, page, sorting, searchUsers]);

  useEffect(() => {
    let newUrlParams = {};
    for (let filterKey of Object.keys(debouncedFilter)) {
      if (
        debouncedFilter[filterKey] !== null &&
        debouncedFilter[filterKey] !== ""
      )
        if (/date/i.test(filterKey)) {
          const { startDate, endDate } = debouncedFilter[filterKey];
          let tempObj = {};
          if (startDate)
            tempObj.startDate = moment(startDate).format("YYYY-MM-DD");
          if (endDate) tempObj.endDate = moment(endDate).format("YYYY-MM-DD");

          newUrlParams[filterKey] = tempObj;
        } else {
          newUrlParams[filterKey] = debouncedFilter[filterKey];
        }
    }

    dispatch({ type: "SET_PAGE_INDEX", payload: 0 });
    setUrlParams(newUrlParams);
    setActiveFilter(debouncedFilter);
  }, [debouncedFilter, setUrlParams, setActiveFilter, dispatch]);

  useEffect(() => {
    history.push({ search: URLHelper.queryFromState(urlParams) });
  }, [urlParams, history]);

  function formatFilterFromUrl() {
    let urlFilter = URLHelper.queryToState(history.location);
    urlFilter.coursesInclude = urlFilter.coursesInclude ?? [];
    urlFilter.coursesExclude = urlFilter.coursesExclude ?? [];
    for (let key of Object.keys(urlFilter)) {
      if (key === "registered")
        urlFilter.registered = urlFilter.registered === "true" ? true : false;
      else if (urlFilter[key].min) {
        urlFilter[key].min = Number(urlFilter[key].min);
        if (urlFilter[key].max) urlFilter[key].max = Number(urlFilter[key].max);
      } else if (/date/i.test(key)) {
        const { startDate, endDate } = urlFilter[key];
        let tempObj = {};
        if (startDate) tempObj.startDate = moment(startDate);
        if (endDate) tempObj.endDate = moment(endDate);
        urlFilter[key] = tempObj;
      }
    }

    return urlFilter;
  }

  function onNavButtonClick(index) {
    dispatch({ type: "SET_PAGE_INDEX", payload: Number(index) });
  }

  return (
    <div className="page">
      <GolfersUsersContext.Provider value={{ state, dispatch }}>
        <h1 className="mb-4">Platform Users</h1>

        <GolfersUserSearch />

        <GolfersUserListTable loading={loading} error={error} />

        <CondensedNavButtonBar
          onBtnClick={onNavButtonClick}
          pageCount={Math.max(Math.ceil(hits / PAGE_SIZE), 1)}
          value={page}
        />
      </GolfersUsersContext.Provider>
    </div>
  );
}

export default GolfersUserList;
