import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  ListGroup,
  ListGroupItem,
  Popover,
  PopoverBody,
  Row
} from "reactstrap";
import moment from "moment";
// App
import CardRow from "../../ui/CardRow";

import SubinvoiceModal from "./SubinvoiceModal";
import {
  InvoiceStatusHelper,
  STATUS_OPTIONS
} from "../../../common/InvoiceStatusHelper";
import PaymentDetails from "./PaymentDetails";
import SubInvoicePayment from "./SubInvoicePayment";
import { InvoiceContext } from "../Invoice";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { REGENERATE_INVOICE, UPDATE_INVOICE } from "../../../common/Mutations";
import { LayoutContext } from "../../app/Layout";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import InvoiceStatusSelect from "../../ui/InvoiceStatusSelect";
import { GET_CLUB_BY_ID_QUERY } from "../../../common/Queries";
import Loader from "../../ui/Loader";

const InvoiceGeneralInfo = () => {
  const {
    invoice,
    isSubInvoice,
    dispatch,
    toggleEdit,
    editing,
    initialState,
    initStates
  } = useContext(InvoiceContext);
  const { addAlert } = useContext(LayoutContext);
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const history = useHistory();
  // states
  const [showCollapsable, setShowCollapsable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverRegenerate, setPopoverRegenerate] = useState(false);

  // handlers
  const toggleCollapsable = () => setShowCollapsable(!showCollapsable);
  const toggleModal = () => setShowModal(!showModal);
  const handleRegenerateInvoice = () => {
    if (invoice?.status === "completed") return;
    setPopoverRegenerate(false);
    regenerateValues({
      variables: {
        regenerateInput: {
          invoiceId: invoice._id,
          type: "ORDER"
        }
      }
    });
  };
  const togglePopoverRegenerate = () =>
    setPopoverRegenerate(!popoverRegenerate);
  const handleOptionChange = (status) => {
    if (status === "queued") {
      setPopoverOpen(true);
    }
    dispatch({ type: "STATUS", payload: status });
  };

  const handleSaveChanges = () => {
    if (popoverOpen) {
      return addAlert({
        color: "danger",
        message: "Please confirm the status change first!"
      });
    }
    toggleEdit();

    const input = { ...invoice };
    delete input._id;
    delete input.invoiceRows;
    delete input.subinvoices;
    delete input.fundsTransferred;
    delete input.comments;

    // call api
    updateInvoice({
      variables: {
        id: invoice._id,
        invoiceInput: input
      }
    });
  };

  const handleDiscardChanges = () => {
    if (popoverOpen) setPopoverOpen(false);
    dispatch({ type: "STATUS", payload: initialState.status });
    toggleEdit();
  };

  const handleConfirmPopover = () => {
    // status is saved already in the state
    setPopoverOpen(false);
  };
  const handleCancelPopover = () => {
    if (popoverOpen) {
      setPopoverOpen(false);
      dispatch({ type: "STATUS", payload: initialState.status });
    }
  };

  const onUpdateInvoiceCompleted = (res) => {
    if (res.updateInvoice.ok) {
      initStates(res.updateInvoice.invoice);
      return addAlert({
        color: "success",
        message: "Invoice updated successfully"
      });
    }
    addAlert({
      color: "danger",
      message: res.updateInvoice.returnMessage ?? "Error while updating invoice"
    });
  };
  const onGetClubByIdCompleted = (data) => {
    const { getClubById } = data;
    if (getClubById?._id) {
      const courseSlug = getClubById.courses.find(
        (course) => course._id.toString() === invoice.courseId
      )?.slug;

      if (!courseSlug) {
        return addAlert({
          color: "danger",
          message: "Error while trying to get course slug"
        });
      }
      history.push(`/club/${getClubById.slug}?course=${courseSlug}`);
    }
  };

  // queries getClubById
  const [getClubById, { loading: loadingClubById }] = useLazyQuery(
    GET_CLUB_BY_ID_QUERY,
    {
      onCompleted: onGetClubByIdCompleted,
      onError: (error) => {
        console.log("error");
        addAlert({
          color: "danger",
          message: "Error while trying to get club"
        });
      }
    }
  );

  // mutation
  const [regenerateValues] = useMutation(REGENERATE_INVOICE, {
    onCompleted: (data) => {
      if (data.regenerateValues.ok) {
        addAlert({
          color: "success",
          message: "Invoice regenerated successfully."
        });
        return window.location.reload();
      }
      addAlert({
        color: "danger",
        message: data.regenerateValues.returnMessage
      });
    },
    onError: (error) => {
      addAlert({
        color: "danger",
        message: "Regenerate invoice failed"
      });
    }
  });

  const [updateInvoice] = useMutation(UPDATE_INVOICE, {
    onCompleted: onUpdateInvoiceCompleted,
    onError: () => {
      addAlert({
        color: "danger",
        message: "Error while trying to update invoice"
      });
    }
  });

  const handleCourseNameClicked = () => {
    getClubById({ variables: { id: invoice.clubId } });
  };

  useEffect(() => {
    // subinvoices should always be shown
    if (isSubInvoice) {
      return setShowCollapsable(true);
    }

    if (invoice?.subinvoices?.length && !showCollapsable) {
      setShowCollapsable(true);
    }
  }, [invoice]); // eslint-disable-line react-hooks/exhaustive-deps

  // guard
  if (!invoice?._id) return null;

  return (
    <Container>
      <div className="d-flex w-100 justify-content-between mb-2">
        <div>
          {editing ? (
            <>
              <Button
                className="mr-2"
                size="md"
                color="primary"
                onClick={handleSaveChanges}
              >
                <span className="mr-1">Save changes</span>
                <FontAwesomeIcon icon={faCheck} />
              </Button>
              <Button size="md" color="danger" onClick={handleDiscardChanges}>
                <span className="mr-1">Discard changes</span>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ) : (
            <Button
              size="md"
              className="p-0"
              color="link"
              disabled={isSubInvoice}
              onClick={() => toggleEdit(true)}
            >
              Edit
            </Button>
          )}
        </div>
        <Button
          color="primary"
          size="md"
          id="regenerate-invoices"
          onClick={togglePopoverRegenerate}
          disabled={invoice?.status === "completed" || editing || isSubInvoice}
        >
          Regenerate invoice
        </Button>
        <Popover
          isOpen={popoverRegenerate}
          placement="left"
          target="regenerate-invoices"
        >
          <PopoverBody>
            <div className="mb-2">
              <div>
                <b>Are you sure?</b>
              </div>
              <p className="mb-1">
                This will override the current commission and invoice values
                with the current rates set in rates table on{" "}
                {invoice?.courseName}
              </p>
            </div>
            <Button color="primary" size="sm" onClick={handleRegenerateInvoice}>
              Yes
            </Button>
            <Button
              className="ml-2"
              color="danger"
              size="sm"
              onClick={togglePopoverRegenerate}
            >
              Cancel
            </Button>
          </PopoverBody>
        </Popover>
      </div>

      <Row>
        <Col xs="12" lg="7">
          <Card className="p2 d-flex h-100">
            <CardHeader>
              <h4>
                {isSubInvoice
                  ? "Sub invoice general info"
                  : "Invoice general info"}
              </h4>
            </CardHeader>
            <CardBody className="d-flex flex-wrap">
              <CardRow
                defaultClassName={false}
                className="col-4 my-2"
                label="Name"
                value={invoice?.name ?? "N/A"}
              />
              <CardRow
                defaultClassName={false}
                className="col-4 my-2 pr-1"
                label="Invoice id"
                value={invoice?._id ?? "N/A"}
              />
              <div className="col-4 my-2">
                <dt className="text-black-50 small mb-0">Order reference</dt>
                <dd className="mb-0">
                  <Link to={`/order/${invoice?.orderReference}`}>
                    {invoice?.orderReference ?? "N/A"}
                  </Link>
                </dd>
              </div>
              <CardRow
                defaultClassName={false}
                className="col-4 my-2"
                label="Invoice reference"
                value={invoice?.reference ?? "N/A"}
              />
              {editing ? (
                <CardRow
                  defaultClassName={false}
                  className="col-4 my-2"
                  label="Status"
                  value={
                    <div ref={inputRef}>
                      <InvoiceStatusSelect
                        options={STATUS_OPTIONS}
                        value={invoice.status}
                        initalValue={invoice.status}
                        isMulti={false}
                        onChange={handleOptionChange}
                      />
                      <Popover
                        isOpen={popoverOpen}
                        innerRef={popoverRef}
                        placement="bottom"
                        target={inputRef}
                      >
                        <PopoverBody>
                          <div>
                            This will queue the invoice again, are you sure?
                          </div>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={handleConfirmPopover}
                          >
                            Yes
                          </Button>
                          <Button
                            className="ml-2"
                            color="secondary"
                            size="sm"
                            onClick={handleCancelPopover}
                          >
                            No
                          </Button>
                        </PopoverBody>
                      </Popover>
                    </div>
                  }
                />
              ) : (
                <CardRow
                  defaultClassName={false}
                  className="col-4 my-2"
                  label="Status"
                  value={
                    InvoiceStatusHelper.findStatusByValue(invoice?.status)
                      ?.label ?? "N/A"
                  }
                  customValueStyles={{
                    color: InvoiceStatusHelper.getStatusColor(invoice?.status)
                  }}
                />
              )}
              <CardRow
                defaultClassName={false}
                className="col-4 my-2"
                label="Funds Transferred"
                value={
                  <div
                    style={{
                      color: InvoiceStatusHelper.getTransferredStatusColor(
                        invoice?.fundsTransferred
                      )
                    }}
                  >
                    {
                      InvoiceStatusHelper.findTransferredOption(
                        invoice?.fundsTransferred
                      )?.label
                    }
                  </div>
                }
              />
              {!isSubInvoice && (
                <CardRow
                  defaultClassName={false}
                  className="col-4 my-2"
                  label="Disbursement number"
                  value={invoice?.disbursementNum ?? "N/A"}
                />
              )}
              <CardRow
                defaultClassName={false}
                className="col-4 my-2"
                label="Currency"
                value={invoice?.currency ?? "N/A"}
              />
              {!isSubInvoice && (
                <CardRow
                  defaultClassName={false}
                  className="col-4 my-2"
                  label="VAT rate"
                  value={invoice?.vatRate ?? "N/A"}
                />
              )}
              <CardRow
                defaultClassName={false}
                className="col-4 my-2 pr-1"
                label="Course name"
                value={
                  loadingClubById ? (
                    <div style={{ width: "24px" }}>
                      <Loader size={40} />
                    </div>
                  ) : (
                    <Button
                      color="link"
                      className="p-0"
                      onClick={handleCourseNameClicked}
                    >
                      {invoice?.courseName ?? "N/A"}
                    </Button>
                  )
                }
              />
              <CardRow
                defaultClassName={false}
                className="col-4 my-2 pr-1"
                label="Club name"
                value={invoice?.clubName ?? "N/A"}
              />
              {!isSubInvoice && (
                <CardRow
                  defaultClassName={false}
                  className="col-4 my-2"
                  label="Play Date"
                  value={
                    invoice?.playDate
                      ? moment(invoice.playDate).format("YYYY-MM-DD HH:mm")
                      : "N/A"
                  }
                />
              )}
              {!isSubInvoice && (
                <CardRow
                  defaultClassName={false}
                  className="col-4 my-2"
                  label="Cancellation Date"
                  value={
                    invoice?.cancellationDate
                      ? moment(invoice.cancellationDate).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "N/A"
                  }
                />
              )}
              <CardRow
                defaultClassName={false}
                className="col-4 my-2"
                label="Payout Date"
                value={
                  invoice?.payoutDate
                    ? moment(invoice.payoutDate).format("YYYY-MM-DD HH:mm")
                    : "N/A"
                }
              />
              <CardRow
                defaultClassName={false}
                className="col-4 my-2"
                label="Created At"
                value={
                  invoice?.createdAt
                    ? moment(invoice.createdAt).format("YYYY-MM-DD HH:mm")
                    : "N/A"
                }
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" lg="5">
          {isSubInvoice ? <SubInvoicePayment /> : <PaymentDetails />}
        </Col>
      </Row>

      <Collapse isOpen={showCollapsable}>
        <Row className="mt-4">
          <Col lg="7">
            {!isSubInvoice ? (
              <Card className="h-100">
                <CardHeader>
                  <h4>Sub Invoices</h4>
                </CardHeader>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <ListGroup>
                        {invoice?.subinvoices?.length > 0 ? (
                          invoice.subinvoices.map((reference) => (
                            <ListGroupItem
                              key={reference._id}
                              tag="a"
                              href={`/invoice/${reference._id}`}
                            >
                              Sub invoice ref# {reference.reference}
                            </ListGroupItem>
                          ))
                        ) : (
                          <p>This invoice does not have any sub invoices.</p>
                        )}
                      </ListGroup>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        onClick={toggleModal}
                        disabled={editing}
                      >
                        Create new
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Card className="h-100">
                <CardHeader>
                  <h4>Parent Invoice details</h4>
                </CardHeader>
                <CardBody>
                  <ListGroupItem
                    tag="a"
                    href={`/invoice/${invoice?.referenceTo?._id}`}
                  >
                    View Parent Invoice: {invoice?.referenceTo?.reference}
                  </ListGroupItem>
                </CardBody>
              </Card>
            )}
          </Col>
          {isSubInvoice && (
            <Col lg="5">
              <Card className="h-100">
                <CardHeader>
                  <h4>Sub invoice description</h4>
                </CardHeader>
                <CardBody>
                  <p>{invoice?.description}</p>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Collapse>
      {!isSubInvoice && (
        <Button
          onClick={toggleCollapsable}
          color="link"
          size="md"
          className="my-1 p-0"
        >
          {showCollapsable ? "Hide" : "Show"} sub invoices
        </Button>
      )}
      <SubinvoiceModal isOpen={showModal} toggle={toggleModal} />
    </Container>
  );
};
export default InvoiceGeneralInfo;
