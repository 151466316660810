// Vendor
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import {
  AvGroup,
  AvForm,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";

// App
import { EMAIL_VALIDATOR, NAME_VALIDATOR } from "../../common/Validators";

class ContactForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    contact: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      email: PropTypes.string
    }).isRequired
  };

  constructor(props) {
    super(props);
    this._isMounted = false;

    const { name, title, email } = this.props.contact;
    this.state = {
      name,
      title,
      email,
      isDirty: false,
      newName: name,
      newTitle: title,
      newEmail: email
    };

    // Bind handlers
    this.beforeSubmit = this.beforeSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.setDirty = this.setDirty.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  beforeSubmit(e) {
    e.stopPropagation();
  }

  handleValidSubmit() {
    const { newName, newTitle, newEmail } = this.state;
    this.setState({ isDirty: false }, () =>
      this.props.onClose({
        name: newName,
        title: newTitle,
        email: newEmail
      })
    );
  }

  toggle() {
    const { name, title, email } = this.state;
    this.props.onClose({
      name,
      title,
      email
    });
  }

  setDirty() {
    if (this._isMounted) {
      this.setState({ isDirty: true });
    }
  }

  render() {
    const { newName, newTitle, newEmail, isDirty } = this.state;
    return (
      <Modal
        className="ContactForm auth-form"
        isOpen={true}
        toggle={this.toggle}
        centered={true}
      >
        <ModalHeader toggle={this.toggle}>{`Club contact${
          isDirty ? "*" : ""
        }`}</ModalHeader>
        <ModalBody>
          <AvForm
            beforeSubmitValidation={this.beforeSubmit}
            onValidSubmit={this.handleValidSubmit}
          >
            <AvGroup className="mb-3">
              <div
                className={`icon-input-group icon-input-group-lg float-label${
                  newName.length > 0 ? " active" : ""
                }`}
              >
                <Label for="contactName">{"Name"}</Label>
                <AvInput
                  className="form-control-lg"
                  name="contactName"
                  value={newName}
                  onChange={el => {
                    this.setState({ newName: el.target.value }, this.setDirty);
                  }}
                  validate={NAME_VALIDATOR}
                />
                <AvFeedback>{NAME_VALIDATOR.pattern.errorMessage}</AvFeedback>
              </div>
            </AvGroup>

            <AvGroup className="mb-3">
              <div
                className={`icon-input-group icon-input-group-lg float-label${
                  newTitle.length > 0 ? " active" : ""
                }`}
              >
                <Label for="contactTitle">{"Title"}</Label>
                <AvInput
                  className="form-control-lg"
                  name="contactTitle"
                  value={newTitle}
                  onChange={el => {
                    this.setState({ newTitle: el.target.value }, this.setDirty);
                  }}
                  // validate={EMAIL_VALIDATOR}
                />
              </div>
            </AvGroup>

            <AvGroup className="mb-3">
              <div
                className={`icon-input-group icon-input-group-lg float-label${
                  newEmail.length > 0 ? " active" : ""
                }`}
              >
                <Label for="contactEmail">{"Email"}</Label>
                <AvInput
                  className="form-control-lg"
                  name="contactEmail"
                  value={newEmail}
                  onChange={el => {
                    this.setState({ newEmail: el.target.value }, this.setDirty);
                  }}
                  validate={EMAIL_VALIDATOR}
                />
                <AvFeedback>{EMAIL_VALIDATOR.email.errorMessage}</AvFeedback>
              </div>
            </AvGroup>
            <Button color="primary" block size="lg" type="submit">
              OK
            </Button>
          </AvForm>
        </ModalBody>
      </Modal>
    );
  }
}

export default ContactForm;
