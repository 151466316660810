export default function tourOperatorReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return {
        ...action.payload,
        users: action.payload.users.map((user) => ({
          ...user
        })),
        fallback: [...action.payload.users],
        loaded: true,
        loading: false
      };
    case "NAME":
      return { ...state, name: action.payload };
    case "VALUE":
      return { ...state, value: action.payload };
    case "REMOVE_EMAIL":
      return {
        ...state,
        users: [...state.users.filter((user) => user.email !== action.payload)]
      };

    case "UPDATE_USERS":
      return {
        ...state,
        users: action.payload
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload
      };

    case "ADD_SINGLE_USER":
      return {
        ...state,
        users: [{ ...action.payload }, ...state.users]
      };

    default:
      return state;
  }
}
