// Vendor
import React from "react";
import { Button, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import TranslationTable from "../../ui/TranslationTable";
import { ImageIdCard } from "../../ui/ImageIdCard";

/**
 * @param { Object } props
 * @param { Category } props.category
 * @param { VoidCallback<Category> } props.onChange
 * @param { VoidCallback<void> } props.onDelete
 */
export function Category(props) {
  const { category, onChange, onDelete } = props;
  const fallback = [
    { key: "title", label: "Title", value: category.title },
    {
      key: "description",
      label: "Description",
      value: category.description
    }
  ];

  /**
   * @param { {key: "fallbacks", value: {key: string, value: string}[]} | {key: "i18n", value: i18nObj<{title: string, description: string}>} } param
   */
  function translationOnChangeHandler({ key, value }) {
    const categoryCopy = { ...category };
    if (key === "fallbacks") {
      categoryCopy.title = value.find((t) => t.key === "title").value;
      categoryCopy.description = value.find(
        (t) => t.key === "description"
      ).value;
    } else if (key === "i18n") {
      categoryCopy.i18n = value;
    }
    onChange(categoryCopy);
  }

  return (
    <Card className="border border-top-0 p-4">
      <ImageIdCard
        className="w-50 mb-4"
        publicId={category.imgId}
        onChangeTargetKey="imgId"
        onChange={({ _, value }) => onChange({ ...category, imgId: value })}
      />

      <TranslationTable
        onChange={translationOnChangeHandler}
        fallbacks={fallback}
        i18n={category.i18n}
      />

      <div className="d-block w-100 p-1">
        <Button
          className="float-right"
          color="danger"
          type="button"
          onClick={onDelete}
        >
          <FontAwesomeIcon icon="trash-alt" className="mr-2" />
          Remove
        </Button>
      </div>
    </Card>
  );
}
