// Vendor
import React, { useContext, useState } from "react";
import { Button, Popover, PopoverBody } from "reactstrap";

// App
import Loader from "../../ui/Loader";
import { OrderContext } from "../Order";

export function OrderSendEmailButton({
  id,
  className = "",
  onClick,
  loading,
  error = undefined,
  success = undefined,
  children
}) {
  const [showPopover, setShowPopover] = useState(false);
  const {
    state: { formatted: order, targetEmail }
  } = useContext(OrderContext);

  function toggle() {
    setShowPopover(prev => !prev);
  }

  function handleBlur() {
    setShowPopover(false);
  }

  function handlePopoverClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <div className={className} onBlur={handleBlur}>
      <Button id={id} onClick={toggle}>
        {children}
      </Button>

      <Popover
        onMouseDown={handlePopoverClick}
        placement="bottom"
        id="cancellation-email-popover"
        target={id}
        isOpen={showPopover}
        toggle={toggle}
      >
        <PopoverBody className="p-2">
          <div className="d-flex justify-content-center">
            <p className="mb-0">
              This email will go to "
              {targetEmail.active ? targetEmail.email : order.email}"
            </p>
          </div>
          {success !== undefined && (
            <div
              className={`d-flex justify-content-center font-weight-bold text-${
                success ? "success" : "danger"
              }`}
            >
              {success ? "Email sent" : "Failed to send email"}
            </div>
          )}
          {error && (
            <div className="d-flex justify-content-center font-weight-bold text-danger">
              Failed to send email
              <br />
              {error.message}
            </div>
          )}
          <div className="d-flex justify-content-center">
            <Button
              className="flex-fill mt-2"
              size="sm"
              disabled={loading}
              onClick={() =>
                onClick(targetEmail.active ? targetEmail.email : order.email)
              }
            >
              {loading ? <Loader /> : "Send"}
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
}
