class AmenityCollection {
  items = [
    "Buggy",
    "Changing room",
    "Chipping green",
    "Driving range",
    "Golf clubs rental",
    "Golf lessons",
    "Lockers",
    "Practice bunker",
    "Pro shop",
    "Pull cart",
    "Putting green",
    "Reception hall",
    "Restaurant",
    "Caddie hire",
    "Club fitting"
  ];
}
export default new AmenityCollection();
