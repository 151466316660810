import React, {
  useState,
  useContext,
  useReducer,
  useEffect,
  useMemo,
  createContext
} from "react";
import {
  Row,
  Col,
  Button,
  Popover,
  PopoverBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink
} from "reactstrap";
import PropTypes from "prop-types";

import RatesHelper from "../../../../../common/RatesHelper";
import SeasonDayTypeSetting from "./SeasonDayTypeSetting";
import SeasonTable from "./table/SeasonTable";
import { SeasonContext } from "../Season";
import seasonTableReducer from "./table/seasonTableReducer";
import { ClubCourseContext } from "../../ClubCourse";

/** @type { SeasonScheduleContext } */
export const SeasonScheduleContext = createContext(null);

/**
 * @param { Object } props
 * @param { ScheduleType } props.scheduleType
 * @param { VoidCallback<boolean> } props.toggleEdit
 */
function SeasonSchedule(props) {
  function addInvalidField(field) {
    if (!invalidFields.includes(field)) {
      const array = [...invalidFields, field];
      setInvalidFields(array);
    }
  }

  function getScheduleLabel(scheduleType) {
    switch (scheduleType) {
      case "STANDARD":
        return "Standard rates";
      case "WEEKEND":
        return "Weekend rates";
      default:
        return "Unknown schedule type";
    }
  }

  function removeRates(dayType) {
    const newSeason = { ...season };
    const rates = newSeason.rates.filter(
      (rate) =>
        rate.dayType.type !== dayType &&
        rate.scheduleType === props.scheduleType
    );
    seasonDispatch({ type: `SET_${props.scheduleType}_RATES`, payload: rates });
  }
  function removeAllRates() {
    seasonDispatch({ type: `SET_${props.scheduleType}_RATES`, payload: [] });
  }
  // Handlers
  function handleRemoveSchedule() {
    setShowPopover(false);
    setScheduleEnabled(false);
    removeAllRates();
  }

  function hasRate(rates, dayType) {
    return (
      RatesHelper.find(rates, {
        dayType
      }) !== undefined
    );
  }

  /**
   * @param { DayType } dayType
   * @param { string } startDate
   * @param { string } endDate
   */
  function addRate(
    dayType,
    startDate = season.startDate,
    endDate = season.endDate,
  ) {
    const { scheduleType } = props;
    let rates = RatesHelper.filter([...state.currentState], { scheduleType });
    let rate = {
      dayType,
      scheduleType,
      prices: [],
      startDate,
      endDate
    };
    // setting dayType to be object
    const objectDayType = courseState.dayTypes.find(
      (dayType) => rate.dayType === dayType.type
    );
    rate.dayType = objectDayType;
    seasonDispatch({
      type: `SET_${scheduleType}_RATES`,
      payload: [...rates, rate]
    });
  }

  function onSettingsChangeHandler(dayType, event) {
    const { scheduleType } = props;
    let rates = RatesHelper.filter([...season.rates], { scheduleType });
    let rate = RatesHelper.find(rates, { scheduleType, dayType });
    rate[event.key] = event.value;
    seasonDispatch({ type: `SET_${scheduleType}_RATES`, payload: rates });
  }

  function removeInvalidField(field) {
    if (invalidFields.includes(field)) {
      const index = invalidFields.indexOf(field);
      const array = [...invalidFields];
      array.splice(index, 1);
      setInvalidFields(array);
    }
  }

  const { scheduleType } = props;

  // Context values
  const { season, dispatch: seasonDispatch } = useContext(SeasonContext);
  const { state: courseState } = useContext(ClubCourseContext);
  const rates = RatesHelper.filter(season.rates, {
    scheduleType
  });
  const [state, dispatch] = useReducer(seasonTableReducer, {
    master: season.rates,
    currentState: season.rates
  });
  const [scheduleEnabled, setScheduleEnabled] = useState(rates.length > 0);
  const [invalidFields, setInvalidFields] = useState([]);
  const [editing, setEditing] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  // getting enabled day types
  const enabledDayTypes = useMemo(
    () =>
      courseState.dayTypes
        .filter((dayType) => hasRate(rates, dayType.type))
        .map((dayType) => dayType),
    [rates] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    // exit edit mode if no day types are enabled
    if (!enabledDayTypes.length && editing) {
      setEditing(false);
    }
  }, [enabledDayTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // inform parent if editing or not
    props.toggleEdit(editing);
  }, [editing]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({
      type: "INIT",
      payload: JSON.parse(JSON.stringify(season.rates))
    });
  }, [season.rates]);

  const checkboxId = `season-schedule-${scheduleType}`;

  return (
    <SeasonScheduleContext.Provider
      value={{
        invalidFields,
        removeInvalidField,
        addInvalidField,
        editing,
        setEditing,
        state,
        dispatch,
        enabledDayTypes
      }}
    >
      <div className="SeasonSchedule mt-3">
        <div className="checkbox-group">
          <input
            id={checkboxId}
            type="checkbox"
            checked={scheduleEnabled}
            onChange={(event) => {
              const { checked } = event.target;
              if (scheduleEnabled && !checked && rates.length > 0) {
                setShowPopover(true);
              } else setScheduleEnabled(checked);
            }}
          />
          <label htmlFor={checkboxId}>{getScheduleLabel(scheduleType)}</label>
        </div>
        <Popover placement="right" isOpen={showPopover} target={checkboxId}>
          <PopoverBody>
            This will remove all {getScheduleLabel(scheduleType).toLowerCase()}!
            <div className="mt-1">
              <Button
                className="mr-1"
                type="button"
                outline
                color="light"
                size="sm"
                onClick={() => setShowPopover(false)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                color="danger"
                size="sm"
                onClick={handleRemoveSchedule}
              >
                OK
              </Button>
            </div>
          </PopoverBody>
        </Popover>
        <hr />
        {scheduleEnabled && (
          <Row>
            <Col md={3}>
              {courseState.dayTypes.map((dayType) => {
                let isActive = enabledDayTypes?.some(
                  (enabledDayType) => enabledDayType.type === dayType.type
                );
                return (
                  <SeasonDayTypeSetting
                    showTimeSelection={
                      dayType.type === "STANDARD" ? false : true
                    }
                    key={dayType._id}
                    label={dayType.name}
                    onToggle={(checked, startDate, endDate) => {
                      if (checked) addRate(dayType.type, startDate, endDate);
                      else removeRates(dayType.type);
                    }}
                    onChange={(event) =>
                      onSettingsChangeHandler(dayType.type, event)
                    }
                    dayType={dayType.type}
                    scheduleType={scheduleType}
                    isActive={isActive}
                    editing={editing}
                  />
                );
              })}
            </Col>
            <Col md={9}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === 0 ? "active" : ""}
                    onClick={() => setActiveTab(0)}
                  >
                    18 Hole Rates
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 1 ? "active" : ""}
                    onClick={() => setActiveTab(1)}
                  >
                    9 Hole Rates
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={0}>
                  <SeasonTable scheduleType={scheduleType} roundType="18H" />
                </TabPane>
                <TabPane tabId={1}>
                  <SeasonTable scheduleType={scheduleType} roundType="9H" />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        )}
      </div>
    </SeasonScheduleContext.Provider>
  );
}

SeasonSchedule.propTypes = {
  scheduleType: PropTypes.oneOf(["STANDARD", "WEEKEND"])
};

export default SeasonSchedule;
