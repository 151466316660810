import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import InfoRow from "./InfoRow";
import { AmadeusContext } from "../Amadeus";

const AmadeusDetails = () => {
  const { state } = useContext(AmadeusContext);
  const {
    cardNumber,
    availableBalance,
    expiryDate,
    cvv,
    externalReference,
    startDate,
    creationDate,
    status
  } = state;

  return (
    <>
      <h4>
        <b>Details</b>
      </h4>
      <Row>
        <Col xs="12" lg="6" className="d-flex flex-column align-items-center">
          <InfoRow label="Card Reference" value={externalReference} />
          <InfoRow label="Card Number" value={cardNumber} />
          <InfoRow label="Cvv" value={cvv} />
          <InfoRow label="Available Balance" value={availableBalance?.amount} />
        </Col>
        <Col xs="12" lg="6">
          <InfoRow label="Start date" value={startDate} />
          <InfoRow label="Expiry date" value={expiryDate} />
          <InfoRow label="Creation" value={creationDate} />
          <InfoRow label="State" value={status} />
        </Col>
      </Row>
    </>
  );
};

export default AmadeusDetails;
