// Vendor
import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalFooter
} from "reactstrap";
import { AvForm, AvInput, AvField } from "availity-reactstrap-validation";
import { ObjectId } from "bson";
import { useQuery } from "@apollo/client";

// App
import { IMAGES_BY_TAG } from "../../../common/Queries";
import Loader from "../Loader";
import HighlightImageUploader from "./HighlightImageUploader";
import { HighlightImagePreview } from "./HighlightImagePreview";

function HighlightForm({ isOpen, toggle, onSubmit }) {
  const [type, setType] = useState("CARD");
  const [imageId, setImageId] = useState("");
  const [imageIdList, setImageIdList] = useState([]);

  const { error, loading } = useQuery(IMAGES_BY_TAG, {
    variables: {
      tag: "highlight"
    },
    onCompleted: ({ ImagesByTag }) =>
      setImageIdList(
        ImagesByTag.map(img => img.publicId).sort((a, b) => (a > b ? 1 : -1))
      )
  });

  function clearState() {
    setType("CARD");
    setImageId("");
  }

  function handleValidSubmit(_, values) {
    delete values.newImageId;
    let highlight = {
      _id: new ObjectId().toHexString(),
      ...values,
      type
    };
    onSubmit(highlight);
    toggle();
  }

  function handleNewImage(publicId) {
    setImageIdList([...imageIdList, publicId]);
    setImageId(publicId);
  }

  const Select = () => {
    if (error) return <span color="danger">Failed to load</span>;
    if (loading) return <Loader />;

    return (
      <AvField
        type="select"
        name="imageId"
        required
        className="form-control"
        value={imageId}
        onChange={e => setImageId(e.target.value)}
      >
        <option className="text-muted" value="" disabled>
          Select image
        </option>
        {imageIdList.map((publicId, i) => (
          <option value={publicId} key={i}>
            {publicId.substr(publicId.lastIndexOf("/") + 1)}
          </option>
        ))}
      </AvField>
    );
  };

  return (
    <Modal
      id="modal"
      isOpen={isOpen}
      toggle={toggle}
      centered
      className="w-auto h-auto mw-100 mx-5"
      contentClassName="w-100 h-100"
      onClosed={clearState}
      unmountOnClose={false} // Unmount on close cause AvForm errors
    >
      <AvForm
        beforeSubmitValidation={event => event.stopPropagation()}
        onValidSubmit={handleValidSubmit}
      >
        <ModalHeader toggle={toggle}>Create promoted content</ModalHeader>
        <ModalBody>
          <div className="d-flex align-items-start">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="text-body font-weight-bold">
                  Type
                </InputGroupText>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
                <Button
                  className="btn-highlight-none"
                  type="button"
                  color={type === "CARD" ? "primary" : "outline-light"}
                  onClick={() => setType("CARD")}
                >
                  Card
                </Button>
                <Button
                  className="btn-highlight-none"
                  type="button"
                  color={type === "BANNER" ? "primary" : "outline-light"}
                  onClick={() => setType("BANNER")}
                >
                  Banner
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>

          {type === "CARD" && (
            <div className="mt-3">
              <AvInput type="text" name="title" placeholder="Title" required />
              <AvInput
                className="mt-3"
                type="textarea"
                name="text"
                placeholder="Text"
                required
              />
            </div>
          )}

          <InputGroup className="mt-3">
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <AvInput
                  className="mr-2"
                  name="openInNew"
                  addon
                  type="checkbox"
                />
                Open new tab
              </InputGroupText>
            </InputGroupAddon>
            <AvInput
              name="url"
              type="url"
              placeholder="https://greenfee365.com"
              required
            />
          </InputGroup>

          <div className="row mt-3">
            <div className="col-auto">
              <Select />
            </div>
            <div className="col">
              <AvInput
                name="altTag"
                type="text"
                placeholder="Image alt tag"
                required
              />
            </div>
          </div>

          <HighlightImageUploader onImageUpload={handleNewImage} />

          <HighlightImagePreview imageType={type} imageId={imageId} />
        </ModalBody>

        <ModalFooter>
          <Button className="mr-2 mb-2">Create highlight</Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}

export default HighlightForm;
