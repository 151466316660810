import React, { useContext, useState } from "react";
import { Button, Table } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Loader from "../ui/Loader";
import {
  InvoiceStatusHelper,
  STATUS_OPTIONS,
  TRANSFERED_OPTIONS
} from "../../common/InvoiceStatusHelper";
import moment from "moment";
import { GET_EXCEL_FILE } from "../../common/Queries";
import { LayoutContext } from "../app/Layout";
import { useLazyQuery } from "@apollo/client";
import Info from "../ui/Info";

import { ExportExcelButton } from "./subcomponents/index.js";
import { InvoiceStatusExplanation } from "../../common/InfoContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import InvoiceReportModal from "./subcomponents/InvoiceReportModal";
import { openDocumentInNewWindow } from "../../common/DocumentHelper";

/**
 * @param {array<Invoice>} invoiceList list of invoices
 * @param {boolean} loading loading state
 */
const InvoiceListTable = ({
  invoiceList,
  loading,
  setCourseName,
  buildFilter
}) => {
  const { addAlert } = useContext(LayoutContext);
  const [excelLoading, setExcelLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleGetExcel = () => {
    if (excelLoading) return;
    setExcelLoading(true);
    getExcelFile({
      variables: {
        filter: buildFilter()
      }
    });
  };

  const handleExcelOnCompleted = (res) => {
    setExcelLoading(false);
    if (res.getExcelFile.ok) {
      openDocumentInNewWindow(
        res.getExcelFile.file.encoding,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } else {
      // add alert
      addAlert({
        color: "danger",
        message: res?.getExcelFile?.returnMessage
      });
    }
  };

  // query to get excel file
  const [getExcelFile] = useLazyQuery(GET_EXCEL_FILE, {
    onCompleted: handleExcelOnCompleted,
    onError: () => {
      setExcelLoading(false);
      addAlert({
        color: "danger",
        message: "Error while generating excel file"
      });
    }
  });

  if (loading) return <Loader centered />;

  return (
    <div>
      <Table responsive className="InvoiceTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Course Name</th>
            <th>Order Ref #</th>
            <th>Invoice Ref #</th>
            <th>Created</th>
            <th>Payout date</th>
            <th>Amount</th>
            <th>Commission</th>
            <th>Net Rate (Club)</th>
            <th>
              <div className="d-flex align-items-center">
                <span className="mr-1">Status</span>
                <Info
                  value={<InvoiceStatusExplanation options={STATUS_OPTIONS} />}
                />
              </div>
            </th>
            <th>
              <div className="d-flex align-items-center">
                <span className="mr-1">Funds transferred</span>
                <Info
                  value={
                    <InvoiceStatusExplanation options={TRANSFERED_OPTIONS} />
                  }
                  id="funds-transferred"
                  className="mt-auto"
                />
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invoiceList?.map((invoice) => {
            const isSubInvoice = !!invoice?.referenceTo;

            return (
              <tr key={invoice._id}>
                <td>{invoice.name}</td>
                <td>
                  <Button
                    color="link"
                    className="mt-0 mb-0 p-0"
                    onClick={() => setCourseName(invoice.courseName)}
                  >
                    {invoice.courseName || "-"}
                  </Button>
                </td>
                <td>{invoice.orderReference}</td>
                <td>{invoice.reference}</td>
                <td>{moment(invoice.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                <td>{moment(invoice.payoutDate).format("YYYY-MM-DD HH:mm")}</td>
                <td>{isSubInvoice ? invoice.amountToCharge : invoice.total}</td>
                <td>{invoice.totalCommission ?? "-"}</td>
                <td>{invoice.amountToCharge}</td>
                <td>
                  <div
                    style={{
                      color: InvoiceStatusHelper.getStatusColor(invoice.status)
                    }}
                  >
                    {InvoiceStatusHelper.findStatusByValue(invoice.status)
                      ?.label ?? "N/A"}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      color:
                        InvoiceStatusHelper.getTransferredStatusColor(
                          invoice.fundsTransferred
                        ) ?? "black"
                    }}
                  >
                    {InvoiceStatusHelper.findTransferredOption(
                      invoice.fundsTransferred
                    )?.label ?? "N/A"}
                  </div>
                </td>
                <td>
                  <Link to={`/invoice/${invoice._id}`}>Details</Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="w-100 d-flex justify-content-between mb-4">
        <Button color="secondary" onClick={() => setIsModalOpen(true)}>
          Monthly Report
          <FontAwesomeIcon fixedWidth icon={faFilePdf} title="Report" />
        </Button>
        <ExportExcelButton
          disabled={invoiceList.length === 0}
          onClick={handleGetExcel}
          loading={excelLoading}
        />
      </div>
      <InvoiceReportModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

InvoiceListTable.propTypes = {
  invoiceList: PropTypes.array,
  loading: PropTypes.bool,
  setCourseName: PropTypes.func,
  buildFilter: PropTypes.func
};
export default InvoiceListTable;
