// Vendor
import React, { createContext, useReducer } from "react";
import { useQuery } from "@apollo/client";

// App
import Loader from "../ui/Loader";
import { MISSING_RATES } from "../../common/Queries";
import { rateMismatchReducer } from "./rateMismatchReducer";
import RateMismatchFilterInput from "./RateMismatchFilterInput";
import RateMismatchTable from "./rate-mismatch-table/RateMismatchTable";

export const RateMismatchContext = createContext();
const initialState = {
  missingRateCourses: [],
  providers: [],
  filter: {
    regexFilter: RegExp("", "i"),
    muteFilter: false,
    providerFilter: ""
  }
};

/**
 * Base component for getting & displaying missing rates.
 */
export function RateMismatchComponent() {
  const { loading, error } = useQuery(MISSING_RATES, {
    onCompleted({ missingRates }) {
      dispatch({ type: "INIT", payload: missingRates });
    }
  });

  const [state, dispatch] = useReducer(rateMismatchReducer, initialState);

  function TableContent() {
    if (loading) return <Loader />;
    if (error) return <p className="text-danger">{error.message}</p>;
    return <RateMismatchTable />;
  }

  return (
    <RateMismatchContext.Provider value={{ dispatch, state }}>
      <div className="mt-5">
        <h3>Missing Rates</h3>
        <RateMismatchFilterInput />
        <TableContent />
      </div>
    </RateMismatchContext.Provider>
  );
}
