// Vendor
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * @callback callbackFunc
 * @param { string } sortOn
 */
/**
 * @param { Object } props
 * @param { JSX.Element } props.children Header text or graphical JSX component
 * @param { string } props.activeSorting Currently active sortOn
 * @param { boolean } props.ascending Ascending or descending
 * @param { string } props.sortOn Value
 * @param { callbackFunc } props.onClick onClick handler.
 */
export function SortableHeader({
  children,
  activeSorting,
  ascending,
  sortOn,
  onClick
}) {
  return (
    <th>
      <div className="cursor-pointer" onClick={() => onClick(sortOn)}>
        {children}
        {activeSorting === sortOn && (
          <FontAwesomeIcon
            icon={`sort-${ascending ? "up" : "down"}`}
            className="ml-1"
          />
        )}
      </div>
    </th>
  );
}
