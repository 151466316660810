// Vendor
import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Fade, FormGroup, Label } from "reactstrap";
import Switch from "react-switch";

// App
import {
  CLUBNAME_VALIDATOR,
  OFFSET_VALIDATOR,
  POSITIVE_NUMBER,
  SLUG_VALIDATOR
} from "../../../../common/Validators";
import { InputField } from "../../../ui/FunctionalComponents";
import variables from "../../../../styles/bootstrap/_variables.scss";
import { ButtonGroup } from "reactstrap";
import classnames from "classnames";
import MembershipCollection from "../collections/MembershipCollection";
import { Select } from "../../../ui/FunctionalComponents";
import Timezones from "../../collections/TimezoneCollection";
import CourseTypes from "../../collections/CourseTypeCollection";
import { ClubCourseContext } from "../ClubCourse";
import CurrencySelect from "../../../ui/CurrencySelect";
import SitesSelect from "../../../ui/SitesSelect";
import { ClubContext } from "../../Club";
import { AvField } from "availity-reactstrap-validation";
import { LayoutContext } from "../../../app/Layout";

function ClubCourseGeneralInfo() {
  function setCurrency(event) {
    dispatch({ type: "CURRENCY", payload: event });
  }

  const { state, dispatch } = useContext(ClubCourseContext);
  const { state: clubState } = useContext(ClubContext);
  const {
    architect,
    active,
    cancellationHours,
    payoutHours,
    daysOffset,
    holes,
    membership,
    courseType,
    name,
    par,
    slug,
    localTimeZone,
    tees,
    payOnSite,
    payOnBooking,
    vatIdentificationNumber,
    vatRate,
    currency,
    _id,
    hiddenForSites,
    index,
    commissionType,
    commission,
    isFeaturedOnAlgolia
  } = state;

  const { addAlert } = useContext(LayoutContext);
  const [showSwitchErrorMsg, setShowSwitchErrorMsg] = useState(false);
  const [fieldsBlockingActivation, setFieldsBlockingActivation] = useState([]);
  // Disallow user from activating a course missing critical information
  const courseActivationValidate = () => {
    const faultyFields = [];
    if (!localTimeZone) faultyFields.push("Time zone");
    if (!cancellationHours) faultyFields.push("Cancellation hours");
    if (!tees?.length) faultyFields.push("At least one tee");
    if (!vatIdentificationNumber) faultyFields.push("Vat ID/registration nr");
    if (!currency) faultyFields.push("Currency");
    if (!state.dailyOrderEmail && !clubState.dailyOrderEmail)
      faultyFields.push("Invoice daily order email");
    // TODO:
    if (!state.invoiceEmail && !clubState.invoiceEmail)
      faultyFields.push("Invoice email");
    if (!architect) faultyFields.push("Architect");
    if (!par) faultyFields.push("Par");
    if (!vatRate && payOnBooking) faultyFields.push("Vat Rate");

    if (faultyFields.length) {
      setFieldsBlockingActivation(faultyFields);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (showSwitchErrorMsg) {
      const timer = setTimeout(() => {
        setShowSwitchErrorMsg(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showSwitchErrorMsg]);

  const HolesBtn = ({ value }) => {
    const active = holes === value;
    const className = classnames({
      btn: true,
      "btn-lg": true,
      "btn-primary": active,
      "btn-outline-light": !active,
      "px-3": true
    });

    return (
      <button
        className={className}
        type="button"
        onClick={() => dispatch({ type: "HOLES", payload: value })}
      >
        {value}
      </button>
    );
  };

  return (
    <div className="ClubCourseGeneralInfo mt-3">
      <p className="text-muted mb-4">Course ID: {_id}</p>
      <h3>General</h3>
      <Row form className="d-flex align-items-end">
        <Col md={4}>
          <InputField
            targetKey="name"
            label="Name"
            value={name}
            validate={CLUBNAME_VALIDATOR}
            onChange={(event) =>
              dispatch({ type: "COURSE_NAME", payload: event.value })
            }
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="slug"
            label="Slug"
            value={slug}
            validate={SLUG_VALIDATOR}
            onChange={(event) =>
              dispatch({ type: "SLUG", payload: event.value })
            }
          />
        </Col>
        <Col md={4}>
          <Select
            label="Membership"
            id={`${slug}-membership`}
            value={membership}
            onChange={(e) =>
              dispatch({ type: "MEMBERSHIP", payload: e.target.value })
            }
          >
            {MembershipCollection.items.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Select>
        </Col>
        <Col md={4}>
          <InputField
            required={false}
            targetKey="architect"
            label="Architect"
            value={architect}
            onChange={(event) =>
              dispatch({ type: "ARCHITECT", payload: event.value })
            }
          />
        </Col>
        <Col md={4}>
          <Select
            label="Course type"
            id={`${slug}-coursetype`}
            value={courseType}
            onChange={(e) =>
              dispatch({ type: "COURSE_TYPE", payload: e.target.value })
            }
          >
            {CourseTypes.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Select>
        </Col>
        <Col md={4}>
          <Select
            label="Time zone"
            id={`${slug}-timezone`}
            value={localTimeZone}
            onChange={(e) =>
              dispatch({ type: "LOCAL_TIME_ZONE", payload: e.target.value })
            }
          >
            {Timezones.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Select>
        </Col>
        <Col xs={6} md={2}>
          <CurrencySelect
            value={state.currency}
            onChange={(value) => setCurrency(value)}
          />
        </Col>
        <Col xs={6} md={2}>
          <InputField
            required={false}
            targetKey="cancellationHours"
            label="Cancellation"
            value={cancellationHours}
            type="number"
            onChange={(event) =>
              dispatch({ type: "CANCELLATION_HOURS", payload: event.value })
            }
          />
        </Col>
        <Col xs={6} md={2}>
          <InputField
            required={false}
            targetKey="payoutHours"
            label="Payout Hours"
            validate={POSITIVE_NUMBER}
            value={payoutHours}
            type="number"
            onChange={(event) =>
              dispatch({ type: "PAYOUT_HOURS", payload: event.value })
            }
          />
        </Col>
        <Col xs={6} md={4}>
          <InputField
            required={clubState.teeSheetProvider === "GF365_SIMPLE"}
            targetKey="daysOffset"
            label="Days Offset"
            validate={
              clubState.teeSheetProvider === "GF365_SIMPLE"
                ? OFFSET_VALIDATOR
                : POSITIVE_NUMBER
            }
            value={daysOffset}
            type="number"
            onChange={(event) =>
              dispatch({ type: "DAYS_OFFSET", payload: event.value })
            }
          />
        </Col>
        <Col xs={6} md={2}>
          <InputField
            required={false}
            targetKey="par"
            label="Par"
            value={par}
            type="number"
            onChange={(event) =>
              dispatch({ type: "PAR", payload: event.value })
            }
          />
        </Col>
        <Col xs={6} md={4}>
          <label>Holes</label>
          <ButtonGroup className="form-group">
            <HolesBtn value={9} />
            <HolesBtn value={18} />
            <HolesBtn value={27} />
          </ButtonGroup>
        </Col>
        <Col md={4}>
          <InputField
            required={false}
            targetKey="vatRate"
            label="VAT Rate in percentage"
            value={vatRate}
            type="number"
            onChange={(event) =>
              dispatch({ type: "VAT_RATE", payload: event.value })
            }
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="vatIdentificationNumber"
            label="VAT Identification Number"
            value={vatIdentificationNumber}
            type="text"
            onChange={(event) =>
              dispatch({
                type: "VAT_IDENTIFICATION_NUMBER",
                payload: event.value
              })
            }
          />
        </Col>
        <Col md={4}>
          <Label>Commision Type</Label>
          <AvField
            name="commissionType"
            type="select"
            className="commissionType-select"
            onChange={(e) => {
              if (!e.target.value) return;
              dispatch({ type: "COMMISSION_TYPE", payload: e.target.value });
            }}
            value={commissionType ?? ""}
            required={typeof commission === "number" && commission >= 0}
          >
            {/* Select will be disabled if it has commissionType */}
            <option value="" disabled={commissionType}>
              Select
            </option>
            <option value="FIXED">Fixed</option>
            <option value="PERCENTAGE">%</option>
          </AvField>
        </Col>
        <Col md={4}>
          <Label>Commision value</Label>
          <InputField
            targetKey="commission"
            type="number"
            required={false}
            validate={POSITIVE_NUMBER}
            value={commission}
            onChange={(event) =>
              dispatch({ type: "COMMISSION", payload: event.value })
            }
          />
        </Col>
        <Col xs={6} md={4}>
          <label>Payment</label>
          <FormGroup tag="fieldset">
            <FormGroup check inline>
              <Label check>
                <input
                  className="form-check-input"
                  type="radio"
                  value="option1"
                  name="payOnSite"
                  checked={payOnSite || !payOnBooking}
                  onChange={(event) =>
                    dispatch({ type: "PAY_ON_SITE", payload: event.value })
                  }
                />
                Pay on arrival
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <input
                  className="form-check-input"
                  disabled={!vatIdentificationNumber || !vatRate}
                  type="radio"
                  value="option2"
                  name="payOnBooking"
                  checked={!!payOnBooking}
                  onChange={(event) =>
                    dispatch({ type: "PAY_ON_BOOKING", payload: event.value })
                  }
                />
                Pay on booking
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
        <Col xs={6} md={6}>
          <SitesSelect
            id="hiddenForSites"
            label="Hidden for sites"
            onChange={(value) =>
              dispatch({ type: "HIDDEN_FOR_SITES", payload: value })
            }
            value={hiddenForSites}
          />
        </Col>
        <Col xs={6} md={4}>
          <label htmlFor="swtich-algolia-featured">Featured on Golfers</label>
          <Switch
            id="swtich-algolia-featured"
            checked={isFeaturedOnAlgolia}
            onChange={() => {
              if (!active && !isFeaturedOnAlgolia) {
                return addAlert({
                  message: "Course must be active to be featured on Golfers!",
                  color: "danger"
                });
              }
              dispatch({
                type: "IS_COURSE_FEATURED_ALGOLIA",
                payload: !isFeaturedOnAlgolia
              });
            }}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={variables.primary}
            offColor={variables.gray300}
            width={50}
          />
        </Col>
      </Row>
      <div className="switch-group mt-2 mr-3">
        <Switch
          id="switch-active"
          checked={active}
          onChange={() => {
            if (active || courseActivationValidate()) {
              dispatch({ type: "ACTIVE", payload: !active });
            } else {
              setShowSwitchErrorMsg(true);
            }

            if (!active) {
              dispatch({ type: "INDEX", payload: false });
            }
          }}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={variables.primary}
          offColor={variables.gray300}
          width={50}
        />
        <label htmlFor="switch-active">{"Activated"}</label>
        <Switch
          id="switch-index"
          className="ml-3"
          checked={index}
          onChange={() => {
            dispatch({ type: "INDEX", payload: !index });
          }}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={variables.primary}
          offColor={variables.gray300}
          width={50}
          disabled={active}
        />
        <label
          htmlFor="switch-index"
          className={`${active ? "text-muted" : ""}`}
        >
          {"Index"}
        </label>
      </div>
      <Fade in={showSwitchErrorMsg} className="text-danger">
        Course missing critical information for activation!
        {fieldsBlockingActivation.map((f) => (
          <p key="f" className="my-0 py-0">
            * {f}
          </p>
        ))}
      </Fade>
    </div>
  );
}

export default ClubCourseGeneralInfo;
