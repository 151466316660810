// Vendor
import React from "react";
import { Row, Col, Input, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";

// App
import TimeDropdownGroup from "../../../../ui/TimeDropdownGroup";

function DayAvailabilityList({ availabilityList, onChange }) {
  function handleChange(index, field, value) {
    let availListCopy = [...availabilityList];
    availListCopy[index][field] = value;
    onChange(availListCopy);
  }

  function handleRemoveItem(index) {
    onChange(availabilityList.filter((_, i) => i !== index));
  }

  function handleAddNewRange() {
    const newRangeTemplate = {
      startTime: moment().hour(8).startOf("hour"),
      endTime: moment().hour(17).startOf("hour"),
      interval: 10
    };
    onChange([...availabilityList, newRangeTemplate]);
  }

  return (
    <div className="d-block">
      {availabilityList.map((dayAvail, index) => (
        <Row key={index}>
          <Col>
            <TimeDropdownGroup
              label="Start time"
              date={dayAvail.startTime}
              validateDate={dayAvail.endTime}
              field="startTime"
              className="TimeTable__start-time-input mr-2"
              onChange={event => handleChange(index, event.field, event.value)}
            />
            <TimeDropdownGroup
              label="End time"
              field="endTime"
              date={dayAvail.endTime}
              validateDate={dayAvail.startTime}
              validateBefore
              className="TimeTable__end-time-input"
              onChange={event => handleChange(index, event.field, event.value)}
            />
          </Col>
          <Col>
            <label className="text-left">Interval</label>
            <Input
              type="number"
              bsSize="sm"
              min={1}
              value={dayAvail.interval}
              onChange={event =>
                handleChange(index, "interval", parseInt(event.target.value))
              }
            />
          </Col>
          <Col>
            <Button
              color="link"
              className="text-danger pt-3"
              id={`delete-day-availability-${index}`}
              onClick={() => handleRemoveItem(index)}
            >
              <FontAwesomeIcon icon="trash-alt" className="mr-1" />
            </Button>
          </Col>
        </Row>
      ))}
      {availabilityList.length > 0 && <hr />}
      <Button color="link" onClick={handleAddNewRange}>
        + Add availability range
      </Button>
    </div>
  );
}

DayAvailabilityList.propTypes = {
  onChange: PropTypes.func.isRequired,
  availabilityList: PropTypes.array
};

DayAvailabilityList.defaultProps = {
  availabilityList: []
};

export { DayAvailabilityList };
