// Vendor
import React from "react";

/**
 * @param {string} [className = ""] - className can be used to apply custom styles to the component.
 * @param {string} label - used to pass in the label text that will be displayed above the value.
 * @param {string} value - used to pass in the value text that will be displayed below the label.
 * @param {string} [valueClass = "key-figure mb-0"] - used to pass in a class name for the `dd` element that displays the value text. This class can be used to apply custom styles to the value text.
 * @param {boolean} [defaultClassName = true] - used to toggle the default styles applied to the component. If set to `true`, the component will have a default style of "mx-1 mt-1 mb-2". If set to `false` the component will not have default styles applied.
 * @param {boolean} [customValueStyles] - used for custom styling of the value text.
 */

export default function CardRow({
  className = "",
  label,
  value,
  valueClass = "key-figure mb-0",
  defaultClassName = true,
  customValueStyles
}) {
  return (
    <div className={`${defaultClassName ? "mx-1 mt-1 mb-2" : ""} ${className}`}>
      <dt className="text-black-50 small mb-0">{label}</dt>
      <dd className={valueClass} style={customValueStyles ?? {}}>
        {value}
      </dd>
    </div>
  );
}
