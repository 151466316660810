// Vendor
import React, { Component } from "react";
import ListFactory from "../ui/ListFactory";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
// import { Query } from "react-apollo";
// import { USERS_QUERY } from "../../common/Queries";
// import Loader from "../ui/Loader";

const Languages = {
  ENGLISH: 0,
  SPANISH: 1,
  SWEDISH: 2
};

class Users extends Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      users: [
        {
          firstName: "Gustavo",
          lastName: "Curtem",
          language: Languages.SPANISH,
          hasAccount: true,
          activated: true,
          registerDate: moment()
            .subtract(10, "days")
            .format("ll"),
          lastActivity: moment().format("ll")
        },
        {
          firstName: "Roberto",
          lastName: "Skyttbergo",
          language: Languages.SWEDISH,
          hasAccount: true,
          activated: true,
          registerDate: moment()
            .subtract(10, "days")
            .format("ll"),
          lastActivity: moment().format("ll")
        }
      ]
    };
  }

  render() {
    // return (
    //   <Query query={USERS_QUERY}>
    //     {({ loading, error, data }) => {
    //       if (loading) return <Loader fullscreen={true} />;
    //       if (error) return `Error! ${error.message}`;

    //       console.log(data);

    return (
      <div className="Users">
        <h1>Users</h1>
        <Card>
          <CardBody>
            <ListFactory type="users" items={this.state.users} />
          </CardBody>
        </Card>
      </div>
    );
    //     }}
    //   </Query>
    // );
  }
}

export default Users;
