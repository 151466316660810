import React, { useContext, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import {
  AvField,
  AvForm,
  AvGroup,
  AvInput
} from "availity-reactstrap-validation";
import { LayoutContext } from "../../app/Layout";
import { useMutation } from "@apollo/client";
import { GENERATE_VIRTUAL_CARD } from "../../../common/Mutations";
import moment from "moment";
import {
  CardCurrencyCollection,
  CardTransactionsMax,
  CardValidateDates,
  CardVendorCodeCollection
} from "../../../common/AmadeusCollections";
import DebouncedDropdownCourses from "../subcomponents/DebouncedDropdownCourses";
import { useHistory } from "react-router-dom";
import Info from "../../ui/Info";

const CardCreationModal = ({ toggle, isOpen }) => {
  const { addAlert } = useContext(LayoutContext);
  const history = useHistory();

  const [currency, setCurrency] = useState("");
  const [vendorCode] = useState(CardVendorCodeCollection[1].value);

  const [amount, setAmount] = useState(0);
  const [courseId, setCourseId] = useState(null);
  const [allowedTransactionsMax, setAllowedTransactionsMax] = useState(
    CardTransactionsMax[0].value
  );
  const [overrideExternalReference, setOverrideExternalReference] = useState(
    false
  );
  const [validityEndDate, setValidityEndDate] = useState(() => {
    const date = moment();
    return date.add(3, "M").format("YYYY-MM-DD");
  });

  const handleTransactionChange = (amount) => {
    setAllowedTransactionsMax(Number(amount));
  };

  const handleCourseSelected = (course) => {
    setCourseId(course.id);
  };

  const handleValidityPeriodChange = (e) => {
    switch (e.target.value) {
      // setting date ahead that is between 0 and 6 months
      case "6_MO": {
        setValidityEndDate(moment().add(3, "M").format("YYYY-MM-DD"));
        break;
      }
      // setting date ahead that is between 6 and 12 months
      case "12_MO": {
        setValidityEndDate(moment().add(8, "M").format("YYYY-MM-DD"));
        break;
      }
      default: {
        console.log("Unknown Validity Date!");
        return;
      }
    }
  };

  const handleSubmit = () => {
    let virtualCardPayload = {
      VendorCode: vendorCode,
      Amount: Number(amount),
      CurrencyCode: currency,
      Limitations: {
        AllowedTransactions: {
          Maximum: allowedTransactionsMax
        },
        ValidityPeriod: {
          EndDate: validityEndDate
        }
      }
    };

    let variables = {
      payload: {
        VirtualCard: virtualCardPayload,
        CourseId: courseId,
        OverrideExternalReference: overrideExternalReference
      }
    };

    // if user didn't set allowed transactions then this will create a single spend card
    if (allowedTransactionsMax === 0) {
      delete variables.payload.VirtualCard.Limitations.AllowedTransactions;
    }

    // if user didn't select course then removing courseId
    if (!courseId) {
      delete variables.payload.CourseId;
    }

    // if user didn't select override external reference then removing it
    if (!overrideExternalReference) {
      delete variables.payload.OverrideExternalReference;
    }

    GenerateVirtualCard({ variables });
  };

  const onGenerateCardCompleted = (data) => {
    if (data.GenerateVirtualCard.ok) {
      addAlert({
        color: "success",
        message: data.GenerateVirtualCard.returnMessage
      });
      return history.replace(
        `/amadeus/${data.GenerateVirtualCard.data.externalReference}`
      );
    }
    addAlert({
      color: "danger",
      message:
        data.GenerateVirtualCard.returnMessage ?? "Error while creating card"
    });
  };

  const onGenerateCardError = () => {
    addAlert({ color: "danger", message: "Error while creating card" });
  };

  const [GenerateVirtualCard] = useMutation(GENERATE_VIRTUAL_CARD, {
    onCompleted: onGenerateCardCompleted,
    onError: onGenerateCardError
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Card Creation Modal</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          <FormGroup>
            <AvField
              label="Currency"
              type="select"
              name="currency"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              errorMessage="Please select a currency"
              validate={{ required: { value: true } }}
            >
              {CardCurrencyCollection.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </AvField>
          </FormGroup>
          <FormGroup>
            <Label for="course">Course</Label>
            <DebouncedDropdownCourses
              id="course"
              onSelect={handleCourseSelected}
            />
          </FormGroup>
          <FormGroup>
            <Label for="validityEndDate">Validity Period</Label>
            <AvField
              name="validityEndDate"
              type="select"
              onChange={handleValidityPeriodChange}
            >
              {CardValidateDates.map((period) => (
                <option key={period.value} value={period.value}>
                  {period.label}
                </option>
              ))}
            </AvField>
          </FormGroup>
          <FormGroup>
            <Label for="vendorCode">Vendor Code</Label>
            <AvField
              name="vendorCode"
              type="select"
              errorMessage="Please select a vendor code"
              defaultValue={CardVendorCodeCollection[1].value}
              disabled
            >
              {CardVendorCodeCollection.map((vendorCode) => (
                <option key={vendorCode.value} value={vendorCode.value}>
                  {vendorCode.label}
                </option>
              ))}
            </AvField>
          </FormGroup>
          <FormGroup>
            <Label for="amount">Prefund Amount</Label>
            <AvInput
              id="amount"
              name="amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
            {amount ? (
              <p>
                {`Amount to be loaded on card: ${amount / 100} ${currency}`}
              </p>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label for="allowedTransactionsMax">Max Allowed Transactions</Label>
            <AvField
              name="allowedTransactionsMax"
              type="select"
              onChange={(e) => handleTransactionChange(e.target.value)}
            >
              {CardTransactionsMax.map((transaction) => (
                <option key={transaction.value} value={transaction.value}>
                  {transaction.label}
                </option>
              ))}
            </AvField>
          </FormGroup>
          <FormGroup>
            <AvGroup check>
              <Label check>
                <AvInput
                  disabled={!courseId}
                  type="checkbox"
                  value={overrideExternalReference}
                  name="overrideExternalReference"
                  onClick={() =>
                    setOverrideExternalReference(!overrideExternalReference)
                  }
                />
                <div className="d-flex">
                  Override External Reference
                  <Info
                    value="When it is checked it will override reference on the selected course"
                    className="ml-2"
                  />
                </div>
              </Label>
            </AvGroup>
          </FormGroup>
          <Button>Submit</Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default CardCreationModal;
