// Vendor
import React, { useEffect, useState } from "react";
import { InputGroup } from "reactstrap";
import moment from "moment";
import PropTypes from "prop-types";
import Select from "react-select";

// App
import { getHours, getMinutes } from "../../common/TimeHelper";

function TimeDropdownGroup({
  label,
  date,
  field,
  className,
  validateDate,
  validateBefore,
  onChange
}) {
  // time
  const hours = getHours();
  const minutes = getMinutes();

  const [activeDate, setActiveDate] = useState(moment(date));

  const mValidateDate = moment(validateDate);
  const mValidateHours = mValidateDate.hours();
  const mValidateMinutes = mValidateDate.minutes();
  const mSameHour = activeDate.hours() === mValidateHours;
  const hourMin = validateBefore ? mValidateHours : 0;
  const hourMax = validateBefore ? 24 : mValidateHours;
  const minutesMin = validateBefore ? mValidateMinutes + 1 : 0;
  const minutesMax = validateBefore ? 59 : mValidateMinutes - 1;

  useEffect(() => {
    onChange({ value: activeDate, field });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDate]);

  const handleHourChange = ({ _, value }) => {
    // set hours
    let date = activeDate.clone().hours(value);
    const sameHour = value === mValidateDate.hours();

    if (sameHour) {
      if (activeDate.minutes() > minutesMax) date.minutes(minutesMax);
      if (activeDate.minutes() < minutesMin) date.minutes(minutesMin);
    }

    setActiveDate(date);
  };

  const handleMinuteChange = ({ _, value }) => {
    const date = activeDate.clone().minutes(value);
    setActiveDate(date);
  };

  const TimeDropdown = ({
    value,
    minValue = -1,
    maxValue = -1,
    onChange,
    items
  }) => {
    return (
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={items.map(item => ({
          ...item,
          isDisabled: item.value < minValue || item.value > maxValue
        }))}
        onChange={val => onChange(val)}
        value={{ label: `${value}`.padStart(2, "0"), value }}
        components={{ DropdownIndicator: false }}
      />
    );
  };

  return (
    <div className={`mb-2 d-inline-block ${className}`}>
      <label>{label}</label>
      <InputGroup className="TimeSelectDropdown" size="sm">
        <div className="input-group-prepend">
          <TimeDropdown
            value={activeDate.hours()}
            minValue={hourMin}
            maxValue={hourMax}
            items={hours}
            onChange={handleHourChange}
          />
        </div>
        <div className="input-group-append input-group-prepend position-relative">
          <div className="input-group-text px-1">:</div>
        </div>
        <div className="input-group-append align-items-center">
          <TimeDropdown
            value={activeDate.minutes()}
            items={minutes}
            minValue={mSameHour ? minutesMin : -1}
            maxValue={mSameHour ? minutesMax : 60}
            onChange={handleMinuteChange}
          />
        </div>
      </InputGroup>
    </div>
  );
}
TimeDropdownGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  field: PropTypes.string,
  validateBefore: PropTypes.bool,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment)
  ]).isRequired,
  validateDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment)
  ]).isRequired,
  onChange: PropTypes.func.isRequired
};
TimeDropdownGroup.defaultProps = {
  label: "",
  field: "",
  className: "",
  validateBefore: false
};

export default TimeDropdownGroup;
